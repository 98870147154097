<ul
  role="list"
  class="pl-0">
  <li
    ec-investigation-list-item
    class="mb-5"
    *ngFor="let investigation of list"
    [investigation]="investigation"
    (doEditItem)="doEditItem.emit($event)"
    (doLeaveItem)="doLeaveItem.emit($event)"
    (doDeleteItem)="doDeleteItem.emit($event)"
    (doSelectItem)="doSelectItem.emit($event)"></li>
</ul>
