import {createSelector} from '@ngrx/store';

import {
  NgPatStripeSubscriptionUIdisplay,
  NgPatStripeSubscriptionUIItem,
  selectNgPatActiveStripeSubscriptionsUIDisplay
} from '../+pricing/+stripe';
import {userIsMentor, userIsStudent, userIsTeacher} from '../fns/user.fns';

export const selectStripeUserIsStudent = createSelector(
  selectNgPatActiveStripeSubscriptionsUIDisplay,
  (
    activeSubscriptionDisplay: NgPatStripeSubscriptionUIdisplay | null
  ): boolean => {
    if (activeSubscriptionDisplay) {
      return (
        activeSubscriptionDisplay.items.reduce(
          (isStudent: boolean | null, i: NgPatStripeSubscriptionUIItem) => {
            if (isStudent === null) {
              return userIsStudent(
                parseInt(i.itemPriceProductMetadataFirebaseRole, 10)
              );
            }

            return isStudent;
          },
          null
        ) || false
      );
    }

    return true;
  }
);

export const selectStripeUserIsMentor = createSelector(
  selectNgPatActiveStripeSubscriptionsUIDisplay,
  (
    activeSubscriptionDisplay: NgPatStripeSubscriptionUIdisplay | null
  ): boolean => {
    if (activeSubscriptionDisplay) {
      return (
        activeSubscriptionDisplay.items.reduce(
          (isStudent: boolean | null, i: NgPatStripeSubscriptionUIItem) => {
            if (isStudent === null) {
              return userIsMentor(
                parseInt(i.itemPriceProductMetadataFirebaseRole, 10)
              );
            }

            return isStudent;
          },
          null
        ) || false
      );
    }

    return false;
  }
);

export const selectStripeUserIsTeacher = createSelector(
  selectNgPatActiveStripeSubscriptionsUIDisplay,
  (
    activeSubscriptionDisplay: NgPatStripeSubscriptionUIdisplay | null
  ): boolean => {
    if (activeSubscriptionDisplay) {
      return (
        activeSubscriptionDisplay.items.reduce(
          (isStudent: boolean | null, i: NgPatStripeSubscriptionUIItem) => {
            if (isStudent === null) {
              return userIsTeacher(
                parseInt(i.itemPriceProductMetadataFirebaseRole, 10)
              );
            }

            return isStudent;
          },
          null
        ) || false
      );
    }

    return false;
  }
);
