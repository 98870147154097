import {provideEffects} from '@ngrx/effects';

import * as BookmarkEffects from './+bookmarks/bookmark.effects';
import {ClassroomEffects} from './+classrooms/classroom.effects';
import * as fromClassroom from './+classrooms/classroom.reducer';
import {CourseEffects} from './+courses/course.effects';
import * as fromCourses from './+courses/course.reducer';
import {InvestigationEffects} from './+investigation/investigation.effects';
import * as fromInvestigation from './+investigation/investigation.reducer';
import {JournalEffects} from './+journal/journal.effects';
import * as fromJournal from './+journal/journal.reducer';
import {MembersEffects} from './+members/members.effects';
import * as fromMemberState from './+members/members.reducer';
import {MembersService} from './+members/members.service';
import {NoteEffects} from './+notes/note.effects';
import {NG_PAT_WEB_INITIALIZERS, NG_PAT_WEB_REDUCERS} from './+pricing/+stripe';
import {ProjectEffects} from './+project/project.effects';
import * as fromProject from './+project/project.reducer';
import {QuizEngineEffect} from './+quizzes/quiz-engine/quiz-engine-effect';
import {QuizEffects} from './+quizzes/quiz.effects';
import * as fromQuizzes from './+quizzes/quiz.reducer';
import {ResearchEffects} from './+research/research.effects';
import * as fromResearch from './+research/research.reducer';
import {StudyGroupEffects} from './+study-groups/study-group.effects';
import * as fromStudyGroups from './+study-groups/study-group.reducer';
import {UiEffects} from './+ui/ui.effects';
import {initialUiState, uiFeatureKey} from './+ui/ui.model';
import * as fromUi from './+ui/ui.reducer';
import {EntityEffects} from './entity/entity.effects';
import {
  NG_PAT_FIREBASE_ROOT_EFFECTS,
  NG_PAT_FIREBASE_ROOT_PROVIDERS,
  NG_PAT_FIREBASE_ROOT_REDUCERS,
  NG_PAT_FIREBASE_ROOT_STATE_INITIALIZERS
} from './state';

export const SHARED_NGRX_REDUCERS = {
  ...NG_PAT_FIREBASE_ROOT_REDUCERS, // [accountFeatureKey]: fromAccountState.reducer,
  [fromClassroom.classroomFeatureKey]: fromClassroom.reducer,
  [fromCourses.courseFeatureKey]: fromCourses.reducer,
  [fromInvestigation.investigationFeatureKey]: fromInvestigation.reducer,
  [fromJournal.journalFeatureKey]: fromJournal.reducer,
  [fromMemberState.membersFeatureKey]: fromMemberState.reducer,
  [fromProject.projectFeatureKey]: fromProject.reducer,
  [fromQuizzes.quizFeatureKey]: fromQuizzes.reducer,
  [fromResearch.researchFeatureKey]: fromResearch.reducer,
  [fromStudyGroups.studyGroupFeatureKey]: fromStudyGroups.reducer, // [websocketRegistryFeatureKey]: fromWebsockRegistory.reducer,
  // [fromRemoteConfig.remoteConfigFeatureKey]: fromRemoteConfig.reducer,

  // ...appStoreReducers,

  [uiFeatureKey]: fromUi.reducer, // Stripe
  ...NG_PAT_WEB_REDUCERS
};

export const SHARED_NGRX_INITIALIZERS = {
  ...NG_PAT_FIREBASE_ROOT_STATE_INITIALIZERS,
  [fromClassroom.classroomFeatureKey]: fromClassroom.initialClassroomState,
  [fromCourses.courseFeatureKey]: fromCourses.initialCourseState,
  [fromInvestigation.investigationFeatureKey]:
    fromInvestigation.initialInvestigationState,
  [fromJournal.journalFeatureKey]: fromJournal.initialJournalState,
  [fromMemberState.membersFeatureKey]: fromMemberState.initialMembersState,
  [fromProject.projectFeatureKey]: fromProject.initialProjectState,
  [fromQuizzes.quizFeatureKey]: fromQuizzes.initialQuizState,
  [fromResearch.researchFeatureKey]: fromResearch.initialResearchState,
  [fromStudyGroups.studyGroupFeatureKey]:
    fromStudyGroups.initialStudyGroupState,

  // [websocketRegistryFeatureKey]: initialWebsocketRegistryState,
  // [fromRemoteConfig.remoteConfigFeatureKey]:
  //   fromRemoteConfig.initialRemoteConfigState,

  // ...appStoreInitialStates,

  // [accountFeatureKey]: initialAccountState,
  [uiFeatureKey]: initialUiState, // Stripe
  ...NG_PAT_WEB_INITIALIZERS
};

export const SHARED_NGRX_EFFECTS = [
  ...NG_PAT_FIREBASE_ROOT_EFFECTS,
  QuizEngineEffect, // AccountEffects,
  ClassroomEffects,
  EntityEffects,
  InvestigationEffects,
  JournalEffects,
  CourseEffects,
  ProjectEffects,
  ResearchEffects,
  StudyGroupEffects,
  UiEffects,
  MembersEffects,

  // RemoteConfigEffects,

  QuizEffects,
  NoteEffects,
  BookmarkEffects
];

export const providedEffects = [provideEffects(BookmarkEffects)];

export const SHARED_DATA_ACCESS_PROVIDERS = [
  MembersService,
  ...NG_PAT_FIREBASE_ROOT_PROVIDERS
];
