import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {
  Research,
  selectLoggedInUserIsResearchOwner
} from '@gigasoftware/shared/store';
import {
  DlcImageDirective,
  DlcRoundedIconButtonComponent,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';
import {Store} from '@ngrx/store';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-research-list-item'
    },
    imports: [
        CommonModule,
        DlcImageDirective,
        DlcRoundedIconButtonComponent,
        DlcRoundedTextIconButtonComponent,
        MatIconModule,
        MatMenuModule
    ],
    selector: 'ec-research-list-item, [ec-research-list-item]',
    styleUrl: './ec-research-list-item.component.scss',
    templateUrl: './ec-research-list-item.component.html'
})
export class EcResearchListItemComponent {
  private store: Store = inject(Store);

  @Input() research!: Research;

  @Output() doDeleteItem: EventEmitter<Research> = new EventEmitter<Research>();
  @Output() doEditItem: EventEmitter<Research> = new EventEmitter<Research>();
  @Output() doLeaveItem: EventEmitter<Research> = new EventEmitter<Research>();
  @Output() doSelectItem: EventEmitter<Research> = new EventEmitter<Research>();

  loggedInUserIsOwner: Signal<boolean> = this.store.selectSignal(
    selectLoggedInUserIsResearchOwner
  );

  onDeleteResearch() {
    this.doDeleteItem.emit(this.research);
  }

  onEditResearch() {
    this.doEditItem.emit(this.research);
  }

  onLeaveResearch() {
    this.doLeaveItem.emit(this.research);
  }

  onSelectResearch() {
    this.doSelectItem.emit(this.research);
  }
}
