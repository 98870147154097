import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  Inject,
  Input,
  Output,
  Signal,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {
  GSFirebaseUploadImageWithDocConfig,
  GsFirestoreUpdateDoc
} from '@gigasoftware/shared/media';
import {
  ecQuizImageUploaderConfig,
  firestoreStudyGroupDoc,
  StudyGroup,
  updateFirestorePartialStudyGroup
} from '@gigasoftware/shared/store';
import {
  DlcInputImageComponent,
  DlcInputImageConfig,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';
import {WINDOW} from '@gigasoftware/shared/utils';
import {Store} from '@ngrx/store';

interface StudyGroupform {
  description: string;
  name: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-study-group-edit'
    },
    imports: [
        CommonModule,
        DlcRoundedTextIconButtonComponent,
        DlcInputImageComponent,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule
    ],
    selector: 'ec-study-group-edit',
    styleUrls: ['./ec-study-group-edit.component.scss'],
    templateUrl: './ec-study-group-edit.component.html'
})
export class EcStudyGroupEditComponent {
  private _resetValue: StudyGroupform | null = null;

  studyGroupSig: WritableSignal<StudyGroup | null> = signal(null);

  inputImageConfig: Signal<DlcInputImageConfig | null | undefined> = computed(
    () => {
      const studyGroup: StudyGroup | null | undefined = this.studyGroupSig();

      if (studyGroup) {
        const firestoreDocPath: string = firestoreStudyGroupDoc(studyGroup.id);

        return {
          filenameWithoutExtension: studyGroup.id || '',
          imagePath: studyGroup.imagePath || '',
          uploadConfig: <GSFirebaseUploadImageWithDocConfig>{
            ...ecQuizImageUploaderConfig,
            firestoreDoc: <GsFirestoreUpdateDoc>{
              docProperty: 'imagePath',
              firestoreDocPath
            }
          }
        };
      }

      return null;
    }
  );

  @Output() savedAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  studyGroupFormGroup: UntypedFormGroup;

  constructor(
    private _fb: FormBuilder,
    private store: Store,
    private _cd: ChangeDetectorRef,
    @Inject(WINDOW) private _win: Window
  ) {
    this.studyGroupFormGroup = this._fb.group({
      description: new UntypedFormControl(null),
      name: new UntypedFormControl(null, [Validators.required])
    });
  }

  getErrorMessage(control: AbstractControl) {
    if (control.hasError('required')) {
      return 'You must enter a value';
    }

    return '';
  }

  onDeleteImage() {
    const studyGroup: StudyGroup | null | undefined = this.studyGroupSig();

    if (studyGroup) {
      this.store.dispatch(
        updateFirestorePartialStudyGroup({
          changes: {
            imagePath: null
          },
          studyGroup: studyGroup
        })
      );
    }
  }

  resetNameForm() {
    this.studyGroupFormGroup.reset(this._resetValue, {emitEvent: false});
    this._cd.markForCheck();
  }

  saveForm() {
    if (this.studyGroupFormGroup.valid && this.studyGroupSig()) {
      this.store.dispatch(
        updateFirestorePartialStudyGroup({
          changes: {
            ...this.studyGroupFormGroup.value
          },
          studyGroup: <StudyGroup>this.studyGroupSig()
        })
      );

      this.savedAction.emit(true);
    }
  }

  @Input()
  set studyGroup(s: StudyGroup | null | undefined) {
    if (s) {
      this.studyGroupSig.set(s);

      this._resetValue = {
        description: s.description || '',
        name: s.name
      };

      this.resetNameForm();
    }
  }
}
