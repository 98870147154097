import {BASE_COLLECTION_NAME} from '@gigasoftware/shared/models';

export function firestoreUserJournalCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.JOURNAL}`;
}

export function firestoreUserJournalDoc(
  uid: string,
  journalID: string
): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.JOURNAL}/${journalID}`;
}

export function firestoreJournalCollection() {
  return `${BASE_COLLECTION_NAME.JOURNAL}`;
}
export function firestoreJournalDoc(journalID: string): string {
  return `${BASE_COLLECTION_NAME.JOURNAL}/${journalID}`;
}
export function firestoreJournalChatTopicsCollection(
  journalID: string
): string {
  return `${BASE_COLLECTION_NAME.JOURNAL}/${journalID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}`;
}
export function firestoreJournalChatTopicsDoc(
  journalID: string,
  chatTopicID: string
): string {
  return `${BASE_COLLECTION_NAME.JOURNAL}/${journalID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}/${chatTopicID}`;
}

export function firestoreJournalByIDQuizzesCollection(
  journalID: string
): string {
  return `${BASE_COLLECTION_NAME.JOURNAL}/${journalID}/${BASE_COLLECTION_NAME.QUIZZES}`;
}
