<h1 mat-dialog-title>Confirm Cancel Subscription</h1>
<div mat-dialog-content>
  @if(error()) { An error occurred while trying to cancel your subscription.
  Please try again later. } @else { Are you sure you want to cancel your
  subscription for
  <strong>{{ data.price.title }}</strong> at {{ data.price.price }}/{{
    data.price.interval
  }}
  ? }
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    color="accent"
    cdkFocusInitial
    (click)="onConfirm()">
    Ok
  </button>
  <button
    mat-flat-button
    mat-dialog-close="false"
    color="primary"
    cdkFocusInitial
    class="ml-16">
    Cancel
  </button>
</div>
<mat-progress-bar
  *ngIf="showProgress()"
  mode="indeterminate"
  color="accent"></mat-progress-bar>
