import {Injectable} from '@angular/core';
import {BaseEntity} from '@gigasoftware/shared/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {EMPTY} from 'rxjs';
import {switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {selectNgPatUserAccount} from '../+account/account.selectors';
import {Classroom} from '../+classrooms/classroom.model';
import {getClassroomByID} from '../+classrooms/classroom.selectors';
import {DynamicStoreService} from '../custom-store/dynamic-store';
import {BaseEntityService} from '../entity/base-entity.service';
import {CollaborativeEntity} from '../entity/entity.model';
import {firestoreQueryPathByEntity} from '../firebase/database-paths';
import {createCollaborativeEntity, createEntity} from '../fns/entity.fns';
import {createOwnerUserRole} from '../fns/user.fns';
import {PLATFORM_DYN_STORE} from '../models/dyn-store';
import {UserIdWithRole} from '../models/user.model.deprecated';
import {
  createQuizParams,
  createReviewQuizFromWrongAnswers,
  createSubQuizParams,
  deleteQuizs,
  updateFirestorePartialQuiz
} from './quiz.actions';
import {combineQuestionsAndPath} from './quiz.fns';
import {CreateSubQuizParams, QuestionWithPath, Quiz} from './quiz.model';
import {PartialQuizState} from './quiz.reducer';
import {QuizService} from './quiz.service';

@Injectable({
  providedIn: 'root'
})
export class QuizEffects {
  deleteDocs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteQuizs),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.store.pipe(
            select(getClassroomByID(action.ids[0])),
            switchMap((classRoom: Classroom | undefined) =>
              this.quizService.deleteQuizzesFirestore$(
                classRoom,
                action.ids,
                <string>account.uid
              )
            )
          )
        )
      ),
    {dispatch: false}
  );

  updateFirestorePartialQuiz$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateFirestorePartialQuiz),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.quizService.updatePartialFirestore$(
            action.changes,
            action.quiz,
            account.uid
          )
        )
      ),
    {dispatch: false}
  );

  createReviewQuizFromWrongAnswers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createReviewQuizFromWrongAnswers),
        withLatestFrom(this.store.pipe(select(selectNgPatUserAccount))),
        switchMap(([action, account]) => {
          const firestoreDestinationPath: string = firestoreQueryPathByEntity(
            action.payload.quiz as BaseEntity,
            <string>account.uid
          );

          const questions: QuestionWithPath[] = combineQuestionsAndPath(
            action.payload.questions,
            action.payload.quiz,
            <string>account.uid
          );

          return this.quizService
            .setQuizAndQuestions$(
              firestoreDestinationPath,
              action.payload.quiz,
              questions
            )
            .pipe(
              tap((q: Quiz) => {
                this.dynStore
                  .getStore(PLATFORM_DYN_STORE.QUIZ_CREATED, {
                    isEmitterStore: true
                  })
                  .dispatch(q);
              })
            );
        })
      ),
    {dispatch: false}
  );

  createEntityParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createQuizParams),
        withLatestFrom(this.store.pipe(select(selectNgPatUserAccount))),
        switchMap(([action, userAccount]) => {
          if (userAccount.uid) {
            // const params: CreateQuizParams = {
            //   ...action.params
            // };
            // params.isCollaborative =
            //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id ||
            //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id
            //     ? false
            //     : params.isCollaborative;

            const user: UserIdWithRole = createOwnerUserRole(userAccount.uid);
            let entity: BaseEntity | CollaborativeEntity = action.params
              .isCollaborative
              ? createCollaborativeEntity(user, action.params)
              : createEntity(action.params);

            entity = {
              ...entity,
              ...action.params
            };

            return this.entityService.createEntity$(
              entity,
              action.params,
              userAccount.uid
            );
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  createSubEntityParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createSubQuizParams),
        withLatestFrom(this.store.pipe(select(selectNgPatUserAccount))),
        switchMap(([action, userAccount]) => {
          if (userAccount.uid) {
            const params: CreateSubQuizParams = {
              ...action.params
            };

            const baseParams: CreateSubQuizParams = {
              ...action.params,
              entityTypeValue:
                action.params.parentEntity.entityTypeValue ||
                action.params.parentEntity.entityType
            };

            // params.isCollaborative =
            //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id ||
            //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id
            //     ? false
            //     : params.isCollaborative;

            const user: UserIdWithRole = createOwnerUserRole(userAccount.uid);

            let entity: BaseEntity | CollaborativeEntity =
              params.isCollaborative
                ? createCollaborativeEntity(user, params)
                : createEntity(action.params);

            entity = {
              ...entity,
              ...action.params
            };

            return this.entityService.createSubEntity$(
              entity,
              baseParams,
              params,
              userAccount.uid
            );
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private store: Store<PartialQuizState>,
    private quizService: QuizService,
    private dynStore: DynamicStoreService,
    private entityService: BaseEntityService
  ) {}
}
