import {ThemeConfig} from '@gigasoftware/shared/utils';

export interface EcThemeDict {
  BLUE: string;
  CLASS: string;
  DEFAULT: string;
  GREEN: string;
  STUDY_GROUP: string;
}

export const EC_THEME_CONFIGS: ThemeConfig[] = [
  {
    cssClass: 'ec-default-theme',
    isDark: true,
    isDefault: true,
    name: 'EC Default'
  },
  {
    cssClass: 'ec-class-theme',
    isDark: true,
    isDefault: false,
    name: 'EC Class'
  },
  {
    cssClass: 'ec-study-group-theme',
    isDark: true,
    isDefault: false,
    name: 'EC Study Group'
  },
  {cssClass: 'ec-blue-theme', isDark: true, isDefault: false, name: 'EC Blue'},
  {
    cssClass: 'ec-green-theme',
    isDark: true,
    isDefault: false,
    name: 'EC Green'
  },
  {
    cssClass: 'ec-alert-theme',
    isDark: true,
    isDefault: false,
    name: 'EC Alert Theme'
  },
  {
    cssClass: 'ec-research-theme',
    isDark: true,
    isDefault: false,
    name: 'EC Research Theme'
  },
  {
    cssClass: 'ec-project-theme',
    isDark: true,
    isDefault: false,
    name: 'EC Project Theme'
  },
  {
    cssClass: 'ec-investigation-theme',
    isDark: true,
    isDefault: false,
    name: 'EC Investigation Theme'
  }
];

const defaultTheme =
  EC_THEME_CONFIGS.find(theme => theme.isDefault) || EC_THEME_CONFIGS[0];

/**
 *  Return 'ec-study-group-theme' from ThemeConfig[]
 * @param themes
 */
export function getStudyTheme(themes: ThemeConfig[]) {
  return (
    themes.find((t: ThemeConfig) => t.cssClass === 'ec-study-group-theme') ||
    defaultTheme
  );
}

/**
 *  Return 'ec-class-theme' from
 * @param themes
 */
export function getClassTheme(themes: ThemeConfig[]) {
  return (
    themes.find((t: ThemeConfig) => t.cssClass === 'ec-class-theme') ||
    defaultTheme
  );
}

/**
 *  Return 'ec-research-theme' from
 * @param themes
 */
export function getResearchTheme(themes: ThemeConfig[]) {
  return (
    themes.find((t: ThemeConfig) => t.cssClass === 'ec-research-theme') ||
    defaultTheme
  );
}

/**
 *  Return 'ec-project-theme' from
 * @param themes
 */
export function getProjectTheme(themes: ThemeConfig[]) {
  return (
    themes.find((t: ThemeConfig) => t.cssClass === 'ec-project-theme') ||
    defaultTheme
  );
}

/**
 *  Return 'ec-investigation-theme' from
 * @param themes
 */
export function getInvestigationTheme(themes: ThemeConfig[]) {
  return (
    themes.find((t: ThemeConfig) => t.cssClass === 'ec-investigation-theme') ||
    defaultTheme
  );
}

/**
 *  Return 'ec-default-theme' from
 * @param themes
 */
export function getDefaultThemeConfig(themes: ThemeConfig[]) {
  return (
    themes.find((t: ThemeConfig) => t.cssClass === 'ec-default-theme') ||
    defaultTheme
  );
}

/**
 *  Return 'ec-blue-theme' from
 * @param themes
 */
export function getBlueTheme(themes: ThemeConfig[]) {
  return (
    themes.find((t: ThemeConfig) => t.cssClass === 'ec-blue-theme') ||
    defaultTheme
  );
}

/**
 *  Return 'ec-green-theme' from
 * @param themes
 */
export function getGreenTheme(themes: ThemeConfig[]) {
  return (
    themes.find((t: ThemeConfig) => t.cssClass === 'ec-green-theme') ||
    defaultTheme
  );
}

export function getSelectedTheme(theme: string, themes: ThemeConfig[]) {
  return themes.find((t: ThemeConfig) => t.cssClass === theme);
}
