import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'ng-pat-packages-data',
    imports: [CommonModule],
    templateUrl: './packages-data.component.html',
    styleUrl: './packages-data.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackagesDataComponent {}
