import {
  NgPatFirestoreCollectionQuery,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {GsAssetService, GsImageThumbails} from '@gigasoftware/shared/media';
import {BaseEntity} from '@gigasoftware/shared/models';
import {NgPatProcessQueue} from '@gigasoftware/shared/utils';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import {
  combineLatest,
  firstValueFrom,
  Observable,
  of,
  ReplaySubject
} from 'rxjs';
import {filter, map, switchMap, take, withLatestFrom} from 'rxjs/operators';

import {NgPatAccountState} from '../../../+account/account.model';
import {selectBaseEntityByTypeAndID} from '../../../common.selectors';
import {NgPatEntityStore} from '../../../custom-store/entity-store/ng-pat-entity-store';
import {CollaborativeEntity} from '../../../entity/entity.model';
import {
  firestorePublishedQuizQuestions,
  firestoreQuizGradeByEntity,
  firestoreQuizQuestionsByEntity
} from '../../../firebase/database-paths';
import {aggregateUpdates} from '../../../fns/aggregate-updates';
import {createTestByQuiz} from '../../quiz.fns';
import {
  Question,
  QuestionAction,
  QuestionPropertyAction,
  QuestionWithComputedProperties,
  Quiz,
  TakeQuizResult
} from '../../quiz.model';

export class QuestionsStore {
  /**
   * Quiz data
   */

  quiz$: ReplaySubject<Quiz> = new ReplaySubject<Quiz>(1);
  private _questionCache: NgPatEntityStore<QuestionWithComputedProperties> =
    new NgPatEntityStore();
  /**
   * Question data
   */
  // questions: Signal<Question[]> = <Signal<Question[]>>(
  //   toSignal(this._questionCache.allSelect$)
  // );
  // numberOfQuestions: Signal<number> = <Signal<number>>(
  //   toSignal(this._questionCache.selectTotal$)
  // );
  questions$: Observable<QuestionWithComputedProperties[]> =
    this._questionCache.selectAll$;
  numberOfQuestions$: Observable<number> = this._questionCache.selectTotal$;
  private _questionsFirestore!: NgPatFirestoreCollectionQuery<QuestionWithComputedProperties>;
  private _imageProcessQueue: NgPatProcessQueue<QuestionWithComputedProperties> =
    new NgPatProcessQueue();

  /**
   * User
   */
  // private uid: Signal<string> = this.store.selectSignal(selectNgPatLoggedInUID)

  constructor(
    private store: Store,
    private customFirestoreService: NgPatFirestoreService,
    private assetsService: GsAssetService
  ) {
    const that = this;
    this._questionsFirestore =
      new NgPatFirestoreCollectionQuery<QuestionWithComputedProperties>(
        {
          deleteManyUpdater: (ids: string[]) =>
            that._questionCache.deleteMany(ids),
          queryMember: false,
          updateManyUpdater: (questions: QuestionWithComputedProperties[]) => {
            that._questionCache.updateMany(aggregateUpdates(questions));
            this._imageProcessQueue.addItems(questions);
          },
          upsertManyUpdater: (questions: QuestionWithComputedProperties[]) => {
            that._questionCache.upsertMany(questions);
            this._imageProcessQueue.addItems(questions);
          }
        },
        this.store,
        this.customFirestoreService
      );

    this._imageProcessQueue.currentItem$.subscribe({
      next: (question: QuestionWithComputedProperties) => {
        if (question) {
          const imagePath: string | null | undefined = question.imagePath;

          if (imagePath && imagePath.length > 0) {
            const thumbnailPaths: GsImageThumbails =
              this.assetsService.getImagesThumbnailStoragePaths(imagePath);

            if (thumbnailPaths) {
              this._questionCache.updateOne({
                changes: {
                  ...thumbnailPaths
                },
                id: question.id
              });

              this._imageProcessQueue.next();
            }
          } else {
            this._imageProcessQueue.next();
          }
        }
      }
    });
  }

  saveTest(r: TakeQuizResult) {
    return this.getResultPath(r).pipe(
      switchMap((path: string) => {
        return this.customFirestoreService
          .upsertDoc$(path, r)
          .pipe(map(() => r));
      })
    );
  }

  createTestByQuiz(): Observable<TakeQuizResult> {
    return combineLatest([
      this.customFirestoreService.user$,
      this._questionCache.selectAll$.pipe(
        filter((questions: Question[]) => questions.length > 0)
      )
    ]).pipe(
      filter(([user, questions]) => user !== null && questions !== null),
      switchMap(([user, questions]) =>
        this.quiz$.pipe(
          map((quiz: Quiz) => {
            return createTestByQuiz(quiz, questions, <string>user.uid);
          })
        )
      ),
      take(1),
      switchMap((r: TakeQuizResult) => {
        return this.saveTest(r);
      })
    );
  }

  selectQuestionById$(
    id: string | undefined | null
  ): Observable<QuestionWithComputedProperties | undefined | null> {
    if (id && id.length) {
      return this._questionCache.selectById$(id);
    }

    return of(null);
  }

  onSaveQuestion(a: QuestionAction) {
    this.getQuestionFirestoreDocPath$(a.question)
      .pipe(take(1))
      .subscribe({
        next: (questionDocPath: string | null) => {
          if (questionDocPath) {
            this.customFirestoreService
              .setDoc(questionDocPath, a.question)
              .then();
          }
        }
      });
  }

  onMergeQuestion(a: QuestionPropertyAction): void {
    this.getQuestionFirestoreDocPath$(a.question)
      .pipe(take(1))
      .subscribe((questionDocPath: string | null) => {
        if (questionDocPath) {
          this.customFirestoreService
            .merge$(questionDocPath, a.update)
            .subscribe({
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              next: () => {}
            });
        }
      });
  }

  getQuestionFirestoreDocPath$(question: Question): Observable<string | null> {
    return combineLatest([this.customFirestoreService.user$, this.quiz$]).pipe(
      map(([user, quiz]: [User, Quiz]) => {
        if (user && user.uid) {
          return `${firestoreQuizQuestionsByEntity(
            quiz as BaseEntity,
            user.uid
          )}/${question.id}`;
        }

        return null;
      })
    );
  }

  async onDeleteQuestion(a: QuestionAction) {
    if (a.question.imagePath) {
      await this.assetsService.deleteImagesInSetBasedOnImagePath(
        a.question.imagePath
      );
    }

    this._questionCache.deleteOne(a.question.id);

    const user: User | null = await firstValueFrom(
      this.customFirestoreService.user$
    );
    const quiz: Quiz | null = await firstValueFrom(this.quiz$);

    if (user && user.uid && quiz) {
      const _path = `${firestoreQuizQuestionsByEntity(
        quiz as BaseEntity,
        user.uid
      )}/${a.question.id}`;
      return this.customFirestoreService.deleteDoc(_path);
    }
  }

  getImagePaths$(): Observable<string[]> {
    return this._questionCache.selectAll$.pipe(
      map((questions: QuestionWithComputedProperties[]) => {
        return questions
          .filter((q: QuestionWithComputedProperties) => {
            return q.imagePath !== null && q.imagePath !== undefined;
          })
          .map((q: QuestionWithComputedProperties): string => {
            return <string>q.imagePath;
          });
      })
    );
  }

  setQuiz(quiz: Quiz) {
    this.quiz$.next(quiz);
  }

  async onConnect(user: NgPatAccountState) {
    const quiz: Quiz | null = await firstValueFrom(this.quiz$);

    if (user.uid && quiz) {
      /**
       * Connect to questions
       */
      let questionsPath: string = firestoreQuizQuestionsByEntity(
        quiz as BaseEntity,
        user.uid
      );

      if (quiz.isPublished) {
        questionsPath = firestorePublishedQuizQuestions(quiz);
      }

      this._questionsFirestore.onConnect(questionsPath);
    }
  }

  onDisconnect(user: NgPatAccountState) {
    // Disconnect from questions
    this._questionsFirestore.onDisconnect(user.uid);
  }

  private getResultEntity(): Observable<CollaborativeEntity | Quiz> {
    return this.quiz$.pipe(
      switchMap((quiz: Quiz) => {
        if (
          quiz &&
          quiz.parentEntityID !== null &&
          quiz.parentEntityID !== undefined &&
          quiz.parentEntityType !== null &&
          quiz.parentEntityType !== undefined
        ) {
          return this.store
            .select(
              selectBaseEntityByTypeAndID(
                quiz.parentEntityType,
                quiz.parentEntityID
              )
            )
            .pipe(
              take(1),
              map((entity: CollaborativeEntity | null | undefined) => {
                if (entity) {
                  return entity;
                }

                return quiz;
              })
            );
        } else {
          return of(quiz);
        }
      })
    );
  }

  private getResultPath(r: TakeQuizResult): Observable<string> {
    return this.customFirestoreService.user$.pipe(
      withLatestFrom(this.getResultEntity()),
      map(([user, entity]: [User, BaseEntity]) => {
        return firestoreQuizGradeByEntity(entity, <string>user.uid, r.id);
      })
    );
  }
}
