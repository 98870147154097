import {inject, Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {Bookmark} from '@gigasoftware/shared/models';
import {Store} from '@ngrx/store';

import {NgPatFirestoreQueryStoreAdd} from '../custom-store/firestore-collection-query-store.models';
import {NgPatFirestoreCollectionQueryStore} from '../custom-store/firestore-collection-query-store.service';
import {CollaborativeEntity} from '../entity/entity.model';
import {createBookmarkCollectionQueryConfig} from './bookmark.fns';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  store = inject(Store);
  firestore = inject(NgPatFirestoreService);

  bookmarksQueryStore = new NgPatFirestoreCollectionQueryStore<Bookmark>(
    'bookmarksQueryStore',
    this.firestore,
    this.store
  );

  addBookmarksQuery(
    bookmarks: NgPatFirestoreQueryStoreAdd<CollaborativeEntity>[]
  ) {
    this.bookmarksQueryStore.upsertConfigs(
      bookmarks.map(createBookmarkCollectionQueryConfig)
    );
  }

  deleteBookmarksQuery(
    bookmarks: NgPatFirestoreQueryStoreAdd<CollaborativeEntity>[]
  ) {
    this.bookmarksQueryStore.deleteConfigs(bookmarks.map(b => b.id));
  }

  upsertBookmarksQuery(
    bookmarks: NgPatFirestoreQueryStoreAdd<CollaborativeEntity>[]
  ) {
    this.addBookmarksQuery(bookmarks);
  }
}
