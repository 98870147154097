import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  effect,
  ElementRef,
  inject,
  input,
  InputSignal,
  OnDestroy,
  OnInit,
  signal
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatTabGroup} from '@angular/material/tabs';
import {gsap} from 'gsap';
import {DlcSwipeHorizontalDirection} from './swipe.model';

@Directive({
  selector: '[dlcMatTabSwipe]',
  standalone: true
})
export class DlcMatTabSwipeDirective implements OnInit, OnDestroy {
  private el: ElementRef = inject(ElementRef);
  private destroyRef = inject(DestroyRef);
  private cd: ChangeDetectorRef = inject(ChangeDetectorRef);

  // Host element must be a MatTabGroup, this will
  // inject the instance of the MatTabGroup
  private matTabGroup: MatTabGroup = inject(MatTabGroup);

  mousePositionXStart = 0;
  mousePositionYStart = 0;

  containerCssSelector = '.mat-mdc-tab-body-wrapper';
  dragElement: HTMLElement | null = null;

  private _swipeLockAxis = 'x';

  selectedTab = signal(0);

  dlcMatTabSwipeLockAxis = input('x');
  dlcMatTabSwipeTabGroup: InputSignal<MatTabGroup | null> = input(
    <MatTabGroup | null>null
  );

  constructor() {
    effect(
      () => {
        if (this.matTabGroup) {
          this.matTabGroup.focusTab(this.selectedTab());
          this.matTabGroup.realignInkBar();
          this.matTabGroup.updatePagination();
          this.matTabGroup.selectedIndex = this.selectedTab();
          this.cd.detectChanges();
          // this.cd.markForCheck();
        }
      },
      {allowSignalWrites: true}
    );
  }

  ngOnInit() {
    this.matTabGroup.selectedIndexChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tab: number) => {
        this.selectedTab.set(tab);
      });

    this._swipeLockAxis = this.dlcMatTabSwipeLockAxis();

    if (this._swipeLockAxis !== 'x' && this._swipeLockAxis !== 'y') {
      this._swipeLockAxis = 'x';
    }

    this.addEventListener();
    // matMdcTabBodyWrapper.addEventListener('touchend', this.touchEnd.bind(this));
  }

  addEventListener() {
    this.dragElement = this.el.nativeElement.querySelector(
      this.containerCssSelector
    );

    // if resetting the event listener, remove the old one
    this.dragElement?.removeEventListener(
      'touchstart',
      this.touchStart.bind(this)
    );

    if (this.dragElement) {
      this.dragElement.addEventListener(
        'touchstart',
        this.touchStart.bind(this)
      );
    }
  }

  /**
   * To allow removal of the event listener
   * @param e
   */
  touchStart(e: TouchEvent) {
    // e.preventDefault();
    // e.stopImmediatePropagation();

    this.mousePositionXStart = e.changedTouches[0].clientX;
    this.mousePositionYStart = e.changedTouches[0].clientY;

    if (this._swipeLockAxis === 'x') {
      this.dragX();
    } else {
      // this.dragY();
    }
  }

  dragX() {
    const that = this;

    const timeStart = new Date().getTime();
    let diffX = 0;

    function reset() {
      if (that.dragElement) {
        that.dragElement.removeEventListener('touchmove', onDrag);
        that.dragElement.removeEventListener('touchend', onDragEnd);

        // gsap.set(matMdcTabBodyWrapper, {});
        gsap.to(that.dragElement, {
          translateX: 0,
          delay: 0.15,
          duration: 0.35,
          ease: 'sine.out',
          onComplete: () => {
            gsap.set(that.dragElement, {clearProps: 'transform'});
          }
        });
      } else {
        that.dragElement = that.el.nativeElement.querySelector(
          that.containerCssSelector
        );
        if (that.dragElement) {
          that.dragElement.style.transform = `translateX(0)`;
          gsap.set(that.dragElement, {clearProps: 'transform'});
        }
      }
    }

    function onDrag(e: TouchEvent) {
      // e.preventDefault();
      // e.stopImmediatePropagation();

      const time = new Date().getTime();
      const duration = time - timeStart;

      diffX = e.changedTouches[0].clientX - that.mousePositionXStart;

      if (that.dragElement) {
        // const newPositon = that.mousePositionXStart - matMdcTabBodyWrapper.offsetLeft;
        that.dragElement.style.transform = `translateX(${diffX}px)`;
      } else {
        that.addEventListener();
        reset();
      }

      if (
        duration < 1000 && // Long enough
        Math.abs(diffX) > 50 // Horizontal enough
      ) {
        that.changeActiveTabTabGroupIndex(diffX < 0 ? 'right' : 'left');
        reset();
      }
    }

    function onDragEnd(e: TouchEvent) {
      // e.preventDefault();
      // e.stopImmediatePropagation();
      reset();
    }

    if (that.dragElement) {
      that.dragElement.addEventListener('touchmove', onDrag);
      that.dragElement.addEventListener('touchend', onDragEnd);
    } else {
      that.addEventListener();
      reset();
    }
  }

  changeActiveTabTabGroupIndex(e: DlcSwipeHorizontalDirection): void {
    if (this.matTabGroup) {
      const tabCount = this.matTabGroup._tabs.length;
      const currentTab = this.matTabGroup.selectedIndex;
      const isFirstTab = currentTab === 0;
      const isLastTab = currentTab === tabCount - 1;

      if (e === 'left' && !isFirstTab) {
        this.selectedTab.set(this.selectedTab() - 1);
      }

      if (e === 'right' && !isLastTab) {
        this.selectedTab.set(this.selectedTab() + 1);
      }

      if (e === 'up' && !isFirstTab) {
        this.selectedTab.set(this.selectedTab() - 1);
      }

      if (e === 'down' && !isLastTab) {
        this.selectedTab.set(this.selectedTab() + 1);
      }
    }
  }

  ngOnDestroy() {
    const matMdcTabBodyWrapper = this.el.nativeElement.querySelector(
      '.mat-mdc-tab-body-wrapper'
    );
    matMdcTabBodyWrapper.removeEventListener(
      'touchstart',
      this.touchStart.bind(this)
    );
  }
}
