import {CommonModule} from '@angular/common';
import {Component, ViewEncapsulation} from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-sidenav-content-footer'
    },
    imports: [CommonModule],
    selector: 'dlc-sidenav-content-footer,[dlc-sidenav-content-footer], [dlcSidenavContentFooter]',
    styleUrl: './dlc-sidenav-content-footer.component.scss',
    templateUrl: './dlc-sidenav-content-footer.component.html'
})
export class DlcSidenavContentFooterComponent {}
