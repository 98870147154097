import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {GsAssetService} from '@gigasoftware/shared/media';
import {BaseEntity} from '@gigasoftware/shared/models';
import {
  CollaborativeEntity,
  firestorePublishedQuizDoc,
  firestoreQueryPathByEntity,
  Quiz
} from '@gigasoftware/shared/store';
import {DlcRoundedTextIconButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {User} from 'firebase/auth';

export interface ConfirmDeleteFirestoreAndAssets {
  entity: CollaborativeEntity | Quiz;
  imagePaths: string[];
  message?: string;
  name: string;
  showRemove?: boolean;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [
        CommonModule,
        DlcRoundedTextIconButtonComponent,
        MatDialogModule,
        MatIconModule,
        MatProgressSpinnerModule
    ],
    selector: 'ec-confirm-delete-firestore-and-assets',
    styleUrls: ['./ec-confirm-delete-firestore-and-assets.component.scss'],
    templateUrl: './ec-confirm-delete-firestore-and-assets.component.html'
})
export class EcConfirmDeleteFirestoreAndAssetsComponent {
  deleteError: WritableSignal<string> = signal('');
  hasDeleteError: WritableSignal<boolean> = signal(false);
  isDeleting: WritableSignal<boolean> = signal(false);

  constructor(
    public dialogRef: MatDialogRef<EcConfirmDeleteFirestoreAndAssetsComponent>,
    private customFirestoreService: NgPatFirestoreService,
    private assetService: GsAssetService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteFirestoreAndAssets
  ) {}

  async onDeleteQuizAndAssets() {
    const that = this;

    const user: User | null = this.customFirestoreService.user();
    // Quiz, Classroom, StudyGroup, or BaseEntity
    const entity: BaseEntity | Quiz | undefined = this.data.entity;

    if (user && entity) {
      this.isDeleting.set(true);

      if (this.data.imagePaths.length > 0) {
        const baseImagePaths: Promise<string[]>[] = this.data.imagePaths.map(
          (imagePath: string) => {
            return this.assetService.getDirectoryList(
              this.assetService.getBaseAssetDirectoryFromPath(imagePath)
            );
          }
        );

        const baseImagePathsResults: string[][] = await Promise.all(
          baseImagePaths
        );

        const baseImagePathsFlat: string[] = baseImagePathsResults.flat();

        await this.assetService.deleteAssets(baseImagePathsFlat);
      }

      let path = firestoreQueryPathByEntity(entity, user.uid);

      if ((<Quiz>this.data.entity).isPublished) {
        path = firestorePublishedQuizDoc((<Quiz>this.data.entity).id);
      }

      return this.customFirestoreService
        .recursiveDelete(path)
        .then(() => {
          that.dialogRef.close(true);
        })
        .catch((error: any) => {
          that.isDeleting.set(false);
          that.hasDeleteError.set(true);
          that.deleteError.set(error.message);
        });
    }
  }
}
