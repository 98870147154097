import {ComponentStore} from '@ngrx/component-store';
import {
  Comparer,
  createEntityAdapter,
  Dictionary,
  EntityAdapter,
  EntityMap,
  EntityMapOne,
  EntityState,
  IdSelector,
  Update
} from '@ngrx/entity';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';

export interface DefaultSignalsParams {
  error: any | null;
  isLoaded: boolean;
  isLoading: boolean;
  selectedId: string | number | null;
}

export type NgPatEntityStoreState<T> = EntityState<T> & DefaultSignalsParams;

export interface SignalEntityOptions<T> {
  selectId?: IdSelector<T>;
  sortComparer?: false | Comparer<T>;
}

function selectFirstIdIfNoIdSelected<T>(
  state: EntityState<T> & DefaultSignalsParams
): EntityState<T> & DefaultSignalsParams {
  if (state.selectedId !== null && state.selectedId !== undefined) {
    return state;
  }
  const firstId: string | number | undefined = state.ids[0];
  if (firstId !== undefined) {
    return {...state, selectedId: firstId};
  }
  return state;
}

function selectPreviousIdIfCurrentDeleted<T>(
  state: EntityState<T> & DefaultSignalsParams,
  deletedIds: string[] | number[]
): EntityState<T> & DefaultSignalsParams {
  const selectedId: string | number | null = state.selectedId;
  if (selectedId !== null && selectedId !== undefined && state.ids.length > 0) {
    // .indexOf types only work with string[]
    const deletedIdsContainSelectedId: boolean =
      (<string[]>deletedIds).indexOf(<string>selectedId) > -1;

    if (!deletedIdsContainSelectedId) {
      return state;
    }

    // .indexOf types only work with string[]
    const previousId: string | number | never =
      state.ids[(<string[]>state.ids).indexOf(<string>selectedId) - 1];
    const nextId: string | number | never =
      state.ids[(<string[]>state.ids).indexOf(<string>selectedId) + 1];

    if (previousId !== undefined) {
      return {...state, selectedId: previousId};
    } else if (nextId !== undefined) {
      return {...state, selectedId: nextId};
    } else {
      return {...state, selectedId: null};
    }
  }

  return state;
}

function defaultIdSelector<T>(entity: T | any): string {
  return entity['id'];
}

export class NgPatEntityStore<T> extends ComponentStore<
  NgPatEntityStoreState<T>
> {
  adapter: EntityAdapter<T>;

  // readonly state: Signal<NgPatEntityStoreState<T>> = <
  //   Signal<NgPatEntityStoreState<T>>
  // >toSignal(this.state$);

  idSelector: IdSelector<T> = defaultIdSelector;
  sortComparer: false | Comparer<T> = false;

  // Used to clear state
  defaultProps = {};
  addOne = this.updater(
    (state: NgPatEntityStoreState<T>, value: T): NgPatEntityStoreState<T> => {
      return selectFirstIdIfNoIdSelected(this.adapter.addOne(value, state));
    }
  );
  setOne = this.updater(
    (state: NgPatEntityStoreState<T>, value: T): NgPatEntityStoreState<T> => {
      return selectFirstIdIfNoIdSelected(this.adapter.setOne(value, state));
    }
  );
  upsertOne = this.updater(
    (state: NgPatEntityStoreState<T>, value: T): NgPatEntityStoreState<T> => {
      return selectFirstIdIfNoIdSelected(this.adapter.upsertOne(value, state));
    }
  );
  addMany = this.updater(
    (state: NgPatEntityStoreState<T>, value: T[]): NgPatEntityStoreState<T> => {
      return selectFirstIdIfNoIdSelected(this.adapter.addMany(value, state));
    }
  );
  upsertMany = this.updater(
    (state: NgPatEntityStoreState<T>, value: T[]): NgPatEntityStoreState<T> => {
      return selectFirstIdIfNoIdSelected(this.adapter.upsertMany(value, state));
    }
  );
  updateOne = this.updater(
    (
      state: NgPatEntityStoreState<T>,
      value: Update<T>
    ): NgPatEntityStoreState<T> => {
      return this.adapter.updateOne(value, state);
    }
  );
  mapOne = this.updater(
    (
      state: NgPatEntityStoreState<T>,
      entityMap: EntityMapOne<T>
    ): NgPatEntityStoreState<T> => {
      return this.adapter.mapOne(entityMap, state);
    }
  );
  map = this.updater(
    (
      state: NgPatEntityStoreState<T>,
      entityMap: EntityMap<T>
    ): NgPatEntityStoreState<T> => {
      return this.adapter.map(entityMap, state);
    }
  );
  deleteOne = this.updater(
    (state: NgPatEntityStoreState<T>, id: string): NgPatEntityStoreState<T> => {
      return selectPreviousIdIfCurrentDeleted(
        this.adapter.removeOne(id, state),
        [id]
      );
    }
  );
  removeMany = this.updater(
    (
      state: NgPatEntityStoreState<T>,
      ids: string[]
    ): NgPatEntityStoreState<T> => {
      return selectPreviousIdIfCurrentDeleted(
        this.adapter.removeMany(ids, state),
        ids
      );
    }
  );
  deleteMany = this.updater(
    (
      state: NgPatEntityStoreState<T>,
      id: string[]
    ): NgPatEntityStoreState<T> => {
      return selectFirstIdIfNoIdSelected(this.adapter.removeMany(id, state));
    }
  );
  setAll = this.updater(
    (state: NgPatEntityStoreState<T>, value: T[]): NgPatEntityStoreState<T> => {
      return selectFirstIdIfNoIdSelected(this.adapter.setAll(value, state));
    }
  );
  setMany = this.updater(
    (state: NgPatEntityStoreState<T>, value: T[]): NgPatEntityStoreState<T> => {
      return selectFirstIdIfNoIdSelected(this.adapter.setMany(value, state));
    }
  );
  updateMany = this.updater(
    (
      state: NgPatEntityStoreState<T>,
      value: Update<T>[]
    ): NgPatEntityStoreState<T> => {
      return this.adapter.updateMany(value, state);
    }
  );
  removeOne = this.updater(
    (state: NgPatEntityStoreState<T>, id: string): NgPatEntityStoreState<T> => {
      return selectFirstIdIfNoIdSelected(this.adapter.removeOne(id, state));
    }
  );
  removeAll = this.updater((state): NgPatEntityStoreState<T> => {
    return this.adapter.removeAll({
      ...state,
      ...this.defaultProps
    });
  });
  private _selectAll$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  selectAll$ = this._selectAll$.asObservable();
  private _selectEntities$: BehaviorSubject<Dictionary<T>> =
    new BehaviorSubject<Dictionary<T>>({});
  selectEntities$ = this._selectEntities$.asObservable().pipe(
    filter((all: Dictionary<T>) => {
      return Object.values(all).length > 0;
    })
  );
  private _selectIds$: BehaviorSubject<string[] | number[]> =
    new BehaviorSubject<string[] | number[]>([]);
  selectIds$ = this._selectIds$
    .asObservable()
    .pipe(filter((all: string[] | number[]) => all.length > 0));
  private _selectTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  selectTotal$ = this._selectTotal$.asObservable();

  constructor(additionalProps = {}, _options?: SignalEntityOptions<T>) {
    super({
      entities: {},
      error: null,
      ids: [],
      isLoaded: false,
      isLoading: true,
      selectedId: null,
      ...additionalProps
    });

    if (_options && _options.selectId) {
      this.idSelector = _options.selectId;
    }

    if (_options && _options.sortComparer) {
      this.sortComparer = _options.sortComparer;
    }

    this.adapter = createEntityAdapter<T>({
      selectId: this.idSelector,
      sortComparer: this.sortComparer
    });

    this.setState(
      this.adapter.getInitialState(<NgPatEntityStoreState<T>>{
        error: null,
        isLoaded: false,
        isLoading: true,
        selectedId: null,
        ...additionalProps
      })
    );

    this.defaultProps = {
      error: null,
      isLoaded: false,
      isLoading: true,
      selectedId: null,
      ...additionalProps
    };

    const {selectAll, selectEntities, selectIds, selectTotal} =
      this.adapter.getSelectors();

    this.select(selectIds).subscribe({
      next: (ids: string[] | number[]) => {
        this._selectIds$.next(ids);
      }
    });

    this.select(selectAll).subscribe({
      next: (entities: T[]) => {
        this._selectAll$.next(entities);
      }
    });

    this.select(selectEntities).subscribe({
      next: (entities: Dictionary<T>) => {
        this._selectEntities$.next(entities);
      }
    });

    this.select(selectTotal).subscribe({
      next: (total: number) => {
        this._selectTotal$.next(total);
      }
    });
  }

  selectId(id: string | number) {
    this.patchState({selectedId: id});
  }

  /**
   * Selects the first entity in the collection if no entity is selected.
   */
  selectFirstIdIfNoIdSelected(): void {
    const selectedId: string | number | null = this.state().selectedId;
    const ids: string[] | number[] = this.state().ids;
    if (ids.length > 0) {
      if (selectedId !== null && selectedId !== undefined) {
        this.selectId(ids[0]);
      }
    }
  }

  selectedId$(): Observable<string | number | null> {
    return this.select((state: NgPatEntityStoreState<T>) => {
      return state.selectedId;
    });
  }

  selectedEntity$(): Observable<T | undefined | null> {
    return this.select((state: NgPatEntityStoreState<T>) => {
      if (state.selectedId) {
        return state.entities[state.selectedId];
      }
      return null;
    });
  }

  selectItemById$(id: string | number): Observable<T | undefined> {
    return <Observable<T | undefined>>this.select(
      (state: NgPatEntityStoreState<any>) => {
        return state.entities[id];
      }
    );
  }

  clear(): void {
    // state state
    const state: NgPatEntityStoreState<T> = this.state();
    // clear state
    this.setState(
      this.adapter.removeAll({
        ...state,
        ...this.defaultProps
      })
    );
  }

  has$ = (id: string): Observable<boolean> => {
    return this.selectItemById$(id).pipe(
      map((found: T | null | undefined) => {
        return found !== null && found !== undefined;
      })
    );
  };

  has = (id: string): boolean => {
    return this.get((state: NgPatEntityStoreState<T>) => {
      return state.entities[id] !== null && state.entities[id] !== undefined;
    });
  };

  hasEntity$ = (entity: T): Observable<boolean> => {
    return this.selectItemById$(this.idSelector(entity)).pipe(
      map((found: T | null | undefined) => {
        return found !== null && found !== undefined;
      })
    );
  };

  hasEntity = (entity: T): boolean => {
    return this.get((state: NgPatEntityStoreState<T>) => {
      return (
        state.entities[this.idSelector(entity)] !== null &&
        state.entities[this.idSelector(entity)] !== undefined
      );
    });
  };

  selectById$(id: string | number | null): Observable<T | null | undefined> {
    if (id !== null && id !== undefined) {
      return this.select((state: NgPatEntityStoreState<T>) => {
        return state.entities[id];
      });
    }

    return of(null);
  }

  selectById(id: string | number): T | null | undefined {
    return this.get((state: NgPatEntityStoreState<T>) => {
      return state.entities[id];
    });
  }

  setError(error: any) {
    this.patchState({error});
  }

  /**
   * Selects the first entity in the collection.
   */
  selectFirstId(): void {
    const ids: string[] | number[] = this.state().ids;
    if (ids.length > 0) {
      this.selectId(ids[0]);
    }
  }

  /**
   * Selects the next entity in the collection or first if the current entity is the last.
   */
  next(): void {
    const selectedId: string | number | null = this.state().selectedId;
    const ids: string[] | number[] = this.state().ids;
    if (ids.length > 0) {
      if (ids.length > 0) {
        if (selectedId !== null && selectedId !== undefined) {
          const index = ids.findIndex((i: string | number) => i === selectedId);
          if (index !== -1) {
            const nextIndex = index + 1;
            if (nextIndex < ids.length) {
              this.selectId(ids[nextIndex]);
            } else {
              this.selectId(ids[0]);
            }
          } else {
            this.selectId(ids[0]);
          }
        } else {
          this.selectId(ids[0]);
        }
      }
    }
  }

  /**
   * Selects the previous entity in the collection or last if the current entity is the first.
   */
  previous(): void {
    const selectedId: string | number | null = this.state().selectedId;
    const ids: string[] | number[] = this.state().ids;
    if (ids.length > 0) {
      if (selectedId !== null && selectedId !== undefined) {
        const index = ids.findIndex((i: string | number) => i === selectedId);
        if (index !== -1) {
          const previousIndex = index - 1;
          if (previousIndex >= 0) {
            this.selectId(ids[previousIndex]);
          } else {
            this.selectId(ids[ids.length - 1]);
          }
        } else {
          this.selectId(ids[ids.length - 1]);
        }
      } else {
        this.selectId(ids[ids.length - 1]);
      }
    }
  }

  /**
   * Compare an entity to an entity in the store
   * with the same id.
   *
   * @param updatedEntity
   * @param compareFn
   */
  compare$(
    updatedEntity: T | any,
    compareFn: (updatedEntity: T, cachedEntity: T) => boolean
  ) {
    return this.selectItemById$(
      updatedEntity[this.idSelector(updatedEntity)]
    ).pipe(
      map((found: T | undefined) => {
        if (found) {
          return compareFn(updatedEntity, found);
        }

        return false;
      })
    );
  }

  selectIsFirstEntitySelected$(): Observable<boolean> {
    return this.select((state: NgPatEntityStoreState<T>) => {
      return state.selectedId === state.ids[0];
    });
  }

  // selectIsFirstEntitySelected: Signal<boolean> = <Signal<boolean>>(
  //   toSignal(this.selectIsFirstEntitySelected$())
  // );

  selectIsLastEntitySelected$(): Observable<boolean> {
    return this.select((state: NgPatEntityStoreState<T>) => {
      return state.selectedId === state.ids[state.ids.length - 1];
    });
  }

  /**
   * Returns entities that have been deleted from the store
   * for post-processing.
   *
   * Rather than using and effect to process deleted entities,
   * this method allows you to process deleted entities in other
   * locations in the app such as other feature services or components.
   */
  deletedEntities$(): Observable<T[]> {
    let currentEntities: Dictionary<T> = {};

    return this.select((state: NgPatEntityStoreState<T>) => {
      const entities = state.entities;

      const deletedEntities = Object.keys(currentEntities).reduce(
        (result: {[key: string]: T}, key: string) => {
          if (!entities[key] && currentEntities[key]) {
            result[key] = <T>currentEntities[key];
          }
          return result;
        },
        {}
      );

      currentEntities = {
        ...entities
      };

      return Object.values(deletedEntities);
    });
  }
}
