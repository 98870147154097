import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {
  selectNgPatLoggedInUID,
  selectNgPatMentorAccountsDict
} from '../+account/account.selectors';
import {NG_PAT_ROLES} from '../+account/user.model';
import {
  getMemberListItemsByEntityID,
  selectAllMemberss
} from '../+members/index';
import {convertCollaborativeEntityToMemberUIDEntityID} from '../+members/member.fns';
import {UiState} from '../+ui/ui.model';
import {selectCurrentJournalID, selectUiState} from '../+ui/ui.selectors';
import {getCreatedAtNanoseconds} from '../fns/get-created-at-nanoseconds-prop';
import {isCreatedBy} from '../fns/user.fns';
import {selectNgPatAddedAndDeletedEntities} from '../selectors';
import {selectHasActiveSubscription} from '../subscription/subscription.selectors';
import {Journal} from './journal.model';
import * as JournalReducer from './journal.reducer';
import {JournalState} from './journal.reducer';

export const selectJournalState =
  createFeatureSelector<JournalReducer.JournalState>(
    JournalReducer.journalFeatureKey
  );

const {selectAll, selectEntities, selectIds, selectTotal} =
  JournalReducer.journalAdapter.getSelectors();

export const selectAllJournals = createSelector(selectJournalState, selectAll);
export const selectJournalEntities = createSelector(
  selectJournalState,
  selectEntities
);
export const selectJournalIds = createSelector(selectJournalState, selectIds);
export const selectJournalTotal = createSelector(
  selectJournalState,
  selectTotal
);
export const selectedJournalID = createSelector(
  selectJournalState,
  (state: JournalReducer.JournalState) => state.selectedJournalID
);

export const selectJournalMemberMap = createSelector(
  selectAllJournals,
  convertCollaborativeEntityToMemberUIDEntityID
);

export const selectedJournal = createSelector(
  selectJournalState,
  (state: JournalState) => {
    if (state.selectedJournalID) {
      return state.entities[state.selectedJournalID];
    } else {
      return null;
    }
  }
);

export const getJournalByID = (journalID: string | null) =>
  createSelector(
    selectJournalState,
    (state: JournalReducer.JournalState): Journal | undefined => {
      if (journalID && state.entities[journalID]) {
        return state.entities[journalID];
      }

      return undefined;
    }
  );

export const getJournalByTimestamp = (nanoseconds: string | null | undefined) =>
  createSelector(selectAllJournals, (journals: Journal[]) => {
    return journals.reduce(
      (found: Journal | null | undefined, i: Journal | undefined) => {
        if (!found && i && getCreatedAtNanoseconds(i) === nanoseconds) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const getJournalById = (id: string | null | undefined) =>
  createSelector(selectAllJournals, (journals: Journal[]) => {
    return journals.reduce(
      (found: Journal | null | undefined, i: Journal | undefined) => {
        if (!found && i && i.id === id) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const selectJournalsIoOwn = createSelector(
  selectNgPatLoggedInUID,
  selectAllJournals,
  (uid: string | null, journals: Journal[]) => {
    if (uid && uid.length) {
      return journals.filter((s: Journal) =>
        uid ? isCreatedBy(s, uid) : false
      );
    }

    return [];
  }
);

export const selectCurrentJournal = createSelector(
  selectUiState,
  selectJournalEntities,
  (
    state: UiState,
    journalEntities: Dictionary<Journal>
  ): Journal | null | undefined => {
    if (state.currentJournalID && journalEntities[state.currentJournalID]) {
      return state.currentJournalID
        ? journalEntities[state.currentJournalID]
        : null;
    }
    return null;
  }
);

export const selectCurrentJournalMembers = createSelector(
  selectCurrentJournalID,
  selectHasActiveSubscription,
  selectNgPatMentorAccountsDict,
  selectAllMemberss,
  getMemberListItemsByEntityID
);

export const canInviteToJournal = createSelector(
  selectCurrentJournal,
  selectNgPatLoggedInUID,
  (
    journal: Journal | null | undefined,
    loggedInUID: string | null
  ): boolean => {
    if (
      loggedInUID &&
      journal &&
      journal.members &&
      journal.members[loggedInUID]
    ) {
      return journal.members[loggedInUID].role === NG_PAT_ROLES.Owner;
    }
    return false;
  }
);

export const selectAddedAndDeletedJournals =
  selectNgPatAddedAndDeletedEntities<Journal>(selectJournalEntities);
