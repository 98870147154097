import {Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {BaseEntity} from '@gigasoftware/shared/models';

import {
  firestoreEntityPathByParams,
  firestoreEntitySubPath
} from '../firebase/database-paths';
import {
  CollaborativeEntity,
  CreateBaseSubEntityParams,
  CreateEntityParams
} from './entity.model';

@Injectable({
  providedIn: 'root'
})
export class BaseEntityService {
  constructor(private customFirestoreService: NgPatFirestoreService) {}

  createEntity$(
    entity: BaseEntity | CollaborativeEntity,
    params: CreateEntityParams,
    uid: string
  ) {
    entity.firestoreDocumentPath = firestoreEntityPathByParams(
      params,
      uid,
      entity.id
    );

    // remove last part of path
    entity.firestoreCollectionPath = entity.firestoreDocumentPath.substring(
      0,
      entity.firestoreDocumentPath.lastIndexOf('/')
    );

    return this.customFirestoreService.upsertDoc$(
      entity.firestoreDocumentPath,
      entity
    );
  }

  createSubEntity$(
    entity: BaseEntity | CollaborativeEntity,
    baseParams: CreateBaseSubEntityParams,
    entityParams: CreateBaseSubEntityParams,
    uid: string
  ) {
    entity.firestoreDocumentPath = `${firestoreEntityPathByParams(
      baseParams,
      uid,
      baseParams.parentEntity.id
    )}/${firestoreEntitySubPath(entityParams, entity.id)}`;

    entity.firestoreCollectionPath = entity.firestoreDocumentPath.substring(
      0,
      entity.firestoreDocumentPath.lastIndexOf('/')
    );

    return this.customFirestoreService.upsertDoc$(
      entity.firestoreDocumentPath,
      entity
    );
  }
}
