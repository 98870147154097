import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-rounded-letter'
    },
    imports: [CommonModule],
    selector: 'dlc-rounded-letter, [dlcRoundedLetter]',
    styleUrl: './dlc-rounded-letter.component.scss',
    templateUrl: './dlc-rounded-letter.component.html'
})
export class DlcRoundedLetterComponent {}
