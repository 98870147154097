import {Inject, Injectable} from '@angular/core';
import {concatLatestFrom} from '@ngrx/operators';
import {IAP_CONFIG, IAPConfig} from './injection-tokens';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {EMPTY, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {
  ngPatBuyNativeAction,
  ngPatCancelNativePurchaseAction
} from '../normalized.actions';
import {NgPatNormalizedOffer} from '../normalized-price.model';
import {
  appStorePurchaseSuccess,
  loadHardCodedSubscriptionToIAPProducts,
  onAppStoreInitEffect,
  updateAppstoreInAppPurchases
} from './in-app-purchase.actions';
import {IAPProduct} from './in-app-purchase.model';
import {selectAllAppstoreInAppProducts} from './in-app-purchase.selectors';
import {NgPatInAppPurchaseService} from './ng-pat-in-app-purchase.service';

@Injectable({providedIn: 'root'})
export class NgPatInAppPurchaseEffects implements OnInitEffects {
  onAppStoreInitEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onAppStoreInitEffect),
      map(() => {
        return loadHardCodedSubscriptionToIAPProducts({
          hardCodedSubscriptionProducts: this.iapConfig.subscriptionProducts
        });
      })
    );
  });

  iapBuyEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ngPatBuyNativeAction),
        tap(({price}: {price: NgPatNormalizedOffer}) => {
          this.iapStore.orderOffer(price);
        })
      );
    },
    {dispatch: false}
  );

  appStorePurchaseSuccessEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appStorePurchaseSuccess),
      concatLatestFrom(() => this.store.select(selectAllAppstoreInAppProducts)),
      switchMap(([action, products]: [{productId: string}, IAPProduct[]]) => {
        const product = products.find(p => p.id === action.productId);
        if (product) {
          return of(
            updateAppstoreInAppPurchases({
              appstoreInAppPurchases: [{id: product.id, changes: {owned: true}}]
            })
          );
        }

        return EMPTY;
      })
    );
  });

  iapCancelPurchaseEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ngPatCancelNativePurchaseAction),
        tap(({price}: {price: NgPatNormalizedOffer}) => {
          this.iapStore.orderOffer(price);
        })
      );
    },
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private iapStore: NgPatInAppPurchaseService,
    private store: Store,
    @Inject(IAP_CONFIG) private iapConfig: IAPConfig
  ) {}

  ngrxOnInitEffects(): Action {
    return onAppStoreInitEffect();
  }
}
