import {NgIf} from '@angular/common';
import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf],
    selector: 'ui-student-subscription-text',
    styleUrls: ['./student-subscription-text.component.scss'],
    templateUrl: './student-subscription-text.component.html'
})
export class StudentSubscriptionTextComponent {
  @Input() showTitle = false;
}
