import {
  Directive,
  ElementRef,
  EventEmitter,
  Injectable,
  NgZone,
  OnDestroy,
  Output
} from '@angular/core';
import {Observable, ReplaySubject, Subject, Subscription, timer} from 'rxjs';
import {debounceTime, map, takeUntil} from 'rxjs/operators';
import {NgPatResizeObserverEntry} from './chart.models';
import {WINDOW_PROVIDERS, WindowService} from '@gigasoftware/shared/utils';

export class NgPatBaseResizeObserver {
  entries$: ReplaySubject<ResizeObserverEntry[]> = new ReplaySubject(1);
  contentRect$: ReplaySubject<DOMRectReadOnly> = new ReplaySubject(1);

  resizeObserver: ResizeObserver;

  constructor() {
    this.resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        this.entries$.next(entries);

        /**
         * Get contentRect from an ResizeObserverEntry
         */
        const contentRect = entries[0].contentRect;
        this.contentRect$.next(contentRect);
      }
    );
  }

  entries<T>(
    fn: (entries: ResizeObserverEntry[]) => T,
    debounceTimeValue = 0
  ): Observable<T> {
    return this.entries$.pipe(map(fn), debounceTime(debounceTimeValue));
  }

  disconnect() {
    this.resizeObserver.disconnect();
  }

  unobserve(target: Element) {
    this.resizeObserver.unobserve(target);
  }

  observe(target: Element, options?: ResizeObserverOptions) {
    const rect: DOMRect = target.getBoundingClientRect();
    this.contentRect$.next(rect);

    const borderBoxSize: ResizeObserverSize = {
      blockSize: rect.height,
      inlineSize: rect.width
    };

    const contentBoxSize: ResizeObserverSize = {
      blockSize: rect.height,
      inlineSize: rect.width
    };

    const devicePixelContentBoxSize: ResizeObserverSize = {
      blockSize: rect.height,
      inlineSize: rect.width
    };

    const entry: ResizeObserverEntry = Object.freeze({
      borderBoxSize,
      contentBoxSize,
      devicePixelContentBoxSize,
      contentRect: rect,
      target
    }) as any;

    this.entries$.next([entry]);

    this.resizeObserver.observe(target, options);
  }
}

@Directive({
  standalone: true,
  selector: '[ngPatResizeObserver]'
})
export class NgPatResizeObserverDirective implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public resize = new EventEmitter<DOMRectReadOnly>();

  public observer: NgPatBaseResizeObserver = new NgPatBaseResizeObserver();

  constructor(private el: ElementRef) {
    this.observer.observe(this.el.nativeElement);

    this.observer.contentRect$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((rect: DOMRectReadOnly) => {
        this.resize.emit(rect);
      });
  }

  ngOnDestroy() {
    this.observer.unobserve(this.el.nativeElement);
    this._onDestroy$.next(true);
  }
}
