/**
 * One Time Login ID
 * Saved to firestore
 * Copy to libs/shared/ui-common/src/lib/login/login.model.ts
 */
export interface OTLIDFirestore {
  action: 'login' | 'cancel' | 'logout' | 'loggingIn';
  displayName: string | null;
  email: string | null;
  jwtToken: string | null;
  otlid: string;
  uid: string | null;
}

/**
 * Copy value to libs/shared/ui-common/src/lib/login/login.model.ts:26
 */
export const FIRESTORE_OT_AUTH_CODE_COLLECTION = 'otLoginAuthCodes';

/**
 * Needs to match libs/shared/firebase/src/lib/models/firestore.model.ts:114
 */
export enum ENV {
  DEV = 'dev',
  PROD = 'prod'
}

export enum APP {
  EC = 'ec'
}

export interface DlcHtmlAttribute {
  name: string;
  value: string;
}

export interface DlcHtml {
  attributes: DlcHtmlAttribute[];
  tag: string;
}

export interface DlcScript {
  attributes: DlcHtmlAttribute[];
  scriptSrc: string;
}

export interface GigaCopyAsset {
  newAssetImagePath: string;
  oldAssetImagePath: string;
}

export interface GigaRecursiveDeleteOptions {
  path: string;
  uid?: string;
}

export interface GigaRecursiveDeleteResult {
  error: any | null;
  path: string;
  success: boolean;
}

export interface GigaInAppPurchaseOptions {
  appName: string;
  url: string;
}

export interface GigaOpenAIQuizAssistantOptions {
  prompt: string;
}

export interface GigaOpenAIQuizAssistantResponse {
  status: 'success' | 'error';
  value: string;
}

/**
 * For Response in client side duplicated here libs/ui-design-library/src/lib/entites.ts:15
 */
export interface WebPriceTable {
  html: DlcHtml;
  scriptSrc: string;
}

export interface GigaStripCheckoutScriptOptions {
  app: string;
  env: string;
}

export interface GigaCancelSubscriptionOptions {
  app: string;
  env: string;
  subscriptionId: string;
}
