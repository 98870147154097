import {LiveAnnouncer} from '@angular/cdk/a11y';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {uuid} from '@gigasoftware/shared/fn';
import {
  AlgoliaChipsAutocomplete,
  AlgoliaPublishedQuiz,
  CollaborativeEntity,
  createQuizParams,
  CreateQuizParams,
  createSubQuizParams,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT,
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment,
  GRADE_LEVELS,
  GradeLevel,
  NgPatUserAccount,
  Quiz,
  selectNgPatUserAccount
} from '@gigasoftware/shared/store';
import {DlcRoundedTextButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, from, Observable, Subject, switchMap} from 'rxjs';
import {map, startWith, take} from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-form-create-quiz'
    },
    imports: [
        CommonModule,
        DlcRoundedTextButtonComponent,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatChipsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule
    ],
    selector: 'ec-create-quiz',
    styleUrls: ['./ec-create-quiz.component.scss'],
    templateUrl: './ec-create-quiz.component.html'
})
export class EcCreateQuizComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _quiz: Quiz | null = null;
  // AUTOCOMPLETE FOR SUBJECTS
  algoliaSubjectAutocomplete: AlgoliaChipsAutocomplete<AlgoliaPublishedQuiz>;
  @Output() createAction: EventEmitter<CreateQuizParams> =
    new EventEmitter<CreateQuizParams>();
  filteredGradeLevels!: Observable<GradeLevel[]>;
  filteredSubjects!: Observable<AlgoliaPublishedQuiz[]>;
  // GRADE LEVELS
  gradeLevels: GradeLevel[] = GRADE_LEVELS;
  nameFormGroup: FormGroup;
  @Input() parentEntity: CollaborativeEntity | null | undefined = null;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() showAggregateFalseAnswersOption = false;
  @Input() showAssignedOption = false;
  showCollaborationOption = false;
  @Input() showCollaborativeOption = false;
  showEntityTypeSelect$: BehaviorSubject<boolean>;
  @Input() showIsPrivate = false;

  @ViewChild('subjectInput') subjectInput!: ElementRef<HTMLInputElement>;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private announcer: LiveAnnouncer,
    @Inject(EC_ENVIRONMENT) private environment: EvolutionCognitionEnvironment
  ) {
    this.nameFormGroup = this.fb.group({
      aggregateFalseAnswers: new FormControl(true),
      assigned: new FormControl(false),
      description: new FormControl(null),
      gradeLevel: new FormControl(null),
      isCollaborative: new FormControl(false),
      isPrivate: new FormControl(true),
      name: new FormControl(null, [Validators.required]),
      subjects: new FormControl(null)
    });

    this.showEntityTypeSelect$ = new BehaviorSubject<boolean>(true);

    this.algoliaSubjectAutocomplete =
      new AlgoliaChipsAutocomplete<AlgoliaPublishedQuiz>({
        apiKey: this.environment.algoliaSubjects.apiKey,
        appId: this.environment.algoliaSubjects.appId,
        index: this.environment.algoliaSubjects.index
      });
  }

  private _filter(name: string): GradeLevel[] {
    const filterValue = name.toLowerCase();

    return this.gradeLevels.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  // AUTOCOMPLETE FOR SUBJECTS
  /**
   * @param event
   */
  algoliaAutoCompleteAdd(event: MatChipInputEvent): void {
    this.algoliaSubjectAutocomplete.add(event);
    this.subjectControl.setValue(null);
  }

  /**
   * @param subject
   */
  algoliaAutoCompleteRemove(subject: string): void {
    const index = this.algoliaSubjectAutocomplete.remove(subject);

    if (index >= 0) {
      this.announcer.announce(`Removed ${subject}`);
    }
  }

  algoliaAutoCompleteSelect(event: MatAutocompleteSelectedEvent): void {
    this.algoliaSubjectAutocomplete.selected(event);
    this.subjectInput.nativeElement.value = '';
    this.subjectControl.setValue(null);
  }

  // GRADE LEVELS
  displayFn(user: GradeLevel): string {
    return user && user.name ? user.name : '';
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  ngOnInit(): void {
    this.showCollaborationOption =
      !this.hasParentEntity && this.showCollaborativeOption;
    this.nameFormGroup.controls['isCollaborative'].setValue(
      this.parentEntity !== null && this.parentEntity !== undefined
    );

    // GRADE LEVELS
    this.filteredGradeLevels = this.gradeLevelControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.gradeLevels.slice();
      })
    );

    // END GRADE LEVELS

    // AUTOCOMPLETE FOR SUBJECTS

    this.filteredSubjects = this.subjectControl.valueChanges.pipe(
      startWith(null),
      switchMap((subject: string | null) => {
        if (typeof subject === 'string' && subject && subject.length > 2) {
          return this.algoliaSubjectAutocomplete.search<AlgoliaPublishedQuiz>(
            subject
          );
        }
        return from([]);
      })
    );

    // END AUTOCOMPLETE FOR SUBJECTS

    this.cd.detectChanges();
  }

  onSubmit() {
    if (this.nameFormGroup.valid) {
      const params: CreateQuizParams = {
        aggregateFalseAnswers: !!this.nameFormGroup.value.aggregateFalseAnswers,
        description: this.nameFormGroup.value.description,
        entityType: DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ.id,
        entityTypeValue: DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ.id,
        gradeLevel:
          (<GradeLevel | null>this.nameFormGroup.value.gradeLevel)?.level ||
          null,
        id: uuid(),
        // parentEntity: this.parentEntity,
        isCollaborative: this.nameFormGroup.value.isCollaborative,
        isPrivate: this.nameFormGroup.value.isPrivate,
        name: this.nameFormGroup.value.name,
        subjects: this.algoliaSubjectAutocomplete.getSelectedAsString()
      };

      this.store
        .pipe(select(selectNgPatUserAccount), take(1))
        .subscribe((userAccount: NgPatUserAccount) => {
          if (userAccount.uid) {
            // if (params.parentEntity) {
            if (this.parentEntity) {
              this.store.dispatch(
                createSubQuizParams({
                  params: {
                    ...params,
                    parentEntity: this.parentEntity
                  }
                })
              );
            } else {
              this.store.dispatch(createQuizParams({params}));
            }

            this.createAction.emit(params);
          }
        });
    }
  }

  get gradeLevelControl() {
    return this.nameFormGroup.controls['gradeLevel'];
  }

  get hasParentEntity() {
    return this.parentEntity !== null && this.parentEntity !== undefined;
  }

  @Input()
  set quiz(q: Quiz | null) {
    this._quiz = q;
  }

  get showPrivateOption() {
    return this.nameFormGroup.value.isCollaborative;
  }

  get subjectControl() {
    return this.nameFormGroup.controls['subjects'];
  }

  // END AUTOCOMPLETE FOR SUBJECTS
}
