import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {GigaNoteDoc} from '@gigasoftware/shared/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, MatButtonModule, MatDialogModule],
    selector: 'dlc-delete-note-dialog',
    styleUrl: './delete-note-dialog.component.scss',
    templateUrl: './delete-note-dialog.component.html'
})
export class DeleteNoteDialogComponent {
  data: GigaNoteDoc = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<DeleteNoteDialogComponent>);
}
