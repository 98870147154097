import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class]': 'cssClass'
    },
    imports: [CommonModule],
    selector: 'ui-quiz-answer-button, button[ui-quiz-answer-button]',
    styleUrls: ['./quiz-answer.component.scss'],
    templateUrl: './quiz-answer.component.html'
})
export class QuizAnswerComponent {
  @Input() index = 1;

  get cssClass() {
    return `ui-quiz-answer-button ui-quiz-answer-button__${this.index}`;
  }
}
