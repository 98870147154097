<div class="w-full">
  <p
    class="text-xs font-normal leading-6 text-ellipsis overflow-hidden text-nowrap text-gray-400">
    {{ bookmark()?.url }}
  </p>
  <p
    class="text-sm font-semibold leading-6 text-white text-ellipsis overflow-hidden text-nowrap">
    {{ bookmark()?.title }}
  </p>
  <div class="flex flex-row justify-between items-center mt-4 w-full">
    <div class="flex-auto"></div>
    <button
      class="mr-5"
      mat-icon-button
      (click)="viewBookmark()">
      <mat-icon aria-hidden="false">open_in_new</mat-icon>
    </button>

    <button
      class="mr-5"
      mat-icon-button
      (click)="editBookmark()">
      <mat-icon aria-hidden="false">edit</mat-icon>
    </button>

    <button
      class="mr-5"
      mat-icon-button
      (click)="deleteBookmark()">
      <mat-icon aria-hidden="false">delete</mat-icon>
    </button>

    <button
      mat-icon-button
      (click)="copyBookmark()">
      <mat-icon aria-hidden="false">content_copy</mat-icon>
    </button>
  </div>
</div>
