import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {
  CollaborativeEntity,
  Quiz,
  QuizzesEngineStore
} from '@gigasoftware/shared/store';

import {EcQuizListItemComponent} from './ec-quiz-list-item/ec-quiz-list-item.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-quiz-list'
    },
    imports: [CommonModule, MatProgressBarModule, EcQuizListItemComponent],
    selector: 'ec-quiz-list',
    styleUrls: ['./ec-quiz-list.component.scss'],
    templateUrl: './ec-quiz-list.component.html'
})
export class EcQuizListComponent {
  @Output() doEditQuiz: EventEmitter<Quiz> = new EventEmitter<Quiz>();

  @Output() doSeeAllGrades: EventEmitter<Quiz> = new EventEmitter<Quiz>();

  @Output() doSelectItem: EventEmitter<CollaborativeEntity> =
    new EventEmitter<CollaborativeEntity>();
  @Output() doStatus: EventEmitter<Quiz> = new EventEmitter<Quiz>();
  @Output() doTakeQuiz: EventEmitter<Quiz> = new EventEmitter<Quiz>();
  quizzes: WritableSignal<string[]> = signal([]);
  constructor(private quizStore: QuizzesEngineStore) {}

  @Input()
  set list(list: string[]) {
    this.quizzes.set(list);
  }
}
