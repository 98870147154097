import {hasDuplicatesByKey} from '@gigasoftware/shared/fn';
import {BaseEntity} from '@gigasoftware/shared/models';
import {map} from 'rxjs/operators';

import {
  BaseEntityType,
  CollaborativeEntity,
  CreateEntityParams,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT,
  PLATFORM_ENTITY_TYPES
} from './entity.model';

export const checkForDuplicateEntityTypes = map((d: BaseEntityType[]) => {
  if (hasDuplicatesByKey(d, 'id')) {
    const errorMsg =
      'Platform Configuration Error: Duplicate BaseEntityType Value detected.';
    console.error(errorMsg);
    return new Error(errorMsg);
  } else if (hasDuplicatesByKey(d, 'name')) {
    const errorMsg =
      'Platform Configuration Error: Duplicate BaseEntityType Name detected.';
    console.error(errorMsg);
    return new Error(errorMsg);
  } else {
    return d;
  }
});

export function getEntityTypeByName(name: string): BaseEntityType {
  const entityType: BaseEntityType | undefined = PLATFORM_ENTITY_TYPES.find(
    (p: BaseEntityType) => p.name === name
  );
  return entityType ? entityType : DEFAULT_PLATFORM_ENTITY_TYPE_DICT.RESEARCH;
}

export function entityTypeIsJournal(entityType: number | undefined | null) {
  return DEFAULT_PLATFORM_ENTITY_TYPE_DICT.JOURNAL.id === entityType;
}

export function entityTypeIsInvestigation(
  entityType: number | undefined | null
) {
  return DEFAULT_PLATFORM_ENTITY_TYPE_DICT.INVESTIGATION.id === entityType;
}

export function entityTypeIsProject(entityType: number | undefined | null) {
  return DEFAULT_PLATFORM_ENTITY_TYPE_DICT.PROJECT.id === entityType;
}

export function entityTypeIsStudyGroup(entityType: number | undefined | null) {
  return DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id === entityType;
}

export function entityTypeIsCourse(entityType: number | undefined | null) {
  return DEFAULT_PLATFORM_ENTITY_TYPE_DICT.COURSE.id === entityType;
}

export function entityTypeIsQuiz(entityType: number | undefined | null) {
  return DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ.id === entityType;
}

export function entityTypeIsClassroom(entityType: number | undefined | null) {
  return DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id === entityType;
}

export function entityTypeIsResearch(entityType: number | undefined | null) {
  return DEFAULT_PLATFORM_ENTITY_TYPE_DICT.RESEARCH.id === entityType;
}

export function createEntityParamsObject(
  p: BaseEntity | CollaborativeEntity
): CreateEntityParams {
  return <CreateEntityParams>{
    description: p.description,
    entityTypeValue: p.entityType,
    id: p.id,
    isCollaborative: p.isCollaborative,
    isPrivate: p.isPrivate,
    name: p.name
  };
}

export function createEntityParamsFromParentEntity(
  p: BaseEntity | CollaborativeEntity
): CreateEntityParams {
  return <CreateEntityParams>{
    description: p.description,
    entityTypeValue: p.parentEntityType,
    id: p.parentEntityID,
    isCollaborative: p.isCollaborative,
    isPrivate: p.isPrivate,
    name: p.name
  };
}

/**
 *
 * @param num - BaseEntity Type
 * @param entityTypes
 */
export function getEntityTypeByNumber(
  num: number,
  entityTypes: BaseEntityType[]
): BaseEntityType {
  const entityType: BaseEntityType | undefined = entityTypes.find(
    (p: BaseEntityType) => p.id === num
  );
  return entityType ? entityType : DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ;
}
