import {NavItemDict} from '../../+ui/ui.model';
import {NavItem} from '../../navigation/model';
import {mobileNavItemDictClass} from './mobile/mobile-nav-item-dict-class';
import {mobileNavItemDictDashboard} from './mobile/mobile-nav-item-dict-dashboard';
import {mobileNavItemDictInvestigations} from './mobile/mobile-nav-item-dict-investigations';
import {mobileNavItemDictJournal} from './mobile/mobile-nav-item-dict-journal';
import {mobileNavItemDictProject} from './mobile/mobile-nav-item-dict-projects';
import {mobileNavItemDictQuiz} from './mobile/mobile-nav-item-dict-quiz';
import {mobileNavItemDictResearch} from './mobile/mobile-nav-item-dict-research';
import {mobileNavItemDictRoot} from './mobile/mobile-nav-item-dict-root';
import {mobileNavItemDictStudyGroup} from './mobile/mobile-nav-item-dict-study-group';
import {NAV} from './nav-item-dict.model';

export const navItemDict: NavItemDict = {
  ...mobileNavItemDictStudyGroup,
  ...mobileNavItemDictInvestigations,
  ...mobileNavItemDictProject,
  ...mobileNavItemDictResearch,
  ...mobileNavItemDictDashboard,
  ...mobileNavItemDictClass,
  ...mobileNavItemDictQuiz,
  ...mobileNavItemDictRoot,
  ...mobileNavItemDictJournal
};

export function getNavItem(nav: NAV): NavItem {
  return navItemDict[nav];
}

export function getNavItemUrl(nav: NAV): string {
  return navItemDict[nav]?.url;
}
