import {Component, ChangeDetectionStrategy, Inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogModule, MatButtonModule],
    selector: 'dlc-subscriptions-stripe-error',
    styleUrls: ['./stripe-stripe-error-modal.component.scss'],
    templateUrl: './stripe-stripe-error-modal.component.html'
})
export class StripeStripeErrorModalComponent {
  constructor(
    public dialogRef: MatDialogRef<StripeStripeErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}
}
