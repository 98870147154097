import {filter} from 'rxjs/operators';
import {DlcNoteProcessQueue, DlcNoteSaveAction} from './note.model';

export const ofDlcNoteActionTypeWithFirestoreDoc = (
  actionType: DlcNoteSaveAction
) => {
  return filter((action: DlcNoteProcessQueue) => {
    return (
      action && action.action === actionType && action.firestoreDoc !== null
    );
  });
};

export const ofDlcNoteActionTypeWithTranscription = (
  actionType: DlcNoteSaveAction
) => {
  return filter((action: DlcNoteProcessQueue) => {
    return (
      action &&
      action.action === actionType &&
      action.transcriptionNoteVersion !== null
    );
  });
};

export const hasOddSegments = (basePath: string): boolean => {
  const segments = basePath.split('/');
  return segments.length % 2 === 1;
};

export const getLastSegment = (basePath: string): string => {
  const segments = basePath.split('/');
  return segments[segments.length - 1];
};
