<div class="flex flex-row">
  <div
    *ngIf="research?.imagePath"
    class="w-12 h-12 block overflow-hidden bg-white/[.12] rounded mr-4 self-start">
    <img
      class="w-full h-full"
      dlcImage
      [dlcImagePath]="research.imagePath" />
  </div>
  <div
    *ngIf="!research?.imagePath"
    class="w-12 h-12 block overflow-hidden bg-white/[.12] rounded mr-4 self-start flex flex-row justify-center items-center">
    <mat-icon>image</mat-icon>
  </div>

  <div class="flex-auto">
    <p class="text-sm font-semibold leading-6 text-white">
      {{ research.name }}
    </p>
  </div>
</div>

<div class="w-full">
  <div class="flex flex-row justify-between items-center mt-4 w-full">
    <div class="flex-auto"></div>

    <button
      class="mr-4"
      dlc-rounded-text-icon-button
      color="primary"
      (click)="onSelectResearch()">
      <mat-icon aria-hidden="false">login</mat-icon>
      Enter
    </button>

    <button
      dlc-rounded-icon-button
      [matMenuTriggerFor]="items">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<mat-menu #items>
  <button
    mat-menu-item
    (click)="onEditResearch()">
    <mat-icon aria-hidden="false"> edit</mat-icon>
    Edit
  </button>
  <button
    *ngIf="!loggedInUserIsOwner()"
    mat-menu-item
    (click)="onLeaveResearch()">
    <mat-icon aria-hidden="false">logout</mat-icon>
    Leave Study Group
  </button>
  <button
    mat-menu-item
    (click)="onDeleteResearch()">
    <mat-icon>delete</mat-icon>
    Delete
  </button>
</mat-menu>
