import {Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {
  BASE_COLLECTION_NAME,
  GigaNoteDoc,
  NgPatAddedAndDeletedEntities,
  NgPatAggregateFirebaseSnapshotChanges
} from '@gigasoftware/shared/models';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {Action, createAction, Store} from '@ngrx/store';
import {concatMap, filter, map, switchMap, tap} from 'rxjs/operators';

import {selectNgPatLoggedInUID} from '../+account/account.selectors';
import {Classroom} from '../+classrooms/classroom.model';
import {selectAddedAndDeletedClassrooms} from '../+classrooms/classroom.selectors';
import {selectAddedAndDeletedInvestigations} from '../+investigation/investigation.selectors';
import {selectAddedAndDeletedProjects} from '../+project/project.selectors';
import {Research} from '../+research';
import {selectAddedAndDeletedResearchs} from '../+research/research.selectors';
import {StudyGroup} from '../+study-groups/study-group.model';
import {selectAddedAndDeletedStudyGroups} from '../+study-groups/study-group.selectors';
import {
  firestoreNotesCollection,
  firestoreUserNoteCollection
} from '../firebase/database-paths/notes';
import {NoteQueryStore} from './note-query.store';
import {NoteQueryStoreEntity} from './note-query.store.model';
import {deleteNotes, upsertNotes} from './note.actions';

const onNoteInit = createAction('[Note/API] On Note Init');

@Injectable()
export class NoteEffects implements OnInitEffects {
  getUserNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onNoteInit),
      switchMap(() => {
        return this.store.select(selectNgPatLoggedInUID).pipe(
          filter(
            (uid: string | null) =>
              uid !== null && uid !== undefined && uid.length > 0
          ),
          map((uid: string | null) => firestoreUserNoteCollection(<string>uid)),
          this.firestore.onSnapshotCollection$<GigaNoteDoc>(),
          concatMap(
            (aggregate: NgPatAggregateFirebaseSnapshotChanges<GigaNoteDoc>) => {
              const actions = [];
              if (aggregate.added.length) {
                actions.push(upsertNotes({notes: [...aggregate.added]}));
              }

              if (aggregate.modified.length) {
                actions.push(upsertNotes({notes: [...aggregate.modified]}));
              }

              if (aggregate.removed.length) {
                actions.push(deleteNotes({ids: [...aggregate.removed]}));
              }

              return actions;
            }
          )
        );
      })
    );
  });

  classNotes$ = createEffect(
    () =>
      this.store.select(selectAddedAndDeletedClassrooms).pipe(
        tap((data: NgPatAddedAndDeletedEntities<Classroom>) => {
          if (data.addedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] =
              data.addedEntities.map((classroom: Classroom) => {
                return {
                  firestoreCollectionPath: firestoreNotesCollection(
                    BASE_COLLECTION_NAME.CLASSES,
                    classroom.id
                  ),
                  id: classroom.id
                };
              });
            this.noteQueryStore.addNoteQueryEngines(firestoreNotePaths);
          }

          if (data.deletedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] =
              data.deletedEntities.map((classroom: Classroom) => {
                return {
                  firestoreCollectionPath: firestoreNotesCollection(
                    BASE_COLLECTION_NAME.CLASSES,
                    classroom.id
                  ),
                  id: classroom.id
                };
              });
            this.noteQueryStore.removeNoteQueryEngines(firestoreNotePaths);
          }
        })
      ),
    {dispatch: false}
  );

  studyGroupNotes$ = createEffect(
    () =>
      this.store.select(selectAddedAndDeletedStudyGroups).pipe(
        tap((data: NgPatAddedAndDeletedEntities<StudyGroup>) => {
          if (data.addedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] =
              data.addedEntities.map((studyGroup: StudyGroup) => {
                return {
                  firestoreCollectionPath: firestoreNotesCollection(
                    BASE_COLLECTION_NAME.STUDY_GROUPS,
                    studyGroup.id
                  ),
                  id: studyGroup.id
                };
              });
            this.noteQueryStore.addNoteQueryEngines(firestoreNotePaths);
          }

          if (data.deletedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] =
              data.deletedEntities.map((studyGroup: StudyGroup) => {
                return {
                  firestoreCollectionPath: firestoreNotesCollection(
                    BASE_COLLECTION_NAME.STUDY_GROUPS,
                    studyGroup.id
                  ),
                  id: studyGroup.id
                };
              });
            this.noteQueryStore.removeNoteQueryEngines(firestoreNotePaths);
          }
        })
      ),
    {dispatch: false}
  );

  investigationNotes$ = createEffect(
    () =>
      this.store.select(selectAddedAndDeletedInvestigations).pipe(
        tap((data: NgPatAddedAndDeletedEntities<StudyGroup>) => {
          if (data.addedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] =
              data.addedEntities.map((studyGroup: StudyGroup) => {
                return {
                  firestoreCollectionPath: firestoreNotesCollection(
                    BASE_COLLECTION_NAME.INVESTIGATIONS,
                    studyGroup.id
                  ),
                  id: studyGroup.id
                };
              });
            this.noteQueryStore.addNoteQueryEngines(firestoreNotePaths);
          }

          if (data.deletedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] =
              data.deletedEntities.map((studyGroup: StudyGroup) => {
                return {
                  firestoreCollectionPath: firestoreNotesCollection(
                    BASE_COLLECTION_NAME.INVESTIGATIONS,
                    studyGroup.id
                  ),
                  id: studyGroup.id
                };
              });
            this.noteQueryStore.removeNoteQueryEngines(firestoreNotePaths);
          }
        })
      ),
    {dispatch: false}
  );

  projectNotes$ = createEffect(
    () =>
      this.store.select(selectAddedAndDeletedProjects).pipe(
        tap((data: NgPatAddedAndDeletedEntities<StudyGroup>) => {
          if (data.addedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] =
              data.addedEntities.map((studyGroup: StudyGroup) => {
                return {
                  firestoreCollectionPath: firestoreNotesCollection(
                    BASE_COLLECTION_NAME.PROJECTS,
                    studyGroup.id
                  ),
                  id: studyGroup.id
                };
              });
            this.noteQueryStore.addNoteQueryEngines(firestoreNotePaths);
          }

          if (data.deletedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] =
              data.deletedEntities.map((studyGroup: StudyGroup) => {
                return {
                  firestoreCollectionPath: firestoreNotesCollection(
                    BASE_COLLECTION_NAME.PROJECTS,
                    studyGroup.id
                  ),
                  id: studyGroup.id
                };
              });
            this.noteQueryStore.removeNoteQueryEngines(firestoreNotePaths);
          }
        })
      ),
    {dispatch: false}
  );

  researchNotes$ = createEffect(
    () =>
      this.store.select(selectAddedAndDeletedResearchs).pipe(
        tap((data: NgPatAddedAndDeletedEntities<Research>) => {
          if (data.addedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] =
              data.addedEntities.map((research: Research) => {
                return {
                  firestoreCollectionPath: firestoreNotesCollection(
                    BASE_COLLECTION_NAME.RESEARCH,
                    research.id
                  ),
                  id: research.id
                };
              });
            this.noteQueryStore.addNoteQueryEngines(firestoreNotePaths);
          }

          if (data.deletedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] =
              data.deletedEntities.map((research: Research) => {
                return {
                  firestoreCollectionPath: firestoreNotesCollection(
                    BASE_COLLECTION_NAME.RESEARCH,
                    research.id
                  ),
                  id: research.id
                };
              });
            this.noteQueryStore.removeNoteQueryEngines(firestoreNotePaths);
          }
        })
      ),
    {dispatch: false}
  );

  constructor(
    private firestore: NgPatFirestoreService,
    private store: Store,
    private actions$: Actions,
    private noteQueryStore: NoteQueryStore
  ) {}

  ngrxOnInitEffects(): Action {
    return onNoteInit();
  }
}
