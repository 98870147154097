import {inject, Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {GsAssetService, GsImageThumbails} from '@gigasoftware/shared/media';
import {Store} from '@ngrx/store';
import {combineLatest, from, Observable, of, ReplaySubject} from 'rxjs';
import {
  concatAll,
  concatMap,
  map,
  mergeMap,
  switchMap,
  take,
  toArray
} from 'rxjs/operators';

import {selectCurrentQuizID} from '../../+ui/ui.selectors';
import {NgPatEntityStore} from '../../custom-store/entity-store/ng-pat-entity-store';
import {
  Question,
  QuestionAction,
  QuestionPropertyAction,
  Quiz,
  QuizGrade,
  TakeQuizResult
} from '../quiz.model';
import {QuizQueryEngine} from './quiz-query-engine';
import {QuizTest} from './quiz-test';

/**
 * @description
 * Imported into any component or services that needs a list of quizzes.
 */
@Injectable({
  providedIn: 'root'
})
export class QuizzesEngineStore {
  private store: Store = inject(Store);

  onDelete$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  quizzesStore = new NgPatEntityStore<QuizQueryEngine>();

  // selectedQuizId: Signal<string | null> =
  //   this.store.selectSignal(selectCurrentQuizID);

  selectedQuizId$: Observable<string | null> =
    this.store.select(selectCurrentQuizID);

  // selectCurrentQuiz: Signal<Quiz | null | undefined> =
  //   this.store.selectSignal(selectCurrentQuiz);

  // selectedQuizQueryEngine: Signal<QuizQueryEngine | undefined | null> =
  //   computed(() => {
  //     const quizId: string | null = this.selectedQuizId();
  //     if (quizId !== null && quizId !== undefined) {
  //       console.log('quizId', quizId);
  //       return this.quizzesStore.selectById(quizId);
  //     }
  //     return null;
  //   });

  selectCurrentQuizQueryEngine$: Observable<
    QuizQueryEngine | undefined | null
  > = this.selectedQuizId$.pipe(
    switchMap((quizId: string | number | null) => {
      return this.quizzesStore.selectById$(quizId);
    })
  );

  selectCurrentQuizFirestoreDocPath$: Observable<string | null | undefined> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.quizFirestoreDocPath$;
        }
        return of(null);
      })
    );

  selectCurrentQuiz$: Observable<Quiz | null | undefined> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.selectQuiz$;
        }
        return of(null);
      })
    );

  selectCurrentQuestion$: Observable<Question | null | undefined> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.selectCurrentQuestion$;
        }
        return of(null);
      })
    );

  // selectQuestions: Signal<Question[]> = computed(() => {
  //   return this.selectedQuizQueryEngine()?.questions() || [];
  // });

  selectCurrentQuizQuestions$: Observable<Question[]> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.selectAllQuestions$;
        }
        return of([]);
      })
    );

  /**
   * TODO rename to selectQuestionAction$
   * @description addQuestion$
   */
  addQuestion$: Observable<QuestionAction | null | undefined> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.onAddQuestionAction$;
        }
        return of(null);
      })
    );

  selectCurrentEditQuestionAction$: Observable<
    QuestionAction | null | undefined
  > = this.selectCurrentQuizQueryEngine$.pipe(
    switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
      if (quizQueryEngine) {
        return quizQueryEngine.selectCurrentEditQuestionAction$;
      }
      return of(null);
    })
  );

  selectCurrentQuizResult$: Observable<TakeQuizResult | null | undefined> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.selectCurrentQuizResult$;
        }
        return of(null);
      })
    );

  selectCurrentImageThumbnailPaths$: Observable<
    GsImageThumbails | null | undefined
  > = this.selectCurrentQuizQueryEngine$.pipe(
    switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
      if (quizQueryEngine) {
        return quizQueryEngine.selectImageThumbnailPaths$;
      }
      return of(null);
    })
  );

  selectCurrentNumberOfQuestions$: Observable<number> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.selectNumberOfQuestions$;
        }
        return of(0);
      })
    );

  selectCurrentNumberOfGrades$: Observable<number> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.selectNumberOfGrades$;
        }
        return of(0);
      })
    );

  selectCurrentQuizGrades$: Observable<QuizGrade<TakeQuizResult>[]> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        return quizQueryEngine ? quizQueryEngine.selectGrades$ : of([]);
      })
    );

  selectCurrentHighestGrade$: Observable<number> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.selectHighestGrade$;
        }
        return of(0);
      })
    );

  lowestTimeToTakeQuizAtHighestGradeMS$: Observable<number> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.selectLowestTimeToTakeQuizAtHighestGradeMS$;
        }
        return of(0);
      })
    );

  onAddQuestionAction$: Observable<QuestionAction | null | undefined> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.onAddQuestionAction$;
        }
        return of(null);
      })
    );
  selectAllCurrentQuestions$: Observable<Question[]> =
    this.selectCurrentQuizQueryEngine$.pipe(
      switchMap((quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          return quizQueryEngine.selectAllQuestions$;
        }
        return of([]);
      })
    );

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private assetService: GsAssetService // private cd: ChangeDetectorRef
  ) {}

  getQuizQueryEnginesByParentId(
    parentId: string
  ): Observable<QuizQueryEngine[]> {
    return this.quizzesStore.selectAll$.pipe(
      map((quizQueryEngines: QuizQueryEngine[]) => {
        return quizQueryEngines.filter(
          (qqe: QuizQueryEngine) => qqe.parentEntityID === parentId
        );
      })
    );
  }

  getImagePathsByParentId(parentId: string): Observable<string[]> {
    return this.getQuizQueryEnginesByParentId(parentId).pipe(
      switchMap((quiz: QuizQueryEngine[]) => {
        if (quiz.length > 0) {
          return from(
            quiz.map((q: QuizQueryEngine) => q.getImagePaths$())
          ).pipe(
            concatAll(),
            map((imagePaths: string[]) => {
              console.log(imagePaths);
              return imagePaths;
              /**
               * Flatten array of arrays
               */
              // return imagePaths.reduce((a: string[], b: string[]) => {
              //   return a.concat(b);
              // });
            })
          );
        }

        return of([]);
      })
    );
  }

  getQuizQueryEngineById(
    id: string
  ): Observable<QuizQueryEngine | null | undefined> {
    return this.quizzesStore.selectById$(id);
  }

  upsertQuizzes(quizzes: Quiz[]) {
    quizzes.forEach(quiz => {
      this.upsertQuiz(quiz);
    });
  }

  upsertQuiz(quiz: Quiz) {
    this.quizzesStore
      .selectById$(quiz.id)
      .pipe(take(1))
      .subscribe({
        next: (quizQueryEngine: QuizQueryEngine | undefined | null) => {
          if (quizQueryEngine) {
            quizQueryEngine.updateQuiz(quiz);
          } else {
            const quizQueryEngine = new QuizQueryEngine(
              quiz,
              this.store,
              this.customFirestoreService,
              this.assetService
              // this.cd
            );
            this.quizzesStore.setOne(quizQueryEngine);
          }
        }
      });
  }

  deleteManyQuizzes(ids: string[]) {
    // for (let i = 0; i < ids.length; i++) {
    //   const id = ids[i];
    //   let quizQueryEngine = this.quizzesStore.selectById(id);
    //
    //   if (quizQueryEngine) {
    //     quizQueryEngine.destroy();
    //     quizQueryEngine = undefined; // garbage collect
    //   }
    // }

    const qqes = from(ids).pipe(
      concatMap((id: string) => this.quizzesStore.selectById$(id)),
      mergeMap((quizQueryEngines: QuizQueryEngine | null | undefined) => {
        return quizQueryEngines ? [quizQueryEngines] : [];
      }),
      toArray(),
      map((quizQueryEngines: QuizQueryEngine[]) => {
        return quizQueryEngines.filter((qqe: QuizQueryEngine) => {
          return qqe !== undefined && qqe !== null;
        });
      })
    );

    qqes.subscribe({
      next: (quizQueryEngines: QuizQueryEngine[]) => {
        quizQueryEngines.forEach((qqe: QuizQueryEngine) => {
          qqe.destroy();
          // qqe = undefined;
        });
        this.quizzesStore.deleteMany(quizQueryEngines.map(qqe => qqe.id));
      }
    });

    this.quizzesStore.deleteMany(ids);
  }

  // createTest(): QuizTest | null {
  //   const quiz: Quiz | null | undefined = this.selectCurrentQuiz();
  //   const questions: Question[] = this.selectQuestions();
  //
  //   if (quiz && questions) {
  //     return new QuizTest(
  //       this.store,
  //       this.customFirestoreService,
  //       quiz,
  //       questions
  //     );
  //   }
  //
  //   return null;
  // }

  createTest$(): Observable<QuizTest | null> {
    return combineLatest([
      this.selectCurrentQuiz$,
      this.selectCurrentQuizQuestions$
    ]).pipe(
      map(
        ([quiz, questions]: [
          Quiz | null | undefined,
          Question[]
        ]): QuizTest | null => {
          if (quiz && questions) {
            return new QuizTest(
              this.store,
              this.customFirestoreService,
              quiz,
              questions
            );
          }
          return null;
        }
      )
    );
  }

  onSaveQuestion(q: QuestionAction) {
    this.selectCurrentQuizQueryEngine$.pipe(take(1)).subscribe({
      next: (quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          quizQueryEngine.onSaveQuestion(q);
        }
      }
    });
  }

  onMergeQuestion(q: QuestionPropertyAction) {
    this.selectCurrentQuizQueryEngine$.pipe(take(1)).subscribe({
      next: (quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          quizQueryEngine.onMergeQuestion(q);
        }
      }
    });
  }

  onDeleteQuestion(q: QuestionAction): Promise<void> {
    return new Promise(resolve => {
      this.selectCurrentQuizQueryEngine$.pipe(take(1)).subscribe({
        next: (quizQueryEngine: QuizQueryEngine | undefined | null) => {
          if (quizQueryEngine) {
            resolve(quizQueryEngine.onDeleteQuestion(q));
          }
        }
      });
    });
  }

  removeQuestionAction(): void {
    this.selectCurrentQuizQueryEngine$.pipe(take(1)).subscribe({
      next: (quizQueryEngine: QuizQueryEngine | undefined | null) => {
        if (quizQueryEngine) {
          quizQueryEngine.removeQuestionAction();
        }
      }
    });
  }
}
