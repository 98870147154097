import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {ngPatLogout} from '../+account/account.actions';
import * as CourseActions from './course.actions';
import {Course} from './course.model';

export const courseFeatureKey = 'course';

export interface CourseState extends EntityState<Course> {
  // additional entities state properties
  selectedCourseID: string | null;
}

export interface PartialCourseState {
  readonly [courseFeatureKey]: CourseState;
}

export const courseAdapter: EntityAdapter<Course> =
  createEntityAdapter<Course>();

export const initialCourseState: CourseState = courseAdapter.getInitialState({
  // additional entity state properties
  selectedCourseID: null
});

export const reducer = createReducer(
  initialCourseState,
  on(CourseActions.addCourse, (state, action) =>
    courseAdapter.addOne(action.course, state)
  ),
  on(CourseActions.setCourse, (state, action) =>
    courseAdapter.setOne(action.course, state)
  ),
  on(CourseActions.addCourses, (state, action) =>
    courseAdapter.addMany(action.courses, state)
  ),
  on(CourseActions.updateCourse, (state, action) =>
    courseAdapter.updateOne(action.course, state)
  ),
  on(CourseActions.updateCourses, (state, action) =>
    courseAdapter.updateMany(action.courses, state)
  ),
  on(CourseActions.upsertCourse, (state, action) =>
    courseAdapter.upsertOne(action.course, state)
  ),
  on(CourseActions.upsertCourses, (state, action) =>
    courseAdapter.upsertMany(action.courses, state)
  ),
  on(CourseActions.mapCourse, (state, {entityMap}) => {
    return courseAdapter.mapOne(entityMap, state);
  }),
  on(CourseActions.mapCourses, (state, {entityMap}) => {
    return courseAdapter.map(entityMap, state);
  }),
  on(CourseActions.deleteCourse, (state, action) =>
    courseAdapter.removeOne(action.id, state)
  ),
  on(CourseActions.deleteCourses, (state, action) =>
    courseAdapter.removeMany(action.ids, state)
  ),
  on(CourseActions.loadCourses, (state, action) =>
    courseAdapter.setAll(action.courses, state)
  ),
  on(CourseActions.setCourses, (state, action) =>
    courseAdapter.setMany(action.courses, state)
  ),
  on(CourseActions.clearCourses, state => courseAdapter.removeAll(state)),
  on(ngPatLogout, state => ({
    ...initialCourseState,
    ...courseAdapter.removeAll(state)
  })),
  on(CourseActions.selectCourseID, (state, action) => {
    return {
      ...state,
      selectedCourseID: action.id
    };
  })
);
