<h4 class="font-bold">My Codes</h4>

@if(hasData()) {
<table
  mat-table
  [dataSource]="dataSource()"
  class="mat-elevation-z8 code-table">
  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th
      mat-header-cell
      *matHeaderCellDef>
      Code
    </th>
    <td
      mat-cell
      *matCellDef="let row">
      <div class="pt-2 pb-2">
        <div>{{ row.name }}</div>
        <div class="text-[12px]">{{ row.description }}</div>
      </div>
    </td>
  </ng-container>

  <!-- Action Column -->
  <ng-container matColumnDef="action">
    <th
      mat-header-cell
      *matHeaderCellDef>
      Remove
    </th>
    <td
      mat-cell
      *matCellDef="let row">
      <button
        mat-icon-button
        (click)="
          $event.stopImmediatePropagation();
          $event.preventDefault();
          doRemove.emit(row.name)
        ">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"></tr>
</table>
}

<form class="code-form mt-5 flex flex-row items-center">
  <mat-form-field class="code-full-width mr-5">
    <mat-label>Add Code</mat-label>
    <input
      type="text"
      matInput
      [formControl]="codeForm"
      placeholder="ABC_123" />

    @if (codeForm.hasError('required')) {
    <mat-error>Code is <strong>required</strong></mat-error>
    }
  </mat-form-field>

  <button
    mat-flat-button
    color="primary"
    [disabled]="codeForm.invalid"
    (click)="
      $event.stopImmediatePropagation(); $event.preventDefault(); onAdd()
    ">
    <div class="button-form-code-content">
      <mat-icon>add</mat-icon><span>Add Code</span>
    </div>
  </button>
</form>
