export const USERS_PATH = 'users';
export const PRICES_PATH = 'prices';
export const PRODUCTS_PATH = 'products';
export const PROMO_CODES_PATH = 'promoCodes';
/**
 * Products
 */
export function firestoreProductCollection() {
  return `${PRODUCTS_PATH}`;
}
export function firestoreProductDoc(productID: string) {
  return `${PRODUCTS_PATH}/${productID}`;
}

export function firestorePriceCollection(productID: string) {
  return `${PRODUCTS_PATH}/${productID}/${PRICES_PATH}`;
}

export function firestoreUserSubscriptionsCollection(uid: string) {
  return `${USERS_PATH}/${uid}/subscriptions`;
}

export function firestoreUserInvoicesCollection(
  subscriptionID: string,
  uid: string
) {
  return `${USERS_PATH}/${uid}/subscriptions/${subscriptionID}/invoices`;
}

export function firestoreUserCheckoutSessionsCollection(uid: string) {
  return `${USERS_PATH}/${uid}/checkout_sessions`;
}

// Subscriptions

export function getProductsPath() {
  return `${PRODUCTS_PATH}`;
}

export function getPricesPath(productId: string) {
  return `${PRODUCTS_PATH}/${productId}/prices`;
}

/**
 * Promo code paths
 */
export function firestorePromoCodesCollection() {
  return `promoCodes`;
}
export function firestorePromoCodeDoc(codeId: string) {
  return `promoCodes/${codeId}`;
}
