import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Research} from '@gigasoftware/shared/store';

import {EcResearchListItemComponent} from './ec-research-list-item/ec-research-list-item.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-research-list'
    },
    imports: [CommonModule, EcResearchListItemComponent],
    selector: 'ec-research-list',
    styleUrl: './ec-research-list.component.scss',
    templateUrl: './ec-research-list.component.html'
})
export class EcResearchListComponent {
  @Output() doDeleteItem: EventEmitter<Research> = new EventEmitter<Research>();

  @Output() doEditItem: EventEmitter<Research> = new EventEmitter<Research>();

  @Output() doLeaveItem: EventEmitter<Research> = new EventEmitter<Research>();

  @Output() doSelectItem: EventEmitter<Research> = new EventEmitter<Research>();

  @Input() list: Research[] = [];
}
