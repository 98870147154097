import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {
  selectNgPatLoggedInUID,
  selectNgPatMentorAccountsDict
} from '../+account/account.selectors';
import {NG_PAT_ROLES} from '../+account/user.model';
import {
  getMemberListItemsByEntityID,
  selectAllMemberss
} from '../+members/index';
import {convertCollaborativeEntityToMemberUIDEntityID} from '../+members/member.fns';
import {UiState} from '../+ui/ui.model';
import {selectCurrentResearchID, selectUiState} from '../+ui/ui.selectors';
import {getCreatedAtNanoseconds} from '../fns/get-created-at-nanoseconds-prop';
import {isCreatedBy} from '../fns/user.fns';
import {selectNgPatAddedAndDeletedEntities} from '../selectors';
import {selectHasActiveSubscription} from '../subscription/subscription.selectors';
import {Research} from './research.model';
import * as ResearchReducer from './research.reducer';
import {ResearchState} from './research.reducer';

export const selectResearchState =
  createFeatureSelector<ResearchReducer.ResearchState>(
    ResearchReducer.researchFeatureKey
  );

const {selectAll, selectEntities, selectIds, selectTotal} =
  ResearchReducer.researchAdapter.getSelectors();

export const selectAllResearchs = createSelector(
  selectResearchState,
  selectAll
);
export const selectResearchEntities = createSelector(
  selectResearchState,
  selectEntities
);
export const selectResearchIds = createSelector(selectResearchState, selectIds);
export const selectResearchTotal = createSelector(
  selectResearchState,
  selectTotal
);
export const selectedResearchID = createSelector(
  selectResearchState,
  (state: ResearchReducer.ResearchState) => state.selectedResearchID
);

export const selectResearchMemberMap = createSelector(
  selectAllResearchs,
  convertCollaborativeEntityToMemberUIDEntityID
);

export const selectedResearch = createSelector(
  selectResearchState,
  (state: ResearchState) => {
    if (state.selectedResearchID) {
      return state.entities[state.selectedResearchID];
    } else {
      return null;
    }
  }
);

export const getResearchByID = (researchID: string | null) =>
  createSelector(
    selectResearchState,
    (state: ResearchReducer.ResearchState): Research | undefined => {
      if (researchID && state.entities[researchID]) {
        return state.entities[researchID];
      }

      return undefined;
    }
  );

export const getResearchByTimestamp = (
  nanoseconds: string | null | undefined
) =>
  createSelector(selectAllResearchs, (researchs: Research[]) => {
    return researchs.reduce(
      (found: Research | null | undefined, i: Research | undefined) => {
        if (!found && i && getCreatedAtNanoseconds(i) === nanoseconds) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const getResearchById = (id: string | null | undefined) =>
  createSelector(selectAllResearchs, (researchs: Research[]) => {
    return researchs.reduce(
      (found: Research | null | undefined, i: Research | undefined) => {
        if (!found && i && i.id === id) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const selectResearchsIoOwn = createSelector(
  selectNgPatLoggedInUID,
  selectAllResearchs,
  (uid: string | null, researchs: Research[]) => {
    if (uid && uid.length) {
      return researchs.filter((s: Research) =>
        uid ? isCreatedBy(s, uid) : false
      );
    }

    return [];
  }
);

export const selectCurrentResearch = createSelector(
  selectUiState,
  selectResearchEntities,
  (
    state: UiState,
    researchEntities: Dictionary<Research>
  ): Research | null | undefined => {
    if (state.currentResearchID && researchEntities[state.currentResearchID]) {
      return state.currentResearchID
        ? researchEntities[state.currentResearchID]
        : null;
    }
    return null;
  }
);

export const selectCurrentResearchMembers = createSelector(
  selectCurrentResearchID,
  selectHasActiveSubscription,
  selectNgPatMentorAccountsDict,
  selectAllMemberss,
  getMemberListItemsByEntityID
);

export const canInviteToResearch = createSelector(
  selectCurrentResearch,
  selectNgPatLoggedInUID,
  (
    research: Research | null | undefined,
    loggedInUID: string | null
  ): boolean => {
    if (
      loggedInUID &&
      research &&
      research.members &&
      research.members[loggedInUID]
    ) {
      return research.members[loggedInUID].role === NG_PAT_ROLES.Owner;
    }
    return false;
  }
);

export const selectAddedAndDeletedResearchs =
  selectNgPatAddedAndDeletedEntities<Research>(selectResearchEntities);
