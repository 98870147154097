import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {ngPatLogout} from '../+account/account.actions';
import {resetNavigation} from '../+ui/ui.actions';
import * as InvestigationActions from './investigation.actions';
import {Investigation} from './investigation.model';

export const investigationFeatureKey = 'investigation';

export interface InvestigationState extends EntityState<Investigation> {
  // additional entities state properties
  selectedInvestigationID: string | null;
}

export interface PartialInvestigationState {
  readonly [investigationFeatureKey]: InvestigationState;
}

export const investigationAdapter: EntityAdapter<Investigation> =
  createEntityAdapter<Investigation>();

export const initialInvestigationState: InvestigationState =
  investigationAdapter.getInitialState({
    // additional entity state properties
    selectedInvestigationID: null
  });

export const reducer = createReducer(
  initialInvestigationState,
  on(InvestigationActions.addInvestigation, (state, action) =>
    investigationAdapter.addOne(action.investigation, state)
  ),
  on(InvestigationActions.setInvestigation, (state, action) =>
    investigationAdapter.setOne(action.investigation, state)
  ),
  on(InvestigationActions.addInvestigations, (state, action) =>
    investigationAdapter.addMany(action.investigations, state)
  ),
  on(InvestigationActions.updateInvestigation, (state, action) =>
    investigationAdapter.updateOne(action.investigation, state)
  ),
  on(InvestigationActions.updateInvestigations, (state, action) =>
    investigationAdapter.updateMany(action.investigations, state)
  ),
  on(InvestigationActions.upsertInvestigation, (state, action) =>
    investigationAdapter.upsertOne(action.investigation, state)
  ),
  on(InvestigationActions.upsertInvestigations, (state, action) =>
    investigationAdapter.upsertMany(action.investigations, state)
  ),
  on(InvestigationActions.mapInvestigation, (state, {entityMap}) => {
    return investigationAdapter.mapOne(entityMap, state);
  }),
  on(InvestigationActions.mapInvestigations, (state, {entityMap}) => {
    return investigationAdapter.map(entityMap, state);
  }),
  on(InvestigationActions.deleteInvestigation, (state, action) =>
    investigationAdapter.removeOne(action.id, state)
  ),
  on(InvestigationActions.deleteInvestigations, (state, action) =>
    investigationAdapter.removeMany(action.ids, state)
  ),
  on(InvestigationActions.loadInvestigations, (state, action) =>
    investigationAdapter.setAll(action.investigations, state)
  ),
  on(InvestigationActions.setInvestigations, (state, action) =>
    investigationAdapter.setMany(action.investigations, state)
  ),
  on(InvestigationActions.clearInvestigations, state =>
    investigationAdapter.removeAll(state)
  ),
  on(ngPatLogout, state => ({
    ...initialInvestigationState,
    ...investigationAdapter.removeAll(state)
  })),
  on(InvestigationActions.selectInvestigationID, (state, action) => {
    return {
      ...state,
      selectedInvestigationID: action.id
    };
  }),
  on(resetNavigation, (state: InvestigationState) => {
    return {
      ...state,
      selectedInvestigationID: null
    };
  })
);
