import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.dlc-mobile-page-content--height-full]': 'heightFull()',
        '[class.dlc-mobile-page-content--overflow-y-scroll]': 'overflowYScroll()',
        '[style.padding-left]': 'paddingLeft()',
        '[style.padding-right]': 'paddingLeft()',
        class: `dlc-mobile-page-content`
    },
    imports: [CommonModule],
    selector: 'dlc-mobile-page-content',
    styleUrls: ['./dlc-mobile-page-content.component.scss'],
    templateUrl: './dlc-mobile-page-content.component.html'
})
export class DlcMobilePageContentComponent {
  heightFull = input(false);
  overflowYScroll = input(true);
  paddingLeft = input('1.25rem');
  paddingRight = input('1.25rem');
}
