import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation
} from '@angular/core';

import {coerceThemePalette} from '../button.fns';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[attr.disabled]': `disabled() === true ? '' : null`,
        '[class.dlc-square-icon-button-accent]': 'color() === "accent"',
        '[class.dlc-square-icon-button-none]': 'color() === "none" || color() === undefined',
        '[class.dlc-square-icon-button-primary]': 'color() === "primary"',
        '[class.dlc-square-icon-button-warn]': 'color() === "warn"',
        class: 'dlc-square-icon-button  dlc-square-button'
    },
    imports: [CommonModule],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[dlc-square-icon-button], button[dlcSquareIconButton]',
    styleUrls: ['./dlc-square-icon-button.component.scss'],
    templateUrl: './dlc-square-icon-button.component.html'
})
export class DlcSquareIconButtonComponent {
  color = input('none', {transform: coerceThemePalette});
  disabled = input(false, {transform: coerceBooleanProperty});
}
