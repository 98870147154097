import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {ngPatLogout} from '../+account/account.actions';
import {resetNavigation} from '../+ui/ui.actions';
import * as StudyGroupActions from './study-group.actions';
import {StudyGroup} from './study-group.model';

export const studyGroupFeatureKey = 'studyGroup';

export interface StudyGroupState extends EntityState<StudyGroup> {
  // additional entities state properties
  selectedStudyGroupID: string | null;
}

export interface PartialStudyGroupState {
  readonly [studyGroupFeatureKey]: StudyGroupState;
}

export const studyGroupAdapter: EntityAdapter<StudyGroup> =
  createEntityAdapter<StudyGroup>();

export const initialStudyGroupState: StudyGroupState =
  studyGroupAdapter.getInitialState({
    // additional entity state properties
    selectedStudyGroupID: null
  });

export const reducer = createReducer(
  initialStudyGroupState,
  on(StudyGroupActions.addStudyGroup, (state, action) =>
    studyGroupAdapter.addOne(action.studyGroup, state)
  ),
  on(StudyGroupActions.setStudyGroup, (state, action) =>
    studyGroupAdapter.setOne(action.studyGroup, state)
  ),
  on(StudyGroupActions.addStudyGroups, (state, action) =>
    studyGroupAdapter.addMany(action.studyGroups, state)
  ),
  on(StudyGroupActions.updateStudyGroup, (state, action) =>
    studyGroupAdapter.updateOne(action.studyGroup, state)
  ),
  on(StudyGroupActions.updateStudyGroups, (state, action) =>
    studyGroupAdapter.updateMany(action.studyGroups, state)
  ),
  on(StudyGroupActions.upsertStudyGroup, (state, action) =>
    studyGroupAdapter.upsertOne(action.studyGroup, state)
  ),
  on(StudyGroupActions.upsertStudyGroups, (state, action) =>
    studyGroupAdapter.upsertMany(action.studyGroups, state)
  ),
  on(StudyGroupActions.mapStudyGroup, (state, {entityMap}) => {
    return studyGroupAdapter.mapOne(entityMap, state);
  }),
  on(StudyGroupActions.mapStudyGroups, (state, {entityMap}) => {
    return studyGroupAdapter.map(entityMap, state);
  }),
  on(StudyGroupActions.deleteStudyGroup, (state, action) =>
    studyGroupAdapter.removeOne(action.id, state)
  ),
  on(StudyGroupActions.deleteStudyGroups, (state, action) =>
    studyGroupAdapter.removeMany(action.ids, state)
  ),
  on(StudyGroupActions.loadStudyGroups, (state, action) =>
    studyGroupAdapter.setAll(action.studyGroups, state)
  ),
  on(StudyGroupActions.setStudyGroups, (state, action) =>
    studyGroupAdapter.setMany(action.studyGroups, state)
  ),
  on(StudyGroupActions.clearStudyGroups, state =>
    studyGroupAdapter.removeAll(state)
  ),
  on(ngPatLogout, state => ({
    ...initialStudyGroupState,
    ...studyGroupAdapter.removeAll(state)
  })),
  on(StudyGroupActions.selectStudyGroupID, (state, action) => {
    return {
      ...state,
      selectedStudyGroupID: action.id
    };
  }),
  on(resetNavigation, (state: StudyGroupState) => {
    return {
      ...state,
      selectedStudyGroupID: null
    };
  })
);
