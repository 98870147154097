import 'cordova-plugin-purchase';
import {formatCurrency} from '@angular/common';
import {Dictionary} from '@ngrx/entity';
import {StripeProductWithPrices} from './+stripe/stripe.model';
import {
  IAPOffer,
  IAPProduct,
  WebSubscriptionProducts
} from './+in_app_purchase/in-app-purchase.model';
import {
  AggregatedPriceByGroup,
  NgPatNormalizedOffer,
  NgPatNormalizedPrice,
  NgPatNormalizedPriceIntervalValueEnum,
  NgPatNormalizedPricePlatform
} from './normalized-price.model';
import RecurrenceMode = CdvPurchase.RecurrenceMode;
import PricingPhase = CdvPurchase.PricingPhase;
import Platform = CdvPurchase.Platform;

/**
 * Input price as an integer and return a string with 2 decimal places if the price has a decimal value
 * else return price without decimal places
 * @param price
 * @param product
 * @param features
 * @param ids
 * @param isMostPopular
 */
// export function transformWebPriceToUiPrice(
//   price: NgPatStripeProductPrice,
//   product: NgPatStripeProduct | undefined,
//   features: string[],
//   ids: string[],
//   isMostPopular = false
// ): NgPatNormalizedPrice {
//   const interval =
//     price.recurring.interval === 'month'
//       ? NgPatNormalizedPriceIntervalValueEnum.Monthly
//       : NgPatNormalizedPriceIntervalValueEnum.Annually;
//
//   return {
//     title: product?.name || '',
//     priceId: price.id,
//     priceIds: [...ids],
//     prices: [
//       {
//         price: price.unit_amount,
//         interval
//       }
//     ],
//     isCustomPrice: false,
//     currencyCode: price.currency,
//     description: price.description,
//     isMostPopular,
//     features,
//     owned: false,
//     paymentPlatform: NgPatNormalizedPricePlatform.WEB
//   };
// }

/**
 *
 * @param billingPeriod
 */
function getNativeInterval(
  billingPeriod: string | undefined
): NgPatNormalizedPriceIntervalValueEnum {
  if (billingPeriod === 'P1M') {
    return NgPatNormalizedPriceIntervalValueEnum.Monthly;
  } else if (billingPeriod === 'P1Y') {
    return NgPatNormalizedPriceIntervalValueEnum.Annually;
  } else {
    return NgPatNormalizedPriceIntervalValueEnum.Custom;
  }
}

function getWebInterval(billingPeriod: string | undefined): string {
  if (billingPeriod === 'month') {
    return NgPatNormalizedPriceIntervalValueEnum.Monthly;
  } else if (billingPeriod === 'year') {
    return NgPatNormalizedPriceIntervalValueEnum.Annually;
  } else {
    return NgPatNormalizedPriceIntervalValueEnum.Custom;
  }
}

/**
 * In IOS products are grouped by group name
 * @param products
 */
export function aggregateProductsByGroup(
  products: IAPProduct[]
): AggregatedPriceByGroup {
  return products.reduce((acc: AggregatedPriceByGroup, product) => {
    if (acc[product.group] && Array.isArray(acc[product.group])) {
      acc[product.group].push(product);
    } else {
      acc[product.group] = [product];
    }

    return acc;
  }, {});
}

function getPriceFromOffer(
  offer: IAPOffer,
  title: string,
  description: string,
  owned: boolean
): NgPatNormalizedOffer {
  const price: PricingPhase = getInfiniteRecurringPrice(offer.pricingPhases);

  return {
    title,
    currencyCode: price.currency || 'USD',
    description,
    productId: offer.productId,
    offerId: offer.id,
    // price: (price.priceMicros || 0) * 0.0001,
    price: price.price,
    interval: getNativeInterval(price.billingPeriod),
    platform: normalizePlatform(offer.platform),
    owned,
    isMostPopular: false
  };
}

function getInfiniteRecurringPrice(prices: PricingPhase[]): PricingPhase {
  return (
    prices.find(p => p.recurrenceMode === RecurrenceMode.INFINITE_RECURRING) ||
    prices[0]
  );
}

function getInfiniteRecurringOffer(offers: IAPOffer[]): IAPOffer {
  /**
   * Loop through offers, then loop through pricingPhases,
   * return the offer with the pricingPhase that has the recurrenceMode of INFINITE_RECURRING
   */
  for (const offer of offers) {
    for (const pricingPhase of offer.pricingPhases) {
      if (pricingPhase.recurrenceMode === RecurrenceMode.INFINITE_RECURRING) {
        return offer;
      }
    }
  }

  return offers[0];
}

export function normalizePlatform(
  platform: Platform
): NgPatNormalizedPricePlatform {
  switch (platform) {
    case Platform.APPLE_APPSTORE:
      return NgPatNormalizedPricePlatform.IOS;
    case Platform.GOOGLE_PLAY:
      return NgPatNormalizedPricePlatform.ANDROID;
    default:
      return NgPatNormalizedPricePlatform.WEB;
  }
}

export function getPlatformFromNormalizedPlatform(
  platform: NgPatNormalizedPricePlatform
): Platform {
  switch (platform) {
    case NgPatNormalizedPricePlatform.IOS:
      return Platform.APPLE_APPSTORE;
    case NgPatNormalizedPricePlatform.ANDROID:
      return Platform.GOOGLE_PLAY;
    default:
      return Platform.APPLE_APPSTORE;
  }
}

function transformGroupToPrice(products: IAPProduct[]): NgPatNormalizedPrice {
  const prices = products.map(p =>
    getPriceFromOffer(
      getInfiniteRecurringOffer(p.offers),
      p.title,
      p.description,
      p.owned
    )
  );

  return {
    title: prices[0].title,
    prices,
    isCustomPrice: false,
    // isMostPopular: false,
    features: products[0].features || [],
    paymentPlatform: normalizePlatform(products[0].platform)
  };
}

/**
 * @param products
 */
export function transformIapPriceToUiPrice(
  products: IAPProduct[]
): NgPatNormalizedPrice[] {
  return Object.values(aggregateProductsByGroup(products)).map(
    transformGroupToPrice
  );
}

export function transformStripePriceToUiPrice(
  products: StripeProductWithPrices[],
  hardCodedSubscriptionProducts: WebSubscriptionProducts,
  activeSubscriptionPriceDictionary: Dictionary<string>
): NgPatNormalizedPrice[] {
  // console.log('transformStripePriceToUiPrice', products);

  return products.map(p => {
    const prices = p.prices.map(price => {
      // console.log('price', price);
      // console.log(activeSubscriptionPriceDictionary);

      return <NgPatNormalizedOffer>{
        title: p.product.name,
        currencyCode: price.currency,
        description: price.description,
        productId: price.id,
        offerId: price.id,
        // price: price.unit_amount.toString(10),
        price: formatCurrency(price.unit_amount * 0.01, 'en', '$'),
        interval: getWebInterval(price.recurring.interval),
        platform: NgPatNormalizedPricePlatform.WEB,
        owned: !!activeSubscriptionPriceDictionary[price.id],
        isMostPopular: false,
        webPriceSubscriptionId: activeSubscriptionPriceDictionary[price.id]
      };
    });

    return {
      title: p.product.name,
      prices,
      isCustomPrice: false,
      features: hardCodedSubscriptionProducts[p.product.id]?.features || [],
      paymentPlatform: NgPatNormalizedPricePlatform.WEB
    };
  });
}
