import {Inject, Injectable} from '@angular/core';

import {AlgoliaPublishedQuiz} from '../../+quizzes';
import {
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment
} from '../../models/environmnent';
import {AlgoliaChipsAutocomplete} from '../algolia/algolia-chips-autocomplete';

@Injectable()
export class AlgoliaSearchSubjects extends AlgoliaChipsAutocomplete<AlgoliaPublishedQuiz> {
  constructor(
    @Inject(EC_ENVIRONMENT) private environment: EvolutionCognitionEnvironment
  ) {
    super(
      {
        apiKey: environment.algoliaSubjects.apiKey,
        appId: environment.algoliaSubjects.appId,
        index: environment.algoliaSubjects.index
      },
      'name'
    );
  }
}
