<ul
  role="list"
  class="pl-0">
  <li
    ec-research-list-item
    class="mb-5"
    *ngFor="let research of list"
    [research]="research"
    (doEditItem)="doEditItem.emit($event)"
    (doLeaveItem)="doLeaveItem.emit($event)"
    (doDeleteItem)="doDeleteItem.emit($event)"
    (doSelectItem)="doSelectItem.emit($event)"></li>
</ul>
