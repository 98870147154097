<form [formGroup]="dlcBookmarkService.bookmarkForm">
  <mat-form-field
    class="w-full mb-4"
    floatLabel="always">
    <mat-label>URL</mat-label>
    <input
      matInput
      placeholder="URL"
      formControlName="url" />
    @if (dlcBookmarkService.urlControl?.hasError('required')) {
    <mat-error>URL required</mat-error>
    } @if (dlcBookmarkService.urlControl?.hasError('minlength')) {
    <mat-error>At least 10 characters required</mat-error>
    }
  </mat-form-field>

  <mat-form-field
    class="w-full mb-4"
    floatLabel="always">
    <mat-label>Title</mat-label>
    <input
      matInput
      placeholder="Title"
      formControlName="title" />
  </mat-form-field>

  <mat-form-field
    class="w-full mb-4"
    floatLabel="always">
    <mat-label>Description</mat-label>
    <input
      matInput
      placeholder="Description"
      formControlName="description" />
  </mat-form-field>

  <mat-form-field class="w-full mb-4">
    <mat-label>URL keywords</mat-label>
    <mat-chip-grid
      #chipGrid
      aria-label="Enter keywords"
      formControlName="tags">
      @for (keyword of dlcBookmarkService.tags(); track keyword) {
      <mat-chip-row (removed)="dlcBookmarkService.removeKeyword(keyword)">
        {{ keyword }}
        <button
          matChipRemove
          aria-label="'remove ' + keyword">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      }
    </mat-chip-grid>
    <input
      placeholder="New keyword..."
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="dlcBookmarkService.separatorKeysCodes"
      (matChipInputTokenEnd)="dlcBookmarkService.addTag($event)" />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="dlcBookmarkService.autoCompleteSelect($event)">
      @for (tag of dlcBookmarkService.filteredTags(); track tag) {
      <mat-option [value]="tag">{{ tag }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</form>
