import {Injectable} from '@angular/core';
import {
  NgPatFirestoreCollectionQuery,
  ngPatFirestoreCollectionQueryFactory,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {BaseEntity, Exists} from '@gigasoftware/shared/models';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

import {NgPatAccountState} from '../+account/account.model';
import {deleteQuizs, updateQuizs, upsertQuizs} from '../+quizzes/quiz.actions';
import {Quiz} from '../+quizzes/quiz.model';
import {NgPatServiceConnector} from '../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../+websocket-registry/websocket-registry.models';
import {assignDeprecatedBaseEntitiesProperties} from '../entity/entity.model';
import {
  firestoreQueryPathByEntity,
  firestoreQuizCollection,
  firestoreStudyGroupCollection,
  firestoreUserStudyGroupCollection
} from '../firebase/database-paths';
import {aggregateUpdates} from '../fns/aggregate-updates';
import {QueryEngineCache} from '../services/query-engine-cache';
import {
  deleteStudyGroups,
  updateStudyGroups,
  upsertStudyGroups
} from './study-group.actions';
import {StudyGroup} from './study-group.model';
import {studyGroupFeatureKey} from './study-group.reducer';
import {selectAddedAndDeletedStudyGroups} from './study-group.selectors';

@Injectable({
  providedIn: 'root'
})
export class StudyGroupService implements NgPatFirebaseConnectionService {
  private _queryCollaborationService!: NgPatFirestoreCollectionQuery<StudyGroup>;
  private _queryPrivateQuizService!: NgPatFirestoreCollectionQuery<StudyGroup>;
  private _studyGroupQuizQueryCache!: QueryEngineCache<Quiz, StudyGroup>;
  connection: NgPatServiceConnector;
  connectionKey = studyGroupFeatureKey;

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {
    this.connection = new NgPatServiceConnector(this, this.store);
  }

  deleteDoc$(classroom: BaseEntity | undefined, uid: string) {
    if (classroom) {
      const path: string = classroom.isPrivate
        ? firestoreUserStudyGroupCollection(uid)
        : firestoreStudyGroupCollection();

      // const path = studyGroup.isPrivate ?
      return this.customFirestoreService.deleteDoc$(path);
    }
    return of(true);
  }

  deleteDocs$(classroom: BaseEntity | undefined, ids: string[], uid: string) {
    if (classroom) {
      const isPrivate = classroom.isPrivate;
      const path: string = isPrivate
        ? firestoreUserStudyGroupCollection(uid)
        : firestoreStudyGroupCollection();
      return this.customFirestoreService.deleteDocs$(path, ids);
    }

    return of(true);
  }

  ngPatOnInit() {
    this._queryCollaborationService =
      new NgPatFirestoreCollectionQuery<StudyGroup>(
        {
          deleteManyAction: (ids: string[]) => deleteStudyGroups({ids}),
          queryMember: true,
          updateManyAction: (studyGroups: StudyGroup[]) =>
            updateStudyGroups({
              studyGroups: aggregateUpdates(
                assignDeprecatedBaseEntitiesProperties(
                  studyGroups
                ) as StudyGroup[]
              )
            }),
          upsertManyAction: (studyGroups: StudyGroup[]) =>
            upsertStudyGroups({
              studyGroups: assignDeprecatedBaseEntitiesProperties(
                studyGroups
              ) as StudyGroup[]
            })
        },
        this.store,
        this.customFirestoreService
      );

    // TODO Not needed
    const queryStudyGroupConfig = ngPatFirestoreCollectionQueryFactory(
      {
        deleteManyAction: (ids: string[]) => deleteStudyGroups({ids}),
        queryMember: false,
        updateManyAction: (studyGroups: StudyGroup[]) =>
          updateStudyGroups({
            studyGroups: aggregateUpdates(
              assignDeprecatedBaseEntitiesProperties(
                studyGroups
              ) as StudyGroup[]
            )
          }),
        upsertManyAction: (studyGroups: StudyGroup[]) =>
          upsertStudyGroups({
            studyGroups: assignDeprecatedBaseEntitiesProperties(
              studyGroups
            ) as StudyGroup[]
          })
      },
      this.store,
      this.customFirestoreService
    );

    this._queryPrivateQuizService =
      queryStudyGroupConfig.createFirestoreCollectionQuery();

    const queryQuizConfig = ngPatFirestoreCollectionQueryFactory(
      {
        deleteManyAction: (ids: string[]) => deleteQuizs({ids}),
        queryMember: false,
        updateManyAction: (quizs: Quiz[]) =>
          updateQuizs({
            quizs: aggregateUpdates(
              assignDeprecatedBaseEntitiesProperties(quizs) as Quiz[]
            )
          }),
        upsertManyAction: (quizs: Quiz[]) =>
          upsertQuizs({
            quizs: assignDeprecatedBaseEntitiesProperties(quizs) as Quiz[]
          })
      },
      this.store,
      this.customFirestoreService
    );

    const quizPathGenerator = (entity: Quiz, uid: string) =>
      `${firestoreQueryPathByEntity(
        entity as BaseEntity,
        uid
      )}/${firestoreQuizCollection()}`;

    this._studyGroupQuizQueryCache = new QueryEngineCache<Quiz, StudyGroup>(
      queryQuizConfig,
      this.store,
      selectAddedAndDeletedStudyGroups,
      quizPathGenerator,
      'id'
    );
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    this._queryCollaborationService.onConnect(
      firestoreStudyGroupCollection(),
      null,
      <string>user.uid
    );
    this._queryPrivateQuizService.onConnect(
      firestoreUserStudyGroupCollection(<string>user.uid),
      null,
      null
    );
    this._studyGroupQuizQueryCache.onConnect(user);
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    this._queryCollaborationService.onDisconnect();
    this._queryPrivateQuizService.onDisconnect();
    this._studyGroupQuizQueryCache.onDisconnect();

    // Unsubscribe to query before calling this
  }

  updateDoc(g: StudyGroup) {
    return this.customFirestoreService.user$.pipe(
      take(1),
      switchMap((u: User) => {
        const path = firestoreQueryPathByEntity(g, <string>u.uid);
        return this.customFirestoreService.merge$(path, g);
      })
    );
  }

  updatePartialFirestore$(
    changes: Partial<StudyGroup>,
    quiz: StudyGroup,
    uid: string | null
  ): Observable<StudyGroup> {
    if (uid) {
      return this.customFirestoreService
        .merge$<StudyGroup>(firestoreQueryPathByEntity(quiz, uid), changes)
        .pipe(map((r: Exists<StudyGroup>) => r.data));
    }

    return of(quiz);
  }
}
