import {
  FirestoreCollectionQueryConfig,
  FirestoreCollectionQueryConnectConfig,
  NgPatFirestoreCollectionQuery
} from '@gigasoftware/shared/firebase';

export interface FirestoreCollectionQueryStoreConfig<T> {
  collectionQuery: NgPatFirestoreCollectionQuery<T>;
  connectConfig: FirestoreCollectionQueryConnectConfig;
  // Create firestore path from connectConfig
  firestorePathFn: (
    config: FirestoreCollectionQueryConnectConfig
  ) => FirestoreCollectionQueryConnectConfig;
  id: string;
}

export interface NgPatFirestoreCollectionQueryStoreUpsert<T> {
  collectionQueryConfig: FirestoreCollectionQueryConfig<T>;
  connectConfig: FirestoreCollectionQueryConnectConfig;
  // firestoreCollectionPath: string;
  // Create firestore path from connectConfig
  firestorePathFn: (
    config: FirestoreCollectionQueryConnectConfig
  ) => FirestoreCollectionQueryConnectConfig;
  id: string;
}

export const defaultFirestorePathFn = (
  config: FirestoreCollectionQueryConnectConfig
): FirestoreCollectionQueryConnectConfig => {
  return config;
};

export interface NgPatFirestoreQueryStoreAdd<T> {
  entity: T;
  firestoreCollectionPath: string;
  id: string;
}
