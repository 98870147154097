import {
  animate,
  AnimationTriggerMetadata,
  group,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const panelAnimation: AnimationTriggerMetadata = trigger(
  'panelAnimation',
  [
    state(
      'void, hidden',
      style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      })
    ),
    transition(':enter, hidden => visible', [
      group([
        animate('0.03s linear', style({opacity: 1})),
        animate(
          '0.12s cubic-bezier(0, 0, 0.2, 1)',
          style({transform: 'scaleY(1)'})
        )
      ])
    ]),
    transition(':leave, visible => hidden', [
      animate('0.075s linear', style({opacity: 0}))
    ])
  ]
);
