import {CommonModule, DOCUMENT} from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  input,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {Subject, takeUntil} from 'rxjs';

import {displayNoneInlineFlexAnimation} from '../../../animations/visible.animation';
import {coerceThemePalette} from '../../button/button.fns';
import {DlcSquareIconButtonComponent} from '../../button/dlc-square-icon-button/dlc-square-icon-button.component';

@Component({
    animations: [displayNoneInlineFlexAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.dlc-accent]': 'color() === "accent"',
        '[class.dlc-mat-inline-edit--center]': 'center',
        '[class.dlc-mat-inline-edit--dirty]': 'dirty()',
        '[class.dlc-mat-inline-edit--focus]': 'inputFocusCss()',
        '[class.dlc-primary]': 'color() !== "accent" && color() !== "warn"',
        '[class.dlc-warn]': 'color() === "warn"',
        class: 'dlc-mat-inline-edit'
    },
    imports: [
        CommonModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DlcSquareIconButtonComponent
    ],
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DlcMatInlineEditComponent)
        }
    ],
    selector: 'dlc-mat-inline-edit, input[dlcMatInlineEdit], input[dlc-mat-inline-edit]',
    styleUrl: './dlc-mat-inline-edit.component.scss',
    templateUrl: './dlc-mat-inline-edit.component.html'
})
export class DlcMatInlineEditComponent
  implements OnDestroy, OnInit, AfterContentInit
{
  private _formControlValue: WritableSignal<string> = signal('');
  private _onDestroy$: Subject<boolean> = new Subject();
  private _placeholder = '';
  private _value: WritableSignal<string> = signal('');
  private context = this;
  @Input() appearance: 'outline' | 'fill' = 'outline';
  @Input() center = false;
  color = input(undefined, {
    transform: coerceThemePalette
  });
  dirty: Signal<boolean> = computed(() => {
    return this._formControlValue() !== this._value();
  });
  doneButtonDisabled = true;
  focusBtnsDisabledSig: WritableSignal<string> = signal('true');
  @Input() formControl: FormControl<string> = new FormControl();
  @ViewChild('input', {static: true}) input!: ElementRef;
  /**
   * the blur event on the input is triggered before any
   * other event and causes a race condition,
   * so we need to listen to the mousedown on the document.
   */
  // blur$ = fromEvent(this.document, 'mousedown').pipe(
  //   takeUntil(this._onDestroy$)
  // );

  inputFocusCss: WritableSignal<boolean> = signal(false);

  @Input() label = '';

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() valueChanges: Subject<string> = new Subject();

  constructor(@Inject(DOCUMENT) private doc: Document) {}

  addDocumentEventListener() {
    const that = this;
    // clearTimeout(this.timer);
    // this.doc.addEventListener(
    //   'mousedown',
    //   function documentEventHandler() {
    //     console.log('mousedown');
    //     that.inputFocusCss.set(false);
    //   },
    //   {once: true}
    // );
  }

  ngAfterContentInit() {
    this.input.nativeElement.addEventListener(
      'blur',
      this.onBlur.bind(this.context)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }

  ngOnInit() {
    if (this.formControl) {
      this.formControl.valueChanges
        .pipe(takeUntil(this._onDestroy$))
        .subscribe(value => {
          this.valueChanges.next(value);
          this._formControlValue.set(value);

          this.focusBtnsDisabledSig.set(`${value === this._value()}`);
          if (!value || (value && value.length > 0)) {
            this.doneButtonDisabled = false;
          }

          if (value && value.length === 0) {
            this.doneButtonDisabled = true;
            this.formControl.markAsPristine();
          }
        });

      if (this._value() && this.formControl.value !== this._value()) {
        this.formControl.setValue(this._value());
        this.formControl.markAsPristine();
      }

      // this.formControl.statusChanges
      //   .pipe(
      //     takeUntil(this._onDestroy$)
      //   )
      //   .subscribe((status: FormControlStatus) => {
      //     console.log('status', status);
      //   })
    }

    // this.blur$.subscribe(() => {
    //   console.log('blur$');
    // });
  }

  onBlur(event: Event) {
    const that = this;
    event.preventDefault();
    event.stopPropagation();
    this.input.nativeElement.removeEventListener(
      'blur',
      this.onBlur.bind(that.context)
    );
    setTimeout(() => {
      this.formControl.setValue(this._value());
      this.inputFocusCss.set(false);
      this.input.nativeElement.addEventListener(
        'blur',
        this.onBlur.bind(that.context)
      );
    }, 100);
  }

  onClear(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.formControl.setValue('');
    this.inputFocusCss.set(true);
    this.doneButtonDisabled = true;
    this.formControl.markAsPristine();
    // this.disabled = true;
    this.input.nativeElement.focus();
  }

  // timer: any;

  onDone(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.valueChange.emit(this.formControl.value);
    this._value.set(this.formControl.value);
    // this.formControl.markAsPristine();

    this.inputFocusCss.set(false);
  }

  onEdit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.inputFocusCss.set(true);

    this.input.nativeElement.focus();
  }

  onEnter(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.valueChange.emit(this.formControl.value);
    this._value.set(this.formControl.value);
    this.addDocumentEventListener();
  }

  onInputFocus(event: FocusEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.inputFocusCss.set(true);
    this.addDocumentEventListener();
  }

  onUndo(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.formControl.setValue(this._value());

    this.inputFocusCss.set(false);
  }

  @Input()
  set disabled(value: boolean) {
    this.focusBtnsDisabledSig.set(`${value}`);
    if (value) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  get placeholder() {
    return this._placeholder;
  }

  @Input()
  set placeholder(value: string) {
    if (value && value.length) {
      this._placeholder = value;
    }
  }

  @Input()
  set value(v: string | undefined) {
    if (v && v.length) {
      this._value.set(v);
    }

    if (this.formControl && v && v.length) {
      this.formControl.setValue(v);
    }
  }
}
