<ng-content
  select="ng-pat-bullet-chart-title, [ng-pat-bullet-chart-title], [ng-patBulletChartTitle]"></ng-content>
<ng-content
  select="ng-pat-bullet-chart-description, [ng-pat-bullet-chart-description], [ngPatBulletChartDescription]"></ng-content>

<div class="ng-pat-bullet-chart-tooltip-container">
  <ng-pat-charts-tooltip
    [calc]="chart.tooltip"
    [style]="tooltipStyle()">
    <ng-content
      select="ng-pat-charts-tooltip-content, [ng-pat-charts-tooltip-content], [ngPatChartsTooltipContent]"></ng-content>
  </ng-pat-charts-tooltip>
</div>

<div
  #chartContainer
  ngPatResizeObserver
  class="g-ng-pat-chart-container svg-bullet-chart"
  (resize)="onResize$.next($event)"></div>
<div class="bullet-chart-labels">
  <div class="bullet-chart-min-label g-ng-pat-chart-label">
    {{ minChartLabel() }}
  </div>
  <div class="bullet-chart-max-label g-ng-pat-chart-label">
    {{ maxChartLabel() }}
  </div>
</div>
