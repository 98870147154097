import {InjectionToken} from '@angular/core';

export const AUTH_URLS: InjectionToken<AuthURLs> = new InjectionToken(
  'AUTH_URLS'
);

export interface AuthURLs {
  appURL: string;
  authURL: string;
}

export interface GigaAuthTokenData {
  action: string;
  displayName: string;
  email: string;
  idToken: string;
  otlid: string;
}
