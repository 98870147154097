import {Dictionary, EntityState} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {UiNotification} from './notification.model';
import * as notificationReducer from './notification.reducer';

export const selectNotificationState =
  createFeatureSelector<notificationReducer.NotificationState>(
    notificationReducer.notificationFeatureKey
  );

const {selectIds, selectEntities, selectAll, selectTotal} =
  notificationReducer.notificationAdapter.getSelectors();

export const selectTotalNotifications = createSelector(
  selectAll,
  (n: UiNotification[]) => n.length
);

export const selectHasNotifications = createSelector(
  selectNotificationState,
  (state: EntityState<UiNotification>) => {
    return selectAll(state).length > 0;
  }
);

export const selectAllNotifications = createSelector(
  selectNotificationState,
  selectAll
);
export const selectNotificationEntities = createSelector(
  selectNotificationState,
  selectEntities
);
export const selectNotificationIds = createSelector(
  selectNotificationState,
  selectIds
);
export const selectNotificationTotal = createSelector(
  selectNotificationState,
  selectTotal
);

export const selectHasNoNotifications = createSelector(
  selectNotificationState,
  (state: EntityState<UiNotification>) => {
    return selectAll(state).length < 1;
  }
);

// export const selectedNotificationID = createSelector(
//   selectNotificationState,
//   (state: NotificationReducer.NotificationState) => state.selectedNotificationID
// );

export const getNotificationByID = (id: string) =>
  createSelector(
    selectNotificationEntities,
    (entities: Dictionary<UiNotification>) => {
      return entities[id];
    }
  );
