import {
  NgPatFirestoreCollectionQuery,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {currentValueFrom} from '@gigasoftware/shared/rxjs';
import {Store} from '@ngrx/store';

import {NgPatAccountState} from '../+account/account.model';
import {NgPatServiceConnector} from '../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../+websocket-registry/websocket-registry.models';
import {NgPatEntityStore} from './entity-store/ng-pat-entity-store';
import {
  FirestoreCollectionQueryStoreConfig,
  NgPatFirestoreCollectionQueryStoreUpsert
} from './firestore-collection-query-store.models';

export class NgPatFirestoreCollectionQueryStore<T>
  implements NgPatFirebaseConnectionService
{
  collectionQueryStore = new NgPatEntityStore<
    FirestoreCollectionQueryStoreConfig<T>
  >();

  connection: NgPatServiceConnector;

  constructor(
    public connectionKey: string,
    private customFirestore: NgPatFirestoreService,
    private store: Store
  ) {
    this.connection = new NgPatServiceConnector(this, this.store);
  }

  upsertConfig(config: NgPatFirestoreCollectionQueryStoreUpsert<T>) {
    if (!this.collectionQueryStore.has(config.id)) {
      const collectionQueryConfig = {
        collectionQuery: new NgPatFirestoreCollectionQuery<T>(
          config.collectionQueryConfig,
          this.store,
          this.customFirestore
        ),
        connectConfig: config.connectConfig,
        firestorePathFn: config.firestorePathFn,
        id: config.id
      };

      this.collectionQueryStore.setOne(collectionQueryConfig);

      if (this.connection.isConnected && this.customFirestore.isLoggedIn) {
        const connectionConfig = config.firestorePathFn({
          ...config.connectConfig,
          uid: this.customFirestore.uid
        });
        collectionQueryConfig.collectionQuery.onConnect(
          connectionConfig.path,
          connectionConfig.parentID,
          this.customFirestore.uid,
          connectionConfig.queryConstraints
        );
      }
    }
  }

  upsertConfigs(configs: NgPatFirestoreCollectionQueryStoreUpsert<T>[]) {
    configs.forEach(config => {
      this.upsertConfig(config);
    });
  }

  deleteConfig(id: string) {
    const config: FirestoreCollectionQueryStoreConfig<T> | null | undefined =
      this.collectionQueryStore.selectById(id);
    if (config) {
      config.collectionQuery.onDisconnect();
      this.collectionQueryStore.deleteOne(id);
    }
  }

  deleteConfigs(ids: string[]) {
    ids.forEach(id => {
      this.deleteConfig(id);
    });
  }

  async onConnect(user: NgPatAccountState) {
    const allQueries = await currentValueFrom(
      this.collectionQueryStore.selectAll$
    );

    if (allQueries && allQueries.length > 0) {
      allQueries.forEach(config => {
        const connectionConfig = config.firestorePathFn({
          ...config.connectConfig,
          uid: user.uid
        });
        config.collectionQuery.onConnect(
          connectionConfig.path,
          connectionConfig.parentID,
          user.uid,
          connectionConfig.queryConstraints
        );
      });
    }
  }

  async onDisconnect(user: NgPatAccountState) {
    const allQueries = await currentValueFrom(
      this.collectionQueryStore.selectAll$
    );

    if (allQueries && allQueries.length > 0) {
      allQueries.forEach(config => {
        config.collectionQuery.onDisconnect();
      });
    }
  }
}
