import {Bookmark} from '@gigasoftware/shared/models';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {BookmarkActions} from './bookmark.actions';

export const bookmarksFeatureKey = 'bookmarks';

export type BookmarkState = EntityState<Bookmark>;

export const bookmarkAdapter: EntityAdapter<Bookmark> =
  createEntityAdapter<Bookmark>();

export const initialBookmarkState: BookmarkState =
  bookmarkAdapter.getInitialState({
    // additional entity state properties
  });

export const reducer = createReducer(
  initialBookmarkState,
  on(BookmarkActions.addBookmark, (state, action) =>
    bookmarkAdapter.addOne(action.bookmark, state)
  ),
  on(BookmarkActions.upsertBookmark, (state, action) =>
    bookmarkAdapter.upsertOne(action.bookmark, state)
  ),
  on(BookmarkActions.addBookmarks, (state, action) =>
    bookmarkAdapter.addMany(action.bookmarks, state)
  ),
  on(BookmarkActions.upsertBookmarks, (state, action) =>
    bookmarkAdapter.upsertMany(action.bookmarks, state)
  ),
  on(BookmarkActions.updateBookmark, (state, action) =>
    bookmarkAdapter.updateOne(action.bookmark, state)
  ),
  on(BookmarkActions.updateBookmarks, (state, action) =>
    bookmarkAdapter.updateMany(action.bookmarks, state)
  ),
  on(BookmarkActions.deleteBookmark, (state, action) =>
    bookmarkAdapter.removeOne(action.id, state)
  ),
  on(BookmarkActions.deleteBookmarks, (state, action) =>
    bookmarkAdapter.removeMany(action.ids, state)
  ),
  on(BookmarkActions.loadBookmarks, (state, action) =>
    bookmarkAdapter.setAll(action.bookmarks, state)
  ),
  on(BookmarkActions.clearBookmarks, state => bookmarkAdapter.removeAll(state))
);
