import {
  RoleMap,
  RoleMapCreator,
  RoleMapCreatorByRole,
  EC_QUIZ_ROLES,
  UserIdWithRole,
  UserRoleCreator
} from '../models/user.model.deprecated';

export const createRoleMap: RoleMapCreatorByRole = (
  role: EC_QUIZ_ROLES
): RoleMap => {
  return {
    anonymous: role === EC_QUIZ_ROLES.Anonymous,
    author: role === EC_QUIZ_ROLES.Author,
    collaborator: role === EC_QUIZ_ROLES.Collaborator,
    owner: role === EC_QUIZ_ROLES.Owner,
    parent: role === EC_QUIZ_ROLES.Mentor,
    professor: role === EC_QUIZ_ROLES.Professor,
    readOnly: role === EC_QUIZ_ROLES.ReadOnly,
    reviewer: role === EC_QUIZ_ROLES.Reviewer,
    student: role === EC_QUIZ_ROLES.Student,
    teacher: role === EC_QUIZ_ROLES.Teacher
  };
};

export const createAuthorRoleMap: RoleMapCreator = () =>
  createRoleMap(EC_QUIZ_ROLES.Author);
export const createOwnerRoleMap: RoleMapCreator = () =>
  createRoleMap(EC_QUIZ_ROLES.Owner);
export const createTeacherRoleMap: RoleMapCreator = () =>
  createRoleMap(EC_QUIZ_ROLES.Teacher);
export const createProfessorRoleMap: RoleMapCreator = () =>
  createRoleMap(EC_QUIZ_ROLES.Professor);
export const createParentRoleMap: RoleMapCreator = () =>
  createRoleMap(EC_QUIZ_ROLES.Mentor);
export const createCollaboratorRoleMap: RoleMapCreator = () =>
  createRoleMap(EC_QUIZ_ROLES.Collaborator);
export const createReadOnlyRoleMap: RoleMapCreator = () =>
  createRoleMap(EC_QUIZ_ROLES.ReadOnly);
export const createAnonymousRoleMap: RoleMapCreator = () =>
  createRoleMap(EC_QUIZ_ROLES.Anonymous);
export const createReviewerRoleMap: RoleMapCreator = () =>
  createRoleMap(EC_QUIZ_ROLES.Reviewer);
export const createStudentRoleMap: RoleMapCreator = () =>
  createRoleMap(EC_QUIZ_ROLES.Student);

export type UserRoleByRoleCreator = (
  uid: string,
  role: EC_QUIZ_ROLES
) => UserIdWithRole;

export const creatUserRole: UserRoleByRoleCreator = (
  uid: string,
  role: EC_QUIZ_ROLES
): UserIdWithRole => ({
  role,
  uid
});

export const createAuthorUserRole: UserRoleCreator = (
  uid: string
): UserIdWithRole => creatUserRole(uid, EC_QUIZ_ROLES.Author);

export const createOwnerUserRole: UserRoleCreator = (
  uid: string
): UserIdWithRole => creatUserRole(uid, EC_QUIZ_ROLES.Owner);

export const createTeacherUserRole: UserRoleCreator = (
  uid: string
): UserIdWithRole => creatUserRole(uid, EC_QUIZ_ROLES.Teacher);

export const createProfessorUserRole: UserRoleCreator = (
  uid: string
): UserIdWithRole => creatUserRole(uid, EC_QUIZ_ROLES.Professor);

export const createParentUserRole: UserRoleCreator = (
  uid: string
): UserIdWithRole => creatUserRole(uid, EC_QUIZ_ROLES.Mentor);

export const createCollaboratorUserRole: UserRoleCreator = (
  uid: string
): UserIdWithRole => creatUserRole(uid, EC_QUIZ_ROLES.Collaborator);

export const createReadOnlyRole: UserRoleCreator = (
  uid: string
): UserIdWithRole => creatUserRole(uid, EC_QUIZ_ROLES.ReadOnly);

export const createAnonymousRole: UserRoleCreator = (
  uid: string
): UserIdWithRole => creatUserRole(uid, EC_QUIZ_ROLES.Anonymous);

export const createReviewerRole: UserRoleCreator = (
  uid: string
): UserIdWithRole => creatUserRole(uid, EC_QUIZ_ROLES.Reviewer);

export const createStudentRole: UserRoleCreator = (
  uid: string
): UserIdWithRole => creatUserRole(uid, EC_QUIZ_ROLES.Student);

export function isCreatedBy(
  config: {createdBy: UserIdWithRole},
  uid: string
): boolean {
  if (config && config.createdBy && config.createdBy.uid) {
    return uid === config.createdBy.uid;
  }
  return false;
}

export const userIsStudent = (role: EC_QUIZ_ROLES) =>
  role === EC_QUIZ_ROLES.Student;
export const userIsTeacher = (role: EC_QUIZ_ROLES) =>
  role === EC_QUIZ_ROLES.Teacher;
export const userIsMentor = (role: EC_QUIZ_ROLES) =>
  role === EC_QUIZ_ROLES.Mentor;

export const userIsAuthor = (role: EC_QUIZ_ROLES) =>
  role === EC_QUIZ_ROLES.Author;
export const userIsOwner = (role: EC_QUIZ_ROLES) =>
  role === EC_QUIZ_ROLES.Owner;
export const userIsProfessor = (role: EC_QUIZ_ROLES) =>
  role === EC_QUIZ_ROLES.Professor;
export const userIsCollaborator = (role: EC_QUIZ_ROLES) =>
  role === EC_QUIZ_ROLES.Collaborator;
export const userIsReadOnly = (role: EC_QUIZ_ROLES) =>
  role === EC_QUIZ_ROLES.ReadOnly;
export const userIsAnonymous = (role: EC_QUIZ_ROLES) =>
  role === EC_QUIZ_ROLES.Anonymous;
export const userIsReviewer = (role: EC_QUIZ_ROLES) =>
  role === EC_QUIZ_ROLES.Reviewer;

export function getMemberOwnerUID(members: {[uid: string]: UserIdWithRole}) {
  return Object.keys(members).reduce((uid: string | null, key: string) => {
    if (!uid && userIsOwner(members[key].role)) {
      return key;
    }

    return uid;
  }, null);
}

export const RoleNames: {[key: string]: string} = {
  [EC_QUIZ_ROLES.Anonymous]: 'Anonymous',
  [EC_QUIZ_ROLES.Author]: 'Author',
  [EC_QUIZ_ROLES.Collaborator]: 'Collaborator',
  [EC_QUIZ_ROLES.Mentor]: 'Mentor',
  [EC_QUIZ_ROLES.Owner]: 'Owner',
  [EC_QUIZ_ROLES.Professor]: 'Professor',
  [EC_QUIZ_ROLES.ReadOnly]: 'Reviewer',
  [EC_QUIZ_ROLES.Reviewer]: 'Reviewer',
  [EC_QUIZ_ROLES.Student]: 'Student',
  [EC_QUIZ_ROLES.Teacher]: 'Teacher'
};

export function getRoleTitle(type: EC_QUIZ_ROLES): string {
  return RoleNames[type];
}

export interface RoleTypeName {
  name: string;
  type: EC_QUIZ_ROLES;
}

export const studyGroupRoleTypes: RoleTypeName[] = [
  {
    name: getRoleTitle(EC_QUIZ_ROLES.Professor),
    type: EC_QUIZ_ROLES.Professor
  },
  {
    name: getRoleTitle(EC_QUIZ_ROLES.Teacher),
    type: EC_QUIZ_ROLES.Teacher
  },
  {
    name: getRoleTitle(EC_QUIZ_ROLES.Mentor),
    type: EC_QUIZ_ROLES.Mentor
  },
  {
    name: getRoleTitle(EC_QUIZ_ROLES.Student),
    type: EC_QUIZ_ROLES.Student
  }
];

export const roleTypeDict: {[key: number]: RoleTypeName} = {
  [EC_QUIZ_ROLES.Anonymous]: {
    name: getRoleTitle(EC_QUIZ_ROLES.Anonymous),
    type: EC_QUIZ_ROLES.Anonymous
  },
  [EC_QUIZ_ROLES.Author]: {
    name: getRoleTitle(EC_QUIZ_ROLES.Author),
    type: EC_QUIZ_ROLES.Author
  },
  [EC_QUIZ_ROLES.Collaborator]: {
    name: getRoleTitle(EC_QUIZ_ROLES.Collaborator),
    type: EC_QUIZ_ROLES.Collaborator
  },
  [EC_QUIZ_ROLES.Mentor]: {
    name: getRoleTitle(EC_QUIZ_ROLES.Mentor),
    type: EC_QUIZ_ROLES.Mentor
  },
  [EC_QUIZ_ROLES.Owner]: {
    name: getRoleTitle(EC_QUIZ_ROLES.Owner),
    type: EC_QUIZ_ROLES.Owner
  },
  [EC_QUIZ_ROLES.Professor]: {
    name: getRoleTitle(EC_QUIZ_ROLES.Professor),
    type: EC_QUIZ_ROLES.Professor
  },
  [EC_QUIZ_ROLES.ReadOnly]: {
    name: getRoleTitle(EC_QUIZ_ROLES.ReadOnly),
    type: EC_QUIZ_ROLES.ReadOnly
  },
  [EC_QUIZ_ROLES.Reviewer]: {
    name: getRoleTitle(EC_QUIZ_ROLES.Reviewer),
    type: EC_QUIZ_ROLES.Reviewer
  },
  [EC_QUIZ_ROLES.Student]: {
    name: getRoleTitle(EC_QUIZ_ROLES.Student),
    type: EC_QUIZ_ROLES.Student
  },
  [EC_QUIZ_ROLES.Teacher]: {
    name: getRoleTitle(EC_QUIZ_ROLES.Teacher),
    type: EC_QUIZ_ROLES.Teacher
  }
};
