import {
  BASE_COLLECTION_NAME,
  Bookmark,
  DlcCreateBookmarkForm
} from '@gigasoftware/shared/models';

import {Research} from '../+research';
import {
  defaultFirestorePathFn,
  NgPatFirestoreCollectionQueryStoreUpsert,
  NgPatFirestoreQueryStoreAdd
} from '../custom-store/firestore-collection-query-store.models';
import {CollaborativeEntity} from '../entity/entity.model';
import {
  firestoreBookmarkCollectionPath,
  firestoreBookmarkDocumentPath,
  firestoreBookmarksCollection
} from '../firebase';
import {aggregateUpdates} from '../fns/aggregate-updates';
import {BookmarkActions} from './bookmark.actions';

export const createBookmarkCollectionQueryConfig = (
  b: NgPatFirestoreQueryStoreAdd<CollaborativeEntity>
): NgPatFirestoreCollectionQueryStoreUpsert<Bookmark> => {
  return {
    collectionQueryConfig: {
      deleteManyAction: (ids: string[]) =>
        BookmarkActions.deleteBookmarks({ids}),
      queryMember: false,
      updateManyAction: (bookmarks: Bookmark[]) =>
        BookmarkActions.updateBookmarks({
          bookmarks: aggregateUpdates(bookmarks)
        }),
      upsertManyAction: (bookmarks: Bookmark[]) =>
        BookmarkActions.upsertBookmarks({bookmarks})
    },
    connectConfig: {
      parentID: b.entity.parentEntityID,
      path: b.firestoreCollectionPath,
      queryConstraints: [],
      uid: null // will be set in onConnect
    },
    firestorePathFn: defaultFirestorePathFn,
    id: b.id
  };
};

export const createDlcBookmark = (
  form: DlcCreateBookmarkForm,
  id: string,
  uid: string,
  collaborativeEntity?: CollaborativeEntity | null
): Bookmark => {
  return {
    createdAt: new Date(),
    description: form.description || '',
    firestoreCollectionPath: firestoreBookmarkCollectionPath(
      id,
      uid,
      collaborativeEntity
    ),
    firestoreDocumentPath: firestoreBookmarkDocumentPath(
      id,
      uid,
      collaborativeEntity
    ),
    id,
    imageUrl: '',
    isArchived: false,
    isDeleted: false,
    isFavorite: false,
    parentEntityID: collaborativeEntity?.id || null,
    parentEntityType: collaborativeEntity?.entityType || null,
    tags: form.tags,
    title: form.title || '',
    updatedAt: new Date(),
    url: form.url || ''
  };
};

export const copyBookmark = (
  source: Bookmark,
  id: string,
  uid: string,
  collaborativeEntity?: CollaborativeEntity | null
): Bookmark => {
  return {
    createdAt: new Date(),
    description: source.description || '',
    firestoreCollectionPath: firestoreBookmarkCollectionPath(
      id,
      uid,
      collaborativeEntity
    ),
    firestoreDocumentPath: firestoreBookmarkDocumentPath(
      id,
      uid,
      collaborativeEntity
    ),
    id,
    imageUrl: source.imageUrl,
    isArchived: source.isArchived,
    isDeleted: source.isDeleted,
    isFavorite: source.isFavorite,
    parentEntityID: collaborativeEntity?.id || null,
    parentEntityType: collaborativeEntity?.entityType || null,
    tags: source.tags,
    title: source.title || '',
    updatedAt: new Date(),
    url: source.url || ''
  };
};

export const createBookmarkConfigs = (
  entities: Research[],
  baseCollectionName: BASE_COLLECTION_NAME
): NgPatFirestoreQueryStoreAdd<CollaborativeEntity>[] => {
  return entities.map((research: Research) => {
    return {
      entity: research,
      firestoreCollectionPath: firestoreBookmarksCollection(
        baseCollectionName,
        research.id
      ),
      id: research.id
    };
  });
};
