import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.dlc-footer-bar--border-upper-left-radius]': `borderUpperLeftRadius() == true`,
    '[class.dlc-footer-bar--border-upper-right-radius]':
      'borderUpperRightRadius() == true',
    class: `dlc-footer-bar`
  },
  selector: 'dlc-footer-bar',
  standalone: true,
  styleUrls: ['./dlc-footer-bar.component.scss'],
  templateUrl: './dlc-footer-bar.component.html'
})
export class DlcFooterBarComponent {
  borderUpperLeftRadius = input(true);
  borderUpperRightRadius = input(true);
}
