import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {
  Project,
  selectLoggedInUserIsProjectOwner
} from '@gigasoftware/shared/store';
import {
  DlcImageDirective,
  DlcRoundedIconButtonComponent,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';
import {Store} from '@ngrx/store';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-project-list-item'
    },
    imports: [
        CommonModule,
        DlcImageDirective,
        DlcRoundedIconButtonComponent,
        DlcRoundedTextIconButtonComponent,
        MatIconModule,
        MatMenuModule
    ],
    selector: 'ec-project-list-item, [ec-project-list-item]',
    styleUrl: './ec-project-list-item.component.scss',
    templateUrl: './ec-project-list-item.component.html'
})
export class EcProjectListItemComponent {
  @Output() doDeleteItem: EventEmitter<Project> = new EventEmitter<Project>();

  @Output() doEditItem: EventEmitter<Project> = new EventEmitter<Project>();

  @Output() doLeaveItem: EventEmitter<Project> = new EventEmitter<Project>();
  @Output() doSelectItem: EventEmitter<Project> = new EventEmitter<Project>();
  loggedInUserIsOwner: Signal<boolean> = this.store.selectSignal(
    selectLoggedInUserIsProjectOwner
  );
  @Input() project!: Project;

  constructor(private store: Store) {}

  onDeleteStudyGroup() {
    this.doDeleteItem.emit(this.project);
  }

  onEditStudyGroup() {
    this.doEditItem.emit(this.project);
  }

  onLeaveStudyGroup() {
    this.doLeaveItem.emit(this.project);
  }

  onSelectStudyGroup() {
    this.doSelectItem.emit(this.project);
  }
}
