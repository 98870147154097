<h1 mat-dialog-title>Subscription Updated</h1>
<div mat-dialog-content>Your subscription has been updated.</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    mat-dialog-close>
    Close
  </button>
</div>
