import {getFileExtension} from '@gigasoftware/shared/fn';
import {FILE_TYPE, FileType, TypeToExtention} from './file-type';

/**
 * Get extension by Blob or File type
 * @param type
 */
export function getExtensionByBlobType(blob: Blob | File): string {
  return TypeToExtention[blob.type];
}

export function getExtensionByType(type: string | FileType) {
  return TypeToExtention[type];
}

export function isDatType(type: string): boolean {
  return type === FILE_TYPE.DAT;
}

export function isAudioBuffer(audioBuffer: {sampleRate: number}): boolean {
  return audioBuffer && audioBuffer.sampleRate !== undefined;
}

export function isURLType(response: {url: string}): boolean {
  return response && response.url !== undefined && response.url.length > 0;
}

// Extensions
export const mp3Extension = TypeToExtention[FILE_TYPE.AUDIO_MP3];

export function pathIsMp3(path: string): boolean {
  return getFileExtension(path) === getExtensionByType(FILE_TYPE.AUDIO_MP3);
}

export const oggExtension = TypeToExtention[FILE_TYPE.AUDIO_OGG];

export function pathIsOgg(path: string): boolean {
  return getFileExtension(path) === getExtensionByType(FILE_TYPE.AUDIO_OGG);
}

export const wavExtension = TypeToExtention[FILE_TYPE.AUDIO_WAV];

export function pathIsWav(path: string): boolean {
  return getFileExtension(path) === getExtensionByType(FILE_TYPE.AUDIO_WAV);
}

/**
 * use File type
 * @param fileList
 */
export function filterFileListByApprovedExtension(fileList: any[]): any[] {
  // File
  return fileList.filter((file: any) => {
    return pathIsMp3(file.name) || pathIsOgg(file.name) || pathIsWav(file.name);
  });
}
