import {
  Directive,
  effect,
  ElementRef,
  inject,
  input,
  Input,
  InputSignal,
  OnDestroy,
  Signal
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {
  getMaxImageSizeFromStandardImageSizes,
  getThumbnailPathFromImagePath,
  GsAssetService
} from '@gigasoftware/shared/media';
import {BehaviorSubject, Subject} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil
} from 'rxjs/operators';

import {ResizeObserver$} from '../../core/utils/resize-observer-observable';

/**
 * From Font Icons
 * https://fonts.google.com/icons?selected=Material+Symbols+Rounded:image:FILL@1;wght@500;GRAD@0;opsz@24&icon.query=image&icon.set=Material+Icons&icon.style=Rounded&icon.size=24&icon.color=%23e8eaed&
 */
const IMAGE_ICON =
  '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M202.87-111.87q-37.78 0-64.39-26.61t-26.61-64.39v-554.26q0-37.78 26.61-64.39t64.39-26.61h554.26q37.78 0 64.39 26.61t26.61 64.39v554.26q0 37.78-26.61 64.39t-64.39 26.61H202.87Zm78.09-165.26h398.08q13.92 0 20.39-12.44 6.48-12.43-2.23-23.86l-109.05-147q-6.72-9.2-18.15-9.32-11.43-.12-18.39 9.08L450-324.78l-71.61-95.89q-6.96-9.2-18.39-9.08-11.43.12-18.15 9.32l-79.05 107q-8.71 11.43-2.23 23.86 6.47 12.44 20.39 12.44Z"/></svg>';
const svgBlob = new Blob([IMAGE_ICON], {type: 'image/svg+xml'});

/**
 * @description Directive to display a blobSignal as an image.
 * @example
 * <img dlc-blobSignal-image [blobSignal]="blobSignal" />
 */
@Directive({
  host: {
    '[attr.src]': 'blobUrl()'
  },
  selector: 'dlc-blob-image, img[dlc-blob-image], img[dlcBlobImage]',
  standalone: true
})
export class DlcBlobImageDirective implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _resizeObserverIsSet = false;
  assetService = inject(GsAssetService);
  imageBlob$: BehaviorSubject<Blob> = new BehaviorSubject(svgBlob);
  blobUrl: Signal<string> = <Signal<string>>toSignal(
    this.imageBlob$.pipe(
      map((imageBlob: Blob) => {
        return <string>URL.createObjectURL(imageBlob);
      })
    )
  );
  dlcImagePath: InputSignal<string | null | undefined> = input(
    <string | null | undefined>null
  );
  elementRef = inject(ElementRef);
  imagePath$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  resizeObserver = new ResizeObserver$();

  constructor() {
    effect(
      () => {
        const dlcImagePath: string | null | undefined = this.dlcImagePath();
        if (dlcImagePath && dlcImagePath.length > 0) {
          this.imagePath$.next(dlcImagePath);
        }
      },
      {allowSignalWrites: true}
    );
    this.resizeObserver.observe(this.elementRef.nativeElement);

    this.initResizeObserver();

    this.elementRef.nativeElement.onload = () => {
      URL.revokeObjectURL(this.blobUrl());
    };
  }

  async downloadBlob(imagePath: string): Promise<Blob> {
    return await this.assetService.downloadBlob(imagePath);
  }

  initResizeObserver() {
    this.imagePath$
      .pipe(
        distinctUntilChanged(),
        filter((imagePath: string | null) => {
          return (
            imagePath !== null &&
            imagePath !== undefined &&
            imagePath.length > 0
          );
        }),
        switchMap((imagePath: string | null) =>
          this.resizeObserver.contentRect$.pipe(
            debounceTime(5),
            map((contentRect: DOMRectReadOnly) => {
              return getMaxImageSizeFromStandardImageSizes(contentRect.width);
            }),
            distinctUntilChanged(),
            map((width: number) => {
              return {
                imagePath,
                width
              };
            })
          )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe({
        next: async ({imagePath, width}): Promise<void> => {
          if (imagePath) {
            const thumbPath = getThumbnailPathFromImagePath(imagePath, width);
            const blob = await this.assetService.downloadBlob(thumbPath);
            this.imageBlob$.next(blob);
          }
        }
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }

  @Input()
  set blob(value: Blob | null) {
    if (value) {
      // this.blobUrl.set(URL.createObjectURL(value));
      this.imageBlob$.next(value);
    }
  }
}
