<h1 mat-dialog-title>Edit Image</h1>
<div mat-dialog-content>
  <dlc-image-cropper
    [imageFile]="data.imageFile"
    (imageCroppedEvent)="imageCroppedEvent.set($event)"></dlc-image-cropper>
</div>
<div mat-dialog-actions>
  <button
    dlc-rounded-text-button
    (click)="onNoClick()">
    Cancel
  </button>
  <button
    dlc-rounded-text-button
    (click)="onSaveClick()"
    [disabled]="imageCroppedEvent() === null">
    Preview
  </button>
</div>
