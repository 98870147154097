import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

import {NgPatAccountEffects} from './+account/account.effects';
import {
  ngPatAccountFeatureKey,
  ngPatInitialAccountState
} from './+account/account.model';
import {ngPatAccountReducer} from './+account/account.reducer';
import {
  ngPatInitialWebsocketRegistryState,
  ngPatWebsocketRegistryFeatureKey
} from './+websocket-registry/websocket-registry.models';
import {ngPatWebSocketReducer} from './+websocket-registry/websocket-registry.reducer';

/**
 * Stripe
 */

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forRoot(
      {
        // TODO change feature key in ng-patterns to ngPatAccountFeatureKey
        [ngPatAccountFeatureKey]: ngPatAccountReducer,
        [ngPatWebsocketRegistryFeatureKey]: ngPatWebSocketReducer
      },
      {
        initialState: {
          [ngPatAccountFeatureKey]: ngPatInitialAccountState,
          [ngPatWebsocketRegistryFeatureKey]: ngPatInitialWebsocketRegistryState
        },
        runtimeChecks: {
          strictActionImmutability: true,
          strictActionSerializability: true,
          strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
          strictStateImmutability: true,
          strictStateSerializability: true
        }
      }
    ),
    EffectsModule.forRoot([NgPatAccountEffects])
  ],
  providers: []
})
export class WebPlatformLoginDataAccessModule {}
