import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-ios-app-store-button'
    },
    imports: [CommonModule],
    selector: 'dlc-ios-app-store-button,[dlc-ios-app-store-button],[dlcIosAppStoreButton]',
    styleUrl: './ios-app-store.component.scss',
    templateUrl: './ios-app-store.component.html'
})
export class DlcIosAppStoreComponent {
  @Input() isBlack = true;
}
