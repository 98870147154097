import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  Output,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {
  Answer,
  getReferenceLinksFromQuestion,
  isMultipleChoiceQuestion,
  isTrueFalseQuestion,
  Question,
  QuestionAction,
  QuestionWithComputedProperties,
  Quiz,
  QuizzesEngineStore
} from '@gigasoftware/shared/store';
import {DlcBlobImageDirective} from '@gigasoftware/shared/ui-design-library';
import {
  DlcLabelContainerComponent,
  DlcRoundedTextIconButtonComponent,
  ReferenceLink
} from '@gigasoftware/shared/ui-design-library';
import {ReplaySubject} from 'rxjs';

import {ConfirmDeleteQuestionAndAssetsComponent} from '../../../dialogs/confirm-delete-question-and-assets/confirm-delete-question-and-assets.component';
import {EcMultipleChoiceSummaryComponent} from './ec-multiple-choice-answer/ec-multiple-choice-summary.component';
import {EcReferenceLinkComponent} from './ec-reference-link/ec-reference-link.component';
import {EcTrueFalseSummaryComponent} from './ec-true-false-answer/ec-true-false-summary.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-question-summary'
    },
    imports: [
        CommonModule,
        DlcLabelContainerComponent,
        DlcRoundedTextIconButtonComponent,
        DlcBlobImageDirective,
        EcMultipleChoiceSummaryComponent,
        EcTrueFalseSummaryComponent,
        EcReferenceLinkComponent,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule
    ],
    selector: 'ec-question-summary',
    styleUrls: ['./ec-question-summary.component.scss'],
    templateUrl: './ec-question-summary.component.html'
})
export class EcQuestionSummaryComponent {
  private quizStore: QuizzesEngineStore = inject(QuizzesEngineStore);

  question$: ReplaySubject<QuestionWithComputedProperties> =
    new ReplaySubject<QuestionWithComputedProperties>(1);

  questionSignal: Signal<QuestionWithComputedProperties> = <
    Signal<QuestionWithComputedProperties>
  >toSignal(this.question$);

  answers: Signal<Answer[]> = computed(() => {
    const answers: {[p: string]: Answer} | undefined =
      this.questionSignal()?.answers;

    if (answers) {
      return Object.values(answers);
    }

    return [];
  });

  @Output() doEditQuestion: EventEmitter<QuestionAction> =
    new EventEmitter<QuestionAction>();

  isMultipleChoiceQuestion: Signal<boolean> = computed(() => {
    return isMultipleChoiceQuestion(this.questionSignal());
  });

  isTrueFalseQuestion: Signal<boolean> = computed(() => {
    return isTrueFalseQuestion(this.questionSignal());
  });
  quiz: Signal<Quiz | null | undefined> = <Signal<Quiz | null | undefined>>(
    toSignal(this.quizStore.selectCurrentQuiz$)
  );

  referenceLinks: Signal<ReferenceLink[]> = computed(() => {
    const question: Question | null | undefined = this.questionSignal();

    if (question) {
      return getReferenceLinksFromQuestion(question);
    }

    return [];
  });

  @Input() showID = false;

  constructor(private dialog: MatDialog) {
    //   effect(
    //     () => {
    //       const imagePath: string | null | undefined =
    //         this.questionSignal()?.imagePath;
    //
    //       if (imagePath && imagePath.length > 0) {
    //         this.assetService.getDownloadUrl(imagePath).then(url => {
    //           if (url) {
    //             this.questionImageUrlSignal.set(url);
    //           }
    //         });
    //       }
    //     },
    //     {allowSignalWrites: true}
    //   );
  }

  onDeleteQuestion() {
    const quiz: Quiz | null | undefined = this.quiz();
    const question: Question | null | undefined = this.questionSignal();

    if (question && quiz) {
      const action: QuestionAction = {
        question,
        quiz
      };

      this.dialog.open(ConfirmDeleteQuestionAndAssetsComponent, {
        backdropClass: 'dlc-global-dialog-background',
        data: action,
        disableClose: true
      });

      // ref.afterClosed().subscribe((doDelete: boolean) => {
      //   if (doDelete) {
      //     this.qe()?.questionsStore.onDeleteQuestion(action);
      //   }
      // });
    }
  }

  onEditQuestion() {
    const quiz: Quiz | null | undefined = this.quiz();
    const question: Question | null | undefined = this.questionSignal();

    if (question && quiz) {
      this.doEditQuestion.emit({question, quiz});
    }
  }

  @Input() set question(
    value: Question | QuestionWithComputedProperties | null
  ) {
    if (value) {
      this.question$.next(<QuestionWithComputedProperties>value);
    }
  }
}
