export enum SIDENAV_CONTENT_ID {
  QUIZ = 0,
  MEMBERS = 1,
  INVITE = 2,
  GRADES = 3,
  NOTES = 4,
  ADD_NOTE = 5,
  BOOKMARKS = 6,
  ADD_BOOKMARK = 7
}

export const EC_SIDENAV_LIST_DICT_ALL: {[key: string]: any}[] = [
  {
    iconcss: 'quiz',
    id: SIDENAV_CONTENT_ID.QUIZ,
    text: 'Quizzes'
  },
  {
    iconcss: 'notes',
    id: SIDENAV_CONTENT_ID.NOTES,
    text: 'Notes'
  },
  {
    iconcss: 'add_notes',
    id: SIDENAV_CONTENT_ID.ADD_NOTE,
    text: 'Add / View Note'
  },
  {
    iconcss: 'star',
    id: SIDENAV_CONTENT_ID.GRADES,
    text: 'Grades'
  },
  {
    iconcss: 'groups',
    id: SIDENAV_CONTENT_ID.MEMBERS,
    text: 'People'
  },
  {
    iconcss: 'person_add',
    id: SIDENAV_CONTENT_ID.INVITE,
    text: 'Invite'
  },
  {
    iconcss: 'bookmarks',
    id: SIDENAV_CONTENT_ID.BOOKMARKS,
    text: 'Bookmarks'
  },
  {
    iconcss: 'bookmark_add',
    id: SIDENAV_CONTENT_ID.ADD_BOOKMARK,
    text: 'Add / Edit Bookmark'
  }
];

export const EC_SIDENAV_LIST_DICT_NO_GRADES: {[key: string]: any}[] = [
  {
    iconcss: 'quiz',
    id: SIDENAV_CONTENT_ID.QUIZ,
    text: 'Quizzez'
  },
  {
    iconcss: 'notes',
    id: SIDENAV_CONTENT_ID.NOTES,
    text: 'Notes'
  },
  {
    iconcss: 'add_notes',
    id: SIDENAV_CONTENT_ID.ADD_NOTE,
    text: 'Add / View Note'
  },
  {
    iconcss: 'groups',
    id: SIDENAV_CONTENT_ID.MEMBERS,
    text: 'People'
  },
  {
    iconcss: 'person_add',
    id: SIDENAV_CONTENT_ID.INVITE,
    text: 'Invite'
  },
  {
    iconcss: 'bookmarks',
    id: SIDENAV_CONTENT_ID.BOOKMARKS,
    text: 'Bookmarks'
  },
  {
    iconcss: 'bookmark_add',
    id: SIDENAV_CONTENT_ID.ADD_BOOKMARK,
    text: 'Add / Edit Bookmark'
  }
];
