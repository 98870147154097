import {Action, createReducer, on} from '@ngrx/store';

import {
  actionUiStateInitialized,
  loadNavItemEntities,
  loadUIToStore,
  resetNavigation,
  selectEntityAction,
  setCurrentNavItemID,
  setCurrentTheme,
  setCurrentURL,
  setIsDesktop,
  setIsMobile,
  setMobileHomeLayoutState,
  setNavigateWithParams,
  setThemes
} from './ui.actions';
import {initialUiState, MobileHomeLayoutState, UiState} from './ui.model';

export const reducer = createReducer(
  initialUiState,
  on(actionUiStateInitialized, state => ({...state, uiStateInitialized: true})),
  on(
    loadUIToStore,
    (state: UiState, action: Action & {payload: Partial<UiState>}) => ({
      ...state,
      ...action.payload
    })
  ),
  on(setIsDesktop, (state: UiState, {isDesktop}) => ({
    ...state,
    isDesktop,
    isMobile: !isDesktop
  })),
  on(setIsMobile, (state: UiState, {isMobile}) => ({
    ...state,
    isLargeScreen: !isMobile,
    isMobile
  })),
  on(setCurrentURL, (state: UiState, {currentURL}) => ({
    ...state,
    currentURL
  })),
  on(selectEntityAction, (state: UiState, action) => ({
    ...state,
    selectedEntity: action.selectedEntity
  })),
  on(loadNavItemEntities, (state: UiState, action) => {
    return <UiState>{
      ...state,
      navItemEntities: {
        ...state.navItemEntities,
        ...action.navItemEntities
      }
    };
  }),
  on(resetNavigation, state => {
    return {
      ...state,
      selectedEntity: null
    };
  }),
  on(setCurrentNavItemID, (state: UiState, {currentNavItemId}) => {
    return {
      ...state,
      currentNavItemId
    };
  }),
  on(
    setNavigateWithParams,
    (state: UiState, {currentNavItemID, navParams, previousNavItemID}) => {
      // console.log(
      //   'setNavigateWithParams',
      //   currentNavItemID,
      //   navParams,
      //   previousNavItemID
      // );
      return {
        ...state,
        currentNavItemID,
        previousNavItemID,

        /**
         * Sets params such as
         *
         */
        ...navParams
      };
    }
  ),
  on(setCurrentTheme, (state: UiState, {currentTheme}) => ({
    ...state,
    currentTheme
  })),
  on(setThemes, (state: UiState, {themes}) => ({
    ...state,
    themes
  })),
  on(
    setMobileHomeLayoutState,
    (state: UiState, action: {state: MobileHomeLayoutState}) => ({
      ...state,
      mobileHomeLayoutState: action.state
    })
  )
);
