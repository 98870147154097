import {CommonModule} from '@angular/common';
import {
  Component,
  inject,
  Input,
  OnDestroy,
  output,
  Output,
  OutputEmitterRef,
  signal,
  ViewEncapsulation
} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {GigaNoteDoc} from '@gigasoftware/shared/models';
import {NgPatProcessQueueState} from '@gigasoftware/shared/utils';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';

import {DlcSaveBtnState} from '../../button/dlc-save-button/dlc-save-button.component';
import {DlcInputImageComponent} from '../../input/dlc-input-image/dlc-input-image.component';
import {SyncfusionRteComponent} from '../syncfusion-rte/syncfusion-rte.component';
import {DlcTranscribeBtnComponent} from './dlc-transcribe-btn/dlc-transcribe-btn.component';
import {DlcNoteFirestoreService} from './services/dlc-note-firestore.service';
import {DlcTranscribeNoteService} from './services/dlc-transcribed-note.service';
import {DlcUserNoteService} from './services/dlc-user-note.service';
import {ofDlcNoteActionTypeWithFirestoreDoc} from './services/note.fns';
import {
  DlcNoteBasePathImageConfig,
  DlcNoteProcessQueue,
  DlcNoteSaveAction
} from './services/note.model';
import {DlcNoteStoreService} from './services/note.store';

@Component({
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-add-note'
    },
    imports: [
        CommonModule,
        FormsModule,
        DlcInputImageComponent,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        ReactiveFormsModule,
        SyncfusionRteComponent,
        DlcTranscribeBtnComponent
    ],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DlcNoteStoreService,
        DlcUserNoteService,
        DlcNoteFirestoreService,
        DlcTranscribeNoteService
    ],
    selector: 'dlc-add-note',
    styleUrl: './dlc-add-note.component.scss',
    templateUrl: './dlc-add-note.component.html'
})
export class DlcAddNoteComponent implements OnDestroy {
  doUpdateSaveBtnState: OutputEmitterRef<DlcSaveBtnState> =
    output<DlcSaveBtnState>();
  firestore = inject(NgPatFirestoreService);
  hasImage = signal(false);
  noteFirestore = inject(DlcNoteFirestoreService);
  store = inject(DlcNoteStoreService);

  processingState$ = this.store.saveDataProcessQueue.processingState$.pipe(
    takeUntilDestroyed(),
    // distinctUntilChanged(),
    map((state: NgPatProcessQueueState) => {
      switch (state) {
        case NgPatProcessQueueState.IDLE:
          return DlcSaveBtnState.DISABLED;
        case NgPatProcessQueueState.PROCESSING:
          return DlcSaveBtnState.IN_PROGRESS;
        case NgPatProcessQueueState.PENDING:
          return DlcSaveBtnState.ACTIVE;
        default:
          return DlcSaveBtnState.DISABLED;
      }
    })
  );

  resolvedImageConfig = toSignal(this.store.selectResolvedImageConfig$);

  selectLatestNoteVersion = toSignal(this.store.selectLatestNoteVersion$);

  selectLatestTranscriptionVersion = toSignal(
    this.store.selectLatestTranscriptionVersion$
  );

  titleControl: FormControl = new FormControl(this.noteFirestore.defaultTitle, [
    Validators.minLength(3),
    Validators.required
  ]);

  /**
   * Set the base path for the firestore collection.
   * The path should have an odd number of segments.
   *
   * [collection]/[document]/[subcollection]
   * @param basePath
   */

  transcribeBtnState = toSignal(this.store.selectTranscribeBtnState$);

  transcribedNoteService = inject(DlcTranscribeNoteService);

  userNote = inject(DlcUserNoteService);
  constructor() {
    this.userNote.init();
    this.transcribedNoteService.init();

    this.titleControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((title: string | null | undefined) => {
        if (title && title.length) {
          this.store.updateNoteTitle(title);
        }
      });

    this.store.selectNoteTitle$
      .pipe(takeUntilDestroyed(), distinctUntilChanged())
      .subscribe((title: string) => {
        this.titleControl.setValue(title, {emitEvent: false});
      });

    this.store.saveDataProcessQueue.currentItem$
      .pipe(
        ofDlcNoteActionTypeWithFirestoreDoc(DlcNoteSaveAction.SAVE_NOTE_TITLE),
        this.store.mergeWithFirestoreDoc((action: DlcNoteProcessQueue) => {
          return {
            title: action.title
          };
        }),
        takeUntilDestroyed()
      )
      .subscribe(async ([action, doc]: [DlcNoteProcessQueue, GigaNoteDoc]) => {
        await this.saveTitle(doc);
        await this.store.saveDataProcessQueue.next(action.id);
      });

    this.processingState$
      .pipe(distinctUntilChanged())
      .subscribe((state: DlcSaveBtnState) => {
        this.doUpdateSaveBtnState.emit(state);
      });

    // this.processingState$
    //   .pipe(
    //     filter((state: DlcSaveBtnState) => state === DlcSaveBtnState.ACTIVE),
    //     debounceTime(3000)
    //   )
    //   .subscribe(async () => {
    //     await this.store.saveDataProcessQueue.next();
    //   });
  }
  clearTitleForm() {
    this.titleControl.setValue('');
    this.titleControl.setErrors({required: true});
  }

  /**
   * Called from parent component directly, instead of using @Input, to save the note.
   */
  doSave() {
    this.store.save();
  }

  /**
   * Called from parent component directly, instead of using @Input, to save the note.
   */
  async doTranscribeImage() {
    await this.transcribedNoteService.doTranscribeImage();
  }

  ngOnDestroy() {
    this.transcribedNoteService.onDestroy();
    this.userNote.onDestroy();
    this.noteFirestore.onDestroy();
    this.store.clear();
  }

  async saveTitle(doc: GigaNoteDoc) {
    await this.store.updateFirestoreDocPromise(doc);
    this.titleControl.markAsPristine();
    this.titleControl.markAsUntouched();

    return doc;
  }

  @Input({required: true})
  set config(config: DlcNoteBasePathImageConfig | null | undefined) {
    if (
      config &&
      config.basePath &&
      config.basePath.length > 0 &&
      config.uploadImageConfig &&
      config.uploadImageConfig?.baseImageDirectory &&
      config.uploadImageConfig?.baseImageDirectory?.length > 0 &&
      config.parentId?.length > 0
    ) {
      this.noteFirestore.setConfig(config);
    }
  }

  @Input()
  set saveAction(action: boolean | null) {
    if (action) {
      this.doSave();
    }
  }

  @Input()
  set transcribeImageAction(action: boolean | null) {
    if (action) {
      this.doTranscribeImage().then(() => {});
    }
  }
}
