import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {DlcRoundedTextButtonComponent} from '@gigasoftware/shared/ui-design-library';

export interface ConfirmCreateReviewQuiz {
  quizName: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        DlcRoundedTextButtonComponent
    ],
    selector: 'ec-plat-confirm-create-review-quiz',
    styleUrls: ['./ec-confirm-create-review-quiz.component.scss'],
    templateUrl: './ec-confirm-create-review-quiz.component.html'
})
export class EcConfirmCreateReviewQuizComponent {
  constructor(
    public dialogRef: MatDialogRef<EcConfirmCreateReviewQuizComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmCreateReviewQuiz
  ) {}
}
