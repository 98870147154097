import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {
  ngPatLoadRemoteConfigEffect,
  ngPatUpsertRemoteConfigs
} from './remote-config.actions';
import {tap} from 'rxjs/operators';
import {
  NgPatAccessConfig,
  NgPatConfigs,
  NgPatRemoteConfigEntity
} from './remote-config.model';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';

@Injectable()
export class NgPatRemoteConfigEffects implements OnInitEffects {
  loadRemoteConfigEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ngPatLoadRemoteConfigEffect),
        tap(() => {
          this.receiveConfigPoll();
        })
      );
    },
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private customFirebase: NgPatFirestoreService,
    private store: Store
  ) {}

  ngrxOnInitEffects(): Action {
    return ngPatLoadRemoteConfigEffect();
  }

  private receiveConfigPoll() {
    this.customFirebase.remoteConfig$.subscribe(
      (remoteConfigs: {id: string; value: string}[]) => {
        try {
          const _remoteConfigs = remoteConfigs.map(config => {
            return {
              id: config.id,
              value: (<unknown>JSON.parse(config.value)) as NgPatAccessConfig
            };
          });

          this.store.dispatch(
            ngPatUpsertRemoteConfigs({remoteConfigs: _remoteConfigs})
          );
        } catch (e) {
          console.error('Error parsing remote configs', e);
        }
      }
    );
  }
}
