import {Injectable} from '@angular/core';
import {BaseType, EnterElement, interpolateNumber, transition} from 'd3';
import {scaleLinear} from 'd3-scale';
import {select, Selection} from 'd3-selection';
// https://github.com/d3/d3-selection/issues/185#issuecomment-418118992
import 'd3-transition';
import {map} from 'rxjs/operators';

import {ChartsTooltipCalc} from '../charts-tooltip/charts-tooltip.calc';
import {ChartTooltipConfig} from '../charts-tooltip/charts-tooltip.model';
import {
  NgPatChartStatus,
  NgPatElSizeConfigDimensions,
  NgPatElSizeConfigDimensionsData
} from '../core/chart.models';
import {
  setToRange,
  SetToRangeFn,
  zeroIfUndefinedOrNull
} from '../core/fns/chart.fns';
import {NgPatBulletChartDataBase} from './bullet-chart.models';

@Injectable()
export class NgPatBulletChartService {
  tooltip: ChartsTooltipCalc<NgPatBulletChartDataBase>;

  barHeight = 10;
  progressIndicatorHeight = 30;
  progressIndicatorWidth = 5;
  limitIndicatorWidth = 2;
  limitInidicatorHeight = this.progressIndicatorHeight;
  barY = (this.progressIndicatorHeight - this.barHeight) / 2;
  defaultTooltipWidth = 200;

  lastProgress = 0;

  constructor() {
    this.tooltip = new ChartsTooltipCalc<NgPatBulletChartDataBase>();
  }

  appendLayout() {
    return map((el: HTMLElement) => {
      /**
       * Add more elements to the layout if needed.
       *
       * The base layout structure is:
       *  <svg class="wrapper">
       *     <g class="bounds"></g>
       *  </svg>
       *
       */

      return el;
    });
  }

  resizeDataLayout({
    config,
    dimensions,
    el,
    size
  }: NgPatElSizeConfigDimensions) {
    return map((data: NgPatBulletChartDataBase[]) => {
      /**
       * resize the layout based on data
       */

      return {
        config,
        data,
        dimensions,
        el,
        size
      };
    });
  }

  applyData({
    config,
    data,
    dimensions,
    el
  }: NgPatElSizeConfigDimensionsData<NgPatBulletChartDataBase>): void {
    const that = this;
    /*
    There is only one datum per bullet chart.
     */

    const min = zeroIfUndefinedOrNull(data[0].min);
    const max = zeroIfUndefinedOrNull(data[0].max);
    const units = (data && data[0] && data[0].units) || 'N/A';

    const withinMinMax: SetToRangeFn = setToRange(min, max);

    const progress = withinMinMax(data[0].progress);
    const status = <NgPatChartStatus>data[0].status;

    const datum: NgPatBulletChartDataBase = {
      max,
      min,
      progress,
      status,
      units
    };

    const xScale = scaleLinear()
      .domain([min, max])
      .range([0, dimensions.boundedWidth]);

    const root = select(el).selectChild('.wrapper');
    const bounds = root.selectChild('.bounds');

    function interpolateProgress(
      lastProgress: number,
      currentProgress: number,
      t: number
    ) {
      // https://github.com/d3/d3-interpolate#interpolateNumber
      const i = interpolateNumber(lastProgress, currentProgress);

      return parseFloat(i(t).toFixed(2));
    }

    function calculateTooltipPosition(progress: number) {
      /**
       * Tooltip width
       */
      const tooltipWidth = config.tooltipConfig?.width
        ? config.tooltipConfig?.width
        : that.defaultTooltipWidth;
      const halfProgressIndicatorWidth = that.progressIndicatorWidth / 2;

      /**
       * this x position of the bubble along the width of the bullet chart.
       */
      const x = xScale(progress) - that.progressIndicatorWidth / 2;

      let translateBubbleX = x + halfProgressIndicatorWidth - tooltipWidth / 2;
      /**
       * Maximum x position tooltip can be positioned to the LEFT before
       * right edge is aligned with end of bullet chart
       */
      const xLowerLimitOfBubble = 0;
      /**
       * Maximum x position tooltip can be positioned to the RIGHT before
       * right edge is aligned with end of bullet chart
       */
      const xUpperLimitOfBubble = xScale(max) - tooltipWidth;

      /**
       * The tooltip config has been merged with default params
       * by the time it gets here. So we have all params available.
       */
      const tooltipConfig: ChartTooltipConfig = <ChartTooltipConfig>(
        config.tooltipConfig
      );

      const halfDivotWidth = tooltipConfig.divotWidth / 2;
      /**
       * Tooltip divot x position when the tooltip is in the left most position
       */
      let tooltipDivotX = -halfDivotWidth;

      // Move tooltip bubble over progress indicator
      if (translateBubbleX < xLowerLimitOfBubble) {
        translateBubbleX = xLowerLimitOfBubble;
        tooltipDivotX = tooltipDivotX + x;
      } else if (translateBubbleX > xUpperLimitOfBubble) {
        translateBubbleX = xUpperLimitOfBubble;
        tooltipDivotX =
          x - xUpperLimitOfBubble - halfDivotWidth + halfProgressIndicatorWidth;
      } else if (
        translateBubbleX > xLowerLimitOfBubble &&
        translateBubbleX < xUpperLimitOfBubble
      ) {
        tooltipDivotX = tooltipConfig.width / 2 - halfDivotWidth;
      }

      // else {
      //   translateX = translateX + 4;
      // }

      that.tooltip.setTooltipData({
        data: {...datum, progress},
        hover: false,
        maxX: xScale(max),
        minX: xScale(min),
        tooltipDivotX,
        width: tooltipWidth,
        x: translateBubbleX,
        y: 0
      });

      that.tooltip.setStatus(status);
    }

    function enterFn(
      enter: Selection<
        EnterElement,
        NgPatBulletChartDataBase,
        BaseType,
        unknown
      >
    ) {
      enter
        .append('g')
        .classed('bullet-container', true)
        .attr('transform', (d: NgPatBulletChartDataBase, index: number) => {
          return `translate(0,${index * 100})`;
        })
        .call((g: any) =>
          /**
           * Background Bar
           */
          g
            .append('rect')
            .classed('ng-pat-bullet-background-bar', true)
            .attr(
              'width',
              dimensions.boundedWidth - that.limitIndicatorWidth * 2
            )
            .attr('height', that.barHeight)
            .attr('y', that.barY)
            // do not overlap limit indicators
            .attr('x', that.limitIndicatorWidth)
        )
        .call((g: any) =>
          g
            /**
             * Progress Bar
             */
            .append('rect')
            .classed('ng-pat-bullet-progress-bar', true)
            // .attr('class', (d: NgPatBulletChartDataBase) => {
            //   if (d.status === 'success') {
            //     return 'g-ng-pat-chart-data-success-background ng-pat-bullet-progress-bar';
            //   } else if (d.status === 'error') {
            //     return 'g-ng-pat-chart-data-error-background ng-pat-bullet-progress-bar';
            //   } else if (d.status === 'warn') {
            //     return 'g-ng-pat-chart-data-warn-background ng-pat-bullet-progress-bar';
            //   } else {
            //     return 't-ng-pat-ng-pat-bullet-progress-bar-primary ng-pat-bullet-progress-bar';
            //   }
            // })
            .attr('height', that.barHeight)
            .attr('y', that.barY)
            .transition()
            .attr('width', (d: NgPatBulletChartDataBase) => xScale(d.progress))
        )
        .call((g: any) =>
          g
            /**
             * Left Indicator
             */
            .append('rect')
            .classed('ng-pat-bullet-chart-limit--left', true)
            .attr('width', that.limitIndicatorWidth)
            .attr('height', that.limitInidicatorHeight)
            .attr('x', 0)
        )
        .call((g: any) =>
          g
            /**
             * Right Indicator
             */
            .append('rect')
            .classed('ng-pat-bullet-chart-limit--right', true)
            .attr('width', that.limitIndicatorWidth)
            .attr('height', that.limitInidicatorHeight)
            .attr(
              'x',
              (d: NgPatBulletChartDataBase) =>
                xScale(d.max) - that.limitIndicatorWidth
            )
        )
        .call((g: any) =>
          g
            /**
             * Progress Indicator
             */
            .append('rect')
            .classed('ng-pat-bullet-progress-indicator', true)
            // .attr('class', (d: NgPatBulletChartDataBase) => {
            //   if (d.status === 'success') {
            //     return 'g-ng-pat-chart-data-success-background ng-pat-bullet-progress-indicator';
            //   } else if (d.status === 'error') {
            //     return 'g-ng-pat-chart-data-error-background ng-pat-bullet-progress-indicator';
            //   } else if (d.status === 'warn') {
            //     return 'g-ng-pat-chart-data-warn-background ng-pat-bullet-progress-indicator';
            //   } else {
            //     return 't-ng-pat-ng-pat-bullet-progress-indicator-primary ng-pat-bullet-progress-indicator';
            //   }
            // })
            .attr('width', that.progressIndicatorWidth)
            .attr('height', that.progressIndicatorHeight)
            .transition()
            .attr(
              'x',
              (d: NgPatBulletChartDataBase) =>
                xScale(d.progress) - that.progressIndicatorWidth / 2
            )
            .tween('tooltip', (d: NgPatBulletChartDataBase) => (t: number) => {
              calculateTooltipPosition(
                interpolateProgress(that.lastProgress, d.progress, t)
              );

              if (t === 1) {
                that.lastProgress = d.progress;
              }
            })
        );

      return enter;
    }

    function updateFn(
      update: Selection<BaseType, NgPatBulletChartDataBase, BaseType, unknown>
    ) {
      update.attr('transform', (d: NgPatBulletChartDataBase, index: number) => {
        return `translate(0,${index * 100})`;
      });

      update
        .select('.ng-pat-bullet-background-bar')
        .attr('width', dimensions.boundedWidth - that.limitIndicatorWidth * 2)
        .attr('x', that.limitIndicatorWidth);

      update
        .select('.ng-pat-bullet-progress-bar')
        .transition()
        .attr('width', (d: NgPatBulletChartDataBase) => xScale(d.progress));

      update
        .select('.ng-pat-bullet-progress-indicator')
        .transition()
        .attr(
          'x',
          (d: NgPatBulletChartDataBase) =>
            xScale(d.progress) - that.progressIndicatorWidth / 2
        )
        .tween('tooltip', (d: NgPatBulletChartDataBase) => (t: number) => {
          calculateTooltipPosition(
            interpolateProgress(that.lastProgress, d.progress, t)
          );

          if (t === 1) {
            that.lastProgress = d.progress;
          }
        });

      update
        .select('.ng-pat-bullet-chart-limit--right')
        .attr(
          'x',
          (d: NgPatBulletChartDataBase) =>
            xScale(d.max) - that.limitIndicatorWidth
        );

      return update;
    }

    function exitFn(exit: Selection<BaseType, any, BaseType, unknown>) {
      transition();
      exit.transition().remove();
    }

    bounds
      .selectAll('.bullet-container')
      .data([datum])
      .join(
        (
          enter: Selection<
            EnterElement,
            NgPatBulletChartDataBase,
            BaseType,
            unknown
          >
        ) => enterFn(enter),
        (
          update: Selection<
            BaseType,
            NgPatBulletChartDataBase,
            BaseType,
            unknown
          >
        ) => updateFn(update),
        (exit: Selection<BaseType, any, BaseType, unknown>) => exitFn(exit)
      );

    if (config.tooltipConfig?.hover) {
      bounds
        // .select('.t-ng-pat-bullet-progress-indicator')
        /**
         * on('mouseover', (e, i)
         */
        .on('mouseover', () => {
          this.tooltip.setTooltipHover(true);
        })
        /**
         * on('mouseover', (e, i)
         */
        .on('mouseout', () => {
          this.tooltip.setTooltipHover(false);
        });
    }
  }
}
