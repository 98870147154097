import {CommonModule} from '@angular/common';
import {
  Component,
  computed,
  effect,
  inject,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {
  NgPatNormalizedOffer,
  NgPatNormalizedPrice
} from '@gigasoftware/shared/store';
import {Store} from '@ngrx/store';

import {DlcWebPricingService} from '../../dlc-web-pricing/dlc-web-pricing/dlc-web-pricing.service';
import {DlcFeaturePriceComponent} from './dlc-feature-price/dlc-feature-price.component';

@Component({
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-feature-list'
    },
    imports: [CommonModule, DlcFeaturePriceComponent],
    selector: 'dlc-feature-list',
    styleUrl: './dlc-feature-list-with-price.component.scss',
    templateUrl: './dlc-feature-list-with-price.component.html'
})
export class FeatureListWithPriceComponent {
  offers: WritableSignal<NgPatNormalizedOffer[]> = signal([]);
  anyOwned = computed(() => {
    return this.offers().some((offer: NgPatNormalizedOffer) => offer.owned);
  });

  description: WritableSignal<string> = signal('');
  doLogin: OutputEmitterRef<boolean> = output();

  price: InputSignal<NgPatNormalizedPrice | undefined> = input();

  features = computed(() => {
    return this.price()?.features || [];
  });

  isCustomPrice = computed(() => {
    return this.price()?.isCustomPrice || false;
  });

  showCheckoutProgressBar: OutputEmitterRef<boolean> = output();

  showFeatureDescription = input(false);

  store: Store = inject(Store);

  title: WritableSignal<string> = signal('');

  webPriceService: DlcWebPricingService = inject(DlcWebPricingService);

  constructor() {
    effect(
      () => {
        const price: NgPatNormalizedPrice | undefined = this.price();

        if (price) {
          const offers: NgPatNormalizedOffer[] = price.prices;

          this.title.set(price.title);
          if (offers.length > 0) {
            this.description.set(offers[0].description);
          }

          this.offers.set(offers);
        }
      },
      {
        allowSignalWrites: true
      }
    );
  }

  get isNativePlatform(): boolean {
    return Capacitor.isNativePlatform();
  }
}
