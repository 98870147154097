import {Capacitor} from '@capacitor/core';
import {BaseEntity} from '@gigasoftware/shared/models';
import {getDefaultThemeConfig, ThemeConfig} from '@gigasoftware/shared/utils';

import {EC_THEME_CONFIGS} from '../ec-themes';
import {NavItem} from '../navigation/model';

export const uiFeatureKey = 'ui';

export interface HomeAndLogin {
  dashboardHome: NavItem;
  login: NavItem;
  mobileHome: NavItem;
}

export enum MobileHomeLayoutState {
  GRID = 'grid',
  LIST = 'list'
}

export interface NavItemDict {
  [key: string]: NavItem;
}

export interface UiState {
  currentClassID: string | null;
  currentInvestigationID: string | null;
  currentJournalID: string | null;
  currentNavItemID: string;
  currentProjectID: string | null;
  currentQuestionID: string | null;
  currentQuizID: string | null;
  currentQuizReviewID: string | null;
  currentResearchID: string | null;
  currentStudyGroupID: string | null;
  currentTheme: ThemeConfig;

  currentURL: string;
  isIOS: boolean;
  isLandscape: boolean;

  isLargeScreen: boolean;
  isLoaded: boolean;

  isMobile: boolean;

  isPortrait: boolean;

  leftSidenavOpen: boolean | null;
  mobileHomeLayoutState: MobileHomeLayoutState;
  navItemEntities: NavItemDict;
  /**
   * @deprecated
   */
  navParams: {[key: string]: string};
  /**
   * @description
   * ID of previous nav item. The page the user navigated from.
   *
   * Nav Item ID from apps/quiz-kit/quiz-kit-app/src/app/nav-item-dict.ts
   */
  previousNavItemID: string;
  selectedEntity: BaseEntity | null;
  themes: ThemeConfig[];
  uiStateInitialized: boolean;
}

export interface PartialUiState {
  readonly [uiFeatureKey]: UiState;
}

export interface SelectedTheme {
  isLoaded: boolean;
  selectedTheme: ThemeConfig;
  themes: ThemeConfig[];
}

/**
 * Params are used to navigate to a page.
 * And are set by nav params route service.
 */
export interface UIParams {
  currentAnswerID?: string | null;
  currentClassID?: string | null;
  currentQuestionID?: string | null;
  currentQuizID?: string | null;
  currentReviewID?: string | null;
  currentStudyGroupID?: string | null;
}

export const initialUiState: UiState = {
  currentClassID: null,
  currentInvestigationID: null,
  currentJournalID: null,
  /** NAV.MOBILE_HOME_PAGE */
  currentNavItemID: '0',
  currentProjectID: null,
  currentQuestionID: null,
  currentQuizID: null,
  currentQuizReviewID: null,
  currentResearchID: null,
  currentStudyGroupID: null,
  currentTheme: getDefaultThemeConfig(EC_THEME_CONFIGS),
  currentURL: '',
  isIOS: Capacitor.getPlatform() === 'ios',
  isLandscape: false,

  isLargeScreen: false,
  isLoaded: false,

  isMobile: false,

  isPortrait: true,

  leftSidenavOpen: null,
  mobileHomeLayoutState: MobileHomeLayoutState.GRID,
  navItemEntities: {},
  navParams: {},
  previousNavItemID: '0',
  selectedEntity: null,
  themes: [...EC_THEME_CONFIGS],
  uiStateInitialized: false
};

/**
 * See libs/evolving-cognition/domain/src/lib/+ui/ui.fns.ts:6
 */
export declare type UiStorage = Pick<
  UiState,
  | 'previousNavItemID'
  | 'currentNavItemID'
  | 'currentQuizID'
  | 'currentQuestionID'
  | 'currentClassID'
  | 'currentStudyGroupID'
  | 'currentQuizReviewID'
  | 'currentTheme'
  | 'isLargeScreen'
  | 'isMobile'
  | 'isIOS'
  | 'currentURL'
>;
