import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {GigaNoteDoc} from '@gigasoftware/shared/models';
import {selectNoteID} from '@gigasoftware/shared/store';
import {Store} from '@ngrx/store';

import {DlcRoundedIconButtonComponent} from '../../../button/dlc-rounded-icon-button/dlc-rounded-icon-button.component';
import {DlcRoundedTextIconButtonComponent} from '../../../button/dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';
import {DlcBlobImageDirective} from '../../../image/blob/dlc-blob-image.directive';
import {DeleteNoteDialogComponent} from '../../delete-note-dialog/delete-note-dialog.component';
import {DlcDeleteNoteService} from './delete-note.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-note-list-item'
    },
    imports: [
        CommonModule,
        DlcBlobImageDirective,
        DlcRoundedIconButtonComponent,
        DlcRoundedTextIconButtonComponent,
        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule
    ],
    selector: 'dlc-note-list-item, [dlc-note-list-item]',
    styleUrl: './dlc-note-list-item.component.scss',
    templateUrl: './dlc-note-list-item.component.html'
})
export class DlcNoteListItemComponent {
  private deleteProcessQueue = inject(DlcDeleteNoteService);
  private deletingQueue: Signal<GigaNoteDoc[]> = <Signal<GigaNoteDoc[]>>(
    toSignal(this.deleteProcessQueue.processQueue.queue$)
  );
  private dialog = inject(MatDialog);
  private store = inject(Store);
  doCopyNote: OutputEmitterRef<GigaNoteDoc> = output();
  doEditNote: OutputEmitterRef<GigaNoteDoc> = output();
  note: InputSignal<GigaNoteDoc> = input.required();

  isDeletingNote = computed(() => {
    const queue = this.deletingQueue();
    const note = this.note();

    return queue.some((doc: GigaNoteDoc) => {
      return doc.id === note.id;
    }, false);
  });

  onDoDeleteNote() {
    const dialog = this.dialog.open(DeleteNoteDialogComponent, {
      backdropClass: 'dlc-global-dialog-background',
      data: this.note(),
      disableClose: true
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.deleteProcessQueue.deleteNote(this.note());
      }
    });
  }

  onDoEditNote() {
    this.store.dispatch(selectNoteID({id: this.note().id}));
    this.doEditNote.emit(this.note());
  }
}
