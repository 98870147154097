import {BASE_COLLECTION_NAME} from '@gigasoftware/shared/models';

export function firestoreUserClassCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.CLASSES}`;
}

export function firestoreUserClassDoc(uid: string, classID: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.CLASSES}/${classID}`;
}

export function firestoreClassCollection() {
  return `${BASE_COLLECTION_NAME.CLASSES}`;
}

export function firestoreClassDoc(classID: string): string {
  return `${BASE_COLLECTION_NAME.CLASSES}/${classID}`;
}
