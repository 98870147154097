import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[style.height]': 'calculatedHeight',
        '[style.width]': 'calculatedWidth',
        class: 'dlc-google-play-store-button'
    },
    imports: [CommonModule],
    selector: 'dlc-google-play-store-button,[dlc-google-play-store-button],[dlcGooglePlayStoreButton]',
    styleUrl: './google-play-store.component.scss',
    templateUrl: './google-play-store.component.html'
})
export class DlcGooglePlayStoreComponent {
  private _buttonHeight = 250;
  private _buttonWidth = 646;

  @Input() targetHeight = 58;

  get calculatedHeight(): string {
    return `${this.targetHeight}px`;
  }

  get calculatedWidth(): string {
    // Calculate the width based on the target height
    return `${(this._buttonWidth / this._buttonHeight) * this.targetHeight}px`;
  }
}
