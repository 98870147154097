import {InjectionToken} from '@angular/core';
import {
  NgPatFirebaseAppConfig,
  NgPatFirebaseAppInstance
} from '@gigasoftware/shared/models';
import {getAnalytics} from 'firebase/analytics';
import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getFunctions} from 'firebase/functions';
import {getRemoteConfig} from 'firebase/remote-config';
import {getStorage} from 'firebase/storage';

export const NG_PAT_FIREBASE_INSTANCE: InjectionToken<NgPatFirebaseAppInstance> =
  new InjectionToken<NgPatFirebaseAppInstance>('NG_PAT_FIREBASE_INSTANCE');

export function createNgPatFirebaseAppInstance<T>(
  config: NgPatFirebaseAppConfig
): NgPatFirebaseAppInstance {
  const app =
    config.appNameInFirebaseConfig && config.appName !== null
      ? initializeApp(config.firebase, <string>config.appName)
      : initializeApp(config.firebase);

  // const app = initializeApp(config.firebaseConfigParams);
  const auth = getAuth(app);
  const storage = getStorage(app);
  const db = getFirestore(app);
  const functions = getFunctions(app);
  const remoteConfig = getRemoteConfig(app);
  const analytics = getAnalytics(app);

  // https://firebase.google.com/docs/remote-config/get-started?platform=web
  if (config.remoteConfigParams?.settings) {
    remoteConfig.settings.minimumFetchIntervalMillis =
      config.remoteConfigParams?.settings.minimumFetchIntervalMillis;
  }

  return {
    ...config,
    analytics,
    app,
    appName: config.appName,
    auth,
    db,
    functions,
    remoteConfig,
    storage
  };
}
