import {Inject, Injectable, signal, WritableSignal} from '@angular/core';
import {SetOptions} from '@firebase/firestore';
import {allValuesMatch, hasValue} from '@gigasoftware/shared/fn';
import {
  AppEventName,
  CopyAsset,
  ENV_SERVER_ENUM,
  Exists,
  FirebaseAnalyticEventParams,
  FirestoreWriteEmailConfig,
  NgPatAggregateFirebaseSnapshotChanges,
  NgPatFirebaseAppInstance,
  RemoteConfigEntity
} from '@gigasoftware/shared/models';
import {AnalyticsCallOptions, logEvent, setUserId} from 'firebase/analytics';
import {
  ActionCodeSettings,
  Auth,
  AuthProvider,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  Persistence,
  PopupRedirectResolver,
  sendPasswordResetEmail,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  User,
  UserCredential
} from 'firebase/auth';
import {
  addDoc,
  clearIndexedDbPersistence,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FieldPath,
  FieldValue,
  GeoPoint,
  getDoc,
  getDocs,
  onSnapshot,
  Query,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  WhereFilterOp,
  writeBatch,
  WriteBatch
} from 'firebase/firestore';
import {HttpsCallable, httpsCallable} from 'firebase/functions';
import {
  fetchAndActivate,
  getAll,
  getValue,
  Value
} from 'firebase/remote-config';
import {
  deleteObject,
  getBlob,
  getDownloadURL,
  ref,
  StorageReference
} from 'firebase/storage';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  Observer,
  startWith,
  Subject,
  Subscription,
  timer
} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';

import {aggregateDocChangesFns} from '../fns/aggregate-doc-changes.fns';
import {NG_PAT_FIREBASE_INSTANCE} from '../fns/firebase-config.fns';
import {
  removeTimeStampCTorFromData,
  removeTimestampCTorFromDocumentSnapshot
} from '../fns/firestore.fns';
import {NgPatSnapshot} from './ng-pat-snapshot';

/**
 * Utility class to abstract connections to firebaseConfigParams.
 * Subclass and provide a NgPatFirebaseAppConfig object in the
 * constructor.
 */
@Injectable({
  providedIn: 'root'
})
export class NgPatFirestoreService {
  private remoteConfigStop$: Subject<boolean> = new Subject();
  private remoteConfigSub: Subscription = Subscription.EMPTY;
  remoteConfig$: BehaviorSubject<{id: string; value: string}[]> =
    new BehaviorSubject<{id: string; value: string}[]>([]);

  get app() {
    return this.appInstance.app;
  }

  get analytics() {
    return this.appInstance.analytics;
  }

  get db() {
    return this.appInstance.db;
  }

  get functions() {
    return this.appInstance.functions;
  }

  get auth(): Auth {
    return this.appInstance.auth;
  }

  get storage() {
    return this.appInstance.storage;
  }

  get remoteConfig() {
    return this.appInstance.remoteConfig;
  }

  get databasePaths() {
    return this.appInstance.databasePaths;
  }

  private _doConnectToFirestore$: BehaviorSubject<boolean> =
    new BehaviorSubject(true);
  set doConnectToFirestore(value: boolean) {
    this._doConnectToFirestore$.next(value);
  }
  get doConnectToFirestore(): boolean {
    return this._doConnectToFirestore$.value;
  }

  // public user$: ReplaySubject<User> = new ReplaySubject<User>(1);
  public userBehaviorSubject$: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);
  public user$: Observable<User> = <Observable<User>>(
    this.userBehaviorSubject$.asObservable().pipe(
      filter((user: User | null) => {
        return (
          user !== null &&
          user.uid !== null &&
          user.uid !== undefined &&
          user.uid.length > 0
        );
      })
    )
  );

  private _isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isLoggedInState$: Observable<boolean> =
    this._isLoggedIn$.asObservable();
  public isLoggedIn$: Observable<boolean> = this._isLoggedIn$
    .asObservable()
    .pipe(filter((b: boolean) => b));

  get isLoggedIn(): boolean {
    return this._isLoggedIn$.getValue();
  }

  public isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private _uid: string | null = null;

  get uid() {
    return this._uid;
  }

  user: WritableSignal<User | null> = signal(null);

  get env(): ENV_SERVER_ENUM {
    return this.appInstance.env;
  }

  constructor(
    @Inject(NG_PAT_FIREBASE_INSTANCE)
    protected appInstance: NgPatFirebaseAppInstance
  ) {
    if (
      this.appInstance.remoteConfigParams?.settings?.minimumFetchIntervalMillis
    ) {
      this.remoteConfig.settings.minimumFetchIntervalMillis =
        this.appInstance.remoteConfigParams.settings.minimumFetchIntervalMillis;
    }

    if (this.appInstance.remoteConfigParams?.settings?.refreshIntervalMillis) {
      this.startRemoteConfigPoll();
    }

    // fetchAndActivate(this.appInstance.remoteConfig)
    //   .then(() => {
    //     // this.getRemoteConfigTimer();
    //
    //     const remoteConfigValue: Record<string, Value> = getAll(this.remoteConfig);
    //
    //     const config: RemoteConfigEntity[] = Object.keys(remoteConfigValue).map((key: string) => {
    //       let value = getValue(this.remoteConfig, key).asString();
    //
    //       try {
    //         value = JSON.parse(value);
    //       } catch (error) {
    //         console.error('error', error);
    //       }
    //
    //       return <RemoteConfigEntity>{
    //         id: key,
    //         value
    //       };
    //     }, []);
    //
    //     this.remoteConfig$.next(config);
    //
    //     // ...
    //   })
    //   .catch(err => {
    //     // ...
    //   });

    const self = this;

    onAuthStateChanged(this.auth, function (user: User | null) {
      if (user && user.uid && user.uid.length) {
        // self.user$.next(user);
        self.userBehaviorSubject$.next(user);
        self.user.set(user);

        if (user.uid && user.uid.length) {
          setUserId(self.analytics, user.uid);
          self._uid = user.uid;
        }
      }

      self._isLoggedIn$.next(user !== null);
      self.isLoaded$.next(user !== null);
    });

    // if (this.environment.emulator) {
    //   this._db.useEmulator('localhost', EMULATOR_PORTS.FIRESTORE);
    //   this._auth.useEmulator(`http://localhost:${EMULATOR_PORTS.AUTH}`);
    //   this._storage.useEmulator('localhost', EMULATOR_PORTS.STORAGE);
    //   this._functions.useEmulator('localhost', EMULATOR_PORTS.FUNCTIONS);
    // }

    // const messaging = firebase.messaging();

    // TODO FirestoreSettings.localCache
    // https://youtu.be/ciu62KLlwGQ?t=318
    // enableIndexedDbPersistence(this.db).catch(err => {
    //   if (err.code == 'failed-precondition') {
    //     // Multiple tabs open, persistence can only be enabled
    //     // in one tab at a a time.
    //     // ...
    //   } else if (err.code == 'unimplemented') {
    //     // The current browser does not support all of the
    //     // features required to enable persistence
    //     // ...
    //   }
    // });

    // console.log('SptFirestoreService INIT');
  }

  private async startRemoteConfigPoll() {
    await fetchAndActivate(this.remoteConfig);

    if (
      this.remoteConfigSub.closed &&
      this.appInstance.remoteConfigParams?.settings.refreshIntervalMillis
    ) {
      combineLatest([
        timer(
          0,
          this.appInstance.remoteConfigParams.settings.refreshIntervalMillis
        ),
        this._doConnectToFirestore$
      ])
        .pipe(
          filter(([num, doConnect]: [number, boolean]) => {
            // console.log('num', num, 'doConnect', doConnect);
            return doConnect;
          }),
          map(() => {
            return getAll(this.remoteConfig);
          })
        )
        .subscribe((remoteConfigValue: Record<string, Value>) => {
          const config: RemoteConfigEntity[] = Object.keys(
            remoteConfigValue
          ).map((key: string) => {
            return {
              id: key,
              value: getValue(this.remoteConfig, key).asString()
            };
          }, []);

          // console.log('remoteConfigValue', remoteConfigValue, 'config', config);

          this.remoteConfig$.next(config);
        });
    }
  }

  /// Firebase Server Timestamp
  get timestamp(): FieldValue {
    // return firebase.database.ServerValue.TIMESTAMP;
    return serverTimestamp();
  }

  logEvent(
    eventName: AppEventName<string>,
    eventParams?: FirebaseAnalyticEventParams,
    options?: AnalyticsCallOptions
  ): void {
    const _eventParams: FirebaseAnalyticEventParams = {
      ...eventParams,
      app_name: this.appInstance.appName
    };

    if (this._uid && this._uid.length) {
      _eventParams['uid'] = this._uid;
    }

    if (options) {
      logEvent(this.analytics, eventName, _eventParams, options);
    } else {
      logEvent(this.analytics, eventName, _eventParams);
    }
  }

  /// **************
  collectionRef(path: string): CollectionReference<DocumentData> {
    return collection(this.db, path);
  }

  collectionData<T>(path: string): Promise<T[]> {
    return getDocs(this.collectionRef(path)).then(
      (q: QuerySnapshot<DocumentData>) => {
        return new Promise<T[]>(resolve => {
          const docs: T[] = [];

          q.forEach((d: QueryDocumentSnapshot<DocumentData>) => {
            docs.push(<T>removeTimestampCTorFromDocumentSnapshot(d));
          });

          resolve(docs);
        });
      }
    );
  }

  collectionData$<T>(path: string): Observable<T[]> {
    return from(this.collectionData<T>(path));
  }

  /// **************
  /// Get Data

  docRef(path: string): DocumentReference<DocumentData> {
    return doc(this.db, path);
  }

  docData$<T>(path: string): Observable<T | null> {
    const that = this;

    return new Observable((observer: Observer<T | null>) => {
      const doc = getDoc(that.docRef(path));
      doc
        .then((snap: DocumentSnapshot) => {
          observer.next(<T>removeTimestampCTorFromDocumentSnapshot(snap));
          observer.complete();
        })
        .catch(() => {
          observer.next(null);
          observer.complete();
        });
    });
  }

  docData<T>(path: string): Promise<T | null> {
    return new Promise(resolve => {
      getDoc(this.docRef(path))
        .then((d: DocumentSnapshot<DocumentData>) => {
          resolve(removeTimestampCTorFromDocumentSnapshot<T>(d));
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  getDoc(path: string): Promise<DocumentSnapshot<DocumentData>> {
    return getDoc(this.docRef(path));
  }

  getDoc$(path: string): Observable<DocumentSnapshot<DocumentData>> {
    return new Observable(
      (observer: Observer<DocumentSnapshot<DocumentData>>) => {
        getDoc(this.docRef(path))
          .then((snap: DocumentSnapshot<DocumentData>) => {
            observer.next(snap);
            observer.complete();
          })
          .catch((error: any) => {
            observer.error(error);
          });
      }
    );
  }

  /**
   * adds createdAt field
   *
   * Usage:
   * db.set('items/ID', data) })
   *
   * @param {DocPredicate<T>} ref
   * @param data
   * @returns {Promise<void>}
   */
  setDoc(path: string, data: any, options?: SetOptions): Promise<void> {
    const payload = this.payloadForSet(data);

    if (options) {
      return setDoc(this.docRef(path), payload, options);
    } else {
      return setDoc(this.docRef(path), payload);
    }
  }

  set$<T>(path: string, data: any, options?: SetOptions): Observable<T> {
    return new Observable((observer: Observer<any>) => {
      const payload = this.payloadForSet(data);

      // console.log(path, data, options);
      const p: Promise<void> = options
        ? setDoc(this.docRef(path), payload, options)
        : setDoc(this.docRef(path), payload);
      p.then(() => {
        observer.next(removeTimeStampCTorFromData(payload));
        observer.complete();
      }).catch((e: any) => {
        console.log('error');
        console.log(e);
        observer.error(e);
      });
    });
  }

  setWithoutTimestamp<T>(path: string, data: any): Promise<void> {
    return setDoc(
      this.docRef(path),
      {
        ...data
      },
      {merge: true}
    );
  }

  /**
   * adds updatedAt field do document
   * @param path
   * @param data
   */
  merge$<T>(path: string, data: any): Observable<Exists<T>> {
    const that = this;

    return new Observable((observer: Observer<any>) => {
      setDoc(that.docRef(path), that.payloadForUpdate(data), {merge: true})
        .then(() => {
          // console.log('result', result);
          // Get data after it is set

          getDoc(that.docRef(path))
            .then((setSnap: DocumentSnapshot) => {
              observer.next(<Exists<T>>{
                data: removeTimeStampCTorFromData(setSnap.data()),
                exists: true
              });
              observer.complete();
            })
            .catch(error => {
              observer.error(error);
            });
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }

  merge<T>(path: string, data: any): Promise<Exists<T>> {
    return new Promise<Exists<T>>((resolve, reject) => {
      this.merge$(path, data).subscribe({
        error: (err: any) => {
          reject(err);
        },
        next: (r: Exists<unknown>) => {
          resolve(r as Exists<T>);
        }
      });
    });
  }

  /**
   * Update firestore doc only if values updated
   * do not match value already set in firestore doc.
   * @param path
   */
  mergeIfValuesNotMatch$<T>(
    path: string,
    data: any,
    transformFn: (data: any) => any
  ) {
    const that = this;
    return new Observable((observer: Observer<T>) => {
      getDoc(that.docRef(path))
        .then((snap: DocumentSnapshot) => {
          if (snap.exists()) {
            if (!allValuesMatch(data, transformFn(snap.data()))) {
              setDoc(that.docRef(path), that.payloadForUpdate(data), {
                merge: true
              })
                .then(() => {
                  observer.next(data);
                  observer.complete();
                })
                .catch((error: any) => {
                  observer.error(error);
                });
            }
          } else {
            setDoc(that.docRef(path), that.payloadForUpdate(data), {
              merge: true
            })
              .then(() => {
                observer.next(data);
                observer.complete();
              })
              .catch((error: any) => {
                observer.error(error);
              });
          }
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }

  update$<T>(path: string, data: any): Observable<Exists<T>> {
    const that = this;

    return new Observable((observer: Observer<any>) => {
      updateDoc(that.docRef(path), that.payloadForUpdate(data))
        .then(() => {
          // console.log('result', result);
          // Get data after it is set

          getDoc(that.docRef(path))
            .then((setSnap: DocumentSnapshot) => {
              observer.next(<Exists<T>>{
                data: removeTimeStampCTorFromData(setSnap.data()),
                exists: true
              });
              observer.complete();
            })
            .catch(error => {
              observer.error(error);
            });
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }

  updateWithoutTimestamp<T>(path: string, data: any): Promise<void> {
    return setDoc(this.docRef(path), data, {merge: true});
  }

  deleteDoc<T>(path: string): Promise<void> {
    return deleteDoc(this.docRef(path));
  }

  deleteDoc$<T>(path: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      deleteDoc(this.docRef(path)).then(
        () => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  deleteDocs$<T>(basePath: string, ids: string[]): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const batch: WriteBatch = this.writeBatch();

      ids.forEach((id: string) => {
        batch.delete(this.docRef(`${basePath}/${id}`));
      });

      batch.commit().then(
        () => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  writeDocs$<T>(
    basePath: string,
    docs: FirestoreWriteEmailConfig[]
  ): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const batch: WriteBatch = this.writeBatch();

      docs.forEach((d: FirestoreWriteEmailConfig) => {
        batch.set(this.docRef(`${basePath}/${d.id}`), d.doc);
      });

      batch.commit().then(
        () => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  /**
   * adds createdAt field
   *
   * Usage:
   * db.add('items', data) })
   *
   * @param {CollectionPredicate<T>} ref
   * @param data
   * @returns {Promise<firebase.firestore.DocumentReference>}
   */
  addDoc<T>(path: string, data: any): Promise<DocumentReference> {
    return addDoc(this.collectionRef(path), this.payloadForSet(data));
  }

  /**
   * Usage:
   * const geopoint = this.db.geopoint(38, -119)
   * return this.db.add('items', { location: geopoint })
   *
   * @param {number} lat
   * @param {number} lng
   * @returns {firebase.firestore.GeoPoint}
   */
  geopoint(lat: number, lng: number): GeoPoint {
    return new GeoPoint(lat, lng);
  }

  /**
   * If doc exists update$, otherwise set
   *
   * Usage:
   * this.db.upsert('notes/xyz', { content: 'hello dude'})
   *
   * @param {DocPredicate<T>} ref
   * @param data
   * @returns {Promise<any>}
   */
  upsertDoc$<T>(path: string, data: any): Observable<Exists<T>> {
    const that = this;
    return new Observable((observer: Observer<any>) => {
      getDoc(this.docRef(path)).then((snap: DocumentSnapshot): any => {
        if (!snap.exists()) {
          that
            .setDoc(path, data)
            .then((result: any) => {
              // console.log('result', result);
              // Get data after it is set
              getDoc(that.docRef(path))
                .then((setSnap: DocumentSnapshot) => {
                  observer.next(<Exists<T>>{
                    data: removeTimestampCTorFromDocumentSnapshot(setSnap),
                    exists: true
                  });
                  observer.complete();
                })
                .catch(error => {
                  observer.error(error);
                });
            })
            .catch(error => {
              observer.error(error);
            });
        } else {
          that.merge$<T>(path, data).subscribe((r: Exists<T>) => {
            observer.next(r);
            observer.complete();
          });
        }
      });
    });
  }

  /**
   * Returns true if doc existed, false if not
   * Always returns data if not exists since it's created
   * @param path
   * @param data
   */
  setDocIfNotExist<T>(path: string, data: any): Observable<Exists<T>> {
    const that = this;

    return new Observable((observer: Observer<any>) => {
      getDoc(this.docRef(path)).then((snap: DocumentSnapshot): any => {
        if (!snap.exists()) {
          setDoc(this.docRef(path), that.payloadForSet(data))
            .then(() => {
              // Get data after it is set
              getDoc(that.docRef(path))
                .then((setSnap: DocumentSnapshot) => {
                  observer.next(<Exists<T>>{
                    data: removeTimeStampCTorFromData(setSnap.data()),
                    exists: true
                  });
                  observer.complete();
                })
                .catch(error => {
                  console.error(error);
                  observer.error(error);
                });
            })
            .catch(error => {
              console.error(error);
              observer.error(error);
            });
        } else {
          observer.next(<Exists<T>>{
            data: removeTimestampCTorFromDocumentSnapshot(snap),
            exists: true
          });
          observer.complete();
        }
      });
    });
  }

  /**
   * Only update if doc exists
   *
   * Usage:
   * this.db.upsert('notes/xyz', { content: 'hello dude'})
   *
   * @param {DocPredicate<T>} path
   * @param data
   * @returns {Observable<Exists<T>}
   */
  updateIfExists$<T>(path: string, data: any): Observable<Exists<T>> {
    // console.log(path, data);
    return new Observable((observer: Observer<Exists<T>>) => {
      getDoc(this.docRef(path)).then((snap: DocumentSnapshot): any => {
        // console.log(snap);

        // If doc exists in firestore
        if (snap && snap.exists()) {
          // Get the entire document
          const rootData: any = snap.data();

          let payload: any = {};

          if (hasValue(rootData)) {
            payload = this.payloadForUpdate(data);
          } else {
            payload = this.payloadForSet(data);
          }

          setDoc(this.docRef(path), payload, {merge: true})
            .then(() => {
              observer.next(<Exists<T>>{
                data: <T>removeTimeStampCTorFromData(payload),
                exists: true
              });
              observer.complete();
            })
            .catch((e: any) => {
              observer.error(e);
            });
        } else {
          observer.next(<Exists<T>>{
            data: data,
            exists: false
          });
          observer.complete();
        }
      });
    });
  }

  /**
   * Only update if doc exists
   *
   * Usage:
   * this.db.upsert('notes/xyz', { content: 'hello dude'})
   *
   * @param {DocPredicate<T>} path
   * @param data
   * @returns {Promise<Exists<T>}
   */
  updateIfExists<T>(path: string, data: any): Promise<Exists<T>> {
    return new Promise((resolve, reject) => {
      getDoc(this.docRef(path)).then((snap: DocumentSnapshot): any => {
        // console.log(snap);

        // If doc exists in firestore
        if (snap && snap.exists()) {
          // Get the entire document
          const rootData: any = snap.data();

          let payload: any = {};

          if (hasValue(rootData)) {
            payload = this.payloadForUpdate(data);
          } else {
            payload = this.payloadForSet(data);
          }

          setDoc(this.docRef(path), payload, {merge: true})
            .then(() => {
              resolve(<Exists<T>>{
                data: <T>removeTimeStampCTorFromData(payload),
                exists: true
              });
            })
            .catch((e: any) => {
              reject(e);
            });
        } else {
          resolve(<Exists<T>>{
            data: data,
            exists: false
          });
        }
      });
    });
  }

  upsert<T>(path: string, data: any): Promise<Exists<T>> {
    return new Promise<Exists<T>>((resolve, reject) => {
      getDoc(this.docRef(path)).then((snap: DocumentSnapshot): any => {
        // console.log(snap);

        if (!snap.exists()) {
          const payload = this.payloadForSet(data);

          // use .set with merge true
          setDoc(this.docRef(path), payload, {merge: true})
            .then(() => {
              resolve(<Exists<T>>{
                data: <T>removeTimeStampCTorFromData(payload),
                exists: true
              });
            })
            .catch((e: any) => {
              console.log(e);
              reject(e);
            });
        } else {
          // Get the entire document
          const rootData: any = snap.data();

          if (hasValue(rootData)) {
            const payload = this.payloadForUpdate(data);

            setDoc(this.docRef(path), payload, {merge: true})
              .then(() => {
                resolve(<Exists<T>>{
                  data: <T>removeTimeStampCTorFromData(payload),
                  exists: true
                });
              })
              .catch((e: any) => {
                reject(e);
              });
          } else {
            // use .set with merge true
            const payload = this.payloadForSet(data);
            setDoc(this.docRef(path), payload, {merge: true})
              .then(() => {
                resolve(<Exists<T>>{
                  data: <T>removeTimeStampCTorFromData(payload),
                  exists: true
                });
              })
              .catch((e: any) => {
                reject(e);
              });
          }
        }
      });
    });
  }

  mergeAndReturnPayload$<T>(path: string, data: any): Observable<Exists<T>> {
    return new Observable((observer: Observer<any>) => {
      const payload = this.payloadForSet(data);

      setDoc(this.docRef(path), payload, {merge: true})
        .then(() => {
          observer.next(<Exists<T>>{
            data: <T>removeTimeStampCTorFromData(payload),
            exists: true
          });
          observer.complete();
        })
        .catch((e: any) => {
          observer.error(e);
        });
    });
  }

  payloadForSet(_data: any): any {
    const timestamp: FieldValue = this.timestamp;

    const data = JSON.parse(JSON.stringify(_data));

    const payload: any = {
      ...data,
      createdAt: timestamp,
      updatedAt: timestamp
    };

    return payload;
  }

  payloadForUpdate(_data: any): any {
    const timestamp: FieldValue = this.timestamp;

    const data = JSON.parse(JSON.stringify(_data));

    delete data.createdAt;
    delete data.updatedAtSeconds;

    const payload: any = {
      ...data,
      updatedAt: timestamp
    };

    return payload;
  }

  /// **************
  /// Inspect Data
  /// **************

  /**
   * Usage:
   * this.db.inspectDoc('notes/xyz')
   *
   * @param {DocPredicate<any>} ref
   */
  inspectDoc(path: string): void {
    const tick: number = new Date().getTime();

    getDoc(this.docRef(path)).then((r: any) => {
      const tock: number = new Date().getTime() - tick;
      console.log(`Loaded Document in ${tock}ms`, r);
    });
  }

  /**
   * Usage:
   * this.db.inspectCol('notes')
   *
   * @param {CollectionPredicate<any>} path
   */
  inspectCol(path: string): void {
    const tick: number = new Date().getTime();

    getDocs(this.collectionRef(path)).then((r: any) => {
      const tock: number = new Date().getTime() - tick;
      console.log(`Loaded Collection in ${tock}ms`, r);
    });
  }

  /// **************
  /// Create and read doc references
  /// **************
  /// create a reference between two documents
  // connect<T>(host: string, key: string, doc: string): Promise<void | T> {
  //   return this.docData(host).then((d: unknown | T) => {
  //     if (d) {
  //       updateDoc(this.docRef(host), {[key]: <T>d});
  //     }
  //     // this.doc(host).update$({[key]: d});
  //   });
  //
  //   // return updateDoc(this.docRef(host), {[key]: this.docRef(doc)});
  //   // return this.doc(host).update$({[key]: this.doc(doc)});
  // }

  writeBatch() {
    return writeBatch(this.db);
  }

  httpsCallable<R, T>(functionName: string): HttpsCallable<R, T> {
    return httpsCallable(this.functions, functionName);
  }

  getDownloadURL(url: string): Promise<string> {
    return getDownloadURL(ref(this.storage, url));
  }

  getBlobFromStorage(url: string): Promise<Blob> {
    const storageRef = ref(this.storage, url);
    return getBlob(storageRef);
  }

  deleteObjectFromStorage(url: string) {
    const desertRef = ref(this.storage, url);
    return deleteObject(desertRef);
  }

  deleteManyObjectsFromStorage(urls: string[]) {
    const refs: StorageReference[] = urls.map((url: string) => {
      return ref(this.storage, url);
    });

    const promises: Promise<void>[] = refs.map((ref: StorageReference) => {
      return deleteObject(ref);
    });

    return Promise.allSettled(promises);
  }

  /// **************
  /// Atomic batch example
  /// **************
  /// Just an example, you will need to customize this method.
  atomic(): Promise<void> {
    // Get a new write batch
    const batch = writeBatch(this.db);

    /// add your operations here
    const itemDoc: DocumentReference = this.docRef('items/myCoolItem');
    const userDoc: DocumentReference = this.docRef('users/userId');
    const currentTime: FieldValue = this.timestamp;
    batch.update(itemDoc, {timestamp: currentTime});
    batch.update(userDoc, {timestamp: currentTime});

    /// commit operations
    return batch.commit();
  }

  signOut() {
    return this.auth.signOut();
  }

  logoutFirebase$(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.auth
        .signOut()
        .then(() => {
          // this.clearIndexedDbPersistence().then(() => {
          //   /* noo */
          // });

          observer.next(true);
          observer.complete();
        })
        .catch((error: any) => {
          observer.error(error);
        });
    });
  }

  /**
   * Call the 'recursiveDeleteV2' callable function with a path to initiate
   * a server-side delete.
   */
  recursiveDelete$(path: string) {
    return new Observable((observer: Observer<boolean>) => {
      const deleteFn = httpsCallable(this.functions, 'recursiveDeleteV2');
      deleteFn({path: path})
        .then(function (result) {
          // console.log('Delete success: ' + JSON.stringify(result));
          observer.next(true);
          observer.complete();
        })
        .catch(function (err) {
          console.log('Delete failed, see console,');
          console.warn(err);
          observer.error(err);
        });
    });
  }

  recursiveDelete(path: string): Promise<boolean> {
    const deleteFn = httpsCallable(this.functions, 'recursiveDeleteV2');
    return deleteFn({path: path})
      .then(function (result) {
        // console.log('Delete success: ' + JSON.stringify(result));
        return true;
      })
      .catch(function (err) {
        console.log('Delete failed, see console,');
        console.warn(err);
        return false;
      });
  }

  copyImageAssets(paths: CopyAsset[]): Promise<boolean> {
    const copyFn = httpsCallable(this.functions, 'copyImageAssetsV2');
    return copyFn({
      env: this.env,
      paths: paths
    })
      .then(function (result) {
        // console.log('Delete success: ' + JSON.stringify(result));
        return true;
      })
      .catch(function (err) {
        console.log('Copy failed, see console,');
        console.warn(err);
        return false;
      });
  }

  /**
   * TODO Move to it's own getService
   * @param key
   */
  // setWebSocketConnected(key: string): void {
  //   this.store.pipe(select(selectNgPatGetWebSocketIdConnected(key)), take(1)).subscribe((connected: boolean) => {
  //     // Only dispatch action if websocket is not connected
  //     if (!connected) {
  //       this.store.dispatch(
  //         ngPatWebsocketIsConnectedAction({
  //           id: key
  //         })
  //       );
  //     }
  //   });
  // }

  // setWebSocketDisconnected(key: string): void {
  //   this.store.dispatch(
  //     ngPatWebsocketIsDisconnectedAction({
  //       id: key
  //     })
  //   );
  // }

  // getSocketIsConnected(key: string): Observable<boolean> {
  //   return this.store.pipe(select(selectNgPatGetWebSocketIdConnected(key)), take(1));
  // }

  removeTimeStampCTorFromData<T>(data: {createdAt: any; updatedAt: any}): T {
    return removeTimeStampCTorFromData(data);
  }

  removeTimeStampCTorFromDocumentData<T>(
    snap: DocumentSnapshot<DocumentData>
  ): T {
    return removeTimestampCTorFromDocumentSnapshot(snap);
  }

  signInWithPopup(
    provider: AuthProvider,
    resolver?: PopupRedirectResolver
  ): Promise<UserCredential> {
    return this.setPersistence().then(() => {
      return signInWithPopup(this.auth, provider, resolver);
    });
  }

  /**
   *
   * @param collectionPath
   * @param fieldPath
   * @param opStr
   * @param value
   */
  queryCollection<T>(
    collectionPath: string,
    fieldPath: string | FieldPath,
    opStr: WhereFilterOp,
    value: unknown
  ): Observable<T[]> {
    return new Observable((observer: Observer<T[]>) => {
      const q: Query<DocumentData> = query(
        this.collectionRef(collectionPath),
        where(fieldPath, opStr, value)
      );

      getDocs(q)
        .then((querySnapshot: QuerySnapshot<DocumentData>) => {
          if (querySnapshot.size) {
            const docs: T[] = [];

            querySnapshot.forEach(
              (doc: QueryDocumentSnapshot<DocumentData>) => {
                docs.push(removeTimestampCTorFromDocumentSnapshot(doc));
              }
            );

            observer.next(docs);
          } else {
            observer.error('No Found');
          }
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }

  signInWithRedirect(
    provider: AuthProvider,
    resolver?: PopupRedirectResolver
  ): Promise<never> {
    return this.setPersistence().then(() => {
      return signInWithRedirect(this.auth, provider, resolver);
    });
  }

  signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return this.setPersistence().then(() => {
      return signInWithEmailAndPassword(this.auth, email, password);
    });
  }

  signInWithCustomToken(customToken: string): Promise<UserCredential> {
    return this.setPersistence().then(() => {
      return signInWithCustomToken(this.auth, customToken);
    });
  }

  createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return this.setPersistence().then(() => {
      return createUserWithEmailAndPassword(this.auth, email, password);
    });
  }

  sendPasswordResetEmail(
    email: string,
    actionCodeSettings?: ActionCodeSettings
  ): Promise<void> {
    return this.setPersistence().then(() => {
      return sendPasswordResetEmail(this.auth, email, actionCodeSettings);
    });
  }

  setPersistence(
    persistence: Persistence = browserLocalPersistence
  ): Promise<void> {
    return setPersistence(this.auth, persistence);
  }

  clearIndexedDbPersistence() {
    return clearIndexedDbPersistence(this.db);
  }

  initialize(): void {
    /* noop */
  }

  // REMOTE CONFIG

  getRemoteConfig(key: string): Value {
    return getValue(this.remoteConfig, key);
  }

  getAllRemoteConfig(): Record<string, Value> {
    return getAll(this.remoteConfig);
  }

  onSnapshotDoc$<T>() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let firebaseUnsubscribe: () => void = () => {};

    const that = this;

    return function (
      source: Observable<string | null | undefined>
    ): Observable<T> {
      return new Observable(subscriber => {
        const subscription = source.subscribe({
          complete() {
            subscriber.complete();
          },
          error(error) {
            subscriber.error(error);
          },
          next(path: string | undefined | null) {
            if (path && path.length) {
              firebaseUnsubscribe();
              firebaseUnsubscribe = onSnapshot(
                that.docRef(path),
                (snap: DocumentSnapshot<DocumentData>) => {
                  if (snap.data() !== null && snap.data() !== undefined) {
                    subscriber.next(<T>snap.data());
                  }
                }
              );
            }
          }
        });

        return () => {
          firebaseUnsubscribe();
          return subscription.unsubscribe();
        };
      });
    };
  }

  /**
   *
   * @param id = id of document
   * @param mapFirestoreId = map firestore id to id
   */
  onSnapshotCollection$<T>(
    id = 'id',
    mapFirestoreId = false
  ): (
    source: Observable<string | null | undefined>
  ) => Observable<NgPatAggregateFirebaseSnapshotChanges<T>> {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let firebaseUnsubscribe: () => void = () => {};

    const that = this;

    return function (
      source: Observable<string | null | undefined>
    ): Observable<NgPatAggregateFirebaseSnapshotChanges<T>> {
      return new Observable(subscriber => {
        const subscription = source.pipe(distinctUntilChanged()).subscribe({
          complete() {
            subscriber.complete();
          },
          error(error) {
            subscriber.error(error);
          },
          next(path: string | undefined | null) {
            if (path && path.length) {
              firebaseUnsubscribe();
              firebaseUnsubscribe = onSnapshot(
                that.collectionRef(path),
                (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
                  subscriber.next(
                    aggregateDocChangesFns<T>(
                      snapshot.docChanges(),
                      id,
                      mapFirestoreId
                    )
                  );
                }
              );
            }
          }
        });

        return () => {
          firebaseUnsubscribe();
          return subscription.unsubscribe();
        };
      });
    };
  }

  onSnapshotSubject$<T>(path: string, startWithData?: T): Observable<T> {
    const subject = new NgPatSnapshot<T>(this.docRef(path));

    if (startWithData) {
      return subject.pipe(startWith(startWithData));
    }

    return subject.asObservable();
  }

  createFirestoreId(): string {
    return uuidv4();
  }
}
