import {CommonModule} from '@angular/common';
import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-pricing-header'
    },
    imports: [CommonModule],
    selector: 'dlc-pricing-header',
    styleUrl: './pricing-header.component.scss',
    templateUrl: './pricing-header.component.html'
})
export class PricingHeaderComponent {
  @Input()
  headerSubtitle = ``;
  @Input() headerTitle = ``;
}
