import {Injectable} from '@angular/core';
import {
  NgPatFirestoreCollectionQuery,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {Store} from '@ngrx/store';
import {where} from 'firebase/firestore';
import {BehaviorSubject, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {NgPatAccountState} from '../../../+account/account.model';
import {NgPatServiceConnector} from '../../../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../../../+websocket-registry/websocket-registry.models';
import {aggregateUpdates} from '../../../fns/aggregate-updates';
import {StripeFirestorePathsService} from '../firestore-paths/stripe-firestore-paths.service';
import {
  ngPatDeleteStripeProducts,
  ngPatUpdateStripeProducts,
  ngPatUpsertStripeProducts
} from './product.actions';
import {NgPatStripeProduct} from './product.model';
import {productFeatureKey} from './product.reducer';

@Injectable({
  providedIn: 'root'
})
export class ProductService implements NgPatFirebaseConnectionService {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _queryService!: NgPatFirestoreCollectionQuery<NgPatStripeProduct>;

  connection: NgPatServiceConnector;
  connectionKey = productFeatureKey;

  initState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  init$ = this.initState$.asObservable().pipe(filter(init => init));

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store,
    private paths: StripeFirestorePathsService
  ) {
    this.connection = new NgPatServiceConnector(this, this.store);
  }

  ngPatOnInit() {
    this._queryService = new NgPatFirestoreCollectionQuery<NgPatStripeProduct>(
      {
        deleteManyAction: (ids: string[]) => ngPatDeleteStripeProducts({ids}),
        mapFirestoreID: true,
        queryConstrains: [where('active', '==', true)],
        queryMember: false,
        updateManyAction: (products: NgPatStripeProduct[]) =>
          ngPatUpdateStripeProducts({products: aggregateUpdates(products)}),
        upsertManyAction: (products: NgPatStripeProduct[]) =>
          ngPatUpsertStripeProducts({products})
      },
      this.store,
      this.customFirestoreService
    );

    if (this._queryService) {
      this.init$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
        this._queryService.onConnect(this.paths.products());
      });
    }
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    // if (this._queryService) {
    //   this.init$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
    //     this._queryService.onConnect(this.paths.products());
    //   });
    // }
  }

  onDisconnect(user: NgPatAccountState) {
    if (this._queryService) {
      // Unsubscribe to query
      this._queryService.onDisconnect();
    }

    this._onDestroy$.next(true);
  }
}
