<div
  class="current-plan"
  *ngIf="isOwned()">
  current plan
</div>
<div class="flex items-center justify-between gap-x-4">
  <p
    *ngIf="isMostPopular()"
    class="most-popular-button rounded-full px-2.5 py-1 text-xs font-semibold leading-5">
    Most popular
  </p>
</div>
<p class="price-container flex items-baseline gap-x-1">
  @if (isCustomPrice()) {
  <span class="text-white text-4xl font-bold tracking-tight w-full text-center"
    >Custom</span
  >
  } @else {
  <span class="text-4xl font-bold tracking-tight text-white">
    {{ priceInteger() }}
    <sup class="superscript">{{ priceDecimal() }}</sup>
  </span>
  }
</p>
<p
  class="text-sm font-semibold leading-6 text-gray-300"
  *ngIf="!isCustomPrice()">
  {{ interval() }}
</p>
@if (!isLoggedIn()) {
<button
  dlc-rounded-text-button
  color="accent"
  (click)="doLogin.emit(true)"
  aria-describedby="tier-startup"
  class="buy-button">
  Login to Buy
</button>
} @else if ( purchaseButtonState() === btnState.PURCHASE ||
purchaseButtonState() === btnState.DISABLED ) {
<button
  dlc-rounded-text-button
  color="primary"
  (click)="onSelectedOffer($event)"
  [disabled]="purchaseButtonState() === btnState.DISABLED"
  aria-describedby="tier-startup"
  class="buy-button">
  Buy
</button>
} @else {
<button
  dlc-rounded-text-button
  color="accent"
  (click)="onCancelOffer($event)"
  aria-describedby="tier-startup"
  class="buy-button">
  Cancel Subscription
</button>
}
