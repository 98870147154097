import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-auto-resize-textarea-controls'
  },
  selector: 'dlc-auto-resize-textarea-controls',
  standalone: false,
  styleUrls: ['./auto-resize-textarea-controls.component.scss'],
  templateUrl: './auto-resize-textarea-controls.component.html'
})
export class AutoResizeTextareaControlsComponent {}
