import {NgIf} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {firestoreUserAccountDoc} from '@gigasoftware/shared/store';
import {
  getMentorAccountProperties,
  NgPatAccountState,
  NgPatMentorAccount,
  selectNgPatMentoringMeAccounts
} from '@gigasoftware/shared/store';
import {LetDirective} from '@ngrx/component';
import {select, Store} from '@ngrx/store';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ui-mentoring-me-list'
    },
    imports: [
        LetDirective,
        NgIf,
        MatTableModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule
    ],
    selector: 'ui-mentoring-me-list',
    styleUrls: ['./mentoring-me-list.component.scss'],
    templateUrl: './mentoring-me-list.component.html'
})
export class MentoringMeListComponent {
  displayedColumns: string[] = ['username', 'displayName', 'uid'];
  userList$: Observable<NgPatMentorAccount[]>;

  constructor(private store: Store, private _firstore: NgPatFirestoreService) {
    this.userList$ = this.store.pipe(
      select(selectNgPatMentoringMeAccounts),
      switchMap((uids: string[]) => {
        if (!(uids && uids.length)) {
          return of([]);
        }

        const accounts$: Observable<NgPatAccountState | null>[] = uids
          .map((uid: string) => firestoreUserAccountDoc(uid))
          .map((path: string) => this._firstore.docData$(path));

        return forkJoin(accounts$).pipe(
          map((accounts: (NgPatAccountState | null)[]) => {
            const accs: NgPatAccountState[] = accounts.filter(
              (a: NgPatAccountState | null) => a !== null
            ) as NgPatAccountState[];

            return accs.map(getMentorAccountProperties);
          })
        );
      })
    );
  }
}
