import {CommonModule} from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';

const DEFAULT_APPEARANCE: 'fill' | 'outline' = 'fill';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-label-container'
    },
    imports: [CommonModule],
    selector: 'dlc-label-container',
    styleUrls: ['./dlc-label-container.component.scss'],
    templateUrl: './dlc-label-container.component.html'
})
export class DlcLabelContainerComponent {}
