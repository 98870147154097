import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {switchMap, withLatestFrom} from 'rxjs/operators';

import {selectNgPatUserAccount} from '../+account/account.selectors';
import {Classroom} from '../+classrooms/classroom.model';
import {getClassroomByID} from '../+classrooms/classroom.selectors';
import {deleteCourse, deleteCourses} from './course.actions';
import {PartialCourseState} from './course.reducer';
import {CourseService} from './course.service';

@Injectable({
  providedIn: 'root'
})
export class CourseEffects {
  deleteDoc$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(deleteCourse),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.store.pipe(
            select(getClassroomByID(action.id)),
            switchMap((course: Classroom | undefined) =>
              this._courseService.deleteDoc$(course, <string>account.uid)
            )
          )
        )
      ),
    {dispatch: false}
  );

  deleteDocs$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(deleteCourses),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.store.pipe(
            select(getClassroomByID(action.ids[0])),
            switchMap((course: Classroom | undefined) =>
              this._courseService.deleteDocs$(
                course,
                action.ids,
                <string>account.uid
              )
            )
          )
        )
      ),
    {dispatch: false}
  );

  constructor(
    private _actions$: Actions,
    private store: Store<PartialCourseState>,
    private _courseService: CourseService
  ) {}
}
