import {
  AddedAndDeletedMemoizedSelector,
  BASE_COLLECTION_NAME,
  BaseEntity,
  GigaNoteDoc
} from '@gigasoftware/shared/models';
import {Dictionary} from '@ngrx/entity';
import {createSelector} from '@ngrx/store';

import {NgPatAccountState, NgPatUserAccount} from './+account/account.model';
import {
  selectNgPatAccountState,
  selectNgPatLoggedInUID,
  selectNgPatUserAccount
} from './+account/account.selectors';
import {
  Classroom,
  selectAllClassrooms,
  selectClassroomEntities
} from './+classrooms';
import {classroomFeatureKey} from './+classrooms/classroom.reducer';
import {selectClassroomMemberMap} from './+classrooms/classroom.selectors';
import {courseFeatureKey} from './+courses/course.reducer';
import {Course, selectCourseEntities} from './+courses/index';
import {
  Investigation,
  selectAllInvestigations,
  selectCurrentInvestigation,
  selectInvestigationEntities
} from './+investigation/index';
import {investigationFeatureKey} from './+investigation/investigation.reducer';
import {Journal, selectCurrentJournal} from './+journal/index';
import {concatMember} from './+members/member.fns';
import {MemberUIDBaseEntityID} from './+members/members.model';
import {getSelectedNote} from './+notes';
import {
  Project,
  selectAllProjects,
  selectCurrentProject,
  selectProjectEntities
} from './+project/index';
import {projectFeatureKey} from './+project/project.reducer';
import {CopyQuizParams, Quiz} from './+quizzes/quiz.model';
import {selectAllQuizzes, selectQuizMemberMap} from './+quizzes/quiz.selectors';
import {
  Research,
  selectAllResearchs,
  selectCurrentResearch,
  selectResearchEntities
} from './+research';
import {researchFeatureKey} from './+research/research.reducer';
import {StudyGroup} from './+study-groups/study-group.model';
import {studyGroupFeatureKey} from './+study-groups/study-group.reducer';
import {
  selectAllStudyGroups,
  selectCurrentStudyGroup,
  selectStudyGroupEntities,
  selectStudyGroupMemberMap
} from './+study-groups/study-group.selectors';
import {
  selectCurrentClassroom,
  selectCurrentInvestigationID,
  selectCurrentJournalID,
  selectCurrentProjectID,
  selectCurrentResearchID,
  selectCurrentStudyGroupID
} from './+ui/ui.selectors';
import {
  CollaborativeEntity,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT,
  PLATFORM_ENTITY_TYPES
} from './entity/entity.model';
import {getEntityTypeByNumber} from './entity/index';
import {
  ecQuizImageUploaderConfig,
  firestoreNotesCollection
} from './firebase/index';
import {UserPermissions} from './models/user.model.deprecated';
import {isEcRoleAdmin, isEcRoleRoot} from './models/user.roles';
import {selectNgPatAddedAndDeletedEntities} from './selectors';
import {selectHasActiveSubscription} from './subscription/subscription.selectors';

/**
 * @deprecated use selectBaseEntityByTypeAndID
 * @param entityType
 * @param id
 */
export const selectBaseEntityByType = (
  entityType: number | null,
  id: string | null
) => {
  return (state: {[key: string]: any}) => {
    if (entityType !== null && id) {
      if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id) {
        return state[studyGroupFeatureKey].entities[id];
      }
      if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id) {
        return state[classroomFeatureKey].entities[id];
      }
      if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.RESEARCH.id) {
        return state[researchFeatureKey].entities[id];
      }
      if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.COURSE.id) {
        return state[courseFeatureKey].entities[id];
      }
      if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.INVESTIGATION.id) {
        return state[investigationFeatureKey].entities[id];
      }

      if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.PROJECT.id) {
        return state[projectFeatureKey].entities[id];
      }
    }

    return null;
  };
};

export const selectBaseEntityByTypeAndID = (
  entityType: number | null,
  id: string | null
) =>
  createSelector(
    selectStudyGroupEntities,
    selectClassroomEntities,
    selectResearchEntities,
    selectCourseEntities,
    selectInvestigationEntities,
    (
      sg: Dictionary<StudyGroup>,
      c: Dictionary<Classroom>,
      r: Dictionary<Research>,
      course: Dictionary<Course>,
      i: Dictionary<Investigation>
    ): CollaborativeEntity | null | undefined => {
      if (entityType !== null && id) {
        if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id) {
          return sg[id];
        }
        if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id) {
          return c[id];
        }
        if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.RESEARCH.id) {
          return r[id];
        }
        if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.COURSE.id) {
          return course[id];
        }
        if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.INVESTIGATION.id) {
          return i[id];
        }
      }

      return null;
    }
  );

export const concatMemberUIDEntityID = createSelector(
  selectStudyGroupMemberMap,
  selectQuizMemberMap,
  selectClassroomMemberMap,
  (
    groups: MemberUIDBaseEntityID[],
    quizzes: MemberUIDBaseEntityID[],
    classes: MemberUIDBaseEntityID[]
  ) => {
    return concatMember([...groups, ...quizzes, ...classes]);
  }
);

export const selectAllBaseEntitiesDictionaries = createSelector(
  selectStudyGroupEntities,
  selectClassroomEntities,
  selectResearchEntities,
  selectProjectEntities,
  selectInvestigationEntities,
  (
    sg: Dictionary<StudyGroup>,
    c: Dictionary<Classroom>,
    r: Dictionary<Research>,
    p: Dictionary<Project>,
    i: Dictionary<Investigation>
  ) => {
    // merge all dictionaries
    return {
      ...sg,
      ...c,
      ...r,
      ...p,
      ...i
    };
  }
);

export const selectAddAndDeletedBaseEntities: AddedAndDeletedMemoizedSelector<BaseEntity> =
  selectNgPatAddedAndDeletedEntities<BaseEntity>(
    selectAllBaseEntitiesDictionaries
  );

export const selectAllBaseEntities = createSelector(
  selectAllStudyGroups,
  selectAllClassrooms,
  selectAllResearchs,
  selectAllProjects,
  selectAllInvestigations,
  (
    studyGroups: StudyGroup[],
    classrooms: Classroom[],
    researchs: Research[],
    projects: Project[],
    investigations: Investigation[]
  ) => {
    return [
      ...studyGroups,
      ...classrooms,
      ...researchs,
      ...projects,
      ...investigations
    ];
  }
);

export const selectAllEntityToCopyTo = createSelector(
  selectAllBaseEntities,
  selectNgPatUserAccount,
  // selectNgPatHasActiveStripeSubscription,
  (
    allEntities: CollaborativeEntity[],
    user: NgPatUserAccount
    // hasActiveSubscription: boolean
  ): CopyQuizParams[] => {
    let params: CopyQuizParams[] = [];

    // if (hasActiveSubscription && studyGroups && studyGroups.length) {
    if (allEntities && allEntities.length) {
      const _sg = allEntities.map((g: CollaborativeEntity) => {
        return <CopyQuizParams>{
          copyToParentEntities: g,
          entityTypeName: getEntityTypeByNumber(
            g.entityType,
            PLATFORM_ENTITY_TYPES
          ).name,
          isPersonal: false,
          name: g.name,
          uid: <string>user.uid
        };
      });

      params = [..._sg];
    }

    return [
      ...params,
      <CopyQuizParams>{
        copyToParentEntities: null,
        entityTypeName: 'Personal ',
        isPersonal: true,
        name: 'My Quizzes',
        uid: <string>user.uid
      }
    ];
  }
);

export const getEntityIsCreatedByLoggedInUser = (p: UserPermissions) =>
  createSelector(selectNgPatLoggedInUID, (uid: string | null) => {
    return p?.createdBy?.uid === uid;
  });

// PROJECTS

export const selectCurrentProjectQuizzes = createSelector(
  selectCurrentProjectID,
  selectAllQuizzes,
  (projectID: string | null | undefined, quizzes: Quiz[]) => {
    if (projectID) {
      return quizzes.filter((q: Quiz) => q.parentEntityID === projectID);
    }
    return [];
  }
);

export const selectCurrentProjectQuizIDs = createSelector(
  selectCurrentProjectQuizzes,
  (quizzes: Quiz[]) => {
    return quizzes.map((q: Quiz) => q.id);
  }
);

export const projectNoteConfig = createSelector(
  selectCurrentProject,
  getSelectedNote,
  (
    project: Project | null | undefined,
    note: GigaNoteDoc | null | undefined
  ) => {
    if (project) {
      let firestoreNoteCollectionPath = '';

      if (note) {
        firestoreNoteCollectionPath = note.firestorePath;
      } else {
        firestoreNoteCollectionPath = firestoreNotesCollection(
          BASE_COLLECTION_NAME.PROJECTS,
          project.id
        );
      }

      return {
        basePath: firestoreNoteCollectionPath,
        parentId: project.id,
        uploadImageConfig: ecQuizImageUploaderConfig
      };
    }

    return null;
  }
);

// INVESTIGATION

export const selectCurrentInvestigationQuizzes = createSelector(
  selectCurrentInvestigationID,
  selectAllQuizzes,
  (investigationID: string | null | undefined, quizzes: Quiz[]) => {
    if (investigationID) {
      return quizzes.filter((q: Quiz) => q.parentEntityID === investigationID);
    }
    return [];
  }
);

export const selectCurrentInvestigationQuizIDs = createSelector(
  selectCurrentInvestigationQuizzes,
  (quizzes: Quiz[]) => {
    return quizzes.map((q: Quiz) => q.id);
  }
);

export const investigationNoteConfig = createSelector(
  selectCurrentInvestigation,
  getSelectedNote,
  (
    investigation: Investigation | null | undefined,
    note: GigaNoteDoc | null | undefined
  ) => {
    if (investigation) {
      let firestoreNoteCollectionPath = '';

      if (note) {
        firestoreNoteCollectionPath = note.firestorePath;
      } else {
        firestoreNoteCollectionPath = firestoreNotesCollection(
          BASE_COLLECTION_NAME.INVESTIGATIONS,
          investigation.id
        );
      }

      return {
        basePath: firestoreNoteCollectionPath,
        parentId: investigation.id,
        uploadImageConfig: ecQuizImageUploaderConfig
      };
    }

    return null;
  }
);

// RESEARCH

export const selectCurrentResearchQuizzes = createSelector(
  selectCurrentResearchID,
  selectAllQuizzes,
  (researchID: string | null | undefined, quizzes: Quiz[]) => {
    if (researchID) {
      return quizzes.filter((q: Quiz) => q.parentEntityID === researchID);
    }
    return [];
  }
);

export const selectCurrentResearchQuizIDs = createSelector(
  selectCurrentResearchQuizzes,
  (quizzes: Quiz[]) => {
    return quizzes.map((q: Quiz) => q.id);
  }
);

export const researchNoteConfig = createSelector(
  selectCurrentResearch,
  getSelectedNote,
  (
    research: Research | null | undefined,
    note: GigaNoteDoc | null | undefined
  ) => {
    if (research) {
      let firestoreNoteCollectionPath = '';

      if (note) {
        firestoreNoteCollectionPath = note.firestorePath;
      } else {
        firestoreNoteCollectionPath = firestoreNotesCollection(
          BASE_COLLECTION_NAME.RESEARCH,
          research.id
        );
      }

      return {
        basePath: firestoreNoteCollectionPath,
        parentId: research.id,
        uploadImageConfig: ecQuizImageUploaderConfig
      };
    }

    return null;
  }
);

// Journal

export const selectCurrentJournalQuizzes = createSelector(
  selectCurrentJournalID,
  selectAllQuizzes,
  (journalID: string | null | undefined, quizzes: Quiz[]) => {
    if (journalID) {
      return quizzes.filter((q: Quiz) => q.parentEntityID === journalID);
    }
    return [];
  }
);

export const selectCurrentJournalQuizIDs = createSelector(
  selectCurrentJournalQuizzes,
  (quizzes: Quiz[]) => {
    return quizzes.map((q: Quiz) => q.id);
  }
);

export const journalNoteConfig = createSelector(
  selectCurrentJournal,
  getSelectedNote,
  (
    journal: Journal | null | undefined,
    note: GigaNoteDoc | null | undefined
  ) => {
    if (journal) {
      let firestoreNoteCollectionPath = '';

      if (note) {
        firestoreNoteCollectionPath = note.firestorePath;
      } else {
        firestoreNoteCollectionPath = firestoreNotesCollection(
          BASE_COLLECTION_NAME.RESEARCH,
          journal.id
        );
      }

      return {
        basePath: firestoreNoteCollectionPath,
        parentId: journal.id,
        uploadImageConfig: ecQuizImageUploaderConfig
      };
    }

    return null;
  }
);

// STUDY GROUP
export const selectCurrentStudyGroupQuizzes = createSelector(
  selectCurrentStudyGroupID,
  selectAllQuizzes,
  (studyGroupID: string | null | undefined, quizzes: Quiz[]) => {
    if (studyGroupID) {
      return quizzes.filter((q: Quiz) => q.parentEntityID === studyGroupID);
    }
    return [];
  }
);

export const selectCurrentStudyGroupQuizIDs = createSelector(
  selectCurrentStudyGroupQuizzes,
  (quizzes: Quiz[]) => {
    return quizzes.map((q: Quiz) => q.id);
  }
);

// Classroom
export const selectCurrentClassroomQuizzesForTeacher = createSelector(
  selectAllQuizzes,
  selectCurrentClassroom,
  selectHasActiveSubscription,
  (
    quizzes: Quiz[],
    classroom: Classroom | null | undefined,
    hasTeacherSubscription: boolean
  ) => {
    if (classroom) {
      return quizzes
        .filter((q: Quiz) => q.parentEntityID === classroom.id)
        .filter((q: Quiz) => hasTeacherSubscription || q.assigned);
    }

    return [];
  }
);

export const selectCurrentClassroomQuizzes = createSelector(
  selectAllQuizzes,
  selectCurrentClassroom,
  (quizzes: Quiz[], classroom: Classroom | null | undefined) => {
    if (classroom) {
      return quizzes.filter((q: Quiz) => q.parentEntityID === classroom.id);
    }

    return [];
  }
);

export const selectCurrentClassroomQuizIDs = createSelector(
  selectCurrentClassroomQuizzes,
  (quizzes: Quiz[]) => {
    return quizzes.map((q: Quiz) => q.id);
  }
);

export const selectLoggedInUserIsClassroomOwner = createSelector(
  selectNgPatUserAccount,
  selectCurrentClassroom,
  (user: NgPatUserAccount, classroom: Classroom | null | undefined) => {
    if (user && classroom) {
      return classroom.createdBy.uid === user.uid;
    }

    return false;
  }
);

export const selectLoggedInUserIsProjectOwner = createSelector(
  selectNgPatUserAccount,
  selectCurrentProject,
  (user: NgPatUserAccount, studyGroup: Project | null | undefined) => {
    if (user && studyGroup) {
      return studyGroup.createdBy.uid === user.uid;
    }

    return false;
  }
);

export const selectLoggedInUserIsResearchOwner = createSelector(
  selectNgPatUserAccount,
  selectCurrentResearch,
  (user: NgPatUserAccount, research: Research | null | undefined) => {
    if (user && research) {
      return research.createdBy.uid === user.uid;
    }

    return false;
  }
);

export const selectLoggedInUserIsJournalOwner = createSelector(
  selectNgPatUserAccount,
  selectCurrentJournal,
  (user: NgPatUserAccount, journal: Journal | null | undefined) => {
    if (user && journal) {
      return journal.createdBy.uid === user.uid;
    }

    return false;
  }
);

export const selectLoggedInUserIsInvestigationOwner = createSelector(
  selectNgPatUserAccount,
  selectCurrentInvestigation,
  (user: NgPatUserAccount, investigation: Investigation | null | undefined) => {
    if (user && investigation) {
      return investigation.createdBy.uid === user.uid;
    }

    return false;
  }
);

export const selectLoggedInUserIsStudyGroupOwner = createSelector(
  selectNgPatUserAccount,
  selectCurrentStudyGroup,
  (user: NgPatUserAccount, studyGroup: StudyGroup | null | undefined) => {
    if (user && studyGroup) {
      return studyGroup.createdBy.uid === user.uid;
    }

    return false;
  }
);

export const selectLoggedInUserIsEcAdmin = createSelector(
  selectNgPatAccountState,
  (user: NgPatAccountState | null): boolean => {
    return isEcRoleAdmin(user);
  }
);

export const selectLoggedInUserIsEcRoot = createSelector(
  selectNgPatAccountState,
  (user: NgPatAccountState | null): boolean => {
    return isEcRoleRoot(user);
  }
);

export const selectLoggedInUserIsEcAdminOrRoot = createSelector(
  selectNgPatAccountState,
  (user: NgPatAccountState | null): boolean => {
    return isEcRoleAdmin(user) || isEcRoleRoot(user);
  }
);

export interface CanDeletePublishedQuiz {
  isAdminOrRoot: boolean;
  loggedInUserUID: string | null;
}

export const selectCanDeletePublishedQuiz = createSelector(
  selectNgPatAccountState,
  selectLoggedInUserIsEcAdminOrRoot,
  (
    user: NgPatAccountState | null,
    isAdminOrRoot: boolean
  ): CanDeletePublishedQuiz => {
    return {
      isAdminOrRoot,
      loggedInUserUID: user?.uid || null
    };
  }
);

export const studyGroupNoteConfig = createSelector(
  selectCurrentStudyGroup,
  getSelectedNote,
  (
    studyGroup: StudyGroup | null | undefined,
    note: GigaNoteDoc | null | undefined
  ) => {
    if (studyGroup) {
      let firestoreNoteCollectionPath = '';

      if (note) {
        firestoreNoteCollectionPath = note.firestorePath;
      } else {
        firestoreNoteCollectionPath = firestoreNotesCollection(
          BASE_COLLECTION_NAME.STUDY_GROUPS,
          studyGroup.id
        );
      }

      return {
        basePath: firestoreNoteCollectionPath,
        parentId: studyGroup.id,
        uploadImageConfig: ecQuizImageUploaderConfig
      };
    }

    return null;
  }
);

export const classroomNoteConfig = createSelector(
  selectCurrentClassroom,
  getSelectedNote,
  (
    classroom: Classroom | null | undefined,
    note: GigaNoteDoc | null | undefined
  ) => {
    if (classroom) {
      let firestoreNoteCollectionPath = '';

      if (note) {
        firestoreNoteCollectionPath = note.firestorePath;
      } else {
        firestoreNoteCollectionPath = firestoreNotesCollection(
          BASE_COLLECTION_NAME.CLASSES,
          classroom.id
        );
      }

      return {
        basePath: firestoreNoteCollectionPath,
        parentId: classroom.id,
        uploadImageConfig: ecQuizImageUploaderConfig
      };
    }

    return null;
  }
);
