import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogModule, MatButtonModule],
    selector: 'spt-cancel-subscription-success',
    styleUrls: ['./cancel-stripe-subscription-success.component.scss'],
    templateUrl: './cancel-stripe-subscription-success.component.html'
})
export class CancelStripeSubscriptionSuccessComponent {}
