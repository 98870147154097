import {formatDate} from '@angular/common';
import {inject, Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {
  GigaNoteDoc,
  GigaNoteImageState,
  GigaNoteTranscriptionState
} from '@gigasoftware/shared/models';
import {typedFilter} from '@gigasoftware/shared/rxjs';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';

import {
  getLastSegment,
  hasOddSegments,
  ofDlcNoteActionTypeWithFirestoreDoc
} from './note.fns';
import {
  DlcNoteBasePathImageConfig,
  DlcNoteProcessQueue,
  DlcNoteSaveAction
} from './note.model';
import {DlcNoteStoreService} from './note.store';

@Injectable()
export class DlcNoteFirestoreService {
  private _onDestroy$: Subject<boolean> = new Subject();
  private store = inject(DlcNoteStoreService);
  firestore: NgPatFirestoreService = inject(NgPatFirestoreService);

  loadDocFromFirestore$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  defaultTitle = formatDate(new Date(), 'medium', 'en-US');

  constructor() {
    combineLatest([
      this.store.selectFirestorePath$.pipe(distinctUntilChanged()),
      this.store.selectUuid$.pipe(
        typedFilter<string | null, string>(
          (uuid: string | null) => <boolean>(uuid && uuid.length > 0)
        ),
        distinctUntilChanged()
      )
    ])
      .pipe(
        takeUntil(this._onDestroy$),
        switchMap(([firestorePath, uuid]: [string, string]) => {
          // console.log(firestorePath, uuid, 'switchMap');
          return this.firestore.onSnapshotSubject$<GigaNoteDoc>(
            firestorePath
            // this.createDefaultDoc({
            //   id: uuid,
            //   firestorePath: firestorePath
            // })
          );
          // .pipe(
          //   tap((doc: GigaNoteDoc) => {
          //     console.log(doc, 'onSnapshotSubject$');
          //   })
          // );
        })
      )
      .subscribe(this.store.patchFirestoreDoc);

    this.store.saveDataProcessQueue.currentItem$
      .pipe(
        takeUntil(this._onDestroy$),
        // tap((action: DlcNoteProcessQueue) => {
        //   console.log(action);
        // }),
        ofDlcNoteActionTypeWithFirestoreDoc(
          DlcNoteSaveAction.SAVE_FIRESTORE_DOC
        )
        // filter((action: DlcNoteProcessQueue) => {
        //   return action && action.action === DlcNoteSaveAction.SAVE_FIRESTORE_DOC && action.firestoreDoc !== null;
        // })
      )
      .subscribe(async (action: DlcNoteProcessQueue) => {
        // console.log(action);
        await this.store.updateFirestoreDocPromise(
          action.firestoreDoc as GigaNoteDoc
        );
        await this.store.saveDataProcessQueue.next(action.id);
      });
  }

  /**
   * Set the base path for the firestore collection.
   * The path should have an odd number of segments.
   *
   * [collection]/[document]/[subcollection]
   * @param config
   */
  setConfig({
    basePath,
    parentId,
    uploadImageConfig
  }: DlcNoteBasePathImageConfig): void {
    // If the base path has an odd number of segments,
    // then create a uuid for the document id.
    // else use the last segment as the document id.
    const lastSegment = getLastSegment(basePath);
    const isOdd = hasOddSegments(basePath);
    const uuid = isOdd ? uuidv4() : lastSegment;

    const docPath = isOdd ? `${basePath}/${uuid}` : basePath;

    // const imagePath = `${uploadImageConfig.baseImageDirectory}/${uuid}/${uuid}.png`;

    this.store.updateFirestorePath(docPath);
    this.store.uuid(uuid);

    if (isOdd) {
      this.store.patchFirestoreDoc(
        this.createDefaultDoc({
          firestorePath: docPath,
          id: uuid,
          parentId
        })
      );
    }

    // this.store.clear();

    this.store.updateUploadConfig({
      ...uploadImageConfig
    });
  }

  createDefaultDoc(update: Partial<GigaNoteDoc>): GigaNoteDoc {
    return {
      firestorePath: '',
      id: '',
      imagePath: '', // image is not uploaded yet, therefore empty
      imageState: GigaNoteImageState.PENDING,
      parentId: 'personal',
      title: this.defaultTitle,
      transcribedTextId: '',
      transcribeState: GigaNoteTranscriptionState.PENDING,
      userNoteId: '',
      ...update
    };
  }

  onDestroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }
}
