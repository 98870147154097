import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {
  Journal,
  selectLoggedInUserIsJournalOwner
} from '@gigasoftware/shared/store';
import {
  DlcImageDirective,
  DlcRoundedIconButtonComponent,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';
import {Store} from '@ngrx/store';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-journal-list-item'
    },
    imports: [
        CommonModule,
        DlcImageDirective,
        DlcRoundedIconButtonComponent,
        DlcRoundedTextIconButtonComponent,
        MatIconModule,
        MatMenuModule
    ],
    selector: 'ec-journal-list-item, [ec-journal-list-item]',
    styleUrl: './ec-journal-list-item.component.scss',
    templateUrl: './ec-journal-list-item.component.html'
})
export class EcJournalListItemComponent {
  @Output() doDeleteItem: EventEmitter<Journal> = new EventEmitter<Journal>();

  @Output() doEditItem: EventEmitter<Journal> = new EventEmitter<Journal>();

  @Output() doLeaveItem: EventEmitter<Journal> = new EventEmitter<Journal>();
  @Output() doSelectItem: EventEmitter<Journal> = new EventEmitter<Journal>();
  @Input() journal!: Journal;
  loggedInUserIsOwner: Signal<boolean> = this.store.selectSignal(
    selectLoggedInUserIsJournalOwner
  );

  constructor(private store: Store) {}

  onDeleteJournal() {
    this.doDeleteItem.emit(this.journal);
  }

  onEditJournal() {
    this.doEditItem.emit(this.journal);
  }

  onLeaveJournal() {
    this.doLeaveItem.emit(this.journal);
  }

  onSelectJournal() {
    this.doSelectItem.emit(this.journal);
  }
}
