import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation
} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

import {DlcBookmarkService} from './dlc-bookmark.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatButtonModule,
        MatChipsModule,
        MatIconModule
    ],
    selector: 'dlc-create-bookmark',
    styleUrl: './dlc-create-bookmark.component.scss',
    templateUrl: './dlc-create-bookmark.component.html'
})
export class DlcCreateBookmarkComponent {
  dlcBookmarkService = inject(DlcBookmarkService);
}
