import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

import {DlcRoundedTextIconLogsubButtonComponent} from '../dlc-rounded-text-icon-logsub-button/dlc-rounded-text-icon-logsub-button.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '(click)': 'onClickTile()',
        '[style.height.px]': 'tileSize()',
        '[style.width.px]': 'tileSize()',
        class: 'dlc-tile-button'
    },
    imports: [
        CommonModule,
        MatIconModule,
        DlcRoundedTextIconLogsubButtonComponent
    ],
    selector: 'dlc-tile-button',
    styleUrl: './tile-button.component.scss',
    templateUrl: './tile-button.component.html'
})
export class TileButtonComponent {
  @ViewChild('actionButton', {static: true})
  actionButton!: DlcRoundedTextIconLogsubButtonComponent;

  ctaIcon = input('');
  ctaText = input('');
  doAction = output();
  fontIcon = input('');
  iconSize = input(50);
  // tile width and height in pixels
  tileSize = input(100);

  onClickTile() {
    if (this.actionButton) {
      this.actionButton.doClick();
    }
  }
}
