import {Injectable} from '@angular/core';
import {generate8CharCodeLowercase} from '@gigasoftware/shared/fn';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {EMPTY, forkJoin} from 'rxjs';
import {switchMap, withLatestFrom} from 'rxjs/operators';

import {selectNgPatUserAccount} from '../+account/account.selectors';
import {
  updateFirestorePartialInvestigation,
  updateInvestigationEffect,
  upsertInvestigations
} from './investigation.actions';
import {Investigation} from './investigation.model';
import {PartialInvestigationState} from './investigation.reducer';
import {InvestigationService} from './investigation.service';

@Injectable({
  providedIn: 'root'
})
export class InvestigationEffects {
  /**
   * Handled in entity list component
   * @param _actions$
   * @param store
   * @param _investigationService
   */
  // deleteDoc$ = createEffect(() =>
  //   this._actions$.pipe(
  //     ofType(deleteInvestigationFromfirestore),
  //     withLatestFrom(this.store.select(selectUserAccount)),
  //     switchMap(([action, account]) =>
  //       this.store.pipe(
  //         select(getClassroomByID(action.id)),
  //         switchMap((classRoom: Classroom | undefined) =>
  //           this._investigationService
  //             .deleteDoc$(classRoom, <string>account.uid)
  //             .pipe(map(() => deleteInvestigation({id: action.id})))
  //         )
  //       )
  //     )
  //   )
  // );

  /**
   * Handled in entity list component
   * @param _actions$
   * @param store
   * @param _investigationService
   */
  // deleteDocs$ = createEffect(
  //   () =>
  //     this._actions$.pipe(
  //       ofType(deleteInvestigations),
  //       withLatestFrom(this.store.select(selectUserAccount)),
  //       switchMap(([action, account]) =>
  //         this.store.pipe(
  //           select(getClassroomByID(action.ids[0])),
  //           switchMap((classRoom: Classroom | undefined) =>
  //             this._investigationService.deleteDocs$(classRoom, action.ids, <string>account.uid)
  //           )
  //         )
  //       )
  //     ),
  //   {dispatch: false}
  // );

  updateFirestorePartialQuiz$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateFirestorePartialInvestigation),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this._investigationService.updatePartialFirestore$(
            action.changes,
            action.investigation,
            account.uid
          )
        )
      ),
    {dispatch: false}
  );

  updateInvestigation$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateInvestigationEffect),
        switchMap(action => {
          return this._investigationService.updateDoc(action.investigation);
        })
      ),
    {dispatch: false}
  );

  updateInvestigationEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateInvestigationEffect),
        switchMap(action => {
          return this._investigationService.updateDoc(action.investigation);
        })
      ),
    {dispatch: false}
  );

  upsertInvestigations$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(upsertInvestigations),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) => {
          interface InvestigationUpdate {
            changes: Partial<Investigation>;
            investigation: Investigation;
          }

          const _investigationsUpdate: InvestigationUpdate[] =
            action.investigations.reduce(
              (a: InvestigationUpdate[], s: Investigation) => {
                const changes: Partial<Investigation> = {};

                let update = false;

                if (!s.mentorCode) {
                  changes.mentorCode = generate8CharCodeLowercase();
                  update = true;
                }

                if (!s.teacherCode) {
                  changes.teacherCode = generate8CharCodeLowercase();
                  update = true;
                }

                if (!s.studentCode) {
                  changes.studentCode = generate8CharCodeLowercase();
                  update = true;
                }

                if (update) {
                  a.push({
                    changes,
                    investigation: {...s}
                  });
                }

                return a;
              },
              <InvestigationUpdate[]>[]
            );

          if (_investigationsUpdate.length) {
            return forkJoin(
              _investigationsUpdate.map((s: InvestigationUpdate) =>
                this._investigationService.updatePartialFirestore$(
                  s.changes,
                  s.investigation,
                  account.uid
                )
              )
            );
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  constructor(
    private _actions$: Actions,
    private store: Store<PartialInvestigationState>,
    private _investigationService: InvestigationService
  ) {}
}
