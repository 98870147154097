import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  signal,
  Signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {combineLatest, of, ReplaySubject, Subject} from 'rxjs';
import {mergeMap, takeUntil, tap} from 'rxjs/operators';

import {ChartsTooltipComponent} from '../charts-tooltip/charts-tooltip.component';
import {NgPatResizeObserverDirective} from '../core/_index';
import {
  NgPatCommonChartConfig,
  NgPatCommonTooltip,
  NgPatElSizeConfigDimensionsData,
  NgPatJSONDOMRect
} from '../core/chart.models';
import {
  calculateDimensions,
  createBaseLayoutMap,
  processResizeMap,
  resizeBaseLayout
} from '../core/fns/chart.fns';
import {
  NgPatBulletChartConfig,
  NgPatBulletChartDataBase
} from './bullet-chart.models';
import {NgPatBulletChartService} from './ng-pat-bullet-chart.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.g-ng-pat-chart-tooltip-hover-closed]': 'tooltipHoverClosed() === true',
        '[class.ng-pat-chart-tooltip-reversed]': 'tooltipReversed() === true',
        '[class.tooltip-hover]': 'showTooltipOnHover() === true',
        class: 't-ng-pat-bullet-chart ng-pat-bullet-chart'
    },
    imports: [CommonModule, ChartsTooltipComponent, NgPatResizeObserverDirective],
    providers: [NgPatBulletChartService],
    selector: 'ng-pat-bullet-chart',
    styleUrls: ['./ng-pat-bullet-chart.component.scss'],
    templateUrl: './ng-pat-bullet-chart.component.html'
})
export class NgPatBulletChartComponent implements AfterViewInit, OnDestroy {
  private _cd: ChangeDetectorRef = inject(ChangeDetectorRef);
  public chart: NgPatBulletChartService = inject(NgPatBulletChartService);

  private _isInitialized = false;
  private _onDestroy$: Subject<boolean> = new Subject();

  _config$: ReplaySubject<NgPatBulletChartConfig> =
    new ReplaySubject<NgPatBulletChartConfig>(1);
  _data$: ReplaySubject<NgPatBulletChartDataBase[]> = new ReplaySubject<
    NgPatBulletChartDataBase[]
  >(1);

  @ViewChild('chartContainer', {static: true})
  public chartContainer: ElementRef | null = null;

  maxChartLabel: WritableSignal<string> = signal('');
  minChartLabel: WritableSignal<string> = signal('');

  onResize$: ReplaySubject<DOMRectReadOnly> =
    new ReplaySubject<DOMRectReadOnly>(1);

  showTooltipOnHover: Signal<boolean> = <Signal<boolean>>(
    toSignal(this.chart.tooltip.hover$)
  );

  @Output() tooltipChange: EventEmitter<
    NgPatCommonTooltip<NgPatBulletChartDataBase>
  > = new EventEmitter<NgPatCommonTooltip<NgPatBulletChartDataBase>>();

  tooltipHoverClosed: Signal<boolean> = <Signal<boolean>>(
    toSignal(this.chart.tooltip.tooltipHoverClosed$)
  );

  tooltipReversed: Signal<boolean> = <Signal<boolean>>(
    toSignal(this.chart.tooltip.reversed$)
  );

  tooltipStyle$ = this.chart.tooltip.tooltipStyle$.pipe(
    tap(() => {
      this._cd.detectChanges();
    })
  );

  tooltipStyle = <Signal<string>>toSignal(this.tooltipStyle$);

  init() {
    const that = this;
    of(this.chartContainer?.nativeElement)
      .pipe(
        createBaseLayoutMap(),
        this.chart.appendLayout(),
        mergeMap((el: HTMLElement) => {
          return combineLatest([
            this._config$,
            that.onResize$.pipe(processResizeMap)
          ]).pipe(
            mergeMap(
              ([config, size]: [
                NgPatCommonChartConfig,
                NgPatJSONDOMRect | unknown
              ]) => {
                // const dim: NgPatSizeConfigDimensions = calculateDimensions(config, <NgPatJSONDOMRect>size);
                // const scd: NgPatElSizeConfigDimensions = resizeBaseLayout(el, calculateDimensions(config, <NgPatJSONDOMRect>size));
                return that._data$.pipe(
                  that.chart.resizeDataLayout(
                    resizeBaseLayout(
                      el,
                      calculateDimensions(config, <NgPatJSONDOMRect>size)
                    )
                  )
                );
              }
            )
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(
        (d: NgPatElSizeConfigDimensionsData<NgPatBulletChartDataBase>) => {
          that.chart.applyData(
            <NgPatElSizeConfigDimensionsData<NgPatBulletChartDataBase>>d
          );

          this._cd.detectChanges();
        }
      );
  }

  ngAfterViewInit(): void {
    if (this.chartContainer?.nativeElement && !this._isInitialized) {
      this._isInitialized = true;
      this.init();
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  @Input()
  set config(c: NgPatBulletChartConfig | undefined) {
    if (c !== null && c !== undefined) {
      c.tooltipConfig = this.chart.tooltip.setConfig(c.tooltipConfig);
      this._config$.next(c);
    }
  }

  @Input()
  set data(d: NgPatBulletChartDataBase | undefined) {
    if (d !== null && d !== undefined) {
      this._data$.next([d]);
    }
  }
}
