import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

export function currentValueFrom<T>(
  obs$: Observable<T>
): Promise<T | undefined> {
  return new Promise((resolve, reject) => {
    obs$.pipe(take(1)).subscribe({
      next: value => resolve(value),
      error: error => reject(error),

      // Observable has no value
      complete: () => resolve(undefined)
    });
  });
}
