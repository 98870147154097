import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Investigation} from '@gigasoftware/shared/store';

import {EcInvestigationListItemComponent} from './ec-investigation-list-item/ec-investigation-list-item.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-investigation-list'
    },
    imports: [CommonModule, EcInvestigationListItemComponent],
    selector: 'ec-investigation-list',
    styleUrl: './ec-investigation-list.component.scss',
    templateUrl: './ec-investigation-list.component.html'
})
export class EcInvestigationListComponent {
  @Output() doDeleteItem: EventEmitter<Investigation> =
    new EventEmitter<Investigation>();

  @Output() doEditItem: EventEmitter<Investigation> =
    new EventEmitter<Investigation>();

  @Output() doLeaveItem: EventEmitter<Investigation> =
    new EventEmitter<Investigation>();

  @Output() doSelectItem: EventEmitter<Investigation> =
    new EventEmitter<Investigation>();

  @Input() list: Investigation[] = [];
}
