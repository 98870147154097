import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import {
  inject,
  Injectable,
  NgZone,
  signal,
  Signal,
  WritableSignal
} from '@angular/core';
import {MatDrawerMode} from '@angular/material/sidenav';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';

import {ngPatLogout} from '../+account/account.actions';
import {NgPatUserImage} from '../+account/account.model';
import {
  selectNgPatIsLoggedIn,
  selectNgPatUserImage
} from '../+account/account.selectors';
import {selectLeftSidenavOpen} from '../+ui/ui.selectors';
import {LeftNavItem} from './left-nav-items';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  private store: Store = inject(Store);
  private router: Router = inject(Router);
  private zone: NgZone = inject(NgZone);

  constructor() {
    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((e: RouterEvent) => {
        this.activeURL = e.url;
      });
  }

  private _navItems$: ReplaySubject<LeftNavItem[]> = new ReplaySubject<
    LeftNavItem[]
  >(1);

  onDestroy$: Subject<boolean> = new Subject();
  sidenavMode$: Observable<MatDrawerMode> = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(map((result: BreakpointState) => (result.matches ? 'over' : 'side')));

  isLoggedIn: Signal<boolean> = this.store.selectSignal(selectNgPatIsLoggedIn);

  userImage: Signal<NgPatUserImage> =
    this.store.selectSignal(selectNgPatUserImage);

  userImage$: Observable<NgPatUserImage> =
    this.store.select(selectNgPatUserImage);

  activeURL = '/';

  leftSidenavIsOpen$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  navItems$: BehaviorSubject<LeftNavItem[]> = new BehaviorSubject<
    LeftNavItem[]
  >([]);
  navItems: WritableSignal<LeftNavItem[]> = signal([]);

  afterViewInit() {
    const takeUntilHaveData$: Subject<boolean> = new Subject<boolean>();

    this.store
      .select(selectLeftSidenavOpen)
      .pipe(takeUntil(takeUntilHaveData$))
      .subscribe((leftSidenavIsOpen: boolean | null) => {
        if (leftSidenavIsOpen !== null && leftSidenavIsOpen !== undefined) {
          takeUntilHaveData$.next(true);
          setTimeout(() => {
            this.leftSidenavIsOpen$.next(leftSidenavIsOpen);
          }, 1000);
        }
      });
  }

  navigateUrl(routeURL: string) {
    this.zone.run(() => {
      this.router.navigate([routeURL]);
    });
  }

  navigate(segments: string[]) {
    this.zone.run(() => {
      this.router.navigate([...segments]);
    });
  }

  logout() {
    this.store.dispatch(ngPatLogout());
  }
}
