import {Injectable} from '@angular/core';
import {BaseEntity} from '@gigasoftware/shared/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {EMPTY} from 'rxjs';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';

import {selectNgPatUserAccount} from '../+account/account.selectors';
import {
  createCollaborativeEntity,
  createEntity,
  createOwnerUserRole
} from '../fns';
import {UserIdWithRole} from '../models/user.model.deprecated';
import {configurePlatform} from '../platform.actions';
import {BaseEntityService} from './base-entity.service';
import {createEntityParams, createSubEntityParams} from './entity.actions';
import {checkForDuplicateEntityTypes} from './entity.fns';
import {
  CollaborativeEntity,
  CreateBaseSubEntityParams,
  CreateEntityParams
} from './entity.model';

@Injectable()
export class EntityEffects {
  detectDuplicateEntityTypes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configurePlatform),
        map(action => action.entityTypes),
        checkForDuplicateEntityTypes
      ),
    {dispatch: false}
  );

  createEntityParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createEntityParams),
        withLatestFrom(this.store.pipe(select(selectNgPatUserAccount))),
        switchMap(([action, userAccount]) => {
          if (userAccount.uid) {
            const params: CreateEntityParams = {
              ...action.params
            };
            // params.isCollaborative =
            //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id ||
            //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id
            //     ? false
            //     : params.isCollaborative;

            const user: UserIdWithRole = createOwnerUserRole(userAccount.uid);
            const entity: BaseEntity | CollaborativeEntity =
              params.isCollaborative
                ? createCollaborativeEntity(user, params)
                : createEntity(action.params);

            return this.entityService.createEntity$(
              entity,
              params,
              userAccount.uid
            );
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  createSubEntityParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createSubEntityParams),
        withLatestFrom(this.store.pipe(select(selectNgPatUserAccount))),
        switchMap(([action, userAccount]) => {
          if (userAccount.uid) {
            const params: CreateBaseSubEntityParams = {
              ...action.params
            };

            const baseParams: CreateBaseSubEntityParams = {
              ...action.params,
              entityTypeValue: action.params.parentEntity.entityType
            };

            // params.isCollaborative =
            //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id ||
            //   params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id
            //     ? false
            //     : params.isCollaborative;

            const user: UserIdWithRole = createOwnerUserRole(userAccount.uid);

            const entity: BaseEntity | CollaborativeEntity =
              params.isCollaborative
                ? createCollaborativeEntity(user, params)
                : createEntity(action.params);

            return this.entityService.createSubEntity$(
              entity,
              baseParams,
              params,
              userAccount.uid
            );
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private entityService: BaseEntityService
  ) {}
}
