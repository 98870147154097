import {Bookmark} from '@gigasoftware/shared/models';
import {Update} from '@ngrx/entity';
import {createActionGroup, emptyProps, props} from '@ngrx/store';

export const BookmarkActions = createActionGroup({
  events: {
    'Add Bookmark': props<{bookmark: Bookmark}>(),
    'Add Bookmarks': props<{bookmarks: Bookmark[]}>(),
    'Clear Bookmarks': emptyProps(),
    'Delete Bookmark': props<{id: string}>(),
    'Delete Bookmarks': props<{ids: string[]}>(),
    'Load Bookmarks': props<{bookmarks: Bookmark[]}>(),
    'Update Bookmark': props<{bookmark: Update<Bookmark>}>(),
    'Update Bookmarks': props<{bookmarks: Update<Bookmark>[]}>(),
    'Upsert Bookmark': props<{bookmark: Bookmark}>(),
    'Upsert Bookmarks': props<{bookmarks: Bookmark[]}>()
  },
  source: 'Bookmark/API'
});
