import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';

/**
 * @description
 * A component that displays grade while the quiz is in progress.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[attr.aria-disabled]': '_disabled() || null',
        '[attr.disabled]': '_disabled() || null', // null will remove attribute
        '[class.ec-current-grade-progress-disabled]': '_disabled()',
        class: 'ec-current-grade-progress ec-quiz'
    },
    imports: [CommonModule],
    selector: 'ec-current-grade-progress',
    styleUrls: ['./ec-current-grade-progress.component.scss'],
    templateUrl: './ec-current-grade-progress.component.html'
})
export class EcCurrentGradeProgressComponent {
  _disabled: WritableSignal<boolean> = signal(false);

  @Input()
  set disabled(value: boolean) {
    this._disabled.set(value);
  }
}
