
import {BuildVersionUi} from '@gigasoftware/shared/models';

/**
* Generated by script tools/bump.version.js
*
* See docs/VERSION.md
*
* To update version:
* 1. Make sure repo is clean ( everything committed )
* 2. Edit the ./assets/app-version.json
* 3. Run yarn bump.version
*
*/

/**
* UI
*/
export const appInstalledBuildVersion: BuildVersionUi = {
  angularVersion: '19.0.4',
  app: 'ec-app',
  buildNumber: 3,
  changeLog: '',
  month: '12',
  releaseType: 'release',
  year: 2024,
};

/**
* Build
*/
export const quizKitBuild = `2024.12.3`;

/**
* Angular Version
*/
export const quizKitAngularVersion = `19.0.4`;

/**
* Build Type
*/
export const releaseType = "release"
  