import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  cloneInitialQuizTestProgress,
  QuizTestProgress
} from '@gigasoftware/shared/store';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-quiz-test-progress ec-quiz'
    },
    imports: [CommonModule],
    selector: 'ec-quiz-test-progress',
    styleUrls: ['./ec-quiz-test-progress.component.scss'],
    templateUrl: './ec-quiz-test-progress.component.html'
})
export class EcQuizTestProgressComponent {
  progressSig: WritableSignal<QuizTestProgress> = signal(
    cloneInitialQuizTestProgress()
  );

  @Input()
  set progress(value: QuizTestProgress) {
    this.progressSig.set(value);
  }
}
