import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {JoinCodeType, JoinCodeTypes} from '@gigasoftware/shared/store';
import {DlcRoundedTextIconButtonComponent} from '@gigasoftware/shared/ui-design-library';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'ec-copy-join-code-dialog'
    },
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        DlcRoundedTextIconButtonComponent
    ],
    selector: 'ec-copy-join-code-dialog',
    styleUrls: ['./ec-copy-join-code-dialog.component.scss'],
    templateUrl: './ec-copy-join-code-dialog.component.html'
})
export class EcCopyJoinCodeDialogComponent {
  joinCodeTypes = JoinCodeTypes;
  cta = 'Copy';

  constructor(
    // private _clipboard: Clipboard,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      joinCode: string;
      joinCodeType: JoinCodeType;
    }
  ) {
    if (data.joinCodeType === JoinCodeTypes.MENTOR) {
      this.cta += ' Mentor';
    }
    if (data.joinCodeType === JoinCodeTypes.TEACHER) {
      this.cta += ' Teacher';
    }
    if (data.joinCodeType === JoinCodeTypes.STUDENT) {
      this.cta += ' Student';
    }
    this.cta += ' Code';
  }

  // copyCode() {
  //   this._clipboard.copy(this.data.joinCode);
  // }
}
