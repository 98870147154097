import {
  NgPatFirestoreDocQuery,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {merge} from '@gigasoftware/shared/fn';
import {
  GS_INITIAL_THUMBNAIL_PATHS,
  GsAssetService,
  GsImageThumbails
} from '@gigasoftware/shared/media';
import {BaseEntity} from '@gigasoftware/shared/models';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject
} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

import {NgPatAccountState} from '../../+account/account.model';
import {
  selectCurrentQuestionID,
  selectCurrentQuizReviewID
} from '../../+ui/ui.selectors';
import {NgPatServiceConnector} from '../../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../../+websocket-registry/websocket-registry.models';
import {assignDeprecatedBaseEntityProperties} from '../../entity';
import {
  firestoreQueryPathByEntity,
  firestoreQuizDoc,
  firestoreQuizQuestionsByEntity,
  firestoreUserQuizDoc
} from '../../firebase/database-paths';
import {createQuestion, createTruFalseQuestion} from '../quiz.fns';
import {
  Question,
  QUESTION_TYPE,
  QuestionAction,
  QuestionPropertyAction,
  QuestionWithComputedProperties,
  Quiz,
  QuizGrade,
  TakeQuizResult
} from '../quiz.model';
import {GradesStore} from './query-caches/grades.store';
import {QuestionsStore} from './query-caches/questions.store';

export class QuizQueryEngine implements NgPatFirebaseConnectionService {
  // private _questionCache: NgPatEntityStore<Question> =
  questionsStore: QuestionsStore = new QuestionsStore(
    this.store,
    this.customFirestoreService,
    this.assetService
  );
  gradesStore: GradesStore = new GradesStore(
    this.store,
    this.customFirestoreService
  );
  quizFirestoreDocPath$: ReplaySubject<string> = new ReplaySubject<string>(1);

  // quizFirestoreDocPath: WritableSignal<string | null | undefined> =
  //   signal(null);
  /**
   * Used to determine which type of question to add
   * when the user clicks the add question button.
   */
  // addQuestion: WritableSignal<QuestionAction | null> = signal(null);
  onAddQuestionAction$: ReplaySubject<QuestionAction | null> =
    new ReplaySubject<QuestionAction | null>(1);
  selectQuiz$: ReplaySubject<Quiz> = new ReplaySubject<Quiz>(1);
  selectQuizInstance$: Observable<Quiz> = this.selectQuiz$.pipe(take(1));
  selectParentEntityID$: Observable<string | null | undefined> =
    this.selectQuiz$.pipe(map((quiz: Quiz) => quiz.parentEntityID));
  selectImageThumbnailPaths$: BehaviorSubject<GsImageThumbails> =
    new BehaviorSubject<GsImageThumbails>({
      ...GS_INITIAL_THUMBNAIL_PATHS
    });
  /**
   * Question data
   */
  selectAllQuestions$: Observable<QuestionWithComputedProperties[]> =
    this.questionsStore.questions$;

  // sQuiz: WritableSignal<Quiz | null> = signal(null);
  selectCurrentQuestionID$: Observable<string | null> = this.store.select(
    selectCurrentQuestionID
  );
  selectCurrentQuestion$: Observable<
    QuestionWithComputedProperties | null | undefined
  > = this.selectCurrentQuestionID$.pipe(
    switchMap((currentQuestionID: string | null) => {
      return this.questionsStore.selectQuestionById$(currentQuestionID);
    })
  );

  // parentEntityID: Signal<string | null | undefined> = computed(() => {
  //   return this.sQuiz()?.parentEntityID;
  // });
  selectCurrentEditQuestionAction$: Observable<QuestionAction | null> =
    combineLatest([this.selectQuiz$, this.selectCurrentQuestion$]).pipe(
      map(
        ([quiz, question]: [
          Quiz,
          QuestionWithComputedProperties | undefined | null
        ]) => {
          if (quiz && question) {
            return {
              question,
              quiz
            };
          }

          return null;
        }
      )
    );

  // imageThumbnailPaths: WritableSignal<GsImageThumbails | null> = signal(null);
  selectNumberOfQuestions$: Observable<number> =
    this.questionsStore.numberOfQuestions$;
  /**
   * Grades data
   */
  selectGrades$: Observable<QuizGrade<TakeQuizResult>[]> =
    this.gradesStore.allGrades$;
  selectNumberOfGrades$: Observable<number> = this.gradesStore.numberOfGrades$;
  selectHighestGrade$: Observable<number> = this.gradesStore.highestGrade$;
  selectLowestTimeToTakeQuizAtHighestGradeMS$: Observable<number> =
    this.gradesStore.gradeLowestTimeToTakeQuizAtHighestGradeMS$;
  selectLowestTimeToTakeQuizSeconds$: Observable<number> =
    this.gradesStore.lowestTimeToTakeQuizSeconds$;
  /**
   * Quiz review data
   */
  selectCurrentQuizReviewID$: Observable<string | null> = this.store.select(
    selectCurrentQuizReviewID
  );
  /**
   * Returns TakeQuizResult for currentQuizReviewID
   */
  selectCurrentQuizResult$: Observable<TakeQuizResult | null | undefined> =
    this.selectCurrentQuizReviewID$.pipe(
      switchMap((currentQuizReviewID: string | undefined | null) => {
        if (currentQuizReviewID) {
          return this.gradesStore.getTakeQuizResultById$(currentQuizReviewID);
        }

        return of(null);
      })
    );
  /**
   * Connect to firestore
   */
  connectionKey = this._quiz.id;
  connection!: NgPatServiceConnector;
  //   new NgPatEntityStore();
  private _quizFirestore!: NgPatFirestoreDocQuery<Quiz>;

  constructor(
    private _quiz: Quiz,
    private store: Store,
    private customFirestoreService: NgPatFirestoreService,
    private assetService: GsAssetService
  ) {
    // console.log('QuizQueryEngine' + '', _quiz);
    this.selectQuiz$.next(this._quiz);

    this.assetService
      .getImagesThumbnailPathsAsDownloadUrls(this._quiz.imagePath)
      .then(thumbnailPaths => {
        this.selectImageThumbnailPaths$.next(thumbnailPaths);
      });

    this.questionsStore.setQuiz(this._quiz);
    this.gradesStore.setQuiz(this._quiz);
    this.connection = new NgPatServiceConnector(this, this.store);
  }

  get createdDateMS(): number {
    return (this._quiz?.createdAt?.seconds || 0) * 1000;
  }

  get updatedDateMS(): number {
    return (this._quiz?.updatedAt?.seconds || 0) * 1000;
  }

  /**
   * Quiz data
   */
  get id() {
    return this._quiz.id;
  }

  get parentEntityID() {
    return this._quiz.parentEntityID;
  }

  ngPatOnInit() {
    const that = this;

    this._quizFirestore = new NgPatFirestoreDocQuery<Quiz>(
      {
        updateUpdater: (quiz: Quiz) => {
          this.selectQuiz$.next(
            assignDeprecatedBaseEntityProperties(quiz as BaseEntity) as Quiz
          );
          // this.sQuiz.set(quiz);
          // this.cd.detectChanges();
        }
      },
      that.store,
      that.customFirestoreService
    );
  }

  /**
   * Return image paths of questions and quiz
   */
  getImagePaths$(): Observable<string[]> {
    return combineLatest([
      this.selectQuiz$,
      this.questionsStore.getImagePaths$()
    ]).pipe(
      map(([quiz, imagePaths]: [Quiz, string[]]) => {
        const imagePath = quiz.imagePath;

        /**
         * return question image paths and include
         * quiz image path if it exists
         */
        if (imagePath) {
          return [...imagePaths, imagePath];
        }

        return imagePaths;
      })
    );
  }

  onConnect(user: NgPatAccountState) {
    if (user.uid) {
      /**
       * Connect to questions
       */
      this.questionsStore?.onConnect(user);
      this.gradesStore?.onConnect(user);

      /**
       * Connect to quiz
       */
      let quizPath = '';
      if (this._quiz.parentEntityID && this._quiz.parentEntityID.length) {
        quizPath = `${firestoreQueryPathByEntity(
          this._quiz as BaseEntity,
          user.uid
        )}`;
      } else if (this._quiz.isPublished) {
        quizPath = firestoreQuizDoc(this._quiz.id);
      } else {
        quizPath = firestoreUserQuizDoc(user.uid, this._quiz.id);
      }

      this.quizFirestoreDocPath$.next(quizPath);
      this._quizFirestore.onConnect(quizPath);
    }
  }

  onDisconnect(user: NgPatAccountState) {
    // Remove connection Key
    this.connection.deleteKey();
    // Disconnect from questions
    // this._questionsFirestore.onDisconnect(user.uid);
    this.questionsStore?.onDisconnect(user);
    this.gradesStore?.onDisconnect(user);
    // Disconnect from quiz
    this._quizFirestore.onDisconnect(user.uid);
  }

  destroy() {
    // this._questions.de
    /**
     * This will ultimately call onDisconnect
     */
    this.connection.destroy();
  }

  async updateQuiz(updatedQuiz: Quiz): Promise<Quiz> {
    return new Promise(resolve => {
      combineLatest([this.selectQuiz$, this.selectImageThumbnailPaths$])
        .pipe(take(1))
        .subscribe({
          next: ([currentQuiz, imageThumbnailPaths]: [
            Quiz,
            GsImageThumbails | null
          ]) => {
            const quiz = merge(currentQuiz, updatedQuiz);
            this._quiz = quiz;
            this.selectQuiz$.next(quiz);

            if (quiz.imagePath && !imageThumbnailPaths) {
              const thumbnailPaths =
                this.assetService.getImagesThumbnailStoragePaths(
                  quiz.imagePath
                );
              this.selectImageThumbnailPaths$.next(thumbnailPaths);
            }

            resolve(quiz);
          }
        });
    });
  }

  getQuestionFirestoreDocPath$(question: Question): Observable<string | null> {
    return this.questionsStore.getQuestionFirestoreDocPath$(question);
  }

  createTrueFalseQuestion(): void {
    // Creates and stores a QuestionAction
    // to which the UI will respond by opening
    // the question create dialog or page ( form mobile ).
    this.onAddQuestionAction$.next({
      question: createTruFalseQuestion(this._quiz, QUESTION_TYPE.TRUE_FALSE),
      quiz: this._quiz
    });
  }

  createMultipleChoiceQuestion(): void {
    this.onAddQuestionAction$.next({
      question: createQuestion(this._quiz, QUESTION_TYPE.MULTIPLE_CHOICE),
      quiz: this._quiz
    });
  }

  removeQuestionAction() {
    this.onAddQuestionAction$.next(null);
  }

  onSaveQuestion(a: QuestionAction): void {
    combineLatest([this.selectQuiz$, this.customFirestoreService.user$])
      .pipe(take(1))
      .subscribe(([quiz, user]: [Quiz, User]) => {
        if (user && user.uid && quiz) {
          const _path = `${firestoreQuizQuestionsByEntity(
            quiz as BaseEntity,
            user.uid
          )}/${a.question.id}`;
          this.customFirestoreService
            .setDoc(_path, a.question)
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .then(() => {})
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {});
        }
      });
  }

  onMergeQuestion(q: QuestionPropertyAction): void {
    this.questionsStore.onMergeQuestion(q);
  }

  onDeleteQuestion(a: QuestionAction): Promise<void> {
    return this.questionsStore.onDeleteQuestion(a);
  }
}
