import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-publish-quiz-terms'
    },
    imports: [CommonModule],
    selector: 'ec-publish-quiz-terms',
    styleUrl: './ec-publish-quiz-terms.component.scss',
    templateUrl: './ec-publish-quiz-terms.component.html'
})
export class EcPublishQuizTermsComponent {}
