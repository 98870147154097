import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {selectUiStateIsLoaded} from '@gigasoftware/shared/store';
import {Store} from '@ngrx/store';
import {filter, map, switchMap} from 'rxjs/operators';

export const ecAuthGuardGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);
  const firestore = inject(NgPatFirestoreService);
  // Make sure the UI state is initialized
  return store.select(selectUiStateIsLoaded).pipe(
    // Wait for the UI state to be initialized
    filter(isLoaded => isLoaded),
    // Make sure the user is logged in
    switchMap(() => firestore.isLoggedInState$),
    map((isLoggedIn: boolean) => {
      // console.log('isLoggedIn', isLoggedIn);
      if (!isLoggedIn) {
        // If the user is not logged in, redirect to the login page
        router.navigate(['home']).then(() => {
          // noop
        });
        // return this._router.parseUrl('/start');
      }

      return isLoggedIn;
    })
  );
};
