import {inject, Injectable} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Params,
  Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

export interface NgPatNavItem {
  label: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class NgPatRouterFacadeService {
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);

  navigationEnd$: Observable<NavigationEnd> = (<Observable<NavigationEnd>>(
    this._router.events
  )).pipe(filter((event: NavigationEnd) => event instanceof NavigationEnd));

  url$: Observable<string> = this.navigationEnd$.pipe(
    map((event: NavigationEnd) => event.url),
    distinctUntilChanged()
  );

  paramMap$: Observable<ParamMap> = this._activatedRoute.paramMap;

  params$: Observable<Params> = this._activatedRoute.params;

  containsUrl$(partialUrl: string): Observable<boolean> {
    return this.url$.pipe(map((url: string) => url.includes(partialUrl)));
  }

  getRouteEnd$(navItems: NgPatNavItem[]): Observable<NgPatNavItem | null> {
    return this.navigationEnd$.pipe(
      map((end: NavigationEnd) => {
        return navItems.reduce(
          (found: NgPatNavItem | null, i: NgPatNavItem) => {
            if (i.url === end.url) {
              return i;
            }

            return found;
          },
          null
        );
      })
    );
  }
}
