import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {
  DlcRoundedTextButtonComponent,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';

import {EcPublishQuizComponent} from './ec-publish-quiz.component';
import {EcPublishQuizTermsComponent} from './ec-publish-quiz-terms/ec-publish-quiz-terms.component';

@NgModule({
  declarations: [EcPublishQuizComponent],
  exports: [EcPublishQuizComponent],
  imports: [
    CommonModule,
    EcPublishQuizTermsComponent,
    MatDialogModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    DlcRoundedTextIconButtonComponent,
    MatProgressSpinnerModule,
    DlcRoundedTextButtonComponent,
    MatCheckboxModule,
    FormsModule
  ]
})
export class EcPublishQuizModule {}
