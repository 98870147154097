import {Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {EntityProcessQueue} from '@gigasoftware/shared/utils';
import {Store} from '@ngrx/store';
import {of, switchMap} from 'rxjs';
import {map, take} from 'rxjs/operators';

import {NgPatEntityStore} from '../custom-store/entity-store/ng-pat-entity-store';
import {NoteQueryEngine} from './note-query.engine';
import {NoteQueryStoreEntity} from './note-query.store.model';

@Injectable({
  providedIn: 'root'
})
export class NoteQueryStore {
  private addProcessQueue: EntityProcessQueue<NoteQueryStoreEntity> =
    new EntityProcessQueue<NoteQueryStoreEntity>();
  private removeProcessQueue: EntityProcessQueue<NoteQueryStoreEntity> =
    new EntityProcessQueue<NoteQueryStoreEntity>();
  private noteQueryStore: NgPatEntityStore<NoteQueryEngine> =
    new NgPatEntityStore<NoteQueryEngine>();

  constructor(private store: Store, private firestore: NgPatFirestoreService) {
    this.addProcessQueue.currentItem$
      .pipe(
        switchMap((entity: NoteQueryStoreEntity) => {
          return this.noteQueryStore.has$(entity.id).pipe(
            take(1),
            map((exists: boolean) => {
              return {
                entity: entity,
                exists
              };
            })
          );
        })
      )
      .subscribe(
        async ({
          entity,
          exists
        }: {
          entity: NoteQueryStoreEntity;
          exists: boolean;
        }) => {
          if (!exists) {
            this.noteQueryStore.setOne(
              new NoteQueryEngine(entity, this.store, this.firestore)
            );
          }
          await this.addProcessQueue.next(entity.id);
        }
      );

    this.removeProcessQueue.currentItem$
      .pipe(
        switchMap((entity: NoteQueryStoreEntity) =>
          this.noteQueryStore.has$(entity.id).pipe(
            take(1),
            switchMap((exists: boolean) => {
              if (exists) {
                return this.noteQueryStore.selectItemById$(entity.id).pipe(
                  take(1),
                  map((noteQueryEngine: NoteQueryEngine | undefined) => {
                    if (noteQueryEngine) {
                      noteQueryEngine.connection.destroy();
                    }

                    return {
                      entity,
                      exists
                    };
                  })
                );
              }

              return of({
                entity,
                exists
              });
            })
          )
        )
      )
      .subscribe(
        async ({
          entity,
          exists
        }: {
          entity: NoteQueryStoreEntity;
          exists: boolean;
        }) => {
          if (!exists) {
            this.noteQueryStore.removeOne(entity.id);
          }

          await this.removeProcessQueue.next(entity.id);
        }
      );
  }

  addNoteQueryEngines(noteQueryStoreEntities: NoteQueryStoreEntity[]) {
    this.addProcessQueue.upsertMany(noteQueryStoreEntities);
    this.addProcessQueue.startProcessing();
  }

  removeNoteQueryEngines(noteQueryStoreEntities: NoteQueryStoreEntity[]) {
    this.removeProcessQueue.upsertMany(noteQueryStoreEntities);
    this.removeProcessQueue.startProcessing();
  }
}
