import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ImageCropperComponent} from './component/image-cropper.component';

@NgModule({
  declarations: [ImageCropperComponent],
  exports: [ImageCropperComponent],
  imports: [CommonModule]
})
export class ImageCropperModule {}
