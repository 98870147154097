<div class="flex flex-row">
  <div
    *ngIf="note().imagePath"
    class="w-12 h-12 block overflow-hidden bg-white/[.12] rounded mr-4 self-start">
    <img
      class="w-full h-full"
      dlcBlobImage
      [dlcImagePath]="note().imagePath" />
  </div>
  <div
    *ngIf="!note().imagePath"
    class="w-12 h-12 block overflow-hidden bg-white/[.12] rounded mr-4 self-start flex flex-row justify-center items-center">
    <mat-icon>image</mat-icon>
  </div>

  <div class="flex-auto">
    <p class="text-sm font-semibold leading-6 text-white">
      {{ note().title }}
    </p>
  </div>
</div>

<div class="w-full">
  <div class="flex flex-row justify-between items-center mt-4 w-full">
    <div class="flex-auto"></div>

    <button
      class="mr-4"
      dlc-rounded-text-icon-button
      color="primary"
      (click)="onDoEditNote()">
      <mat-icon aria-hidden="false">visibility</mat-icon>
      View
    </button>

    <button
      dlc-rounded-icon-button
      [matMenuTriggerFor]="items">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<mat-menu #items>
  <!--  <button-->
  <!--    mat-menu-item-->
  <!--    (click)="doCopyNote.emit(note())">-->
  <!--    <mat-icon>content_copy</mat-icon>-->
  <!--    Copy-->
  <!--  </button>-->

  <button
    mat-menu-item
    [disabled]="isDeletingNote()"
    (click)="onDoDeleteNote()">
    <mat-icon>delete</mat-icon>
    Delete
  </button>
</mat-menu>

@if (isDeletingNote()) {
<div
  class="absolute inset-0 flex flex-col justify-start items-stretch bg-black/[.5]">
  <mat-progress-bar
    class="relative top-0"
    color="accent"
    mode="indeterminate"></mat-progress-bar>

  <div
    class="flex-auto flex flex-row justify-center items-center font-bold text-[--dlc-color-warn] text-lg">
    <div>Deleting...</div>
  </div>
</div>
}
