import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  Inject,
  Input,
  Output,
  Signal,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {
  GSFirebaseUploadImageWithDocConfig,
  GsFirestoreUpdateDoc
} from '@gigasoftware/shared/media';
import {
  ecQuizImageUploaderConfig,
  firestoreInvestigationDoc,
  Investigation,
  updateFirestorePartialInvestigation
} from '@gigasoftware/shared/store';
import {
  DlcInputImageComponent,
  DlcInputImageConfig,
  DlcRoundedTextIconButtonComponent
} from '@gigasoftware/shared/ui-design-library';
import {WINDOW} from '@gigasoftware/shared/utils';
import {Store} from '@ngrx/store';

interface Investigationform {
  description: string;
  name: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-investigation-edit'
    },
    imports: [
        CommonModule,
        DlcRoundedTextIconButtonComponent,
        DlcInputImageComponent,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule
    ],
    selector: 'ec-investigation-edit',
    styleUrls: ['./ec-investigation-edit.component.scss'],
    templateUrl: './ec-investigation-edit.component.html'
})
export class EcInvestigationEditComponent {
  private _resetValue: Investigationform | null = null;

  investigationSig: WritableSignal<Investigation | null> = signal(null);

  inputImageConfig: Signal<DlcInputImageConfig | null | undefined> = computed(
    () => {
      const investigation: Investigation | null | undefined =
        this.investigationSig();

      if (investigation) {
        const firestoreDocPath: string = firestoreInvestigationDoc(
          investigation.id
        );

        return {
          filenameWithoutExtension: investigation.id || '',
          imagePath: investigation.imagePath || '',
          uploadConfig: <GSFirebaseUploadImageWithDocConfig>{
            ...ecQuizImageUploaderConfig,
            firestoreDoc: <GsFirestoreUpdateDoc>{
              docProperty: 'imagePath',
              firestoreDocPath
            }
          }
        };
      }

      return null;
    }
  );

  investigationFormGroup: UntypedFormGroup;

  @Output() savedAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _fb: FormBuilder,
    private store: Store,
    private _cd: ChangeDetectorRef,
    @Inject(WINDOW) private _win: Window
  ) {
    this.investigationFormGroup = this._fb.group({
      description: new UntypedFormControl(null),
      name: new UntypedFormControl(null, [Validators.required])
    });
  }

  getErrorMessage(control: AbstractControl) {
    if (control.hasError('required')) {
      return 'You must enter a value';
    }

    return '';
  }

  onDeleteImage() {
    const investigation: Investigation | null | undefined =
      this.investigationSig();

    if (investigation) {
      this.store.dispatch(
        updateFirestorePartialInvestigation({
          changes: {
            imagePath: null
          },
          investigation: investigation
        })
      );
    }
  }

  resetNameForm() {
    this.investigationFormGroup.reset(this._resetValue, {emitEvent: false});
    this._cd.markForCheck();
  }

  saveForm() {
    if (this.investigationFormGroup.valid && this.investigationSig()) {
      this.store.dispatch(
        updateFirestorePartialInvestigation({
          changes: {
            ...this.investigationFormGroup.value
          },
          investigation: <Investigation>this.investigationSig()
        })
      );

      this.savedAction.emit(true);
    }
  }

  @Input()
  set investigation(s: Investigation | null | undefined) {
    if (s) {
      this.investigationSig.set(s);

      this._resetValue = {
        description: s.description || '',
        name: s.name
      };

      this.resetNameForm();
    }
  }
}
