import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-no-subscription-text p-accent'
  },
  selector: 'ui-no-subscription-text',
  standalone: true,
  styleUrls: ['./ui-no-subscription-text.component.scss'],
  templateUrl: './ui-no-subscription-text.component.html'
})
export class UiNoSubscriptionTextComponent {
  @Input() size: 'small' | null = null;
}
