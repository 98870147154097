<ul
  role="list"
  class="pl-0">
  <li
    ec-journal-list-item
    class="mb-5"
    *ngFor="let journal of list"
    [journal]="journal"
    (doEditItem)="doEditItem.emit($event)"
    (doLeaveItem)="doLeaveItem.emit($event)"
    (doDeleteItem)="doDeleteItem.emit($event)"
    (doSelectItem)="doSelectItem.emit($event)"></li>
</ul>
