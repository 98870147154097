import {Injectable} from '@angular/core';
import {
  FirestoreWriteEmailConfig,
  NgPatFirestoreTriggerEmailDoc
} from '@gigasoftware/shared/models';
import {Observable} from 'rxjs';
import {v4 as uuidv4} from 'uuid';

import {NgPatFirestoreService} from './ng-pat-firestore.service';

export function ngPatCreateTriggerObject(): NgPatFirestoreTriggerEmailDoc {
  return {
    message: {
      subject: '',
      text: ''
    },
    to: []
  };
}

export function createFirestoreWriteEmailConfig(
  doc: NgPatFirestoreTriggerEmailDoc,
  id: string | null | undefined = null
): FirestoreWriteEmailConfig {
  return {
    doc,
    id: id && id.length > 0 ? id : uuidv4()
  };
}

/**
 *
 * @param docs: NgPatFirestoreTriggerEmailDoc
 * @param id: id of firestore document in mail ( trigger email ) collection
 */
export function createFirestoreWriteEmailConfigs(
  docs: NgPatFirestoreTriggerEmailDoc[]
): FirestoreWriteEmailConfig[] {
  return docs.map(
    (doc: NgPatFirestoreTriggerEmailDoc): FirestoreWriteEmailConfig =>
      createFirestoreWriteEmailConfig(doc, doc.id)
  );
}

@Injectable({
  providedIn: 'root'
})
export class NgPatTriggerEmailFromFirestoreService {
  constructor(private fs: NgPatFirestoreService) {}

  sendMessage$(
    config: FirestoreWriteEmailConfig,
    collection = 'mail'
  ): Observable<NgPatFirestoreTriggerEmailDoc> {
    return this.fs.set$<NgPatFirestoreTriggerEmailDoc>(
      `${collection}/${config.id}`,
      config.doc
    );
  }

  createConfigAndSendMessage$(
    config: NgPatFirestoreTriggerEmailDoc,
    collection = 'mail'
  ): Observable<NgPatFirestoreTriggerEmailDoc> {
    return this.sendMessage$(
      createFirestoreWriteEmailConfig(config, config.id),
      collection
    );
  }

  sendMessages$(
    docs: FirestoreWriteEmailConfig[],
    collection = 'mail'
  ): Observable<NgPatFirestoreTriggerEmailDoc> {
    return this.fs.writeDocs$<NgPatFirestoreTriggerEmailDoc>(collection, docs);
  }

  createConfigAndSendMessages$(
    docs: NgPatFirestoreTriggerEmailDoc[],
    collection = 'mail'
  ): Observable<NgPatFirestoreTriggerEmailDoc> {
    const _configs: FirestoreWriteEmailConfig[] =
      createFirestoreWriteEmailConfigs(docs);

    return this.sendMessages$(_configs, collection);
  }
}
