import {
  SearchClient,
  searchClient,
  SearchResponse
} from '@algolia/client-search';
import {Inject, Injectable} from '@angular/core';
import {ENVIRONMENT, IEnvironmentState} from '@gigasoftware/shared/models';
import {from, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {NgPatAccountAlgolia} from '../../+account/account.model';

@Injectable()
export class SearchAlgoliaService {
  private _cancelCurrentQuery$: Subject<boolean> = new Subject<boolean>();
  private _client: SearchClient;

  constructor(@Inject(ENVIRONMENT) private environment: IEnvironmentState) {
    this._client = searchClient(
      this.environment.algolia.appId,
      this.environment.algolia.apiKey
    );
    // this.searchIndex = _client.initIndex('users');
  }

  searchUser(search: string): Observable<any> {
    this._cancelCurrentQuery$.next(true);

    return from(
      this._client.searchSingleIndex({
        indexName: 'users',
        searchParams: {
          hitsPerPage: 10,
          query: search
        }
      })
    ).pipe(
      takeUntil(this._cancelCurrentQuery$),
      map((results: SearchResponse<unknown>) => {
        return <SearchResponse<NgPatAccountAlgolia>>{
          ...results
        };
      }),
      map((results: SearchResponse<NgPatAccountAlgolia>) => results.hits)
    );

    // return this.store.pipe(select(selectDoConnect)).pipe(
    //   filter((doConnect: boolean) => doConnect),
    //   take(1),
    //   switchMap((): any => {
    //     return combineLatest([this.searchEmail(search), this.searchStagename(search)]).pipe(
    //       take(1),
    //       map(([emails, usernames]: [AccountState[], AccountState[]]) => {
    //         return [...emails, ...usernames];
    //       })
    //     );
    //   })
    // );
  }
}
