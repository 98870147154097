import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  Signal,
  WritableSignal
} from '@angular/core';
import {Route, RouterLink} from '@angular/router';
import {
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment,
  selectHasActiveSubscription
} from '@gigasoftware/shared/store';
import {
  NgPatNormalizedPrice,
  selectAllProductsWithPrices
} from '@gigasoftware/shared/store';
import {
  APP,
  AppLoginService,
  DlcFooterBarComponent,
  DlcHeaderBarComponent,
  DlcMobilePageContentComponent,
  DlcPricingComponent
} from '@gigasoftware/shared/ui-design-library';
import {WINDOW} from '@gigasoftware/shared/utils';
import {Store} from '@ngrx/store';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'ec-in-app-subscribe-page dlc-global-mobile-page'
    },
    imports: [
        CommonModule,
        DlcMobilePageContentComponent,
        DlcHeaderBarComponent,
        DlcPricingComponent,
        DlcFooterBarComponent,
        RouterLink
    ],
    providers: [],
    selector: 'ec-in-app-subscribe-page',
    styleUrls: ['./ec-in-app-subscribe-page.component.scss'],
    templateUrl: './ec-in-app-subscribe-page.component.html'
})
export class EcInAppSubscribePageComponent {
  private appAuthService: AppLoginService = inject(AppLoginService);
  private store: Store = inject(Store);
  app: APP = APP.EC;
  env: EvolutionCognitionEnvironment = <EvolutionCognitionEnvironment>(
    inject(EC_ENVIRONMENT)
  );

  hasActiveSubscription: Signal<boolean> = <Signal<boolean>>(
    this.store.selectSignal(selectHasActiveSubscription)
  );

  prices: Signal<NgPatNormalizedPrice[] | undefined | null> = <
    Signal<NgPatNormalizedPrice[] | undefined | null>
  >this.store.selectSignal(selectAllProductsWithPrices);

  showCheckoutProgress = false;

  // get isNativePlatform(): boolean {
  //   return Capacitor.isNativePlatform();
  // }

  showCheckoutProgressBar: WritableSignal<boolean> = signal(false);

  // private webPriceService: DlcWebPricingService = inject(DlcWebPricingService);
  win: Window = <Window>inject(WINDOW);

  // ngOnInit() {
  //   if (!this.isNativePlatform) {
  //     this.webPriceService.loadWebCheckoutScript(this.app);
  //   }
  // }

  onLogin() {
    this.appAuthService.login();
  }
}

export const EC_IN_APP_SUBSCRIBE_ROUTES: Route[] = [
  {
    component: EcInAppSubscribePageComponent,
    path: ''
  }
];
