import {pipe} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

export const typedFilter = <Input, Output>(
  predicateFn: (value: Input) => boolean
) =>
  pipe(
    // @ts-expect-error - This is a workaround for a TypeScript bug
    distinctUntilChanged(),
    filter(predicateFn),
    map((input: Input) => (<unknown>input) as Output)
  );
