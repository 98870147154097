import {currentValueFrom, jsonPopulated} from '@gigasoftware/shared/rxjs';
import {Observable} from 'rxjs';
import {take, switchMap, filter} from 'rxjs/operators';
import {
  EntityComponentStore,
  EntityComponentStoreOptions,
  EntityComponentStoreSelectAllAndSelectedId
} from './entity-component-store';
import {NgPatProcessQueueState} from './process-queue.model';

export class EntityProcessQueue<T> extends EntityComponentStore<T> {
  constructor(override options?: EntityComponentStoreOptions<T>) {
    super(options);
  }

  currentItem$: Observable<T> = this.select(state => {
    const selectedId = state.selectedId;
    return selectedId && state.entities[selectedId]
      ? <T>state.entities[selectedId]
      : null;
  }).pipe(jsonPopulated());

  // processingState$: BehaviorSubject<NgPatProcessQueueState> = new BehaviorSubject<NgPatProcessQueueState>(
  //   NgPatProcessQueueState.IDLE
  // );

  /**
   * Selects the next entity in the collection or first if the current entity is the last.
   */
  async next(previousId?: string | number): Promise<void> {
    this.setProcessingState(NgPatProcessQueueState.PROCESSING);

    if (previousId) {
      this.removeOne(previousId);
    }

    const current: EntityComponentStoreSelectAllAndSelectedId<T> | undefined =
      await currentValueFrom<EntityComponentStoreSelectAllAndSelectedId<T>>(
        this.selectAllAndSelectedId$
      );
    if (current) {
      const {all, selectedId} = current;
      if (all && all.length > 0) {
        // Get first entity in the collection
        // get Id of the first entity
        const firstId = (<any>all[0])[this.idKey];
        // select the first entity
        this.selectId(firstId);
      } else {
        this.selectId(null);
        this.setProcessingState(NgPatProcessQueueState.IDLE);
      }

      if (selectedId) {
        this.removeOne(selectedId);
      }
    } else {
      this.setProcessingState(NgPatProcessQueueState.IDLE);
    }
  }

  startProcessing(): void {
    this.selectAll$
      .pipe(
        filter((items: T[]) => items.length > 0),
        switchMap(() => {
          return this.isProcessing$;
        }),
        take(1)
      )
      .subscribe((isProcessing: boolean) => {
        if (!isProcessing) {
          this.next();
        }
      });
  }
}
