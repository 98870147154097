<ng-container *ngrxLet="subscriptions$; let sub">
  <ng-container *ngIf="sub">
    <h2 class="!leading-none">My Subscriptions</h2>
    <div class="subscriptions">
      <div
        class="flex flex-row justify-between items-center"
        *ngFor="let s of sub.items">
        <div class="flex flex-col justify-start items-start">
          <div class="text-[--dlc-color-primary]">
            {{ s.itemPriceProductName }}
          </div>
          <div class="mat-caption">
            {{ parsePrice(s.itemPriceUnit_amount) | currency }} /
            {{ s.itemPlanInterval }}
          </div>
          <div
            *ngIf="sub.hasCoupon"
            class="mat-caption p-accent">
            Promo Applied: {{ sub?.coupon?.name }}
          </div>
          <div
            class="p-warn"
            *ngIf="sub.subscription.cancel_at_period_end">
            Your subscription will end on
            {{
              sub.subscription.cancel_at?.seconds
                | ngPatSecondsToMilliseconds
                | date
            }}
          </div>
        </div>
        <div>
          <ng-container *ngIf="showUpdateBtn">
            <button
              class="p-mt-20 p-mr-20"
              (click)="updateSubscription()"
              dlc-rounded-text-button
              color="primary">
              Update Plan
            </button>
          </ng-container>

          <button
            dlc-rounded-text-button
            color="accent"
            (click)="onCancelSubscription(s)"
            aria-describedby="tier-startup"
            class="buy-button ml-5">
            Cancel plan
          </button>
        </div>
      </div>

      <!--      TOTO REDO SINCE NOT USING MARKETING APP TO SUBSCRIBE-->
      <!--      <button-->
      <!--        *ngIf="-->
      <!--          showCancelBtn &&-->
      <!--          !sub.subscription.cancel_at_period_end &&-->
      <!--          (hasActiveSubscription$ | ngrxPush)-->
      <!--        "-->
      <!--        class="p-mt-20"-->
      <!--        (click)="onCancelSubscription()"-->
      <!--        mat-flat-button-->
      <!--        color="primary">-->
      <!--        <span *ngIf="!env.deviceBuild.isMobileApp"> Cancel Subscription </span>-->
      <!--        <span *ngIf="env.deviceBuild.isMobileApp"> Manage Subscription At QuizKit.us </span>-->
      <!--      </button>-->
    </div>
  </ng-container>
</ng-container>
