import {Observable, pipe, UnaryFunction} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

export const stringPopulated: () => UnaryFunction<
  Observable<unknown>,
  Observable<string>
> = (): UnaryFunction<Observable<unknown>, Observable<string>> =>
  pipe(
    // @ts-expect-error - This is a workaround for a TypeScript bug
    distinctUntilChanged(),
    filter(
      (value: string | null | undefined) => <boolean>(value && value.length > 0)
    ),
    map(value => value as string)
  );
