<h2
  mat-dialog-title
  class="text-truncate">
  Copy {{ sourceBookmark.title || sourceBookmark.url }}
</h2>
<mat-dialog-content>
  <table
    [dataSource]="allBaseEntities()"
    mat-table>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <!--        <th mat-header-cell *matHeaderCellDef>Name</th>-->
      <td
        *matCellDef="let baseEntity"
        mat-cell>
        {{ baseEntity.name }}
      </td>
    </ng-container>

    <!-- Type copyAction -->
    <ng-container matColumnDef="copyAction">
      <!--        <th mat-header-cell *matHeaderCellDef></th>-->
      <td
        *matCellDef="let baseEntity"
        mat-cell>
        <div class="my-4">
          <button
            mat-flat-button
            (click)="
              $event.stopImmediatePropagation(); copyToEntity(baseEntity)
            ">
            Copy
          </button>
        </div>
      </td>
    </ng-container>

    <!--      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>-->
    <tr
      *matRowDef="let row; columns: displayedColumns"
      mat-row></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    (click)="dialogRef.close(null)"
    mat-flat-button>
    Cancel
  </button>
</mat-dialog-actions>
