import {NgPatAccountState} from '../+account/account.model';

/**
 * Evolving Cognition Roles and Permissions
 * Format is: [role]_[permission]
 */
export enum EC_ROLES_PERMISSIONS {
  ADMIN = 'ec_admin',
  ROOT = 'ec_root'
}

export interface EcRolePermissionName {
  name: string;
  value: EC_ROLES_PERMISSIONS;
}

export const ecRoleNames: {[key: string]: string} = {
  [EC_ROLES_PERMISSIONS.ADMIN]: 'Evolving Cognition Admin',
  [EC_ROLES_PERMISSIONS.ROOT]: 'Evolving Cognition Root'
};

export function getEcRoleTitle(type: EC_ROLES_PERMISSIONS): string {
  return ecRoleNames[type];
}

export const ecRolePermissionsDict: {[key: string]: EcRolePermissionName} = {
  [EC_ROLES_PERMISSIONS.ADMIN]: {
    name: getEcRoleTitle(EC_ROLES_PERMISSIONS.ADMIN),
    value: EC_ROLES_PERMISSIONS.ADMIN
  },
  [EC_ROLES_PERMISSIONS.ROOT]: {
    name: getEcRoleTitle(EC_ROLES_PERMISSIONS.ROOT),
    value: EC_ROLES_PERMISSIONS.ROOT
  }
};

export const ecRoleAndPermissions: EcRolePermissionName[] = Object.values(
  ecRolePermissionsDict
);

export function isEcRoleAdmin(
  user: NgPatAccountState | null | undefined
): boolean {
  if (user && user.rolesAndPermissions) {
    return user.rolesAndPermissions[EC_ROLES_PERMISSIONS.ADMIN] === true;
  }
  return false;
}

export function isEcRoleRoot(
  user: NgPatAccountState | null | undefined
): boolean {
  if (user && user.rolesAndPermissions) {
    return user.rolesAndPermissions[EC_ROLES_PERMISSIONS.ROOT] === true;
  }
  return false;
}

/**
 * Get EC role from user object and return EcRolePermissionName
 * @param user
 */
export function getEcRole(
  user: NgPatAccountState
): EcRolePermissionName | null {
  const role = ecRoleAndPermissions.find((r: EcRolePermissionName) => {
    return (
      user?.rolesAndPermissions && user?.rolesAndPermissions[r.value] === true
    );
  });

  if (role) {
    return role;
  }

  return null;
}

export function setEcRoleToTrue(
  role: EC_ROLES_PERMISSIONS,
  user: NgPatAccountState
): NgPatAccountState {
  return {
    ...user,
    rolesAndPermissions: {
      // Maintain existing permissions from other roles
      ...user.rolesAndPermissions,

      // Reset EC roles to false
      ...ecRoleAndPermissions.reduce(
        (a: {[value: string]: boolean}, r: EcRolePermissionName) => {
          a[r.value] = false;
          return a;
        },
        {}
      ),

      // Set the selected role to true
      [role]: true
    }
  };
}
