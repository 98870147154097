import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Journal} from '@gigasoftware/shared/store';

import {EcJournalListItemComponent} from './ec-journal-list-item/ec-journal-list-item.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-journal-list'
    },
    imports: [CommonModule, EcJournalListItemComponent],
    selector: 'ec-journal-list',
    styleUrl: './ec-journal-list.component.scss',
    templateUrl: './ec-journal-list.component.html'
})
export class EcJournalListComponent {
  @Output() doDeleteItem: EventEmitter<Journal> = new EventEmitter<Journal>();

  @Output() doEditItem: EventEmitter<Journal> = new EventEmitter<Journal>();

  @Output() doLeaveItem: EventEmitter<Journal> = new EventEmitter<Journal>();

  @Output() doSelectItem: EventEmitter<Journal> = new EventEmitter<Journal>();

  @Input() list: Journal[] = [];
}
