<button
  dlc-rounded-text-icon-button
  (click)="$event.stopPropagation(); onTranscribe()"
  [disabled]="isDisabled()"
  color="primary">
  @if (transcribeInProgress()) {
  <mat-progress-spinner
    class="mr-2"
    diameter="16"
    mode="indeterminate"></mat-progress-spinner>
  Transcribing... } @else if (transcribePending()) {
  <mat-icon>summarize</mat-icon>
  Transcription Pending } @else {
  <mat-icon>summarize</mat-icon>
  Transcribe Image }
</button>
