import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  Input,
  InputSignal,
  output,
  OutputEmitterRef,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatDividerModule} from '@angular/material/divider';
import {
  NgPatNormalizedPrice,
  NgPatNormalizedPriceIntervalValueEnum
} from '@gigasoftware/shared/store';
// import { Store } from '@ngrx/store';
import {
  DlcTextItem,
  DlcTextToggleComponent
} from '../../toggle/text-toggle/dlc-text-toggle.component';
import {DlcStripeSubscriptionsComponent} from '../subscriptions-stripe/subscriptions/dlc-stripe-subscriptions.component';
import {FeatureListWithPriceComponent} from './feature-list/dlc-feature-list-with-price.component';
import {PriceToggleComponent} from './price-toggle/price-toggle.component';
import {PricingHeaderComponent} from './pricing-header/pricing-header.component';

/**
 * Ported from https://tailwindui.com/components/marketing/sections/pricing
 */

export const toggleItems: DlcTextItem[] = [
  {title: 'Monthly', value: 'monthly'},
  {title: 'Annually', value: 'annually'}
];
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-pricing'
    },
    imports: [
        CommonModule,
        PricingHeaderComponent,
        DlcTextToggleComponent,
        PriceToggleComponent,
        DlcStripeSubscriptionsComponent,
        MatDividerModule,
        PriceToggleComponent,
        FeatureListWithPriceComponent
    ],
    selector: 'dlc-pricing',
    styleUrl: './dlc-pricing.component.scss',
    templateUrl: './dlc-pricing.component.html'
})
export class DlcPricingComponent {
  doLogin: OutputEmitterRef<boolean> = output();
  // hasWebSubscription: Signal<boolean> = this.store.selectSignal(selectHasActiveStripeSubscription);
  // hasWebSubscription: InputSignal<boolean> = input(false);
  hasActiveSubscription: InputSignal<boolean> = input(false);
  headerSubtitle = input(``);
  headerTitle = input(``);

  priceIntervals: DlcTextItem[] = toggleItems;

  pricesSig: WritableSignal<NgPatNormalizedPrice[]> = signal([]);

  pricesColumnsClassSig: WritableSignal<string> = <WritableSignal<string>>(
    computed(() => {
      const prices = this.pricesSig();
      return `force-lg-grid-cols-${prices.length} ${
        prices.length > 1 ? 'lg:mx-0 lg:max-w-none' : ''
      }`;
    })
  );

  selectedPriceInterval: WritableSignal<DlcTextItem> = signal(
    this.priceIntervals[0]
  );
  selectedPriceIntervalEnum: WritableSignal<NgPatNormalizedPriceIntervalValueEnum> =
    <WritableSignal<NgPatNormalizedPriceIntervalValueEnum>>(
      signal(NgPatNormalizedPriceIntervalValueEnum.Monthly)
    );
  showBothPricesAtSameTime = input(false);
  showCheckoutProgressBar: OutputEmitterRef<boolean> = output();
  showFeatureDescription = input(false);
  // header
  showHeader = input(false);

  showIntervalToggle = input(false);

  onIntervalChange(interval: DlcTextItem) {
    if (interval.value === 'monthly') {
      this.selectedPriceIntervalEnum.set(
        NgPatNormalizedPriceIntervalValueEnum.Monthly
      );
    } else {
      this.selectedPriceIntervalEnum.set(
        NgPatNormalizedPriceIntervalValueEnum.Annually
      );
    }
  }

  @Input()
  set prices(value: NgPatNormalizedPrice[] | undefined | null) {
    console.log('prices', value);
    this.pricesSig.set(value || []);
  }
}
