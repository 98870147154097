<span class="ec-question-counter__progress">{{ progress }}</span>
<svg
  width="11"
  height="11"
  viewBox="0 0 11 11"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <line
    x1="0.646447"
    y1="9.83883"
    x2="9.83883"
    y2="0.646441" />
</svg>
<span class="ec-question-counter__total">{{ total }}</span>
