import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatButton} from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {BaseEntity, Bookmark} from '@gigasoftware/shared/models';
import {
  CollaborativeEntity,
  NgPatAllBaseEntitiesService
} from '@gigasoftware/shared/store';
import {DlcBookmarkService} from '@gigasoftware/shared/ui-design-library';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, MatDialogModule, MatTableModule, MatButton],
  selector: 'dlc-copy-bookmark-dialog',
  styleUrl: './dlc-copy-bookmark-dialog.component.scss',
  templateUrl: './dlc-copy-bookmark-dialog.component.html'
})
export class DlcCopyBookmarkDialogComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();
  private firestoreService: NgPatFirestoreService = inject(
    NgPatFirestoreService
  );
  entityStoreService = inject(NgPatAllBaseEntitiesService);
  bookmarkService = inject(DlcBookmarkService);

  dialogRef: MatDialogRef<DlcCopyBookmarkDialogComponent> =
    inject(MatDialogRef);
  sourceBookmark: Bookmark = inject(MAT_DIALOG_DATA);

  displayedColumns = ['name', 'copyAction'];

  // Filter sourceBookmark from allBaseEntities
  allBaseEntities: Signal<BaseEntity[]> = <Signal<BaseEntity[]>>(
    toSignal(
      this.entityStoreService.allBaseEntities$.pipe(
        map((entities: BaseEntity[]) =>
          entities.filter(
            (entity: BaseEntity) =>
              entity.id !== this.sourceBookmark.parentEntityID
          )
        )
      )
    )
  );

  async copyToEntity(entity: CollaborativeEntity) {
    this.bookmarkService.copyBookmark(this.sourceBookmark, entity);

    this.dialogRef.close();
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }
}
