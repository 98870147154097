import {Injectable, NgZone} from '@angular/core';
import {
  NgPatFirestoreCollectionQuery,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {BaseEntity} from '@gigasoftware/shared/models';
import {ComponentStore} from '@ngrx/component-store';
import {createEntityAdapter, EntityState, Update} from '@ngrx/entity';
import {Store} from '@ngrx/store';
import {where} from 'firebase/firestore';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {NgPatAccountState} from '../../+account/account.model';
import {NgPatServiceConnector} from '../../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../../+websocket-registry/websocket-registry.models';
import {firestoreQuizGradesByEntity} from '../../firebase/database-paths';
import {FirestoreWebSocketConnectorService} from '../../firebase/firestore-web-socket-connector.service';
import {aggregateUpdates} from '../../fns/aggregate-updates';
import {createGradeChartData} from '../quiz.fns';
import {Quiz, QuizGrade, TakeQuizResult} from '../quiz.model';

export class QuizGradesService
  extends ComponentStore<EntityState<TakeQuizResult>>
  implements NgPatFirebaseConnectionService
{
  private _adapter = createEntityAdapter<TakeQuizResult>();
  private _onDestroy$: Subject<boolean> = new Subject();
  private _path$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _queryCollaborationService: NgPatFirestoreCollectionQuery<TakeQuizResult>;
  private _user$: ReplaySubject<NgPatAccountState> =
    new ReplaySubject<NgPatAccountState>(1);
  connection: NgPatServiceConnector;
  connectionKey = null;
  readonly deleteMany = this.updater((state, ids: string[]) =>
    this._adapter.removeMany(ids, state)
  );
  readonly grades$: Observable<QuizGrade<TakeQuizResult>[]> = this.select(
    (state: EntityState<TakeQuizResult>) => {
      const that = this;

      const {selectAll} = this._adapter.getSelectors();

      return selectAll(state)
        .filter((r: TakeQuizResult) => {
          return r.quiz.id === that.quiz.id;
        })
        .map(createGradeChartData)
        .sort(
          (a: QuizGrade<TakeQuizResult>, b: QuizGrade<TakeQuizResult>) =>
            b.timestamp - a.timestamp
        );
    }
  );
  quiz!: Quiz;
  readonly updateMany = this.updater(
    (state, questions: Update<TakeQuizResult>[]) => {
      return this._adapter.updateMany(questions, state);
    }
  );
  readonly upsertMany = this.updater((state, questions: TakeQuizResult[]) => {
    return this._adapter.upsertMany(questions, state);
  });

  constructor(
    private store: Store,
    private customFirestoreService: NgPatFirestoreService
  ) {
    super(createEntityAdapter<TakeQuizResult>().getInitialState());

    this.connection = new NgPatServiceConnector(this, this.store);

    const that = this;
    this._queryCollaborationService =
      new NgPatFirestoreCollectionQuery<TakeQuizResult>(
        {
          deleteManyUpdater: (ids: string[]) => that.deleteMany(ids),
          queryMember: false,
          updateManyUpdater: (questions: TakeQuizResult[]) =>
            that.updateMany(aggregateUpdates(questions)),
          upsertManyUpdater: (questions: TakeQuizResult[]) =>
            that.upsertMany(questions)
          // logUpsert: true
        },
        store,
        customFirestoreService
      );
  }

  private getKey(q: Quiz) {
    return `${q.id}-${q.parentEntityID}-grade`;
  }

  init(q: Quiz) {
    this.quiz = q;

    this.connection.setConnectionKey(this.getKey(q));
  }

  onConnect(user: NgPatAccountState) {
    if (this.quiz && user.uid) {
      const _path = firestoreQuizGradesByEntity(
        this.quiz as BaseEntity,
        user.uid
      );
      this._path$.next(_path);
      this._queryCollaborationService.onConnect(_path, null, user.uid, [
        where('createdByUID', '==', user.uid)
      ]);
      this._user$.next(user);
    }
  }

  onDestroy() {
    this._onDestroy$.next(true);
    this.connection.destroy();
    this._user$.pipe(take(1)).subscribe((user: NgPatAccountState) => {
      this.onDisconnect(user);
    });
  }

  onDisconnect(user: NgPatAccountState) {
    this.connection.deleteKey();
    this._queryCollaborationService.onDisconnect(user.uid);
  }
}

@Injectable()
export class QuizGradesCreatorService {
  private _gradeService: QuizGradesService | undefined | null = null;
  private _onDestroy$: Subject<boolean> = new Subject();
  grades$: ReplaySubject<QuizGrade<TakeQuizResult>[]> = new ReplaySubject<
    QuizGrade<TakeQuizResult>[]
  >(1);

  constructor(
    private store: Store,
    private _zone: NgZone,
    private customFirestoreService: NgPatFirestoreService,
    private connector: FirestoreWebSocketConnectorService
  ) {}

  init(quiz: Quiz) {
    this.onDestroy();
    this.service.init(quiz);

    this.service.grades$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((_grades: QuizGrade<TakeQuizResult>[]) => {
        this.grades$.next([..._grades]);
      });
  }

  onDestroy() {
    if (this._gradeService) {
      this._onDestroy$.next(true);
      this._gradeService.onDestroy();
      this._gradeService = null;
    }
  }

  get service(): QuizGradesService {
    if (!this._gradeService) {
      this._gradeService = new QuizGradesService(
        this.store,
        this.customFirestoreService
      );
    }

    return this._gradeService;
  }
}
