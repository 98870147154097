import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  Output,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';

import {coerceThemePalette} from '../../button/button.fns';

export interface DlcTextItem {
  title: string;
  value: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.dlc-text-toggle-accent]': 'color() === "accent"',
        '[class.dlc-text-toggle-primary]': 'color() === "primary" || color() === "none" || color() === undefined',
        '[class.dlc-text-toggle-warn]': 'color() === "warn"',
        class: 'dlc-text-toggle'
    },
    imports: [CommonModule],
    selector: 'dlc-text-toggle',
    styleUrl: './dlc-text-toggle.component.scss',
    templateUrl: './dlc-text-toggle.component.html'
})
export class DlcTextToggleComponent {
  _selected: WritableSignal<DlcTextItem | undefined | null> = signal(null);

  color = input('none', {
    transform: coerceThemePalette
  });
  @Input() items: DlcTextItem[] = [];

  @Output() selectedChange: EventEmitter<DlcTextItem> =
    new EventEmitter<DlcTextItem>();

  @Input() srLabel = 'Select Item';

  onSelect(item: DlcTextItem) {
    this._selected.set(item);
    this.selectedChange.emit(item);
  }

  @Input() set selected(value: DlcTextItem) {
    this._selected.set(value);
  }
}
