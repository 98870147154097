import {GradeLevel} from './quiz.model';

export const GRADE_LEVELS: GradeLevel[] = [
  {
    level: 0.1,
    name: 'Pre-School'
  },
  {
    level: 0.2,
    name: 'Kindergarten'
  },
  {
    level: 1,
    name: '1st Grade'
  },
  {
    level: 2,
    name: '2nd Grade'
  },
  {
    level: 3,
    name: '3rd Grade'
  },
  {
    level: 4,
    name: '4th Grade'
  },
  {
    level: 5,
    name: '5th Grade'
  },
  {
    level: 6,
    name: '6th Grade'
  },
  {
    level: 7,
    name: '7th Grade'
  },
  {
    level: 8,
    name: '8th Grade'
  },
  {
    level: 9,
    name: '9th Grade'
  },
  {
    level: 10,
    name: '10th Grade'
  },
  {
    level: 11,
    name: '11th Grade'
  },
  {
    level: 12,
    name: '12th Grade'
  },
  {
    level: 13,
    name: 'College'
  },

  {
    level: 14,
    name: 'University'
  },
  {
    level: 15,
    name: 'Graduate School'
  },
  {
    level: 16,
    name: 'Post Graduate School'
  },
  {
    level: 17,
    name: 'Doctorate'
  },
  {
    level: 18,
    name: 'Post Doctorate'
  },
  {
    level: 20,
    name: 'Certification'
  },
  {
    level: 21,
    name: 'Other'
  }
];

/**
 * returns an object with the level as the key and the value as the GradeLevel object.
 *  {
 *    '0.1': {level: 0.1, name: 'Pre-School'},
 *    '0.2': {level: 0.2, name: 'Kindergarten'},
 *    ...
 *  }
 */
export const GRADE_LEVELS_MAP: {[key: string]: GradeLevel} =
  GRADE_LEVELS.reduce((acc: {[key: string]: GradeLevel}, curr: GradeLevel) => {
    acc[curr.level.toString()] = curr;
    return acc;
  }, {});

/**
 * get the grade level object by the level
 */
export function getGradeLevel(
  level: number | null | undefined
): GradeLevel | null {
  if (level !== null && level !== undefined) {
    return GRADE_LEVELS_MAP[level.toString()];
  }
  return null;
}

/**
 * get the grade level by string
 */
export function getGradeLevelByString(level: string): GradeLevel | undefined {
  return GRADE_LEVELS_MAP[level];
}
