import {NavItemDict} from '../../../+ui/ui.model';
import {URL_PROPS} from '../../../entity/entity.model';
import {NAV} from '../nav-item-dict.model';

export const mobileNavItemDictJournal: NavItemDict = {
  [NAV.MOBILE_JOURNAL_ALL_GRADES_PAGE]: {
    icon: 'grading',
    id: NAV.MOBILE_JOURNAL_ALL_GRADES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'All Grades',
    navigateTo: ['mobile', 'journal-all-grades'],
    paramKeys: [URL_PROPS.JOURNAL_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_JOURNAL_ITEM_PAGE,
    url: '/mobile/journal-all-grades'
  },
  [NAV.MOBILE_JOURNAL_CREATE_PAGE]: {
    icon: 'add',
    id: NAV.MOBILE_JOURNAL_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Journal',
    navigateTo: ['mobile', 'journal-create'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_JOURNAL_LIST_PAGE,
    url: '/mobile/journal-create'
  },
  [NAV.MOBILE_JOURNAL_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_JOURNAL_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit',
    navigateTo: ['mobile', 'journal-edit'],
    paramKeys: [URL_PROPS.JOURNAL_ID],
    previousNavItemID: NAV.MOBILE_JOURNAL_LIST_PAGE,
    url: '/mobile/journal-edit'
  },
  [NAV.MOBILE_JOURNAL_ITEM_PAGE]: {
    icon: 'groups',
    id: NAV.MOBILE_JOURNAL_ITEM_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Journal',
    navigateTo: ['mobile', 'journal-item'],
    paramKeys: [URL_PROPS.JOURNAL_ID],
    previousNavItemID: NAV.MOBILE_JOURNAL_LIST_PAGE,
    url: '/mobile/journal-item'
  },
  [NAV.MOBILE_JOURNAL_LIST_PAGE]: {
    icon: 'groups',
    id: NAV.MOBILE_JOURNAL_LIST_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Journals',
    navigateTo: ['mobile', 'journal-list'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/journal-list'
  },
  [NAV.MOBILE_JOURNAL_QUIZ_CREATE_PAGE]: {
    icon: 'add',
    id: NAV.MOBILE_JOURNAL_QUIZ_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Add Quiz',
    navigateTo: ['mobile', 'journal-quiz-create'],
    paramKeys: [URL_PROPS.JOURNAL_ID],
    previousNavItemID: NAV.MOBILE_JOURNAL_ITEM_PAGE,
    url: '/mobile/journal-quiz-create'
  },
  [NAV.MOBILE_JOURNAL_QUIZ_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_JOURNAL_QUIZ_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit Quiz',
    navigateTo: ['mobile', 'journal-quiz-edit'],
    paramKeys: [URL_PROPS.JOURNAL_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_JOURNAL_QUIZ_STATS_PAGE,
    url: '/mobile/journal-quiz-edit'
  },
  [NAV.MOBILE_JOURNAL_QUIZ_GRADES_PAGE]: {
    icon: 'grading',
    id: NAV.MOBILE_JOURNAL_QUIZ_GRADES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Grades',
    navigateTo: ['mobile', 'journal-quiz-grades'],
    paramKeys: [URL_PROPS.JOURNAL_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_JOURNAL_QUIZ_STATS_PAGE,
    url: '/mobile/journal-quiz-grades'
  },
  [NAV.MOBILE_JOURNAL_QUIZ_QUESTION_CREATE_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_JOURNAL_QUIZ_QUESTION_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Create Question',
    navigateTo: ['mobile', 'journal-quiz-question-create'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    previousNavItemID: NAV.MOBILE_JOURNAL_QUIZ_EDIT_PAGE,
    url: '/mobile/journal-quiz-question-create'
  },
  [NAV.MOBILE_JOURNAL_QUIZ_QUESTION_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_JOURNAL_QUIZ_QUESTION_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit Question',
    navigateTo: ['mobile', 'journal-quiz-question-edit'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    previousNavItemID: NAV.MOBILE_JOURNAL_QUIZ_EDIT_PAGE,
    url: '/mobile/journal-quiz-question-edit'
  },
  [NAV.MOBILE_JOURNAL_QUIZ_REVIEW_PAGE]: {
    icon: 'read_more',
    id: NAV.MOBILE_JOURNAL_QUIZ_REVIEW_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Review',
    navigateTo: ['mobile', 'journal-quiz-review'],
    paramKeys: [
      URL_PROPS.JOURNAL_ID,
      URL_PROPS.QUIZ_ID,
      URL_PROPS.QUIZ_REVIEW_ID
    ],
    previousNavItemID: NAV.MOBILE_JOURNAL_QUIZ_GRADES_PAGE,
    url: '/mobile/journal-quiz-review'
  },
  [NAV.MOBILE_JOURNAL_QUIZ_STATS_PAGE]: {
    icon: 'quiz',
    id: NAV.MOBILE_JOURNAL_QUIZ_STATS_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Quiz',
    navigateTo: ['mobile', 'journal-quiz-stats'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.JOURNAL_ID],
    previousNavItemID: NAV.MOBILE_JOURNAL_ITEM_PAGE,
    url: '/mobile/journal-quiz-stats'
  },
  [NAV.MOBILE_JOURNAL_QUIZ_TEST_PAGE]: {
    icon: 'quiz',
    id: NAV.MOBILE_JOURNAL_QUIZ_TEST_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Quiz',
    navigateTo: ['mobile', 'journal-quiz-item-test'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.JOURNAL_ID],
    previousNavItemID: NAV.MOBILE_JOURNAL_QUIZ_STATS_PAGE,
    url: '/mobile/journal-quiz-item-test'
  }
};
