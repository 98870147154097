import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  input,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  MatProgressBarModule,
  ProgressBarMode
} from '@angular/material/progress-bar';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.dlc-header-bar--border-lower-left-radius]': 'borderLowerLeftRadius() == true',
        '[class.dlc-header-bar--border-lower-right-radius]': 'borderLowerRightRadius() == true',
        class: `dlc-header-bar`
    },
    imports: [CommonModule, MatProgressBarModule],
    selector: 'dlc-header-bar',
    styleUrl: './dlc-header-bar.component.scss',
    templateUrl: './dlc-header-bar.component.html'
})
export class DlcHeaderBarComponent {
  borderLowerLeftRadius = input(true);

  borderLowerRightRadius = input(true);

  progressBarModeValue: WritableSignal<ProgressBarMode> =
    signal('indeterminate');
  progressBarValue = input(0);

  showProgressBar = input(false);
  @Input()
  set progressBarMode(mode: ProgressBarMode) {
    this.progressBarModeValue.set(mode);
  }
}
