import {
  RectangleDimensions,
  scaleRectangle
} from '@gigasoftware/shared/calculations';
import {getFilenamesWithSize} from '../firebase/image';
import {FILE_TYPE} from '../utils/file-type';
import {GSStorageAssetBlob, ResizeConfig} from './sizes';

export interface GSTargetWidthAndHeight {
  width: number;
  height: number;
}

/**
 *
 * @param width - width of the image
 * @param height - height of the image
 * @param MAX_WIDTH - max width of the image
 * @param MAX_HEIGHT - max height of the image
 */
export function getTargetWidthAndHeight(
  width: number,
  height: number,
  MAX_WIDTH: number,
  MAX_HEIGHT: number
): GSTargetWidthAndHeight {
  // Change the resizing logic
  if (width > height) {
    if (width > MAX_WIDTH) {
      height = height * (MAX_WIDTH / width);
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width = width * (MAX_HEIGHT / height);
      height = MAX_HEIGHT;
    }
  }

  return {width, height};
}

/**
 * https://imagekit.io/blog/how-to-resize-image-in-javascript/
 */
export function resizeImage(
  blobInput: Blob,
  fileNameWithoutExtension: string,
  fileNameExtension: string,
  maxWidth: number,
  maxHeight: number,
  imageType: FILE_TYPE = FILE_TYPE.IMAGE_PNG
): Promise<GSStorageAssetBlob> {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      // Dynamically create a canvas element
      const canvas = document.createElement('canvas');

      // var canvas = document.getElementById("canvas");
      const ctx = canvas.getContext('2d');

      const dimensions: RectangleDimensions = scaleRectangle(
        img.width,
        img.height,
        maxWidth,
        maxHeight
      );

      if (ctx) {
        ctx.canvas.width = dimensions.width;
        ctx.canvas.height = dimensions.height;

        // Actual resizing
        ctx.drawImage(img, 0, 0, dimensions.width, dimensions.height);

        canvas.toBlob((blob: Blob | null) => {
          resolve({
            filenameWithoutExtension: fileNameWithoutExtension,
            filenameExtension: fileNameExtension,
            blob
          });
        }, imageType);
      }
    };

    img.src = URL.createObjectURL(blobInput);
  });
}

export function resizeImageToStandardSizes(
  blob: Blob,
  filenameWithoutExtension: string,
  filenameExtension: string,
  maxImageSizes: number[]
): Promise<GSStorageAssetBlob[]> {
  const configs: ResizeConfig[] = getFilenamesWithSize(
    filenameWithoutExtension,
    filenameExtension,
    maxImageSizes
  );

  return Promise.all([
    ...configs.map((config: ResizeConfig) => {
      return resizeImage(
        blob,
        config.filenameWithoutExtension,
        config.filenameExtension,
        config.thumbSize,
        config.thumbSize,
        FILE_TYPE.IMAGE_PNG
      );
    })
  ]);
}
