<div class="flex items-center justify-between gap-x-4">
  <p class="text-4xl font-semibold leading-8 text-white text-center">
    {{ title() }}
  </p>
</div>
@if (showFeatureDescription()) {
<p class="mt-4 text-sm leading-6 text-gray-300">
  {{ description() }}
</p>
}

<!-- FEATURES -->
<!--<h5 class="mt-8">Features Include:</h5>-->
<ul
  role="list"
  class="mt-4 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10 !pl-0">
  @for (i of features(); track i) {
  <li class="flex gap-x-3">
    <svg
      class="h-6 w-5 flex-none text-white"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true">
      <path
        fill-rule="evenodd"
        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
        clip-rule="evenodd" />
    </svg>
    {{ i }}
  </li>
  }
</ul>

<!-- OFFERS -->
<div class="flex flex-row justify-center gap-x-4 w-full items-start mt-4">
  @if (isCustomPrice()) {
  <dlc-feature-price
    class="w-full"
    [isCustomPrice]="true"
    [anyOwned]="anyOwned()"
    [offer]="offers()[0]"></dlc-feature-price>
  } @else { @for (i of offers(); track i) {
  <dlc-feature-price
    [anyOwned]="anyOwned()"
    [offer]="i"></dlc-feature-price>
  } }
</div>
