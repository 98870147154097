import {
  ChangeDetectionStrategy,
  Component,
  input,
  InputSignal
} from '@angular/core';
import {Route} from '@angular/router';
import {fadeInAnimation} from '@gigasoftware/shared/ui-design-library';

@Component({
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[@fadeInAnimation]': '',
    '[class.is-light-theme]': 'isLightTheme() === true',
    class: 'ec-terms-of-use dlc-global-mobile-page'
  },
  selector: 'ec-terms-of-use',
  standalone: true,
  styleUrls: ['./ec-terms-of-use.component.scss'],
  templateUrl: './ec-terms-of-use.component.html'
})
export class EcTermsOfUseComponent {
  isLightTheme: InputSignal<boolean> = input(false);

  lastUpdated = '12 February 2024';
}

export const EC_TERMS_OF_USE_ROUTES: Route[] = [
  {component: EcTermsOfUseComponent, path: '', pathMatch: 'full'}
];
