import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {generateRandomString, uuid} from '@gigasoftware/shared/fn';
import {
  BaseEntityType,
  CollaborationEntityForm,
  CreateEntityParams,
  createEntityParams,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT,
  getEntityTypeByName,
  PLATFORM_ENTITY_TYPES
} from '@gigasoftware/shared/store';
import {DlcRoundedTextButtonComponent} from '@gigasoftware/shared/ui-design-library';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-create-project'
    },
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        DlcRoundedTextButtonComponent
    ],
    selector: 'ec-create-project',
    styleUrls: ['./ec-create-project.component.scss'],
    templateUrl: './ec-create-project.component.html'
})
export class EcCreateProjectComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();
  @Output() createAction: EventEmitter<CreateEntityParams> =
    new EventEmitter<CreateEntityParams>();
  entityTypeForm: FormControl = new FormControl(
    DEFAULT_PLATFORM_ENTITY_TYPE_DICT.PROJECT.name,
    [Validators.required]
  );
  entityTypes: BaseEntityType[] = PLATFORM_ENTITY_TYPES;
  entityTypeNames: string[] = this.entityTypes.map(
    (p: BaseEntityType) => p.name
  );
  nameFormGroup: FormGroup;
  selectEntityType: BaseEntityType = DEFAULT_PLATFORM_ENTITY_TYPE_DICT.PROJECT;
  showEntityTypeSelect: WritableSignal<boolean> = signal(true);
  @Input() showIsPrivate = false;

  constructor(private store: Store, private _fb: FormBuilder) {
    this.nameFormGroup = this._fb.group({
      description: new FormControl(null),
      isCollaborative: new FormControl(true),
      isPrivate: new FormControl(false),
      name: new FormControl(null, [Validators.required])
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  onSubmit() {
    const params: CreateEntityParams = {
      entityTypeValue: getEntityTypeByName(this.entityTypeForm.value).id,
      id: uuid(),
      ...this.nameFormGroup.value,
      joinCode: generateRandomString(8, '', false)
    };

    this.store.dispatch(createEntityParams({params}));

    this.nameFormGroup.reset(
      <CollaborationEntityForm>{
        description: null,
        isCollaborative: this.nameFormGroup.value.isCollaborative,
        isPrivate: this.nameFormGroup.value.isPrivate,
        name: null
      },
      {emitEvent: false}
    );

    this.createAction.emit(params);
  }

  get entityTypeName() {
    return this.selectEntityType?.name;
  }
}
