import {Action, createAction, props} from '@ngrx/store';
import {
  NgPatNormalizedOffer,
  NgPatNormalizedPricePlatform
} from './normalized-price.model';

export const ngPatBuyNativeAction = createAction(
  '[Buy] Buy Native',
  props<{price: NgPatNormalizedOffer}>()
);

// export const ngPatBuyWebAction = createAction(
//   '[Buy] Buy Web',
//   props<{price: NgPatNormalizedOffer}>()
// );

// export function getNgPatBuyAction(price: NgPatNormalizedOffer): Action {
//   if (price.platform === NgPatNormalizedPricePlatform.WEB) {
//     return ngPatBuyWebAction({price});
//   } else {
//     return ngPatBuyNativeAction({price});
//   }
// }

export const ngPatCancelNativePurchaseAction = createAction(
  '[Buy] Cancel Native Purchase',
  props<{price: NgPatNormalizedOffer}>()
);

export const ngPatCancelWebPurchaseAction = createAction(
  '[Buy] Cancel Web Purchase',
  props<{price: NgPatNormalizedOffer}>()
);

export function getNgPatCancelPurchaseAction(
  price: NgPatNormalizedOffer
): Action {
  if (price.platform === NgPatNormalizedPricePlatform.WEB) {
    return ngPatCancelWebPurchaseAction({price});
  } else {
    return ngPatCancelNativePurchaseAction({price});
  }
}
