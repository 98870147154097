<ul
  role="list"
  class="pl-0">
  @for (bookmark of bookmarkService.currentBookmarkList$ | async; track
  bookmark.url) {
  <li
    class="mb-5"
    dlc-bookmark
    [bookmark]="bookmark"
    (doEditBookmark)="doEditBookmark.emit($event)"></li>
  }
</ul>
