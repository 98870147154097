import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {NgPatResizeObserverDirective} from '../core/resize-observer';
import {UiLineChartComponent} from './ui-line-chart.component';

@NgModule({
  declarations: [UiLineChartComponent],
  exports: [UiLineChartComponent],
  imports: [CommonModule, NgPatResizeObserverDirective]
})
export class UiLineChartModule {}
