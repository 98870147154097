import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  output,
  OutputEmitterRef,
  ViewEncapsulation
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {GigaNoteDoc} from '@gigasoftware/shared/models';
import {getNotesByParentId} from '@gigasoftware/shared/store';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

import {DlcNoteListItemComponent} from './note-list-item/dlc-note-list-item.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-note-list'
    },
    imports: [CommonModule, DlcNoteListItemComponent],
    selector: 'dlc-note-list',
    styleUrl: './dlc-note-list.component.scss',
    templateUrl: './dlc-note-list.component.html'
})
export class DlcNoteListComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  private store = inject(Store);
  doCopyNote: OutputEmitterRef<GigaNoteDoc> = output();

  doEditNote: OutputEmitterRef<GigaNoteDoc> = output();

  parentId$: Subject<string> = new Subject<string>();

  notes = toSignal(
    this.parentId$.pipe(
      switchMap((parentId: string) =>
        this.store
          .select(getNotesByParentId(parentId))
          .pipe(takeUntil(this._onDestroy$))
      )
    )
  );
  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  @Input() set parentId(value: string | null | undefined) {
    if (value) {
      this.parentId$.next(value);
    }
  }
}
