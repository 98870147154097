import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule],
    selector: 'ui-mentor-subscription-text',
    styleUrls: ['./mentor-subscription-text.component.scss'],
    templateUrl: './mentor-subscription-text.component.html'
})
export class MentorSubscriptionTextComponent {
  @Input() showTitle = false;
}
