import {Injectable} from '@angular/core';
import {avg} from '@gigasoftware/shared/calculations';
import {
  FirestoreCollectionQueryFactoryConfig,
  NgPatFirestoreCollectionQuery,
  ngPatFirestoreCollectionQueryFactory,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {BaseEntity} from '@gigasoftware/shared/models';
import {ComponentStore} from '@ngrx/component-store';
import {createEntityAdapter, EntityState, Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {map, switchMap, take, takeUntil} from 'rxjs/operators';

import {NgPatAccountState} from '../../+account/account.model';
import {selectNgPatLoggedInUID} from '../../+account/account.selectors';
import {
  Classroom,
  StudentGrade,
  StudentGrades,
  StudentGradesTableData
} from '../../+classrooms';
import {highestQuizGradeByStudent} from '../../+classrooms/class-grades/class-grades.fns';
import {MemberListItem} from '../../+members/members.model';
import {getStudentsByEntityID} from '../../+members/members.selectors';
import {NgPatServiceConnector} from '../../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../../+websocket-registry/websocket-registry.models';
import {firestoreQuizGradesByEntity} from '../../firebase/database-paths';
import {aggregateUpdates} from '../../fns/aggregate-updates';
import {selectHasActiveSubscription} from '../../subscription/subscription.selectors';
import {calculateGrade} from '../quiz.fns';
import {Quiz, TakeQuizResult} from '../quiz.model';
import {
  getQuizAssignedByParentID,
  getQuizzesByParentID
} from '../quiz.selectors';
import {memberQuizMap, MemberQuizMap} from './class-grades.fns';

export interface ClassGradesState extends EntityState<TakeQuizResult> {
  classroom: Classroom | null;
}

/**
 * The rest of the columns are
 * dynamically created by Quiz names
 */
export enum COLUMNS {
  AVERAGE = 'Highest Grade'
}

@Injectable()
export class ClassGradesService
  extends ComponentStore<ClassGradesState>
  implements NgPatFirebaseConnectionService
{
  private _adapter = createEntityAdapter<TakeQuizResult>();
  private _allGrades$: Observable<TakeQuizResult[]> = this.select(
    (state: ClassGradesState) => {
      const {selectAll} = this._adapter.getSelectors();

      return selectAll(state);
    }
  );
  private _classroom$: ReplaySubject<Classroom> = new ReplaySubject<Classroom>(
    1
  );
  private _isConnected$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy$: Subject<boolean> = new Subject();
  private _path$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _queryCache: {
    [path: string]: NgPatFirestoreCollectionQuery<TakeQuizResult>;
  } = {};
  private _queryFactory: FirestoreCollectionQueryFactoryConfig<TakeQuizResult>;
  private _user$: ReplaySubject<NgPatAccountState> =
    new ReplaySubject<NgPatAccountState>(1);
  private connectorRegistered = false;
  readonly addClassroom = this.updater(
    (state: EntityState<TakeQuizResult>, classroom: Classroom) => {
      return {
        ...state,
        classroom
      };
    }
  );
  connection: NgPatServiceConnector;
  connectionKey = null;
  readonly deleteMany = this.updater((state, ids: string[]) =>
    this._adapter.removeMany(ids, state)
  );
  displayedColumns$: Observable<string[]>;
  readonly selectClassQuizzes$: Observable<Classroom | null> = this.select(
    (state: ClassGradesState) => state.classroom
  );
  studentGrades$: Observable<StudentGrades[]>;
  studentGradesTableData$: Observable<StudentGradesTableData[]>;
  readonly updateMany = this.updater(
    (state, questions: Update<TakeQuizResult>[]) => {
      return this._adapter.updateMany(questions, state);
    }
  );
  readonly upsertMany = this.updater((state, questions: TakeQuizResult[]) => {
    return this._adapter.upsertMany(questions, state);
  });

  constructor(
    private store: Store,
    private customFirestoreService: NgPatFirestoreService
  ) {
    super(
      createEntityAdapter<TakeQuizResult>().getInitialState({
        classroom: null
      })
    );

    this.connection = new NgPatServiceConnector(this, this.store);

    const that = this;
    this._queryFactory = ngPatFirestoreCollectionQueryFactory<TakeQuizResult>(
      {
        deleteManyUpdater: (ids: string[]) => that.deleteMany(ids),
        queryMember: false,
        updateManyUpdater: (questions: TakeQuizResult[]) =>
          that.updateMany(aggregateUpdates(questions)),
        upsertManyUpdater: (questions: TakeQuizResult[]) =>
          that.upsertMany(questions)
        // logUpsert: true
      },
      store,
      customFirestoreService
    );

    combineLatest([this._isConnected$, this._user$, this._classroom$])
      .pipe(
        switchMap(
          ([isConnected, user, classroom]: [
            boolean,
            NgPatAccountState,
            Classroom
          ]): Observable<[boolean, NgPatAccountState, string[]]> => {
            return this.store.pipe(
              select(getQuizzesByParentID(classroom.id)),
              map((quizList: Quiz[]) => {
                const paths: string[] = quizList.map((quiz: Quiz) =>
                  firestoreQuizGradesByEntity(
                    quiz as BaseEntity,
                    <string>user.uid
                  )
                );

                return [isConnected, user, paths];
              })
            );
          }
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(
        ([isConnected, user, paths]: [
          boolean,
          NgPatAccountState,
          string[]
        ]) => {
          if (isConnected) {
            const pathSet: Set<string> = new Set(paths);

            // Add Query connections
            for (let q = 0; q < paths.length; q++) {
              const path: string = paths[q];
              if (!that._queryCache[path]) {
                that._queryCache[path] =
                  that._queryFactory.createFirestoreCollectionQuery();
                that._queryCache[path].onConnect(path, null, user.uid);
              }
            }

            // remove unused query connections
            Object.keys(that._queryCache).forEach((key: string) => {
              if (!pathSet.has(key)) {
                that._queryCache[key].onDisconnect(<string>user.uid);
                delete that._queryCache[key];
              }
            });
          }
        }
      );

    this.studentGrades$ = this._classroom$.pipe(
      switchMap((c: Classroom) =>
        combineLatest([
          this.store.pipe(select(getStudentsByEntityID(c.id))),
          this.store.pipe(select(getQuizzesByParentID(c.id))),
          this.store.pipe(select(selectHasActiveSubscription)),
          this.store.pipe(select(selectNgPatLoggedInUID)),
          this._allGrades$
        ]).pipe(
          takeUntil(this._onDestroy$),
          map(
            ([
              members,
              quizzes,
              loggedInUserIsStudent,
              loggedInUID,
              allClassGrades
            ]: [
              MemberListItem[],
              Quiz[],
              boolean,
              string | null,
              TakeQuizResult[]
            ]): StudentGrades[] => {
              // console.log('quizzes', quizzes);

              return (
                members
                  // .filter((m: MemberListItem) => {
                  //   return loggedInUserIsStudent ? m.uid === loggedInUID : true;
                  // })
                  .map((m: MemberListItem) => {
                    const quizMap: MemberQuizMap = memberQuizMap(quizzes);

                    const studentGrades: StudentGrades = {
                      grades: [],
                      student: m.member,
                      uid: m.uid
                    };

                    for (const memberQuizMapValue of quizMap.values()) {
                      const attempts = allClassGrades.filter(
                        (r: TakeQuizResult) => r.createdByUID === m.uid
                      ).length;

                      const grade: StudentGrade = {
                        attempts,
                        quizID: memberQuizMapValue.quizID,
                        quizName: memberQuizMapValue.name,
                        quizTaken: attempts > 0,
                        result: highestQuizGradeByStudent(
                          studentGrades.student,
                          allClassGrades.filter(
                            (a: TakeQuizResult) =>
                              a.quiz.id === memberQuizMapValue.quizID
                          )
                        ),
                        student: m.member,
                        uid: m.uid
                      };

                      studentGrades.grades.push(grade);
                    }

                    return studentGrades;
                  })
              );
            }
          )
        )
      )
    );

    this.studentGradesTableData$ = this.studentGrades$.pipe(
      map((grades: StudentGrades[]) =>
        grades.map((g: StudentGrades) => {
          const _g: StudentGradesTableData = {
            student: g.student.username
          };

          const grades: number[] = [];
          for (let i = 0; i < g.grades.length; i++) {
            const _grade: StudentGrade = g.grades[i];
            if (_grade && _grade.quizID) {
              _g[`${_grade.quizName}`] =
                _grade.attempts === 0
                  ? 0
                  : calculateGrade(
                      _grade?.result?.totalCorrect,
                      Object.keys(_grade?.result?.result?.questions || {})
                        ?.length
                    );

              grades.push(parseFloat(<string>_g[`${_grade.quizName}`]));
            }
          }

          _g[COLUMNS.AVERAGE] = avg(grades).toFixed(0);

          return _g;
        })
      )
    );

    this.displayedColumns$ = this._classroom$.pipe(
      switchMap((c: Classroom) =>
        this.store.pipe(
          select(getQuizAssignedByParentID(c.id)),
          map((q: Quiz[]) => [
            'student',
            ...q.map((_q: Quiz) => _q.name),
            COLUMNS.AVERAGE
          ])
        )
      )
    );
  }

  private getKey(q: Classroom) {
    return `${q.id}-class-grades`;
  }

  init(q: Classroom) {
    this.addClassroom(q);
    this._classroom$.next(q);

    this.connection.setConnectionKey(this.getKey(q));
  }

  onConnect(user: NgPatAccountState) {
    if (user.uid) {
      this._isConnected$.next(true);
      // const _path = firestoreQuizGradesByEntity(this.quiz, user.uid);
      // this._path$.next(_path);
      this._user$.next(user);
    }
  }

  onDestroy() {
    this._onDestroy$.next(true);
    this.connection.destroy();
    this._user$.pipe(take(1)).subscribe((user: NgPatAccountState) => {
      this.onDisconnect(user);
    });
  }

  onDisconnect(user: NgPatAccountState) {
    const that = this;

    this.connection.deleteKey();

    that._isConnected$.next(false);
  }
}
