import {Injectable} from '@angular/core';
import {NgPatAggregateFirebaseSnapshotChanges} from '@gigasoftware/shared/models';
import {Action, Store} from '@ngrx/store';
import {
  DocumentChange,
  DocumentData,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
  where
} from 'firebase/firestore';

import {
  addParentIDToAggregateDocChanges,
  aggregateDocChangesFns
} from '../fns/aggregate-doc-changes.fns';
import {NgPatFirestoreService} from './ng-pat-firestore.service';

export interface FirestoreCollectionQueryConfig<T> {
  deleteManyAction?: (ids: string[]) => Action;
  deleteManyUpdater?: (ids: string[]) => void;
  logUpsert?: boolean;
  /**
   * Map firestore document id to id property of document
   */
  mapFirestoreID?: boolean;
  queryConstrains?: QueryConstraint[];

  queryMember: boolean;
  updateManyAction?: (payload: T[]) => Action;
  updateManyUpdater?: (payload: T[]) => void;
  upsertManyAction?: (payload: T[]) => Action;
  upsertManyUpdater?: (payload: T[]) => void;
}

export interface FirestoreCollectionQueryConnectConfig {
  parentID: string | null;
  path: string;
  queryConstraints: QueryConstraint[];
  uid: string | null | undefined;
}

export interface QueryModel<T> {
  onConnect(path: string, uid: string): void;

  onDisconnect(): void;

  process(
    snapshot: DocumentChange<DocumentData>[],
    parentID: string | null
  ): void;
}

export class NgPatFirestoreCollectionQuery<T> implements QueryModel<T> {
  private _firebaseSub: (() => void) | undefined | null = null;

  constructor(
    private queryConfig: FirestoreCollectionQueryConfig<T>,
    private store: Store,
    private firestoreService: NgPatFirestoreService
  ) {}

  onConnect(
    path: string,
    parentID: string | null = null,
    uid: string | null = null,
    queryConstraints: QueryConstraint[] = []
  ) {
    const that = this;

    if (this.queryConfig.queryMember && !uid) {
      return;
    }

    if (this._firebaseSub) {
      this._firebaseSub();
    }

    const _pathRef = this.firestoreService.collectionRef(path);

    let _queryRef = undefined;
    let _queryConstraints: QueryConstraint[] = [];

    if (this.queryConfig.queryMember && uid) {
      _queryConstraints = [where('memberUIDs', 'array-contains', uid)];
    }

    if (queryConstraints && queryConstraints.length > 0) {
      _queryConstraints = [..._queryConstraints, ...queryConstraints];
    }

    if (
      this.queryConfig.queryConstrains &&
      this.queryConfig.queryConstrains.length > 0
    ) {
      _queryConstraints = [
        ..._queryConstraints,
        ...this.queryConfig.queryConstrains
      ];
    }

    if (_queryConstraints && _queryConstraints.length > 0) {
      _queryRef = query(_pathRef, ..._queryConstraints);
    }

    // if (this._config.queryMember && uid) {
    //   _queryRef = query(_pathRef, where('memberUIDs', 'array-contains', uid));
    // }

    this._firebaseSub = onSnapshot(
      _queryRef ? _queryRef : _pathRef,
      // .where('fileUploaded', '==', true)
      (snapshot: QuerySnapshot) => {
        that.process.apply(that, [snapshot.docChanges(), parentID]);
      },
      () => {
        /* noop */
      },
      () => {
        /* noop */
      }
    );
  }

  onDisconnect(uid: string | null = null) {
    if (this._firebaseSub) {
      this._firebaseSub();
      this._firebaseSub = null;
    }
  }

  process(snapshot: DocumentChange<DocumentData>[], parentID: string | null) {
    const that = this;
    const mapFirestoreID: boolean =
      this.queryConfig.mapFirestoreID !== undefined &&
      this.queryConfig.mapFirestoreID !== null
        ? this.queryConfig.mapFirestoreID
        : false;

    let aggregate: NgPatAggregateFirebaseSnapshotChanges<T> =
      aggregateDocChangesFns<T>(snapshot, 'id', mapFirestoreID);

    if (parentID) {
      aggregate = addParentIDToAggregateDocChanges(aggregate, parentID);
    }

    if (aggregate.added.length) {
      if (this.queryConfig.upsertManyAction) {
        if (this.queryConfig.logUpsert) {
          console.log(JSON.stringify(aggregate.added[0], null, 2));
        }

        that.store.dispatch(this.queryConfig.upsertManyAction(aggregate.added));
      }

      if (this.queryConfig.upsertManyUpdater) {
        this.queryConfig.upsertManyUpdater(aggregate.added);
      }
    }

    if (aggregate.modified.length) {
      if (this.queryConfig.updateManyAction) {
        that.store.dispatch(
          this.queryConfig.updateManyAction(aggregate.modified)
        );
      }

      if (this.queryConfig.updateManyUpdater) {
        this.queryConfig.updateManyUpdater(aggregate.modified);
      }
    }

    if (aggregate.removed.length) {
      if (this.queryConfig.deleteManyAction) {
        that.store.dispatch(
          this.queryConfig.deleteManyAction(aggregate.removed)
        );
      }

      if (this.queryConfig.deleteManyUpdater) {
        this.queryConfig.deleteManyUpdater(aggregate.removed);
      }
    }
  }
}

export interface FirestoreCollectionQueryFactoryConfig<T> {
  createFirestoreCollectionQuery: () => NgPatFirestoreCollectionQuery<T>;
}

export function ngPatFirestoreCollectionQueryFactory<T>(
  _config: FirestoreCollectionQueryConfig<T>,
  _store: Store,
  _customFirestore: NgPatFirestoreService
): FirestoreCollectionQueryFactoryConfig<T> {
  return {
    createFirestoreCollectionQuery: () => {
      return new NgPatFirestoreCollectionQuery(
        _config,
        _store,
        _customFirestore
      );
    }
  };
}

@Injectable({
  providedIn: 'root'
})
export class NgPatFirestoreCollectionQueryFactory {
  constructor(
    private store: Store,
    private customFirestore: NgPatFirestoreService
  ) {}

  /**
   *
   * @param config: FirestoreCollectionQueryConfig
   */
  createFirestoreCollectionQuery<T>(config: FirestoreCollectionQueryConfig<T>) {
    return new NgPatFirestoreCollectionQuery(
      config,
      this.store,
      this.customFirestore
    );
  }
}
