import {GigaNoteDoc} from '@gigasoftware/shared/models';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {ngPatLogout} from '../+account/account.actions';
import * as NoteActions from './note.actions';

export const noteFeatureKey = 'ngPat_note';

export interface NoteState extends EntityState<GigaNoteDoc> {
  // additional entities state properties
  selectedNoteID: string | null;
}

export interface PartialNoteState {
  readonly [noteFeatureKey]: NoteState;
}

export const noteAdapter: EntityAdapter<GigaNoteDoc> =
  createEntityAdapter<GigaNoteDoc>();

export const initialNoteState: NoteState = noteAdapter.getInitialState({
  // additional entity state properties
  selectedNoteID: null
});

export const reducer = createReducer(
  initialNoteState,
  on(NoteActions.addNote, (state, action) =>
    noteAdapter.addOne(action.note, state)
  ),
  on(NoteActions.setNote, (state, action) =>
    noteAdapter.setOne(action.note, state)
  ),
  on(NoteActions.addNotes, (state, action) =>
    noteAdapter.addMany(action.notes, state)
  ),
  on(NoteActions.updateNote, (state, action) =>
    noteAdapter.updateOne(action.note, state)
  ),
  on(NoteActions.updateNotes, (state, action) =>
    noteAdapter.updateMany(action.notes, state)
  ),
  on(NoteActions.upsertNote, (state, action) =>
    noteAdapter.upsertOne(action.note, state)
  ),
  on(NoteActions.upsertNotes, (state, action) =>
    noteAdapter.upsertMany(action.notes, state)
  ),
  on(NoteActions.mapNote, (state, {entityMap}) => {
    return noteAdapter.mapOne(entityMap, state);
  }),
  on(NoteActions.mapNotes, (state, {entityMap}) => {
    return noteAdapter.map(entityMap, state);
  }),
  on(NoteActions.deleteNote, (state, action) =>
    noteAdapter.removeOne(action.id, state)
  ),
  on(NoteActions.deleteNotes, (state, action) =>
    noteAdapter.removeMany(action.ids, state)
  ),
  on(NoteActions.loadNotes, (state, action) =>
    noteAdapter.setAll(action.notes, state)
  ),
  on(NoteActions.setNotes, (state, action) =>
    noteAdapter.setMany(action.notes, state)
  ),
  on(NoteActions.clearNotes, state => noteAdapter.removeAll(state)),
  on(ngPatLogout, state => ({
    ...initialNoteState,
    ...noteAdapter.removeAll(state)
  })),
  on(NoteActions.selectNoteID, (state, action) => {
    return {
      ...state,
      selectedNoteID: action.id
    };
  }),
  on(NoteActions.clearSelectedNote, state => {
    return {
      ...state,
      selectedNoteID: null
    };
  })
);
