import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Output,
  Signal,
  ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {selectAllNotifications} from '@gigasoftware/shared/store';
import {Store} from '@ngrx/store';

import {DlcRoundedIconButtonComponent} from '../components/button/dlc-rounded-icon-button/dlc-rounded-icon-button.component';
import {
  DEFAULT_NOTIFICATION,
  NOTIFICATION_ID,
  UiNotification
} from './notification.model';

@Component({
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-notifications'
    },
    imports: [
        CommonModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        DlcRoundedIconButtonComponent
    ],
    selector: 'dlc-notifications',
    styleUrls: ['./notifications.component.scss'],
    templateUrl: './notifications.component.html'
})
export class NotificationsComponent {
  private store = inject(Store);

  defaultNotifications: NOTIFICATION_ID = DEFAULT_NOTIFICATION;

  @Output() notificationAction: EventEmitter<UiNotification> =
    new EventEmitter<UiNotification>();

  notifications: Signal<UiNotification[]> = <Signal<UiNotification[]>>(
    this.store.selectSignal(selectAllNotifications)
  );
}
