import {NgPatD3CanvasDimension} from '../core/chart.models';

/**
 * For smaller version of the chart such as a dashboard widget
 * or inside a table row, hide axis' and axis title elements.
 */
export interface LineChartConfig extends NgPatD3CanvasDimension {
  showXAxis: boolean;
  showYAxis: boolean;
}

export const initialLineChartConfig: LineChartConfig = {
  height: 400,
  margin: {
    bottom: 40,
    left: 60,
    right: 15,
    top: 15
  },

  showXAxis: true,
  showYAxis: true,

  // will be overridden by resizer
  width: 600
};
