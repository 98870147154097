<h1 mat-dialog-title>Subscription Update Error</h1>
<div mat-dialog-content>{{ data | json }}</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    mat-dialog-close>
    Close
  </button>
</div>
