import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {
  NgPatThemeSwitcherService,
  ThemeConfig
} from '@gigasoftware/shared/utils';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Action, select, Store} from '@ngrx/store';
import {from, Observable, Observer} from 'rxjs';
import {
  concatMap,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  tap
} from 'rxjs/operators';

import {db} from '../db';
import {
  EC_THEME_CONFIGS,
  getClassTheme,
  getDefaultThemeConfig,
  getInvestigationTheme,
  getProjectTheme,
  getResearchTheme,
  getSelectedTheme,
  getStudyTheme
} from '../ec-themes';
import {
  navigateWithParams,
  navigateWithParamsAction
} from '../navigation/actions';
import {NAV_ITEM_DICT} from '../navigation/model';
import {
  initUiState,
  loadUIToStore,
  setCurrentTheme,
  setCurrentURL,
  setNavigateWithParams
} from './ui.actions';
import {NavItemDict, SelectedTheme, UiState, UiStorage} from './ui.model';
import {getNavItemByID, selectTheme} from './ui.selectors';

@Injectable()
export class UiEffects implements OnInitEffects {
  currentRoute$ = createEffect(() =>
    this._router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((n: NavigationEnd) => n.url),
      concatMap((url: string) => {
        const actions: any[] = [setCurrentURL({currentURL: url})];

        if (url.includes('study')) {
          actions.push(
            setCurrentTheme({currentTheme: getStudyTheme(EC_THEME_CONFIGS)})
          );
        } else if (url.includes('class')) {
          actions.push(
            setCurrentTheme({currentTheme: getClassTheme(EC_THEME_CONFIGS)})
          );
        } else if (url.includes('research')) {
          actions.push(
            setCurrentTheme({currentTheme: getResearchTheme(EC_THEME_CONFIGS)})
          );
        } else if (url.includes('project')) {
          actions.push(
            setCurrentTheme({currentTheme: getProjectTheme(EC_THEME_CONFIGS)})
          );
        } else if (url.includes('investigation')) {
          actions.push(
            setCurrentTheme({
              currentTheme: getInvestigationTheme(EC_THEME_CONFIGS)
            })
          );
        } else {
          actions.push(
            setCurrentTheme({
              currentTheme: getDefaultThemeConfig(EC_THEME_CONFIGS)
            })
          );
        }

        return from(actions);
      })
    )
  );

  loadUIFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initUiState),
      switchMap(() => {
        return this.getUiStateFromStorage$();
      }),
      concatMap((uiState: UiStorage | null) => {
        let hasUpdateFromStorage = false;

        try {
          uiState = JSON.parse(JSON.stringify(uiState));
          hasUpdateFromStorage = true;
        } catch (e) {
          uiState = <UiState>{};
        }

        const actions: any[] = [];

        if (hasUpdateFromStorage && uiState !== null) {
          const selectecdTheme: ThemeConfig | null | undefined = (<any>uiState)[
            'currentTheme'
          ];

          // Check if selected theme is in the list of themes.
          // If not, delete selected theme so initial reducer state will be used.
          if (
            selectecdTheme &&
            !getSelectedTheme(selectecdTheme.cssClass, EC_THEME_CONFIGS)
          ) {
            delete (<any>uiState)['currentTheme'];
          }

          // Use theme list from  initial store initialization.
          delete (<any>uiState)['themes'];
          delete (<any>uiState)['navItemEntities'];

          // uiState.navItemEntities = this.navItemDict;

          actions.push(
            loadUIToStore({
              payload: <Partial<UiState>>{
                ...(uiState as UiState),
                isLoaded: true,
                navItemEntities: this.navItemDict,
                themes: [...EC_THEME_CONFIGS]
              }
            })
          );

          if ((<any>uiState).currentNavItemID) {
            actions.push(navigateWithParams((<any>uiState).currentNavItemID));
          }
        } else {
          actions.push(
            loadUIToStore({
              payload: <Partial<UiState>>{
                isLoaded: true,
                navItemEntities: this.navItemDict,
                themes: [...EC_THEME_CONFIGS]
              }
            })
          );
        }

        return from(actions);
      })
    )
  );

  initUiState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initUiState),
      concatMap(() => {
        const actions: any[] = [];

        actions.push(
          loadUIToStore({
            payload: <Partial<UiState>>{
              isLoaded: true,
              navItemEntities: this.navItemDict,
              themes: [...EC_THEME_CONFIGS]
            }
          })
        );

        return from(actions);
      })
    )
  );

  // loadUIToStore$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(loadUIToStore),
  //       tap(() => {
  //         this.init();
  //       })
  //     ),
  //   {dispatch: false}
  // );

  // saveUIFromStorage$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(saveUIToStore),
  //     withLatestFrom(this.store.pipe(select(selectUiState))),
  //     switchMap(
  //       ([action, state]: [Action & {payload: Partial<UiState>}, UiState]) => {
  //         const payload: UiStorage = createUiPayloadForIndexedDBStorage({
  //           ...state,
  //           ...action.payload
  //         });
  //
  //         return from(this.storage
  //           .set(uiFeatureKey, payload))
  //           .pipe(map(() => loadUIToStore({payload})));
  //       }
  //     )
  //   )
  // );

  // saveUIThemes$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(saveUIThemes, configurePlatform),
  //     switchMap(action =>
  //       this.store.pipe(
  //         select(selectUiState),
  //         filter((state: UiState) => state.isLoaded),
  //         take(1),
  //         switchMap((state: UiState) => {
  //           const payload: UiStorage = createUiPayloadForIndexedDBStorage({
  //             ...state,
  //             themes: action.themes
  //           });
  //           return from(this.storage
  //             .set(uiFeatureKey, payload))
  //             .pipe(map(() => loadUIToStore({payload})));
  //         })
  //       )
  //     )
  //   )
  // );

  navigateWithParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(navigateWithParamsAction),
      concatLatestFrom(({navItemKey}) =>
        this.store.select(getNavItemByID(navItemKey))
      ),
      concatMap(([{navParams}, navItem]) => {
        const actions = [];

        if (navItem) {
          this._router.navigate([...navItem.navigateTo]);

          actions.push(
            setNavigateWithParams({
              currentNavItemID: navItem.id,
              navItem,
              navParams,
              previousNavItemID: navItem.previousNavItemID
            })
          );

          // actions.push(setCurrentURL({currentURL: navItem.url}));

          return from(actions);
        }

        actions.push(
          setNavigateWithParams({
            currentNavItemID: '0',
            navParams: {},
            previousNavItemID: '0'
          })
        );

        return from(actions);
      })
    )
  );

  updateThemeInBodyTag$ = createEffect(
    () =>
      this.store.pipe(
        select(selectTheme),
        filter(
          (theme: SelectedTheme) =>
            theme.selectedTheme !== null && theme.selectedTheme !== undefined
        ),
        distinctUntilKeyChanged('selectedTheme'),
        tap((theme: SelectedTheme) => {
          this.themeSwitcher.switchTheme(theme.selectedTheme);
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private _router: Router,
    private store: Store,
    private themeSwitcher: NgPatThemeSwitcherService,
    @Inject(NAV_ITEM_DICT) public navItemDict: NavItemDict,
    @Inject(DOCUMENT) private _document: Document
  ) {
    themeSwitcher.setConfig({
      allThemeConfigs: [...EC_THEME_CONFIGS],
      bodyTagClassesToKeep: [],
      bodyTagClassesToRemove: ['qk-default-theme', 'ed-default-theme']
    });
  }

  getUiStateFromStorage$(): Observable<UiStorage | null> {
    return new Observable((observer: Observer<UiStorage | null>) => {
      db.ui
        .get({id: 1})
        .then((d: UiStorage | undefined) => {
          if (d) {
            observer.next(d);
          } else {
            observer.next(null);
          }
        })
        .catch((e: any) => {
          observer.error(e);
        });
    });
  }

  // init(): void {
  //   this.store
  //     .select(selectUiState)
  //     .pipe(
  //       filter((state: UiState) => state.isLoaded),
  //       map((state: UiState) => {
  //         try {
  //           return JSON.stringify(state);
  //         } catch (e) {
  //           return state;
  //         }
  //       }),
  //       distinctUntilChanged(),
  //       map((state: string | UiState) => {
  //         // If state is a string, it is a JSON stringified object.
  //         // parse the string to get the object.
  //         if (typeof state === 'string') {
  //           try {
  //             return JSON.parse(state);
  //           } catch (e) {
  //             return null;
  //           }
  //         } else {
  //           return state;
  //         }
  //       })
  //     )
  //     .subscribe((state: UiState | null) => {
  //       // console.log('set uiState to storage', state);
  //       if (state !== null) {
  //         // db.ui.put(createUiPayloadForIndexedDBStorage(state), 1);
  //       }
  //     });
  // }

  ngrxOnInitEffects(): Action {
    return initUiState();
  }
}
