import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {
  selectNgPatLoggedInUID,
  selectNgPatMentorAccountsDict
} from '../+account/account.selectors';
import {NG_PAT_ROLES} from '../+account/user.model';
import {
  getMemberListItemsByEntityID,
  selectAllMemberss
} from '../+members/index';
import {convertCollaborativeEntityToMemberUIDEntityID} from '../+members/member.fns';
import {UiState} from '../+ui/ui.model';
import {selectCurrentInvestigationID, selectUiState} from '../+ui/ui.selectors';
import {getCreatedAtNanoseconds} from '../fns/get-created-at-nanoseconds-prop';
import {isCreatedBy} from '../fns/user.fns';
import {selectNgPatAddedAndDeletedEntities} from '../selectors';
import {selectHasActiveSubscription} from '../subscription/subscription.selectors';
import {Investigation} from './investigation.model';
import * as InvestigationReducer from './investigation.reducer';
import {InvestigationState} from './investigation.reducer';

export const selectInvestigationState =
  createFeatureSelector<InvestigationReducer.InvestigationState>(
    InvestigationReducer.investigationFeatureKey
  );

const {selectAll, selectEntities, selectIds, selectTotal} =
  InvestigationReducer.investigationAdapter.getSelectors();

export const selectAllInvestigations = createSelector(
  selectInvestigationState,
  selectAll
);
export const selectInvestigationEntities = createSelector(
  selectInvestigationState,
  selectEntities
);
export const selectInvestigationIds = createSelector(
  selectInvestigationState,
  selectIds
);
export const selectInvestigationTotal = createSelector(
  selectInvestigationState,
  selectTotal
);
export const selectedInvestigationID = createSelector(
  selectInvestigationState,
  (state: InvestigationReducer.InvestigationState) =>
    state.selectedInvestigationID
);

export const selectInvestigationMemberMap = createSelector(
  selectAllInvestigations,
  convertCollaborativeEntityToMemberUIDEntityID
);

export const selectedInvestigation = createSelector(
  selectInvestigationState,
  (state: InvestigationState) => {
    if (state.selectedInvestigationID) {
      return state.entities[state.selectedInvestigationID];
    } else {
      return null;
    }
  }
);

export const getInvestigationByID = (investigationID: string | null) =>
  createSelector(
    selectInvestigationState,
    (
      state: InvestigationReducer.InvestigationState
    ): Investigation | undefined => {
      if (investigationID && state.entities[investigationID]) {
        return state.entities[investigationID];
      }

      return undefined;
    }
  );

export const getInvestigationByTimestamp = (
  nanoseconds: string | null | undefined
) =>
  createSelector(selectAllInvestigations, (investigations: Investigation[]) => {
    return investigations.reduce(
      (
        found: Investigation | null | undefined,
        i: Investigation | undefined
      ) => {
        if (!found && i && getCreatedAtNanoseconds(i) === nanoseconds) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const getInvestigationById = (id: string | null | undefined) =>
  createSelector(selectAllInvestigations, (investigations: Investigation[]) => {
    return investigations.reduce(
      (
        found: Investigation | null | undefined,
        i: Investigation | undefined
      ) => {
        if (!found && i && i.id === id) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const selectInvestigationsIoOwn = createSelector(
  selectNgPatLoggedInUID,
  selectAllInvestigations,
  (uid: string | null, investigations: Investigation[]) => {
    if (uid && uid.length) {
      return investigations.filter((s: Investigation) =>
        uid ? isCreatedBy(s, uid) : false
      );
    }

    return [];
  }
);

export const selectCurrentInvestigation = createSelector(
  selectUiState,
  selectInvestigationEntities,
  (
    state: UiState,
    investigationEntities: Dictionary<Investigation>
  ): Investigation | null | undefined => {
    if (
      state.currentInvestigationID &&
      investigationEntities[state.currentInvestigationID]
    ) {
      return state.currentInvestigationID
        ? investigationEntities[state.currentInvestigationID]
        : null;
    }
    return null;
  }
);

export const selectCurrentInvestigationMembers = createSelector(
  selectCurrentInvestigationID,
  selectHasActiveSubscription,
  selectNgPatMentorAccountsDict,
  selectAllMemberss,
  getMemberListItemsByEntityID
);

export const canInviteToInvestigation = createSelector(
  selectCurrentInvestigation,
  selectNgPatLoggedInUID,
  (
    investigation: Investigation | null | undefined,
    loggedInUID: string | null
  ): boolean => {
    if (
      loggedInUID &&
      investigation &&
      investigation.members &&
      investigation.members[loggedInUID]
    ) {
      return investigation.members[loggedInUID].role === NG_PAT_ROLES.Owner;
    }
    return false;
  }
);

export const selectAddedAndDeletedInvestigations =
  selectNgPatAddedAndDeletedEntities<Investigation>(
    selectInvestigationEntities
  );
