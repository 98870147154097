<ng-container *ngrxLet="trialParams$; let p">
  <h2
    *ngIf="!size"
    class="mat-h2">
    Your trial expires in {{ p.remaining | number : "1.0-0" }} days.
  </h2>
  <span *ngIf="size === 'small'"
    >Your trial expires in {{ p.remaining | number : "1.0-0" }} days.</span
  >
</ng-container>
