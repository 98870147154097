<mat-progress-bar
  mode="indeterminate"
  *ngIf="cmpSvc.selectInProgress$ | ngrxPush"></mat-progress-bar>
<h1 mat-dialog-title>Join A {{ data.title }}</h1>
<mat-dialog-content>
  <ng-container *ngrxLet="cmpSvc.selectError$; let error">
    <div
      class="text-center text-[--dlc-color-warn]"
      @expandEnterAnimation
      *ngIf="error.hasError">
      {{ error.error }}
    </div>
  </ng-container>
  <form [formGroup]="joinFormGroup">
    <div class="flex flex-col justify-start items-start pt-4">
      <mat-form-field
        appearance="outline"
        class="mb-8">
        <mat-label>Enter Code</mat-label>
        <input
          matInput
          formControlName="joinCode"
          autocomplete="off" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Select Role</mat-label>
        <mat-select formControlName="role">
          <mat-option
            *ngFor="let o of cmpSvc.joinRoles$ | ngrxPush"
            [value]="o.role">
            {{ o.displayValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    dlc-rounded-text-button
    mat-dialog-close
    class="mr-4">
    Cancel
  </button>

  <button
    dlc-rounded-text-button
    color="primary"
    [disabled]="
      joinFormGroup.invalid ||
      !joinFormGroup.dirty ||
      (cmpSvc.selectInProgress$ | ngrxPush)
    "
    (click)="joinGroup()">
    Join
  </button>
</mat-dialog-actions>
