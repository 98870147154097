@if (isMobile()) {
<button
  dlc-rounded-text-icon-button
  color="primary"
  (click)="openFileBrowser()">
  <mat-icon>photo_camera</mat-icon>
  Add Photo
</button>
} @else {
<div
  class="dlc-input-file__desktop--add-file"
  (dragover)="dragOverHandler($event)"
  (dragleave)="dragEvent($event)"
  (dragend)="dragEvent($event)"
  (drop)="dropHandler($event)"
  (click)="openFileBrowser()">
  <div class="dlc-input-file__desktop--add-file--cta">
    @if (isDragOverSignal()) { } @else if (isImageSignal()) {
    <mat-icon svgIcon="image-upload"></mat-icon>
    } @else if (isVideoSignal()) {
    <mat-icon svgIcon="video-upload"></mat-icon>
    } @else if (isAudioSignal()) {
    <mat-icon svgIcon="audio-upload"></mat-icon>
    } @else if (isDocumentSignal()) {
    <mat-icon svgIcon="document-upload"></mat-icon>
    } @else if (isMidiSignal()) {
    <mat-icon svgIcon="midi-upload"></mat-icon>
    } @else {
    <mat-icon svgIcon="file-upload"></mat-icon>
    }

    <div>
      @if (isDragOverSignal()) {
      <div class="dlc-input-file__desktop--add-file--cta-title">
        Drop File(s)
      </div>
      } @else {
      <div class="dlc-input-file__desktop--add-file--cta-title">
        {{ titleSignal() }}
      </div>
      <div class="dlc-input-file__desktop--add-file--cta-subTitle">
        {{ subtitleSignal() }}
      </div>
      }
    </div>
  </div>

  <div
    class="dlc-input-file__desktop--instructions"
    *ngIf="showUploadInput">
    <span>&mdash;&nbsp;&nbsp;</span>
    <span class="dlc-input-file__desktop--instructions-content"
      >Click or drag and drop,<br />
      or paste url below.</span
    >
    <span>&nbsp;&nbsp;&mdash;</span>
  </div>
  <div
    class="dlc-input-file__desktop--form"
    *ngIf="showUploadInput">
    <mat-form-field
      floatLabel="always"
      appearance="outline">
      <mat-label>Paste URL</mat-label>
      <input
        matInput
        placeholder="https://my-image.png"
        autocomplete="off"
        [formControl]="urlForm"
        (click)="$event.stopPropagation(); $event.preventDefault()" />
    </mat-form-field>
    <button
      dlc-rounded-icon-button
      color="primary"
      (click)="downloadFromUrl($event)"
      [disabled]="!urlForm.dirty">
      <mat-icon>download</mat-icon>
    </button>
  </div>
</div>
}

<input
  type="file"
  #fileInput
  (change)="onFileChange($event)"
  [attr.accept]="acceptSignal()"
  class="dlc-input-file__desktop--input" />
