import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {DomSanitizer} from '@angular/platform-browser';
import {Camera, CameraResultType} from '@capacitor/camera';
import {Capacitor} from '@capacitor/core';
import {
  convertBase64ToBlob,
  getFileFromEventTarget
} from '@gigasoftware/shared/media';

import {DlcRoundedIconButtonComponent} from '../../button/dlc-rounded-icon-button/dlc-rounded-icon-button.component';
import {DlcRoundedTextIconButtonComponent} from '../../button/dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';
import {getInputFileFileConfig} from './input-file.fns';
import {
  AUDIO_ICON,
  DOCUMENT_ICON,
  FILE_UPLOAD,
  IMAGE_ICON,
  InputFileType,
  MIDI_UPLOAD,
  StandardInputConfig,
  VIDEO_ICON
} from './input-file.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.dlc-input-file__desktop]': 'isMobile() === false',
        '[class.dlc-input-file__is-mobile]': 'isMobile()',
        class: 'dlc-input-file'
    },
    imports: [
        CommonModule,
        DlcRoundedTextIconButtonComponent,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        DlcRoundedIconButtonComponent,
        MatFormFieldModule,
        MatInputModule
    ],
    selector: 'dlc-input-file',
    styleUrls: ['./input-file.component.scss'],
    templateUrl: './input-file.component.html'
})
export class InputFileComponent {
  private httpClient: HttpClient = inject(HttpClient);
  /**
   * accept attribute for the input element
   *
   * See https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
   *
   * @default 'image/*'
   */
  acceptSignal: WritableSignal<string> = signal('image/*');
  @Output() errorChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() fileChange: EventEmitter<Blob> = new EventEmitter<Blob>();

  @ViewChild('fileInput') fileInput: ElementRef | undefined;

  @Output() filesChange: EventEmitter<Blob[]> = new EventEmitter<Blob[]>();

  iconRegistry: MatIconRegistry = inject(MatIconRegistry);

  mediaTyeSignal: WritableSignal<InputFileType> = signal('image');

  isAllSignal = computed(() => {
    return this.mediaTyeSignal().includes('other');
  });

  isAudioSignal = computed(() => {
    return this.mediaTyeSignal().includes('audio');
  });

  isDataSignal = computed(() => {
    return this.mediaTyeSignal().includes('data');
  });

  isDatSignal = computed(() => {
    return this.mediaTyeSignal().includes('dat');
  });

  isDocumentSignal = computed(() => {
    return this.mediaTyeSignal().includes('document');
  });

  isDragOverSignal: WritableSignal<boolean> = signal(false);

  isImageSignal = computed(() => {
    return this.mediaTyeSignal().includes('image');
  });

  isMidiSignal = computed(() => {
    return this.mediaTyeSignal().includes('midi');
  });

  // isMobile = this.store.selectSignal(selectNgPatIsMobile);
  isMobile = signal(Capacitor.isNativePlatform());

  isVideoSignal = computed(() => {
    return this.mediaTyeSignal().includes('video');
  });

  @Input() multiple = false;

  sanitizer: DomSanitizer = inject(DomSanitizer);

  @Input() showUploadInput = true;

  subtitleSignal: WritableSignal<string> = signal('PNG, JPG, GIF');

  titleSignal: WritableSignal<string> = signal('Upload Image');

  @Output() urlChange: EventEmitter<void> = new EventEmitter<void>();
  urlForm: FormControl = new FormControl('');

  constructor() {
    // https://material.angular.io/components/icon/examples
    this.iconRegistry.addSvgIconLiteral(
      'audio-upload',
      this.sanitizer.bypassSecurityTrustHtml(AUDIO_ICON)
    );

    // https://material.angular.io/components/icon/examples
    this.iconRegistry.addSvgIconLiteral(
      'image-upload',
      this.sanitizer.bypassSecurityTrustHtml(IMAGE_ICON)
    );

    // https://material.angular.io/components/icon/examples
    this.iconRegistry.addSvgIconLiteral(
      'video-upload',
      this.sanitizer.bypassSecurityTrustHtml(VIDEO_ICON)
    );

    // https://material.angular.io/components/icon/examples
    this.iconRegistry.addSvgIconLiteral(
      'document-upload',
      this.sanitizer.bypassSecurityTrustHtml(DOCUMENT_ICON)
    );

    // https://material.angular.io/components/icon/examples
    this.iconRegistry.addSvgIconLiteral(
      'file-upload',
      this.sanitizer.bypassSecurityTrustHtml(FILE_UPLOAD)
    );

    // https://material.angular.io/components/icon/examples
    this.iconRegistry.addSvgIconLiteral(
      'midi-upload',
      this.sanitizer.bypassSecurityTrustHtml(MIDI_UPLOAD)
    );
  }

  async downloadFromUrl(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    const url = this.urlForm.value;

    if (this.urlForm.valid && this.urlForm.dirty && url) {
      this.urlChange.emit();

      this.httpClient.get(url, {responseType: 'blob'}).subscribe({
        error: () => {
          this.errorChange.emit('URL Download Error');
        },
        next: (blob: Blob) => {
          this.fileChange.emit(blob);
        }
      });
    }
  }

  dragEvent(ev: any) {
    ev.stopPropagation();
    ev.preventDefault();
    this.isDragOverSignal.set(false);
  }

  dragOverHandler(ev: any) {
    ev.stopPropagation();
    ev.preventDefault();
    this.isDragOverSignal.set(true);
  }

  async dropHandler(ev: any) {
    ev.stopPropagation();
    ev.preventDefault();
    this.isDragOverSignal.set(false);
    let files: File[] = [];

    if (ev.dataTransfer.items && ev.dataTransfer.items.length) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          files.push(ev.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      files = ev.dataTransfer.files;
    }

    if (files && files.length > 0) {
      if (this.multiple) {
        this.filesChange.emit(files);
      } else {
        this.fileChange.emit(files[0]);
      }
    }
  }

  async getAndroidPicture() {
    const image = await Camera.getPhoto({
      allowEditing: false,
      quality: 90,
      resultType: CameraResultType.Base64
    });

    if (image.base64String) {
      this.fileChange.emit(
        convertBase64ToBlob(image.base64String, image.format)
      );
    }
  }

  async onFileChange(event: Event) {
    const file: File | undefined = getFileFromEventTarget(event);

    if (file) {
      this.fileChange.emit(file);
    }
  }

  openFileBrowser() {
    if (Capacitor.getPlatform() === 'android') {
      this.getAndroidPicture();
    } else {
      if (this.fileInput) {
        this.fileInput.nativeElement.click();
      }
    }
  }

  /**
   * accept attribute for the input element
   *
   * See https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
   *
   * @default 'image/*'
   */
  @Input()
  set accept(value: string | InputFileType) {
    this.acceptSignal.set(value);
  }

  @Input()
  set mediaType(value: InputFileType) {
    const config: StandardInputConfig = getInputFileFileConfig(value);
    this.mediaTyeSignal.set(value);
    this.acceptSignal.set(config.accept);
    this.titleSignal.set(config.title);
    this.subtitleSignal.set(config.subTitle);
  }

  @Input()
  set subTitle(value: string) {
    this.subtitleSignal.set(value);
  }

  @Input()
  set title(value: string) {
    this.titleSignal.set(value);
  }
}
