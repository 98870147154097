import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {
  computed,
  inject,
  Injectable,
  signal,
  WritableSignal
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal
} from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
  Validators
} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {dataStaticResearchPlatforms} from '@gigasoftware/shared/data';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {Bookmark} from '@gigasoftware/shared/models';
import {
  CollaborativeEntity,
  copyBookmark,
  createDlcBookmark,
  selectBookmarksByEntityID
} from '@gigasoftware/shared/store';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';

import {
  DlcSaveBtnState,
  mapFormControlStatusToSaveBtnState
} from '../../button/dlc-save-button/dlc-save-button.component';

@Injectable({
  providedIn: 'root'
})
export class DlcBookmarkService {
  private firestoreService: NgPatFirestoreService = inject(
    NgPatFirestoreService
  );
  private fb: FormBuilder = inject(FormBuilder);
  private store: Store = inject(Store);

  doUpdateSaveBtnState: WritableSignal<DlcSaveBtnState> =
    signal<DlcSaveBtnState>(DlcSaveBtnState.DISABLED);

  cancelIsDisabled = computed(() => {
    const doUpdateSaveBtnState = this.doUpdateSaveBtnState();

    return doUpdateSaveBtnState === DlcSaveBtnState.DISABLED;
  });
  /**
   * 'YouTube', 'Google', 'Brave', 'DuckDuckGo'
   */
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private editBookmarkEntity: Bookmark | undefined | null = null;

  // tags: Signal<any> | undefined;
  bookmarkForm: FormGroup = this.fb.group({
    description: new FormControl(''),
    tags: new FormControl(['Google']),
    title: new FormControl(''),
    url: new FormControl('', [Validators.required, Validators.minLength(10)])
  });

  tags = toSignal(
    this.bookmarkForm.get('tags')?.valueChanges.pipe(debounceTime(300)) ??
      of([]),
    {}
  );

  readonly filteredTags = computed(() => {
    // const tag = this.tags()?.toLowerCase();
    const selectedTags: string[] = this.tags() || [];

    /**
     * Filter out the selected tags from the all tags
     */
    return [...dataStaticResearchPlatforms].filter(
      tag => !selectedTags.includes(tag)
    );
  });

  constructor() {
    this.bookmarkForm.statusChanges
      .pipe(takeUntilDestroyed())
      .subscribe((status: FormControlStatus) => {
        this.doUpdateSaveBtnState.set(
          mapFormControlStatusToSaveBtnState(status)
        );
      });
  }

  private _id: string = this.firestoreService.createFirestoreId();

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  baseEntity: WritableSignal<CollaborativeEntity | null | undefined> =
    signal(null);

  currentBookmarkList$ = toObservable(this.baseEntity).pipe(
    switchMap((baseEntity: CollaborativeEntity | null | undefined) =>
      this.store.select(selectBookmarksByEntityID(baseEntity?.id))
    )
  );

  get urlControl() {
    return this.bookmarkForm.get('url');
  }

  removeKeyword(keyword: string) {
    // remove tag from form control
    const tags = this.bookmarkForm.get('tags')?.value || [];
    const index = tags.indexOf(keyword);
    if (index > -1) {
      tags.splice(index, 1);
      this.bookmarkForm.get('tags')?.setValue([...tags]);
    }
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      // add tag to form control
      const tags = this.bookmarkForm.get('tags')?.value || [];
      this.bookmarkForm.get('tags')?.setValue([...tags, value]);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  autoCompleteSelect(event: MatAutocompleteSelectedEvent): void {
    // get tags from tags form control
    const tags = this.bookmarkForm.get('tags')?.value || [];

    // add tag to tags form controll
    this.bookmarkForm.get('tags')?.setValue([...tags, event.option.viewValue]);

    event.option.deselect();
  }

  reset() {
    this.baseEntity.set(null);
    this.id = this.firestoreService.createFirestoreId();
    this.bookmarkForm.reset({
      description: '',
      tags: ['Google'],
      title: '',
      url: ''
    });
  }

  init(baseEntity: CollaborativeEntity | null | undefined) {
    this.reset();
    this.baseEntity.set(baseEntity);
  }

  /**
   * See libs/evolving-cognition/ui/src/lib/quiz/ec-quiz-edit/ec-edit-quiz.component.ts
   */
  async doSave() {
    this.doUpdateSaveBtnState.set(DlcSaveBtnState.DISABLED);

    const id = this.editBookmarkEntity
      ? this.editBookmarkEntity.id
      : this.firestoreService.createFirestoreId();

    const bookmark = createDlcBookmark(
      this.bookmarkForm.value,
      id,
      <string>this.firestoreService.uid,
      this.baseEntity()
    );

    await this.firestoreService.setDoc(
      bookmark.firestoreDocumentPath,
      bookmark
    );

    this.init(this.baseEntity());
    this.editBookmarkEntity = null;
  }

  cancel() {
    this.init(this.baseEntity());
    this.editBookmarkEntity = null;
  }

  async copyBookmark(
    sourceBookmark: Bookmark,
    entity: CollaborativeEntity | null | undefined
  ) {
    const copiedBookmark = copyBookmark(
      sourceBookmark,
      this.firestoreService.createFirestoreId(),
      this.firestoreService.uid as string,
      entity && entity.isCollaborative ? entity : null
    );

    await this.firestoreService.setDoc(
      copiedBookmark.firestoreDocumentPath,
      copiedBookmark
    );

    return true;
  }

  deleteBookmark(bookmark: Bookmark | undefined | null) {
    if (bookmark) {
      this.firestoreService.deleteDoc(bookmark.firestoreDocumentPath);
    }
  }

  editBookmark(bookmark: Bookmark | undefined | null) {
    if (bookmark) {
      this.bookmarkForm.patchValue(bookmark);
      this.editBookmarkEntity = bookmark;
    }
  }
}
