import {generate8CharCodeLowercase} from '@gigasoftware/shared/fn';
import {BaseEntity} from '@gigasoftware/shared/models';

import {Classroom, CreateClassParams} from '../+classrooms/classroom.model';
import {
  CollaborativeEntity,
  CreateBaseSubEntityParams,
  CreateEntityParams,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT
} from '../entity/entity.model';
import {firestoreClassCollection} from '../firebase/index';
import {
  EC_QUIZ_ROLES,
  UserIdWithRole,
  UserPermissions
} from '../models/user.model.deprecated';

export function createEntity(
  params: CreateEntityParams | CreateBaseSubEntityParams
): BaseEntity {
  let entity = {
    createdAt: null,
    description: params.description,
    entityType: params.entityTypeValue,
    entityTypeValue: params.entityTypeValue,
    firestoreCollectionPath: '',
    firestoreDocumentPath: '',
    // TreeConfig
    id: params.id,
    imagePath: null,
    isCollaborative: params.isCollaborative,
    isDefault: false,
    isPrivate: params.isPrivate,
    name: params.name,
    parentEntityID: (<CreateBaseSubEntityParams>params).parentEntity
      ? (<CreateBaseSubEntityParams>params).parentEntity.id
      : null,
    parentEntityType: (<CreateBaseSubEntityParams>params).parentEntity
      ? (<CreateBaseSubEntityParams>params).parentEntity.entityType
      : null,
    updatedAt: null

    // Permissions
    // createdBy: user,
    // createdByUID: user.uid,
    // membersList: {
    //   [user.uid]: user
    // },
    // memberUIDs: [user.uid],
    // owners: {
    //   [user.uid]: user
    // },
    // ownerUIDs: [user.uid]
  };

  if (params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ.id) {
    entity = <any>{
      ...entity,
      aggregateFalseAnswers: true,
      isPublic: false,
      publicDisplayName: '',
      totalAnswers: 4
    };
  }

  if (params.isCollaborative) {
    entity = <any>{
      ...entity,
      // joinCode: generate8CharCodeLowercase(),
      mentorCode: generate8CharCodeLowercase()
    };
  }

  if (params.assigned !== null && params.assigned !== undefined) {
    entity = <any>{
      ...entity,
      assigned: params.assigned
    };
  }

  return entity;
}

export function createClass(
  p: CreateClassParams,
  user: UserIdWithRole
): Classroom {
  return {
    ...p,
    createdAt: null,
    entityType: DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id,
    entityTypeValue: DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id,
    firestoreCollectionPath: firestoreClassCollection(),
    firestoreDocumentPath: '',
    imagePath: null,
    isCollaborative: true,
    isDefault: false,
    isPrivate: false,
    mentorCode: generate8CharCodeLowercase(),
    name: <string>p.name,
    parentEntityID: null,
    parentEntityType: null,
    // joinCode: generate8CharCodeLowercase(),
    studentCode: generate8CharCodeLowercase(),
    teacherCode: generate8CharCodeLowercase(),
    updatedAt: null,
    ...createEntityUserArchitecture(user)
  };
}

export function createEntityUserArchitecture(
  user: UserIdWithRole
): UserPermissions {
  return {
    createdBy: user,
    createdByUID: user.uid,
    members: {
      [user.uid]: user
    },
    memberUIDs: [user.uid]
  };
}

export function removeEntityUserArchitecture(
  p: CollaborativeEntity
): BaseEntity {
  const entity: any = {
    ...p
  };

  delete entity.createdBy;
  delete entity.createdByUID;
  delete entity.members;
  delete entity.memberUIDs;

  return entity as BaseEntity;
}

export function createCollaborativeEntity(
  user: UserIdWithRole,
  params: CreateEntityParams | CreateBaseSubEntityParams
) {
  return {
    ...createEntity(params),
    ...createEntityUserArchitecture(user)
  };
}

export function addUserAndRoleToEntity(
  p: CollaborativeEntity,
  uid: string,
  role: EC_QUIZ_ROLES
): CollaborativeEntity {
  return {
    ...p,
    members: {
      ...p.members,
      [uid]: {
        role,
        uid
      }
    },
    memberUIDs: [...new Set([...p.memberUIDs, uid])]
  };
}

export function removeUserAndRoleFromEntity(
  p: CollaborativeEntity,
  uid: string
) {
  const members = Object.values(p.members).reduce(
    (a: {[uid: string]: UserIdWithRole}, m: UserIdWithRole) => {
      if (m.uid !== uid) {
        a[m.uid] = m;
      }

      return a;
    },
    {}
  );

  return {
    ...p,
    members,
    memberUIDs: p.memberUIDs.filter((muid: string) => muid !== uid)
  };
}
