import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogModule, MatButtonModule],
    selector: 'dlc-update-subscription-success',
    styleUrls: ['./update-stripe-subscription-success.component.scss'],
    templateUrl: './update-stripe-subscription-success.component.html'
})
export class UpdateStripeSubscriptionSuccessComponent {}
