import {Injectable} from '@angular/core';
import {
  NgPatFirestoreCollectionQuery,
  ngPatFirestoreCollectionQueryFactory,
  NgPatFirestoreService
} from '@gigasoftware/shared/firebase';
import {BaseEntity, Exists} from '@gigasoftware/shared/models';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

import {NgPatAccountState} from '../+account/account.model';
import {deleteQuizs, updateQuizs, upsertQuizs} from '../+quizzes/quiz.actions';
import {Quiz} from '../+quizzes/quiz.model';
import {NgPatServiceConnector} from '../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../+websocket-registry/websocket-registry.models';
import {assignDeprecatedBaseEntitiesProperties} from '../entity/entity.model';
import {
  firestoreQueryPathByEntity,
  firestoreQuizCollection,
  firestoreResearchCollection,
  firestoreUserResearchCollection
} from '../firebase/database-paths';
import {aggregateUpdates} from '../fns/aggregate-updates';
import {QueryEngineCache} from '../services/query-engine-cache';
import {
  deleteResearchs,
  updateResearchs,
  upsertResearchs
} from './research.actions';
import {Research} from './research.model';
import {researchFeatureKey} from './research.reducer';
import {selectAddedAndDeletedResearchs} from './research.selectors';

@Injectable({
  providedIn: 'root'
})
export class ResearchService implements NgPatFirebaseConnectionService {
  private _queryCollaborationService!: NgPatFirestoreCollectionQuery<Research>;
  private _queryPrivateQuizService!: NgPatFirestoreCollectionQuery<Research>;
  private _researchQuizQueryCache!: QueryEngineCache<Quiz, Research>;
  connection: NgPatServiceConnector;
  connectionKey = researchFeatureKey;

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {
    this.connection = new NgPatServiceConnector(this, this.store);
  }

  deleteDoc$(classroom: BaseEntity | undefined, uid: string) {
    if (classroom) {
      const path: string = classroom.isPrivate
        ? firestoreUserResearchCollection(uid)
        : firestoreResearchCollection();

      // const path = research.isPrivate ?
      return this.customFirestoreService.deleteDoc$(path);
    }
    return of(true);
  }

  deleteDocs$(classroom: BaseEntity | undefined, ids: string[], uid: string) {
    if (classroom) {
      const isPrivate = classroom.isPrivate;
      const path: string = isPrivate
        ? firestoreUserResearchCollection(uid)
        : firestoreResearchCollection();
      return this.customFirestoreService.deleteDocs$(path, ids);
    }

    return of(true);
  }

  ngPatOnInit() {
    this._queryCollaborationService =
      new NgPatFirestoreCollectionQuery<Research>(
        {
          deleteManyAction: (ids: string[]) => deleteResearchs({ids}),
          queryMember: true,
          updateManyAction: (researchs: Research[]) =>
            updateResearchs({
              researchs: aggregateUpdates(
                assignDeprecatedBaseEntitiesProperties(researchs) as Research[]
              )
            }),
          upsertManyAction: (researchs: Research[]) =>
            upsertResearchs({
              researchs: assignDeprecatedBaseEntitiesProperties(
                researchs
              ) as Research[]
            })
        },
        this.store,
        this.customFirestoreService
      );

    // TODO Not needed
    const queryResearchConfig = ngPatFirestoreCollectionQueryFactory(
      {
        deleteManyAction: (ids: string[]) => deleteResearchs({ids}),
        queryMember: false,
        updateManyAction: (researchs: Research[]) =>
          updateResearchs({
            researchs: aggregateUpdates(
              assignDeprecatedBaseEntitiesProperties(researchs) as Research[]
            )
          }),
        upsertManyAction: (researchs: Research[]) =>
          upsertResearchs({
            researchs: assignDeprecatedBaseEntitiesProperties(
              researchs
            ) as Research[]
          })
      },
      this.store,
      this.customFirestoreService
    );

    this._queryPrivateQuizService =
      queryResearchConfig.createFirestoreCollectionQuery();

    const queryQuizConfig = ngPatFirestoreCollectionQueryFactory(
      {
        deleteManyAction: (ids: string[]) => deleteQuizs({ids}),
        queryMember: false,
        updateManyAction: (quizs: Quiz[]) =>
          updateQuizs({
            quizs: aggregateUpdates(
              assignDeprecatedBaseEntitiesProperties(quizs) as Quiz[]
            )
          }),
        upsertManyAction: (quizs: Quiz[]) =>
          upsertQuizs({
            quizs: assignDeprecatedBaseEntitiesProperties(quizs) as Quiz[]
          })
      },
      this.store,
      this.customFirestoreService
    );

    const quizPathGenerator = (entity: Quiz, uid: string) =>
      `${firestoreQueryPathByEntity(
        entity as BaseEntity,
        uid
      )}/${firestoreQuizCollection()}`;

    this._researchQuizQueryCache = new QueryEngineCache<Quiz, Research>(
      queryQuizConfig,
      this.store,
      selectAddedAndDeletedResearchs,
      quizPathGenerator,
      'id'
    );
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    this._queryCollaborationService.onConnect(
      firestoreResearchCollection(),
      null,
      <string>user.uid
    );
    this._queryPrivateQuizService.onConnect(
      firestoreUserResearchCollection(<string>user.uid),
      null,
      null
    );
    this._researchQuizQueryCache.onConnect(user);
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    this._queryCollaborationService.onDisconnect();
    this._queryPrivateQuizService.onDisconnect();
    this._researchQuizQueryCache.onDisconnect();

    // Unsubscribe to query before calling this
  }

  updateDoc(g: Research) {
    return this.customFirestoreService.user$.pipe(
      take(1),
      switchMap((u: User) => {
        const path = firestoreQueryPathByEntity(g, <string>u.uid);
        return this.customFirestoreService.merge$(path, g);
      })
    );
  }

  updatePartialFirestore$(
    changes: Partial<Research>,
    quiz: Research,
    uid: string | null
  ): Observable<Research> {
    if (uid) {
      return this.customFirestoreService
        .merge$<Research>(firestoreQueryPathByEntity(quiz, uid), changes)
        .pipe(map((r: Exists<Research>) => r.data));
    }

    return of(quiz);
  }
}
