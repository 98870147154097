export function getAorAn(word: string): string {
  const firstLetter = word.charAt(0).toLowerCase();
  if (
    firstLetter === 'a' ||
    firstLetter === 'e' ||
    firstLetter === 'i' ||
    firstLetter === 'o' ||
    firstLetter === 'u'
  ) {
    return 'an';
  }

  // get first 3 letters
  const firstThreeLetters = word.substring(0, 3).toLowerCase();

  // numbers
  if (
    firstThreeLetters === '1st' ||
    firstThreeLetters === '2nd' ||
    firstThreeLetters === '3rd' ||
    firstThreeLetters === '4th' ||
    firstThreeLetters === '5th' ||
    firstThreeLetters === '6th' ||
    firstThreeLetters === '7th' ||
    firstThreeLetters === '9th' ||
    firstThreeLetters === '12t'
  ) {
    return 'a';
  }

  if (
    firstThreeLetters === '8th' ||
    firstThreeLetters === '11t' ||
    firstThreeLetters === '13t' ||
    firstThreeLetters === '14t' ||
    firstThreeLetters === '15t' ||
    firstThreeLetters === '16t'
  ) {
    return 'an';
  }

  return 'a';
}
