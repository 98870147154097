import {inject, Inject, Injectable, Signal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {AUTH_URLS, AuthURLs} from '@gigasoftware/shared/models';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import {cancelAuth, updateAuthAction} from './login.actions';
import {AuthLoginAction, currentLoginState} from './login.model';
import {selectLoginAction} from './login.selectors';

@Injectable()
export class AuthLoginService {
  private store = inject(Store);
  action: Signal<string> = this.store.selectSignal(selectLoginAction);

  loginStatus = AuthLoginAction;
  header: Signal<string> = <Signal<string>>toSignal(
    this.store.select(selectLoginAction).pipe(
      map((status: AuthLoginAction) => {
        return currentLoginState(status);
      })
    )
  );

  doLogin: Subject<boolean> = new Subject<boolean>();

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    @Inject(AUTH_URLS) private authURLs: AuthURLs
  ) {}

  onLogout() {
    this.customFirestoreService.signOut();
  }

  onIsLoggingIn() {
    this.store.dispatch(updateAuthAction({action: AuthLoginAction.LOGGING_IN}));
  }

  async cancelLogin() {
    this.store.dispatch(cancelAuth());
  }

  // TEST STATES
  testSuccess() {
    this.store.dispatch(updateAuthAction({action: AuthLoginAction.SUCCESS}));
  }

  testError() {
    this.store.dispatch(updateAuthAction({action: AuthLoginAction.ERROR}));
  }

  testCancel() {
    this.store.dispatch(updateAuthAction({action: AuthLoginAction.CANCEL}));
  }

  testLogin() {
    this.store.dispatch(updateAuthAction({action: AuthLoginAction.LOGIN}));
  }

  testLoggingIn() {
    this.store.dispatch(updateAuthAction({action: AuthLoginAction.LOGGING_IN}));
  }
}
