import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {ngPatLogout} from '../+account/account.actions';
import {resetNavigation} from '../+ui/ui.actions';
import * as ProjectActions from './project.actions';
import {Project} from './project.model';

export const projectFeatureKey = 'project';

export interface ProjectState extends EntityState<Project> {
  // additional entities state properties
  selectedProjectID: string | null;
}

export interface PartialProjectState {
  readonly [projectFeatureKey]: ProjectState;
}

export const projectAdapter: EntityAdapter<Project> =
  createEntityAdapter<Project>();

export const initialProjectState: ProjectState = projectAdapter.getInitialState(
  {
    // additional entity state properties
    selectedProjectID: null
  }
);

export const reducer = createReducer(
  initialProjectState,
  on(ProjectActions.addProject, (state, action) =>
    projectAdapter.addOne(action.project, state)
  ),
  on(ProjectActions.setProject, (state, action) =>
    projectAdapter.setOne(action.project, state)
  ),
  on(ProjectActions.addProjects, (state, action) =>
    projectAdapter.addMany(action.projects, state)
  ),
  on(ProjectActions.updateProject, (state, action) =>
    projectAdapter.updateOne(action.project, state)
  ),
  on(ProjectActions.updateProjects, (state, action) =>
    projectAdapter.updateMany(action.projects, state)
  ),
  on(ProjectActions.upsertProject, (state, action) =>
    projectAdapter.upsertOne(action.project, state)
  ),
  on(ProjectActions.upsertProjects, (state, action) =>
    projectAdapter.upsertMany(action.projects, state)
  ),
  on(ProjectActions.mapProject, (state, {entityMap}) => {
    return projectAdapter.mapOne(entityMap, state);
  }),
  on(ProjectActions.mapProjects, (state, {entityMap}) => {
    return projectAdapter.map(entityMap, state);
  }),
  on(ProjectActions.deleteProject, (state, action) =>
    projectAdapter.removeOne(action.id, state)
  ),
  on(ProjectActions.deleteProjects, (state, action) =>
    projectAdapter.removeMany(action.ids, state)
  ),
  on(ProjectActions.loadProjects, (state, action) =>
    projectAdapter.setAll(action.projects, state)
  ),
  on(ProjectActions.setProjects, (state, action) =>
    projectAdapter.setMany(action.projects, state)
  ),
  on(ProjectActions.clearProjects, state => projectAdapter.removeAll(state)),
  on(ngPatLogout, state => ({
    ...initialProjectState,
    ...projectAdapter.removeAll(state)
  })),
  on(ProjectActions.selectProjectID, (state, action) => {
    return {
      ...state,
      selectedProjectID: action.id
    };
  }),
  on(resetNavigation, (state: ProjectState) => {
    return {
      ...state,
      selectedProjectID: null
    };
  })
);
