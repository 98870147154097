import {NgIf} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  ReactiveFormsModule
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {
  NgPatAccountRemoteConfigs,
  ngPatAccountSaveFirebase,
  NgPatPartialAccountStates,
  selectNgPatAccountRemoteConfigs
} from '@gigasoftware/shared/store';
import {select, Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {distinctUntilChanged, take, takeUntil} from 'rxjs/operators';

/**
 * @deprecated move to dlc as "dlc-access-code"
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ui-promo-code'
    },
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        NgIf,
        MatButtonModule,
        MatIconModule
    ],
    selector: 'ec-promo-code',
    styleUrls: ['./promo-code.component.scss'],
    templateUrl: './promo-code.component.html'
})
export class PromoCodeComponent implements OnDestroy, OnInit {
  private _onDestroy$: Subject<boolean> = new Subject();
  promoCodeForm: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(3)
  ]);

  constructor(
    private store: Store<NgPatPartialAccountStates>,
    private _cd: ChangeDetectorRef
  ) {}

  apply() {
    if (this.promoCodeForm.valid) {
      this.store.dispatch(
        ngPatAccountSaveFirebase({
          payload: {
            remoteConfigs: {[this.promoCodeForm.value]: true}
          }
        })
      );
    }
  }

  /**
   * TODO delete promo code is setting value to false
   */
  delete() {
    this.promoCodeForm.reset(null, {emitEvent: false});

    // this.store.dispatch(
    //   ngPatAccountSaveFirebase({
    //     payload: {
    //       remoteConfigs: null
    //     }
    //   })
    // );
  }

  getErrorMessage() {
    if (this.promoCodeForm.hasError('required')) {
      return 'You must enter a value';
    }

    // if (this.promoCodeForm.hasError('minlength')) {
    //   return 'Minimum of 3 characters required';
    // }

    return '';
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  ngOnInit() {
    this.reset();

    this.store
      .pipe(
        select(selectNgPatAccountRemoteConfigs),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((promoCode: NgPatAccountRemoteConfigs) => {
        if (promoCode !== null && promoCode !== undefined) {
          this.promoCodeForm.reset(promoCode, {emitEvent: false});
          this._cd.detectChanges();
        }
      });
  }

  reset(): void {
    this.store
      .pipe(select(selectNgPatAccountRemoteConfigs), take(1))
      .subscribe((promoCode: NgPatAccountRemoteConfigs) => {
        this.promoCodeForm.reset(promoCode, {emitEvent: false});
      });
  }
}
