import {inject, Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {merge} from '@gigasoftware/shared/fn';
import {GSFirebaseUploadImageConfig} from '@gigasoftware/shared/media';
import {
  GigaNoteDoc,
  GigaNoteImageState,
  GigaNoteTranscribeNoteVersionUpdate,
  GigaNoteTranscriptionState,
  RecursivePartial
} from '@gigasoftware/shared/models';
import {
  distinctUntilJsonChangedOperator,
  ngPatWithLastValueFrom,
  stringPopulated
} from '@gigasoftware/shared/rxjs';
import {EC_HTTPS_CALLABLE} from '@gigasoftware/shared/store';
import {
  EntityProcessQueue,
  NgPatProcessQueueState
} from '@gigasoftware/shared/utils';
import {ComponentStore} from '@ngrx/component-store';
import {Observable, of, Subject} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import {DlcSaveBtnState} from '../../../button/dlc-save-button/dlc-save-button.component';
import {DlcInputImageConfig} from '../../../input/dlc-input-image/dlc-input-image.component';
import {
  addProcessQueueIdNoteTitle,
  addProcessQueueIdNoteVersion,
  addProcessQueueIdTranscriptionVersion,
  DlcNoteProcessQueue,
  DlcNoteSaveAction,
  DlcNoteStore,
  DlcNoteTranscribedTextIdNull,
  DlcNoteUserTextIdNull,
  DlcTranscribeBtnState
} from './note.model';

@Injectable()
export class DlcNoteStoreService extends ComponentStore<DlcNoteStore> {
  firestore: NgPatFirestoreService = inject(NgPatFirestoreService);
  saveDataProcessQueue: EntityProcessQueue<DlcNoteProcessQueue> =
    new EntityProcessQueue();
  autoSaveTimer$ = new Subject();

  constructor() {
    super({
      firestoreDoc: null,
      firestorePath: null,
      isDirty: false,
      latestNoteVersion: null,
      latestTransitionVersion: null,
      saveBtnState: DlcSaveBtnState.DISABLED,
      transcribedNote: null,
      transcribedTextId: '',
      uploadConfig: null,
      userNote: null,
      userTextId: '',
      uuid: null
    });

    this.autoSaveTimer$
      .pipe(
        switchMap(() => {
          // Only save if the process queue is pending something to save
          return this.saveDataProcessQueue.processingState$.pipe(
            filter(
              (state: NgPatProcessQueueState) =>
                state === NgPatProcessQueueState.PENDING
            ),
            debounceTime(2000)
          );
        })
      )
      .subscribe(() => {
        this.saveDataProcessQueue.next();
      });
  }

  // *********** Updaters *********** //
  // *********** Updaters *********** //
  // *********** Updaters *********** //

  readonly clear = this.updater(() => ({
    firestoreDoc: null,
    firestorePath: null,
    isDirty: false,
    latestNoteVersion: null,
    latestTransitionVersion: null,
    saveBtnState: DlcSaveBtnState.DISABLED,
    transcribedNote: null,
    transcribedTextId: '',
    uploadConfig: null,
    userNote: null,
    userTextId: '',
    uuid: null
  }));

  readonly patchFirestoreDoc = this.updater(
    (state: DlcNoteStore, patch: RecursivePartial<GigaNoteDoc>) => {
      const newDoc: GigaNoteDoc = merge(state.firestoreDoc || {}, patch);
      // console.log(newDoc, 'newDoc');
      // console.log(patch, 'patch');

      return {
        ...state,
        firestoreDoc: newDoc,
        transcribedTextId: newDoc.transcribedTextId || '',
        userTextId: newDoc.userNoteId || '',
        uuid: newDoc.id
      };
    }
  );

  readonly updateFirestorePath = this.updater(
    (state: DlcNoteStore, firestorePath: string | null) => ({
      ...state,
      firestorePath
    })
  );

  readonly updateIsDirty = this.updater(
    (state: DlcNoteStore, isDirty: boolean) => ({
      ...state,
      isDirty
    })
  );

  readonly uuid = this.updater((state: DlcNoteStore, uuid: string | null) => ({
    ...state,
    uuid
  }));

  readonly updateLatestNoteVersion = this.updater(
    (state: DlcNoteStore, latestNoteVersion: string | null) => ({
      ...state,
      latestNoteVersion
    })
  );

  readonly updateLatestTranscriptionVersion = this.updater(
    (state: DlcNoteStore, latestTransitionVersion: string | null) => ({
      ...state,
      latestTransitionVersion
    })
  );

  readonly updateUploadConfig = this.updater(
    (
      state: DlcNoteStore,
      uploadConfig: Partial<GSFirebaseUploadImageConfig>
    ) => ({
      ...state,
      uploadConfig
    })
  );

  private readonly _updateNoteTitle = this.updater(
    (
      state: DlcNoteStore,
      update: {
        isDirty: boolean;
        saveBtnState: DlcSaveBtnState;
      }
    ) => {
      return {
        ...state,
        ...update
      };
    }
  );

  private readonly _updateUserNote = this.updater(
    (
      state: DlcNoteStore,
      update: {
        userNote: string;
        isDirty: boolean;
        saveBtnState: DlcSaveBtnState;
      }
    ) => {
      return {
        ...state,
        ...update
      };
    }
  );

  private readonly _updateTranscribedNote = this.updater(
    (
      state: DlcNoteStore,
      update: {
        transcribedNote: string;
        isDirty: boolean;
        saveBtnState: DlcSaveBtnState;
      }
    ) => ({
      ...state,
      ...update
    })
  );

  readonly updateSaveBtnState = this.updater(
    (state: DlcNoteStore, saveBtnState: DlcSaveBtnState) => ({
      ...state,
      saveBtnState
    })
  );

  // *********** Selectors *********** //
  // *********** Selectors *********** //
  // *********** Selectors *********** //
  readonly selectFirestoreDoc$ = this.select(
    (state: DlcNoteStore) => state.firestoreDoc
  ).pipe(
    filter((doc: GigaNoteDoc | null) => {
      return doc !== null;
    })
  );
  readonly selectFirestorePath$ = this.select(
    (state: DlcNoteStore) => state.firestorePath
  ).pipe(stringPopulated());
  readonly selectIsDirty$ = this.select(
    (state: DlcNoteStore) => state.isDirty
  ).pipe(distinctUntilChanged());
  readonly selectNoteTitle$ = this.select(
    (state: DlcNoteStore) => state.firestoreDoc?.title
  ).pipe(stringPopulated());
  readonly selectUserNote$ = this.select(
    (state: DlcNoteStore) => state.userNote
  ).pipe(stringPopulated());
  readonly selectTranscribedNote$ = this.select(
    (state: DlcNoteStore) => state.transcribedNote
  ).pipe(stringPopulated());
  readonly selectSaveBtnState$ = this.select(
    (state: DlcNoteStore) => state.saveBtnState
  );
  readonly selectUuid$ = this.select((state: DlcNoteStore) => state.uuid).pipe(
    stringPopulated()
  );
  readonly selectImageIsUploaded$ = this.select(
    state => state.firestoreDoc?.imageState === GigaNoteImageState.UPLOADED
  );

  readonly selectUploadConfig$ = this.select(
    (state: DlcNoteStore) => state.uploadConfig
  ).pipe(distinctUntilJsonChangedOperator());

  readonly selectLatestNoteVersion$ = this.select(
    (state: DlcNoteStore) => state.latestNoteVersion
  ).pipe(stringPopulated());

  readonly selectTranscribedTextId$ = this.select(
    (state: DlcNoteStore): DlcNoteTranscribedTextIdNull => {
      return {
        firestorePath: state.firestorePath,
        transcribedTextId: state.transcribedTextId
      };
    }
  );

  readonly selectUserTextId$ = this.select(
    (state: DlcNoteStore): DlcNoteUserTextIdNull => {
      return {
        firestorePath: state.firestorePath,
        userTextId: state.userTextId
      };
    }
  );

  readonly selectLatestTranscriptionVersion$ = this.select(
    (state: DlcNoteStore) => state.latestTransitionVersion
  ).pipe(stringPopulated());

  readonly selectTranscribeBtnState$ = this.select(state => {
    if (state.firestoreDoc) {
      if (state.firestoreDoc.imageState === GigaNoteImageState.UPLOADED) {
        if (
          state.firestoreDoc.transcribeState ===
          GigaNoteTranscriptionState.IN_PROGRESS
        ) {
          return DlcTranscribeBtnState.IN_PROGRESS;
        }
        return DlcTranscribeBtnState.ACTIVE;
      }
    }
    return DlcTranscribeBtnState.DISABLED;
  });

  readonly selectResolvedImageConfig$: Observable<DlcInputImageConfig> = <
    Observable<DlcInputImageConfig>
  >this.select(state => {
    if (state.uuid && state.uploadConfig && state.firestorePath) {
      return <DlcInputImageConfig>{
        filenameWithoutExtension: state.uuid,
        imagePath: state.firestoreDoc?.imagePath || '',
        uploadConfig: {
          ...state.uploadConfig,
          firestoreDoc: {
            docProperty: 'imagePath',
            firestoreDocPath: state.firestorePath
          }
        }
      };
    }

    return null;
  }).pipe(
    filter((config: DlcInputImageConfig | null) => config !== null),
    distinctUntilKeyChanged('imagePath')
    // take(1)
  );

  // *********** Effects *********** //
  // *********** Effects *********** //
  // *********** Effects *********** //
  readonly updateNoteTitle = this.effect((title$: Observable<string>) => {
    return title$.pipe(
      ngPatWithLastValueFrom(this.state$),
      tap({
        next: ([newTitle, state]: [string, DlcNoteStore]) => {
          const isDirty =
            state.isDirty || newTitle !== state.firestoreDoc?.title;

          this._updateNoteTitle({
            isDirty,
            saveBtnState: isDirty
              ? DlcSaveBtnState.ACTIVE
              : DlcSaveBtnState.DISABLED
          });

          this.saveDataProcessQueue.upsertOne(
            addProcessQueueIdNoteTitle({
              action: DlcNoteSaveAction.SAVE_NOTE_TITLE,
              firestoreDoc: state.firestoreDoc,
              id: 0, // overridden by id function
              title: newTitle
            })
          );

          this.autoSaveTimer$.next(true);
        }
      })
    );
  });

  readonly addUserNoteVersion = this.effect((note$: Observable<string>) => {
    return note$.pipe(
      ngPatWithLastValueFrom(this.state$),
      tap({
        next: ([newNote, state]: [string, DlcNoteStore]) => {
          const isDirty = state.isDirty || newNote !== state.userNote;

          this._updateUserNote({
            isDirty,
            saveBtnState: isDirty
              ? DlcSaveBtnState.ACTIVE
              : DlcSaveBtnState.DISABLED,
            userNote: newNote
          });

          this.saveDataProcessQueue.upsertOne(
            addProcessQueueIdNoteVersion({
              action: DlcNoteSaveAction.SAVE_USER_NOTE_VERSION,
              firestoreDoc: state.firestoreDoc,
              id: 0, // overridden by id function
              userNoteVersion: newNote
            })
          );

          this.autoSaveTimer$.next(true);
        }
      })
    );
  });

  readonly addTranscribedNoteVersion = this.effect(
    (note$: Observable<string>) => {
      return note$.pipe(
        ngPatWithLastValueFrom(this.state$),
        tap({
          next: ([newNote, state]: [string, DlcNoteStore]) => {
            const isDirty = state.isDirty || newNote !== state.userNote;

            this._updateTranscribedNote({
              isDirty,
              saveBtnState: isDirty
                ? DlcSaveBtnState.ACTIVE
                : DlcSaveBtnState.DISABLED,
              transcribedNote: newNote
            });

            this.saveDataProcessQueue.upsertOne(
              addProcessQueueIdTranscriptionVersion({
                action: DlcNoteSaveAction.SAVE_TRANSCRIPTION_NOTE_VERSION,
                firestoreDoc: state.firestoreDoc,
                id: 0, // overridden by id function
                transcriptionNoteVersion: newNote
              })
            );

            this.autoSaveTimer$.next(true);
          }
        })
      );
    }
  );

  readonly updateFirestoreDocPromise = (
    update: RecursivePartial<GigaNoteDoc>
  ) => {
    return new Promise((resolve, reject) => {
      of(update)
        .pipe(
          ngPatWithLastValueFrom(this.selectFirestoreDoc$),
          switchMap(
            ([update, storeDoc]: [
              RecursivePartial<GigaNoteDoc>,
              GigaNoteDoc
            ]) => {
              const payload: GigaNoteDoc = merge(storeDoc, update);

              return this.firestore
                .merge$<GigaNoteDoc>(payload.firestorePath, payload)
                .pipe(map(() => payload));
            }
          )
        )
        .subscribe({
          error: (value: any) => {
            reject(value);
          },
          next: (value: GigaNoteDoc) => {
            this.patchFirestoreDoc(value);
            resolve(value);
          }
        });
    });
  };

  readonly updateUploadImagePath = this.effect<void>(
    (config$: Observable<void>) => {
      return config$.pipe(
        switchMap(() =>
          this.selectFirestoreDoc$.pipe(
            take(1),
            map((doc: GigaNoteDoc) => {
              return {
                imagePath: doc.imagePath
              };
            })
          )
        )
      );
    }
  );

  readonly doTranscribeImage = this.effect(
    (trigger$: Observable<RecursivePartial<GigaNoteDoc>>) => {
      return trigger$.pipe(
        ngPatWithLastValueFrom(this.selectFirestoreDoc$),
        tap(
          async ([partialDoc, doc]: [
            RecursivePartial<GigaNoteDoc>,
            GigaNoteDoc
          ]) => {
            this.patchFirestoreDoc(partialDoc);

            // const firebaseFunction = this.firestore.httpsCallable(this.noteFirestore.httpsCallableFnNameTranscribeImage);
            const firebaseFunction = this.firestore.httpsCallable(
              EC_HTTPS_CALLABLE.TRANSCRIBE_IMAGE
            );

            if (doc) {
              await firebaseFunction({
                ...doc,
                imageState: GigaNoteImageState.UPLOADED
              });
            }
          }
        )
      );
    }
  );

  readonly upsertTranscribedVersionPromise = (
    update: string
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      of(update)
        .pipe(ngPatWithLastValueFrom(this.selectFirestoreDoc$))
        .subscribe({
          error: (value: any) => {
            reject(value);
          },
          next: async ([text, storeDoc]: [string, GigaNoteDoc]) => {
            this.patchFirestoreDoc({
              transcribeState: GigaNoteTranscriptionState.IN_PROGRESS
            });

            const payload: GigaNoteTranscribeNoteVersionUpdate = {
              doc: storeDoc,
              text: text
            };

            const firebaseCallableFunction = this.firestore.httpsCallable(
              EC_HTTPS_CALLABLE.ADD_TRANSCRIBED_IMAGE_TEXT_VERSION
            );

            await firebaseCallableFunction(payload);
            resolve('success');
          }
        });
    });
  };

  mergeWithFirestoreDoc(
    update: (action: DlcNoteProcessQueue) => RecursivePartial<GigaNoteDoc>
  ) {
    return switchMap((action: DlcNoteProcessQueue) => {
      return this.selectFirestoreDoc$.pipe(
        take(1),
        map((doc: GigaNoteDoc) => {
          return [
            action,
            {
              ...doc,
              ...update(action)
            } as GigaNoteDoc
          ] as [DlcNoteProcessQueue, GigaNoteDoc];
        })
      );
    });
  }

  // *********** Actions *********** //
  // *********** Actions *********** //
  // *********** Actions *********** //
  save() {
    this.saveDataProcessQueue.next();
  }
}
