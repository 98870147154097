import {Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {isDefinedOperator} from '@gigasoftware/shared/rxjs';
import {
  addUserAndRoleToEntity,
  CollaborativeEntity,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT,
  EC_QUIZ_ROLES,
  firestoreCollectionByEntityType,
  getClassroomByID,
  getStudyGroupByID,
  selectHasActiveSubscription
} from '@gigasoftware/shared/store';
import {selectNgPatLoggedInUID} from '@gigasoftware/shared/store';
import {ComponentStore} from '@ngrx/component-store';
import {select, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  map,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';

import {
  JOIN_CLASS_ERROR,
  JoinEntityComponentState,
  JoinEntityTypeDisplay,
  JoinGroupForm
} from './join-group-with-code-dialog.model';

@Injectable()
export class JoinGroupWithCodeDialogService extends ComponentStore<JoinEntityComponentState> {
  joinRoles$: Observable<JoinEntityTypeDisplay[]>;

  readonly selectError$: Observable<JoinEntityComponentState> = this.select(
    state => state
  ).pipe(distinctUntilKeyChanged('hasError'));

  readonly selectInProgress$: Observable<boolean> = this.select(
    state => state.inProgress
  ).pipe(distinctUntilChanged());

  readonly selectEntityType$: Observable<number> = this.select(
    state => state.entityType
  ).pipe(distinctUntilChanged());
  readonly setEntityType = this.updater((state, entityType: number) => ({
    ...state,
    entityType: entityType
  }));
  readonly setInProgress = this.updater((state, inProgress: boolean) => ({
    ...state,
    inProgress
  }));
  readonly setError = this.updater(
    (state, error: JOIN_CLASS_ERROR | string) => {
      let message: string;
      if (error === JOIN_CLASS_ERROR.IS_JOINED) {
        message = 'Already Joined';
      } else if (error === JOIN_CLASS_ERROR.NOT_FOUND) {
        message = 'Not Found';
      } else {
        message = error;
      }

      return {
        ...state,
        error: message,
        hasError: true
      };
    }
  );
  readonly clearError = this.updater(state => ({
    ...state,
    error: '',
    hasError: false
  }));

  constructor(private store: Store, private _firestore: NgPatFirestoreService) {
    super(<JoinEntityComponentState>{
      entityType: DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id,
      error: '',
      hasError: false,
      inProgress: false
    });

    this.joinRoles$ = this.store.pipe(select(selectHasActiveSubscription)).pipe(
      map(hasSubscription => {
        const entityTypes: JoinEntityTypeDisplay[] = [];

        // if (hasSubscription) {
        //   entityTypes.push({
        //     role: EC_QUIZ_ROLES.Student,
        //     displayValue: 'Student'
        //   });
        // }
        // TODO: subscription check
        entityTypes.push({
          displayValue: 'Student',
          role: EC_QUIZ_ROLES.Student
        });

        return entityTypes;
      })
    );
  }

  joinGroup(f: JoinGroupForm): Observable<CollaborativeEntity | null> {
    this.setInProgress(true);
    this.clearError();

    return this.selectEntityType$.pipe(
      take(1),
      withLatestFrom(
        this.store.pipe(
          select(selectNgPatLoggedInUID),
          isDefinedOperator<unknown, string>()
        )
      ),
      switchMap(([entityType, uid]) => {
        const firestorePath: string =
          firestoreCollectionByEntityType(entityType);

        let fieldPath = '';

        if (f.role === EC_QUIZ_ROLES.Student) {
          fieldPath = 'studentCode';
        }

        if (f.role === EC_QUIZ_ROLES.Teacher) {
          fieldPath = 'teacherCode';
        }

        if (f.role === EC_QUIZ_ROLES.Mentor) {
          fieldPath = 'mentorCode';
        }

        if (!fieldPath.length) {
          return of(null);
        }

        return this._firestore
          .queryCollection<CollaborativeEntity>(
            firestorePath,
            fieldPath,
            '==',
            f.joinCode
          )
          .pipe(
            switchMap((entities: CollaborativeEntity[]) => {
              const id: string | undefined =
                entities && entities.length ? entities[0].id : undefined;

              if (id) {
                let selectEntityByID$: Observable<
                  CollaborativeEntity | undefined
                >;

                if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id) {
                  selectEntityByID$ = this.store.pipe(
                    select(getClassroomByID(id))
                  );
                } else {
                  selectEntityByID$ = this.store.pipe(
                    select(getStudyGroupByID(id))
                  );
                }

                return selectEntityByID$.pipe(
                  map((entity: CollaborativeEntity | null | undefined) => {
                    if (entity && entity.id == id) {
                      this.setError(JOIN_CLASS_ERROR.IS_JOINED);
                      return null;
                    }

                    if (uid && entities && entities.length) {
                      // FOUND
                      return addUserAndRoleToEntity(
                        <CollaborativeEntity>entities[0],
                        uid,
                        f.role
                      );
                    }

                    return null;
                  })
                );
              }

              this.setError(JOIN_CLASS_ERROR.NOT_FOUND);
              return of(null);
            }),
            catchError((error: string) => {
              this.setError(error);
              return of(null);
            })
          );
      })
    );
  }
}
