<div class="flex flex-row justify-center items-center">
  <div class="aspect-square block w-full max-w-sm overflow-hidden">
    <dlc-input-image
      [config]="inputImageConfig()"
      (deleteImage)="onDeleteImage()"></dlc-input-image>
  </div>
</div>

<dlc-label-container
  *ngIf="showID"
  class="grow mt-8">
  <span dlcLabel>Image ID Path</span>
  <span>
    {{ inputImageConfig()?.imagePath }}
  </span>
</dlc-label-container>

<form [formGroup]="editQuizFormGroup" class="mt-6">
  <mat-form-field class="mb-2 w-full" floatLabel="always" >
    <mat-label>Quiz Name</mat-label>
    <input matInput formControlName="name" />
    <button
      mat-mini-fab
      matSuffix
      class="mr-2"
      (click)="clearNameForm()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field class="mb-2 w-full" floatLabel="always" >
    <mat-label>Quiz Description</mat-label>
    <input matInput formControlName="description" />
    <button
      mat-mini-fab
      matSuffix
      class="mr-2"
      (click)="clearDescriptionForm()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field class="mb-2 w-full">
    <mat-label>Grade Level</mat-label>
    <mat-select formControlName="gradeLevel">
      @for (gradeLevel of gradeLevels; track gradeLevel) {
        <mat-option [value]="gradeLevel">{{ gradeLevel.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field
    class="w-full"
    floatLabel="always">
    <mat-label>Subjects</mat-label>
    <mat-chip-grid
      #chipGrid
      aria-label="Fruit selection">
      @for (
          subject of algoliaSubjectAutocomplete.selectedViewValues;
        track subject
        ) {
        <mat-chip-row (removed)="algoliaAutoCompleteRemove(subject)">
          {{ subject }}
          <button
            matChipRemove
            [attr.aria-label]="'remove ' + subject">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-grid>
    <input
      placeholder="math, science, chemistry, history"
      #subjectInput
      formControlName="subjects"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="algoliaAutoCompleteAdd($event)" />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="algoliaAutoCompleteSelect($event)">
      @for (fruit of filteredSubjects | async; track fruit) {
        <mat-option [value]="fruit">{{ fruit.name }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <!-- END AUTOCOMPLETE SUBJECTS -->

  <div class="mb-6">
    <mat-checkbox formControlName="aggregateFalseAnswers"
    ><span class="font-medium">( Recommended ) Aggregate false answers.</span>
      <span>
      Use answers from all questions to create wrong answers for each question.
      Only use this if you have a lot of questions. Only create correct answers
      for each multiple choice question.
    </span></mat-checkbox
    >
  </div>
</form>

<!--<dlc-mat-inline-edit-->
<!--  class="mb-6 mt-6 w-full"-->
<!--  label="Quiz Name"-->
<!--  [value]="quiz()?.name"-->
<!--  (valueChange)="onEditQuizName($event)"-->
<!--  [center]="true"></dlc-mat-inline-edit>-->

<!--<dlc-mat-inline-edit-->
<!--  class="mb-6 w-full"-->
<!--  label="Quiz Description ( optional )"-->
<!--  [value]="quiz()?.description || ''"-->
<!--  (valueChange)="onEditQuizDescription($event)"></dlc-mat-inline-edit>-->

<!-- GRADE LEVELS -->

<!-- END GRADE LEVELS -->

<!-- AUTOCOMPLETE SUBJECTS -->


<dlc-label-container
  *ngIf="showID"
  class="grow mt-1">
  <span dlcLabel>Quiz ID</span>
  <span>
    {{ quiz()?.id }}
  </span>
</dlc-label-container>

@for (question of questions(); track question.id) {
  <ec-question-summary
    class="mb-6 w-full"
    (doEditQuestion)="doEditQuestion.emit($event)"
    [showID]="showID"
    [question]="question"></ec-question-summary>
}
