<h3 mat-dialog-title>Copy</h3>

<mat-dialog-content *ngIf="isCopying()">
  <div class="mb-8">{{ copyingQuiz() }}</div>
  <div class="flex flex-row justify-center items-center mb-8">
    <mat-spinner
      color="primary"
      mode="indeterminate"></mat-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="!isCopying()">
  <div>{{ data.quiz.name }} to:</div>
  <div class="example-container mat-elevation-z8">
    <table
      [dataSource]="copyToOptions"
      mat-table>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <!--        <th mat-header-cell *matHeaderCellDef>Name</th>-->
        <td
          *matCellDef="let element"
          mat-cell>
          {{ element.name }}
        </td>
      </ng-container>

      <!-- Type entityTypeName -->
      <ng-container matColumnDef="entityTypeName">
        <!--        <th mat-header-cell *matHeaderCellDef>Type</th>-->
        <td
          *matCellDef="let element"
          mat-cell>
          {{ element.entityTypeName }}
        </td>
      </ng-container>

      <!-- Type copyAction -->
      <ng-container matColumnDef="copyAction">
        <!--        <th mat-header-cell *matHeaderCellDef></th>-->
        <td
          *matCellDef="let element"
          mat-cell>
          <div class="my-4">
            <button mat-flat-button  (click)="$event.stopImmediatePropagation(); onCopyQuizAndAssets(element)">Copy</button>
          </div>
        </td>
      </ng-container>

      <!--      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>-->
      <tr
        *matRowDef="let row; columns: displayedColumns"
        mat-row></tr>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    (click)="dialogRef.close(null)"
    dlc-rounded-text-icon-button>
    Cancel
  </button>
</mat-dialog-actions>
