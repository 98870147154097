import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import {ReferenceLink} from '@gigasoftware/shared/ui-design-library';
import {WindowService} from '@gigasoftware/shared/utils';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-reference-link'
    },
    imports: [CommonModule],
    selector: 'ec-reference-link',
    styleUrls: ['./ec-reference-link.component.scss'],
    templateUrl: './ec-reference-link.component.html'
})
export class EcReferenceLinkComponent {
  @Input() links: ReferenceLink[] = [];

  constructor(private win: WindowService) {}

  openLink(event: Event, referenceLink: ReferenceLink) {
    event.preventDefault();
    event.stopPropagation();
    this.win.open(referenceLink.url);
  }
}
