import {BASE_COLLECTION_NAME} from '@gigasoftware/shared/models';

export function firestoreUserCourseDoc(uid: string, courseID: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.COURSES}/${courseID}`;
}

export function firestoreCourseCollection() {
  return `${BASE_COLLECTION_NAME.COURSES}`;
}
export function firestoreCourseDoc(courseID: string): string {
  return `${BASE_COLLECTION_NAME.COURSES}/${courseID}`;
}
export function firestoreCourseChatTopicsCollection(courseID: string): string {
  return `${BASE_COLLECTION_NAME.COURSES}/${courseID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}`;
}
export function firestoreCourseChatTopicsDoc(
  courseID: string,
  chatTopicID: string
): string {
  return `${BASE_COLLECTION_NAME.COURSES}/${courseID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}/${chatTopicID}`;
}
