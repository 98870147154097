<div class="flex flex-row justify-center items-center">
  <div class="aspect-square block w-full max-w-sm overflow-hidden">
    <dlc-input-image
      [config]="inputImageConfig()"
      (deleteImage)="onDeleteImage()"></dlc-input-image>
  </div>
</div>

<form
  [formGroup]="projectFormGroup"
  class="relative flex flex-col justify-start items-stretch mt-8">
  <mat-form-field class="w-full">
    <mat-label>Name</mat-label>
    <input
      matInput
      formControlName="name" />
    <mat-error *ngIf="projectFormGroup.controls['name'].hasError('required')">
      {{ getErrorMessage(projectFormGroup.controls["name"]) }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="mt-4 w-full">
    <mat-label>Description</mat-label>
    <input
      matInput
      formControlName="description" />
  </mat-form-field>
</form>
<div class="flex flex-row justify-start items-start mt-8">
  <button
    dlc-rounded-text-icon-button
    color="primary"
    class="mr-4"
    (click)="resetNameForm()"
    [disabled]="!projectFormGroup.dirty">
    <mat-icon>undo</mat-icon>
    Undo
  </button>

  <button
    dlc-rounded-text-icon-button
    color="primary"
    [disabled]="!projectFormGroup.dirty || projectFormGroup.invalid"
    (click)="saveForm()">
    <mat-icon>check</mat-icon>
    Save
  </button>
</div>
