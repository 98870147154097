import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Project, StudyGroup} from '@gigasoftware/shared/store';

import {EcProjectListItemComponent} from './ec-project-list-item/ec-project-list-item.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-project-list'
    },
    imports: [CommonModule, EcProjectListItemComponent],
    selector: 'ec-project-list',
    styleUrl: './ec-project-list.component.scss',
    templateUrl: './ec-project-list.component.html'
})
export class EcProjectListComponent {
  @Output() doDeleteItem: EventEmitter<Project> = new EventEmitter<Project>();

  @Output() doEditItem: EventEmitter<Project> = new EventEmitter<Project>();

  @Output() doLeaveItem: EventEmitter<Project> = new EventEmitter<Project>();

  @Output() doSelectItem: EventEmitter<Project> = new EventEmitter<Project>();

  @Input() list: Project[] = [];
}
