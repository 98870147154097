import {Clipboard} from '@angular/cdk/clipboard';
import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltip, MatTooltipModule} from '@angular/material/tooltip';
import {Route} from '@angular/router';
import {
  DlcHeaderBarComponent,
  DlcMobilePageContentComponent,
  fadeInAnimation
} from '@gigasoftware/shared/ui-design-library';

@Component({
    animations: [fadeInAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[@fadeInAnimation]': '',
        class: 'ec-support-small-page dlc-global-mobile-page'
    },
    imports: [
        DlcHeaderBarComponent,
        DlcMobilePageContentComponent,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule
    ],
    selector: 'ec-support-small-page',
    styleUrls: ['./ec-support-small-page.component.scss'],
    templateUrl: './ec-support-small-page.component.html'
})
export class EcSupportSmallPageComponent {
  @ViewChild('copyTooltip', {static: true}) copyTooltip!: MatTooltip;

  email = `support@gigasoftware.io`;

  constructor(private clipboard: Clipboard) {}

  copyEmail() {
    this.clipboard.copy(this.email);

    if (this.copyTooltip) {
      this.copyTooltip.show();
    }
  }
}

export const EC_SUPPORT_ROUTES: Route[] = [
  {component: EcSupportSmallPageComponent, path: '', pathMatch: 'full'}
];
