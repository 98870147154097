import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';

import {DlcRoundedTextButtonComponent} from '../../../button/dlc-rounded-text-button/dlc-rounded-text-button.component';
import {DlcImageCropperComponent} from '../image-cropper.component';
import {ImageCroppedEvent} from '../image-cropper/index';

export interface DialogData {
  imageFile: File | null;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-image-cropper-dialog'
    },
    imports: [
        CommonModule,
        DlcImageCropperComponent,
        MatDialogModule,
        DlcRoundedTextButtonComponent
    ],
    selector: 'dlc-image-cropper-dialog',
    styleUrls: ['./image-cropper-dialog.component.scss'],
    templateUrl: './image-cropper-dialog.component.html'
})
export class ImageCropperDialogComponent {
  imageCroppedEvent: WritableSignal<ImageCroppedEvent | null> = signal(null);

  constructor(
    public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.dialogRef.close(this.imageCroppedEvent());
  }
}
