import {Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@gigasoftware/shared/firebase';
import {select, Store} from '@ngrx/store';

import {NgPatAccountState} from '../+account/account.model';
import {NgPatServiceConnector} from '../+websocket-registry/ng-pat-service-connector';
import {NgPatFirebaseConnectionService} from '../+websocket-registry/websocket-registry.models';
import {concatMemberUIDEntityID} from '../common.selectors';
import {MemberQuery} from './member.query';
import {Member} from './members.model';
import {membersFeatureKey} from './members.reducer';

@Injectable({
  providedIn: 'root'
})
export class MembersService implements NgPatFirebaseConnectionService {
  // private _queryService: FirestoreCollectionQuery<Member>;
  private _cache: {[uid: string]: MemberQuery} = {};
  connection: NgPatServiceConnector;
  connectionKey = membersFeatureKey;

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {
    this.connection = new NgPatServiceConnector(this, this.store);
  }

  addUIDs(m: Member[]) {
    if (m && m.length) {
      for (let i = 0; i < m.length; i++) {
        if (!this._cache[m[i].uid]) {
          this._cache[m[i].uid] = new MemberQuery(
            m[i],
            this.customFirestoreService,
            this.store
          );
        } else {
          this._cache[m[i].uid].addEntity(m[i]);
        }
      }
    }
  }

  ngPatOnInit() {
    this.store
      .pipe(select(concatMemberUIDEntityID))
      .subscribe((r: Member[]) => {
        this.addUIDs(r);
      });
  }

  onConnect(user: NgPatAccountState) {
    for (const key in this._cache) {
      this._cache[key].onConnect();
    }
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    // this._queryService.onDisconnect();

    for (const key in this._cache) {
      this._cache[key].onDisconnect();
    }
  }
}
