import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';
import {SecondsToMillisecondsPipe} from '@gigasoftware/shared/date';
import {
  NgPatStripeProductPrice,
  NgPatStripeSubscriptionUIdisplay,
  NgPatStripeSubscriptionUIItem,
  selectNagPatHasActiveSubscription,
  selectNgPatActiveStripeSubscriptionsUIDisplay,
  selectNgPatStripePriceByID
} from '@gigasoftware/shared/store';
import {WINDOW} from '@gigasoftware/shared/utils';
import {LetDirective} from '@ngrx/component';
import {Store} from '@ngrx/store';
import {lastValueFrom, Observable} from 'rxjs';

import {DlcRoundedTextButtonComponent} from '../../../button/dlc-rounded-text-button/dlc-rounded-text-button.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ec-subscriptions'
    },
    imports: [
        CommonModule,
        LetDirective,
        SecondsToMillisecondsPipe,
        DlcRoundedTextButtonComponent
    ],
    selector: 'dlc-stripe-subscriptions',
    styleUrls: ['./dlc-stripe-subscriptions.component.scss'],
    templateUrl: './dlc-stripe-subscriptions.component.html'
})
export class DlcStripeSubscriptionsComponent {
  hasActiveSubscription$: Observable<boolean>;
  @Input() showCancelBtn = true;

  @Output() showCancelProgress: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() showUpdateBtn = true;
  subscriptions$: Observable<NgPatStripeSubscriptionUIdisplay | null>;

  constructor(
    private store: Store,
    private router: Router,
    @Inject(WINDOW) public win: Window
  ) {
    this.subscriptions$ = this.store.select(
      selectNgPatActiveStripeSubscriptionsUIDisplay
    );

    this.hasActiveSubscription$ = this.store.select(
      selectNagPatHasActiveSubscription
    );
  }

  async onCancelSubscription(
    subscriptionUIItem: NgPatStripeSubscriptionUIItem
  ) {
    this.showCancelProgress.next(true);

    // console.log(subscriptionUIItem);

    // Select price based on id
    const price: NgPatStripeProductPrice | undefined = await lastValueFrom(
      this.store.select(selectNgPatStripePriceByID(subscriptionUIItem.itemId))
    );

    // console.log(price);
  }

  parsePrice(p: number) {
    return p / 100;
  }

  updateSubscription() {
    this.router.navigate(['subscribe']);
  }
}
