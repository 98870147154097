import {Injectable} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Unsubscribe} from '@firebase/firestore';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {combineLatest} from 'rxjs';
import {concatLatestFrom} from '@ngrx/operators';
import {
  concatMap,
  distinctUntilChanged,
  filter,
  switchMap,
  tap
} from 'rxjs/operators';
import {AuthFirestoreSyncService} from './auth-firestore-sync.service';
import {AuthStorageSync} from './auth-storage-sync.service';
import {
  cancelAuth,
  clearAuthState,
  clearAuthStorage,
  initAuthAction,
  setOtlid,
  updateAuthAction
} from './login.actions';
import {AuthLoginAction, OTLID_QUERY_PARAM} from './login.model';
import {selectOtlid} from './login.selectors';

@Injectable()
export class LoginEffects implements OnInitEffects {
  initAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initAuthAction),
      switchMap(() => {
        /**
         * otlid may be null if the user has not
         */
        return combineLatest([
          // Get auth state from storage, but only looking for otlid from storage
          this.authStorage.getAuthOtlidFromStorage$(),
          // Get otlid from query params
          this.route.queryParamMap.pipe(
            tap((params: ParamMap) => {
              if (params.has(OTLID_QUERY_PARAM)) {
                this.authStorage.putAuthOTLID(
                  params.get(OTLID_QUERY_PARAM) as string
                );
              }
            })
          )
        ]).pipe(
          concatMap(
            ([otlidFromStore, params]: [string | null | null, ParamMap]) => {
              const actions: Action[] = [];
              let otlid: string | null = null;

              // if otlid is in query params, use that
              if (params.has(OTLID_QUERY_PARAM)) {
                actions.push(
                  setOtlid({otlid: params.get(OTLID_QUERY_PARAM) as string})
                );
                otlid = params.get(OTLID_QUERY_PARAM);
              }
              // else if otlid is in storage, use that
              else if (otlidFromStore && otlidFromStore.length > 0) {
                actions.push(setOtlid({otlid: otlidFromStore}));
                otlid = otlidFromStore;
              } else {
                // if no otlid, then error
                // actions.push(updateAuthAction({action: AuthLoginAction.ERROR}));
                actions.push(updateAuthAction({action: AuthLoginAction.ERROR}));
              }

              // if otlid is NOT in query params, add it
              if (!params.has(OTLID_QUERY_PARAM) && otlid && otlid.length > 0) {
                // Add to query params
                this.router.navigate([], {
                  queryParams: {[OTLID_QUERY_PARAM]: otlid}
                });
              }

              return actions;
            }
          )
        );
      })
    );
  });

  initOnSnapShot$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(initAuthAction),
        switchMap(() => {
          return this.store.select(selectOtlid).pipe(
            filter((otlid: string | null) => {
              return otlid !== null && otlid.length > 0;
            }),
            distinctUntilChanged(),
            tap((otlid: string | null) => {
              if (otlid && otlid.length > 0) {
                this.firestoreSync.initFirestoreSync(otlid);
              }
            })
          );
        })
      );
    },
    {dispatch: false}
  );

  clearAuthState$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(clearAuthState, clearAuthStorage),
        tap(() => {
          this.authStorage.clear();
        })
      );
    },
    {dispatch: false}
  );

  watchForAuthStateChanges$ = createEffect(
    () => {
      return this.store.select(selectOtlid).pipe(
        filter((otlid: string | null) => otlid !== null),
        distinctUntilChanged(),
        tap((otlid: string | null) => {
          if (otlid) {
            this.firestoreSync.watchForAuthStateChanges(otlid);
          }
        })
      );
    },
    {dispatch: false}
  );

  cancelAuth$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(cancelAuth),
        concatLatestFrom(() => this.store.select(selectOtlid)),
        tap(([, otlid]: [Action, string | null]) => {
          this.firestoreSync.cancelLogin(otlid);
        })
      );
    },
    {dispatch: false}
  );

  updateAuthStatus$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateAuthAction),
        concatLatestFrom(() => this.store.select(selectOtlid)),
        tap(([{action}, otlid]: [{action: AuthLoginAction}, string | null]) => {
          if (otlid) {
            this.firestoreSync.saveStatusToFiresbase(otlid, action);
          }
        })
      );
    },
    {dispatch: false}
  );

  docSnapshotSubscription: Unsubscribe | null = null;

  constructor(
    private firestoreSync: AuthFirestoreSyncService,
    private authStorage: AuthStorageSync,
    private actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {}

  ngrxOnInitEffects(): Action {
    return initAuthAction();
  }
}
