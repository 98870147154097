import {
  ChangeDetectionStrategy,
  Component,
  input,
  InputSignal
} from '@angular/core';
import {Route} from '@angular/router';
import {fadeInAnimation} from '@gigasoftware/shared/ui-design-library';

@Component({
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[@fadeInAnimation]': '',
    '[class.is-light-theme]': 'isLightTheme() === true',
    class: 'ec-privacy-policy dlc-global-mobile-page'
  },
  selector: 'ec-privacy-policy',
  standalone: true,
  styleUrls: ['./ec-privacy-policy.component.scss'],
  templateUrl: './ec-privacy-policy.component.html'
})
export class EcPrivacyPolicyComponent {
  isLightTheme: InputSignal<boolean> = input(false);

  lastUpdated = '12 February 2024';
}

export const EC_POLICY_ROUTES: Route[] = [
  {component: EcPrivacyPolicyComponent, path: '', pathMatch: 'full'}
];
