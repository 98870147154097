<form
  [formGroup]="nameFormGroup"
  class="flex flex-col justify-start items-start w-full">
  <mat-form-field class="w-full mb-4">
    <mat-label>{{ entityTypeName }} Name</mat-label>
    <input
      autocomplete="off"
      matInput
      formControlName="name" />
    <mat-error *ngIf="nameFormGroup.controls['name'].hasError('required')"
      >Required
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label>{{ entityTypeName }} Description</mat-label>
    <input
      autocomplete="off"
      matInput
      formControlName="description" />
  </mat-form-field>
</form>

<button
  (click)="onSubmit()"
  [disabled]="nameFormGroup.invalid || entityTypeForm.invalid"
  color="primary"
  dlc-rounded-text-button>
  CREATE
</button>
