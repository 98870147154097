import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation
} from '@angular/core';

import {coerceThemePalette} from '../button.fns';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[attr.disabled]': `disabled() === true ? '' : null`,
        '[class.dlc-rounded-text-button-accent]': 'color() === "accent"',
        '[class.dlc-rounded-text-button-none]': 'color() === "none" || color() === undefined',
        '[class.dlc-rounded-text-button-primary]': 'color() === "primary"',
        '[class.dlc-rounded-text-button-warn]': 'color() === "warn"',
        class: 'dlc-rounded-text-button  dlc-rounded-button'
    },
    imports: [CommonModule],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[dlc-rounded-text-button], button[dlcRoundedTextButton]',
    styleUrls: ['./dlc-rounded-text-button.component.scss'],
    templateUrl: './dlc-rounded-text-button.component.html'
})
export class DlcRoundedTextButtonComponent {
  color = input('none', {
    transform: coerceThemePalette
  });
  disabled = input(false, {transform: coerceBooleanProperty});
}
