import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule
} from '@angular/material/dialog';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogModule, MatButtonModule],
    // 'plat-failed-to-checkout'
    selector: 'ui-failed-to-checkout',
    styleUrls: ['./plat-failed-to-purchase.component.scss'],
    templateUrl: './plat-failed-to-purchase.component.html'
})
export class PlatFailedToPurchaseComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PlatFailedToPurchaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}
}
