import {
  FirestoreCollectionQueryFactoryConfig,
  NgPatFirestoreCollectionQuery
} from '@gigasoftware/shared/firebase';
import {
  AddedAndDeletedMemoizedSelector,
  NgPatAddedAndDeletedEntities
} from '@gigasoftware/shared/models';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';

import {NgPatAccountState} from '../+account/account.model';

interface QueryCacheItem<QueriedSubCollectionEntity, ParentEntity> {
  item: ParentEntity;
  query: NgPatFirestoreCollectionQuery<QueriedSubCollectionEntity>;
}

export class QueryEngineCache<QueriedSubCollectionEntity, ParentEntity> {
  private _sub: Subscription = Subscription.EMPTY;
  private _cache: {
    [key: string]: QueryCacheItem<QueriedSubCollectionEntity, ParentEntity>;
  } = {};

  constructor(
    private _config: FirestoreCollectionQueryFactoryConfig<QueriedSubCollectionEntity>,
    private store: Store,
    private selectNgPatAddedAndDeletedEntities: AddedAndDeletedMemoizedSelector<ParentEntity>,
    private _pathGeneratorFn: (...args: any[]) => string,
    private _id = 'id'
  ) {}

  onConnect(user: NgPatAccountState | null = null) {
    const that = this;
    this._sub.unsubscribe();

    this._sub = this.store
      .pipe(select(that.selectNgPatAddedAndDeletedEntities))
      .subscribe((result: NgPatAddedAndDeletedEntities<ParentEntity>) => {
        // Added
        for (let i = 0; i < result.addedEntities.length; i++) {
          const item: ParentEntity = result.addedEntities[i];

          if (!that._cache[(<any>item)[this._id]]) {
            that._cache[(<any>item)[this._id]] = {
              item,
              query: that._config.createFirestoreCollectionQuery()
            };
          }

          let _path = '';
          if (user && user.uid) {
            _path = this._pathGeneratorFn(item, user.uid);
          } else {
            _path = this._pathGeneratorFn(item);
          }

          that._cache[(<any>item)[this._id]].query.onConnect(_path);
        }

        // Deleted
        for (let i = 0; i < result.deletedIds.length; i++) {
          const id = result.deletedIds[i];
          if (that._cache[id]) {
            that._cache[id].query.onDisconnect();
            delete that._cache[id];
          }
        }
      });
  }

  onDisconnect() {
    this._sub.unsubscribe();

    for (const prop in this._cache) {
      if (this._cache[prop]) {
        this._cache[prop].query.onDisconnect();
      }
    }
  }

  deleteMany(ids: string[]) {
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      if (this._cache[id]) {
        this._cache[id].query.onDisconnect();
        delete this._cache[id];
      }
    }
  }
}
