<h2
  *ngIf="data.id === dialogID.NETWORK_OFFLINE"
  mat-dialog-title>
  Offline
</h2>
<mat-dialog-content *ngIf="data.id === dialogID.NETWORK_OFFLINE">
  Your network connection is offline.
</mat-dialog-content>

<h2
  *ngIf="data.id === dialogID.USER_IDLE"
  mat-dialog-title>
  Idle
</h2>
<mat-dialog-content *ngIf="data.id === dialogID.USER_IDLE"
  >Disconnected from server while you are idle.</mat-dialog-content
>

<mat-dialog-actions align="end">
  <button
    dlc-rounded-text-button
    color="primary"
    (click)="onClose()"
    cdkFocusInitial>
    OK
  </button>
</mat-dialog-actions>
