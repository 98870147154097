<div class="mx-auto max-w-4xl text-center">
  <h2 class="text-base font-semibold leading-7 text-[--dlc-color-primary]">
    Pricing
  </h2>
  <p
    class="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl"
    [innerHTML]="headerTitle"></p>
</div>
<p
  class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300"
  [innerHTML]="headerSubtitle"></p>
