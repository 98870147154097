import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {UiNotification} from './notification.model';
import * as NotificationActions from './notification.actions';
import {ngPatLogout} from '../+account/account.actions';

export const notificationFeatureKey = 'ngPat_notfications';

export type NotificationState = EntityState<UiNotification>;

export interface PartialNotificationState {
  readonly [notificationFeatureKey]: NotificationState;
}

export const notificationAdapter: EntityAdapter<UiNotification> =
  createEntityAdapter<UiNotification>();

export const initialNotificationState: NotificationState =
  notificationAdapter.getInitialState({
    // additional entity state properties
    // selectedNotificationID: null
  });

export const notificationReducer = createReducer(
  initialNotificationState,
  on(NotificationActions.addNotification, (state, action) =>
    notificationAdapter.addOne(action.notification, state)
  ),
  on(NotificationActions.setNotification, (state, action) =>
    notificationAdapter.setOne(action.notification, state)
  ),
  on(NotificationActions.addNotifications, (state, action) =>
    notificationAdapter.addMany(action.notifications, state)
  ),
  on(NotificationActions.updateNotification, (state, action) =>
    notificationAdapter.updateOne(action.notification, state)
  ),
  on(NotificationActions.updateNotifications, (state, action) =>
    notificationAdapter.updateMany(action.notifications, state)
  ),
  on(NotificationActions.upsertNotification, (state, action) =>
    notificationAdapter.upsertOne(action.notification, state)
  ),
  on(NotificationActions.upsertNotifications, (state, action) =>
    notificationAdapter.upsertMany(action.notifications, state)
  ),
  on(NotificationActions.mapNotification, (state, {entityMap}) => {
    return notificationAdapter.mapOne(entityMap, state);
  }),
  on(NotificationActions.mapNotifications, (state, {entityMap}) => {
    return notificationAdapter.map(entityMap, state);
  }),
  on(NotificationActions.deleteNotification, (state, action) =>
    notificationAdapter.removeOne(action.id, state)
  ),
  on(NotificationActions.deleteNotifications, (state, action) =>
    notificationAdapter.removeMany(action.ids, state)
  ),
  on(NotificationActions.loadNotifications, (state, action) =>
    notificationAdapter.setAll(action.notifications, state)
  ),
  on(NotificationActions.setNotifications, (state, action) =>
    notificationAdapter.setMany(action.notifications, state)
  ),
  on(NotificationActions.clearNotifications, state =>
    notificationAdapter.removeAll(state)
  ),
  on(ngPatLogout, state => ({
    ...initialNotificationState,
    ...notificationAdapter.removeAll(state)
  })),
  on(NotificationActions.selectNotificationID, (state, action) => {
    return {
      ...state,
      selectedNotificationID: action.id
    };
  })
);
